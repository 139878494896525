/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NyckeltalvaerdeMaanadWrapperDTO,
  NyckeltalvaerdeMaanadWrapperDTOFromJSON,
  NyckeltalvaerdeMaanadWrapperDTOFromJSONTyped,
  NyckeltalvaerdeMaanadWrapperDTOToJSON,
} from './NyckeltalvaerdeMaanadWrapperDTO';

/**
 *
 * @export
 * @interface NyckeltalvaerdeMaanadBudgetWrapperDTO
 */
export interface NyckeltalvaerdeMaanadBudgetWrapperDTO {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeMaanadBudgetWrapperDTO
   */
  BudgetId?: number | null;
  /**
   *
   * @type {Array<NyckeltalvaerdeMaanadWrapperDTO>}
   * @memberof NyckeltalvaerdeMaanadBudgetWrapperDTO
   */
  NyckeltalvaerdeMaanadWrapperDTOList: Array<NyckeltalvaerdeMaanadWrapperDTO>;
}

export function NyckeltalvaerdeMaanadBudgetWrapperDTOFromJSON(json: any): NyckeltalvaerdeMaanadBudgetWrapperDTO {
  return NyckeltalvaerdeMaanadBudgetWrapperDTOFromJSONTyped(json, false);
}

export function NyckeltalvaerdeMaanadBudgetWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeMaanadBudgetWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetId: !exists(json, 'BudgetId') ? undefined : json['BudgetId'],
    NyckeltalvaerdeMaanadWrapperDTOList: (json['NyckeltalvaerdeMaanadWrapperDTOList'] as Array<any>).map(
      NyckeltalvaerdeMaanadWrapperDTOFromJSON
    ),
  };
}

export function NyckeltalvaerdeMaanadBudgetWrapperDTOToJSON(value?: NyckeltalvaerdeMaanadBudgetWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetId: value.BudgetId,
    NyckeltalvaerdeMaanadWrapperDTOList: (value.NyckeltalvaerdeMaanadWrapperDTOList as Array<any>).map(
      NyckeltalvaerdeMaanadWrapperDTOToJSON
    ),
  };
}
