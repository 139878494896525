import { addSpacesToNumber, FbFormInput, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { ErrorMessage, RemoteData, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { autosaveHoc } from 'components/autosaveHoc';
import { getBudgetStatusDomain } from 'domain/budgetStatusDomain';
import { NyckeltalDomain } from 'domain/nyckeltalDomain';
import { BudgetListDTO, BudgetnyckeltalListDTO, Enhetstyp, Typ } from 'generated-models/budgetera';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { FbGrid } from 'shared-components/grid/FbGrid';
import { ThunkAction, useAppDispatch } from 'store/store';
import { renderFormula } from 'utils/formulaUtils';
import { getEnhetText, getIsNyckeltalSpecificDate } from 'utils/nyckeltalsUtils';
import { useUpdateNyckeltalVaerde } from 'utils/useUpdateNyckeltalVaerde';
import { selectBudgetnyckeltalListWrapperQry } from '../qryBudgetnyckeltalList';
import { beraeknatNyckeltalFormelQryAction, selectBeraeknatNyckeltalFormelQry } from './qryBeraeknatNyckeltalFormel';
import { nyckeltalvaerdeQryAction } from './qryNyckeltalVaerde';
import { nyckeltalvaerdeUtfallQryAction, selectNyckeltalvaerdeUtfallQry } from './qryNyckeltalvaerdeUtfall';

interface NyckeltalInfoProps {
  nyckeltal: BudgetnyckeltalListDTO;
  budget: BudgetListDTO;
  foervaentning?: number | null;
}

export const NyckeltalInfo: React.FC<NyckeltalInfoProps> = ({ nyckeltal, budget, foervaentning }) => {
  const nyckeltalvaerdeUtfall = useSelector(
    selectNyckeltalvaerdeUtfallQry({ BudgetId: budget.BudgetPK.BudgetId!, NyckeltalId: nyckeltal.NyckeltalId })
  );
  const beraeknadeNyckeltal = useSelector(selectBeraeknatNyckeltalFormelQry(nyckeltal.NyckeltalId));
  const budgetNyckeltalList = useSelector(selectBudgetnyckeltalListWrapperQry(budget.BudgetPK));

  const [createNyckeltalVaerde, updateNyckeltalVaerde, nyckeltalVaerde] = useUpdateNyckeltalVaerde(nyckeltal);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (
      !nyckeltal.NotIncludedInBudget &&
      (RemoteDataFunctions.isNotAsked(nyckeltalVaerde) || RemoteDataFunctions.isStale(nyckeltalVaerde))
    ) {
      dispatch(
        nyckeltalvaerdeQryAction({
          BudgetId: nyckeltal.BudgetId,
          BudgetnyckeltalLoepnummer: nyckeltal.BudgetnyckeltalLoepnummer,
        })
      );
    }
  }, [nyckeltal, dispatch, nyckeltalVaerde]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(nyckeltalvaerdeUtfall)) {
      dispatch(
        nyckeltalvaerdeUtfallQryAction({
          BudgetId: nyckeltal.BudgetId,
          NyckeltalId: nyckeltal.NyckeltalId,
        })
      );
    }
  }, [dispatch, nyckeltal.BudgetId, nyckeltal.NyckeltalId, nyckeltalvaerdeUtfall]);
  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(beraeknadeNyckeltal)) {
      dispatch(beraeknatNyckeltalFormelQryAction(nyckeltal.NyckeltalId));
    }
  }, [beraeknadeNyckeltal, dispatch, nyckeltal.NyckeltalId]);

  const getNyckeltalvaerdeUtfallValue = (value: number | null | undefined) => {
    return value !== null && value !== undefined
      ? addSpacesToNumber(value.toString().replace('.', ',')) + ' ' + getEnhetText(nyckeltal.Enhet, budget.AerUtland)
      : !RemoteDataFunctions.isLoading(nyckeltalvaerdeUtfall)
      ? '-'
      : '';
  };

  const renderLeftPanelValue = (title: string, value: JSX.Element, className?: string) => {
    return (
      <>
        <span className={`pb-2 pr-2 ${className ?? ''}`}>{title}</span>
        {value}
      </>
    );
  };

  const spinnerOrElement = <T,>(
    remoteData: RemoteData<T, ErrorMessage>,
    fetch: (data: T) => JSX.Element | React.ReactText,
    className?: (data: T) => string
  ) => {
    return (
      <>
        {RemoteDataFunctions.isLoading(remoteData) && renderPanelSpinner()}
        {RemoteDataFunctions.hasData(remoteData) &&
          renderPanelValue(className ? className(remoteData.data) : '', fetch(remoteData.data))}
      </>
    );
  };

  const renderPanelSpinner = () => {
    return renderPanelValue('', <FbSpinner size="small" />);
  };

  const renderPanelValue = (className: string, content: JSX.Element | React.ReactText) => {
    return <span className={`pb-2 justify-self-end ${className ?? ''}`}>{content}</span>;
  };

  const renderBudgeteratVaerde = () => {
    if (nyckeltal.Typ === Typ.Beraeknat) {
      if (!RemoteDataFunctions.hasData(beraeknadeNyckeltal) || !RemoteDataFunctions.hasData(budgetNyckeltalList)) {
        return renderLeftPanelValue('Beräknat värde', renderPanelSpinner());
      }
      const nyckeltalCalculation = beraeknadeNyckeltal.data.NyckeltalCalculation;
      return (
        <>
          {renderLeftPanelValue(
            'Beräknat värde',
            spinnerOrElement(
              nyckeltalVaerde,
              d => addSpacesToNumber(`${d.Aarsvaerde ?? '-'} ${getEnhetText(nyckeltal.Enhet, budget.AerUtland)}`),
              () => 'pt-2'
            ),
            ' pt-2'
          )}

          {nyckeltalCalculation &&
            renderFormula(
              nyckeltalCalculation,
              budgetNyckeltalList.data.Budgetnyckeltal,
              ' -mt-2 text-fb-grey-dark-2 col-start-1 col-end-3 pb-2'
            )}
        </>
      );
    } else {
      return (
        <div className=" col-start-1 col-end-3">
          {spinnerOrElement(nyckeltalVaerde, d => {
            return autosaveHoc(FbFormInput)({
              id: nyckeltal.Kategori + nyckeltal.Subkategori + nyckeltal.Namn + 'budgeteratVaerde',
              label: 'Budgeterat värde',
              autoFocus: true,
              autoFocusDelay: 500,
              type: nyckeltal.Enhet === Enhetstyp.StyckDecimal ? 'float' : 'number',
              disabled:
                getBudgetStatusDomain(budget.BudgetStatusVO) !== 'ready' &&
                getBudgetStatusDomain(budget.BudgetStatusVO) !== 'for supplementation',
              tooltip:
                getBudgetStatusDomain(budget.BudgetStatusVO) === 'done'
                  ? 'Klarmarkerad budget kan ej redigeras'
                  : getBudgetStatusDomain(budget.BudgetStatusVO) === 'approved'
                  ? 'Godkänd budget kan ej redigeras'
                  : undefined,
              tooltipMaxwidth: 400,
              defaultValue: d.Aarsvaerde?.toString() ?? '',
              allowNegative: false,
              onSave: (val): ThunkAction | undefined => {
                val = val?.replaceAll(' ', '').replace(',', '.');
                if (val !== '') {
                  const numberval = Number(val);
                  if (numberval !== d.Aarsvaerde) {
                    if (!d.NyckeltalvaerdeId) {
                      return createNyckeltalVaerde(numberval);
                    } else {
                      return updateNyckeltalVaerde(numberval);
                    }
                  }
                } else {
                  if (d.NyckeltalvaerdeId) {
                    return updateNyckeltalVaerde(undefined);
                  }
                }
                return undefined;
              },
            });
          })}
        </div>
      );
    }
  };

  const isNyckeltalSpecificDate = getIsNyckeltalSpecificDate(nyckeltal.NyckeltalId);

  const isRenderPreviousYears = () => {
    return nyckeltal.NyckeltalId !== NyckeltalDomain.Antal.Partners;
  };

  return (
    <FbGrid
      className="p-4 text-xs-increased font-sans min-w-[20rem] w-1/4 h-max"
      displayHeaderShadow={false}
      columns={[
        {
          header: { type: 'none' },
          columnTemplate: 'auto',
        },
        {
          header: { type: 'none' },
          columnTemplate: 'auto',
        },
      ]}
      rows={[
        ...(isRenderPreviousYears()
          ? [
              {
                element: renderLeftPanelValue(
                  isNyckeltalSpecificDate
                    ? `Utfall ${budget.Kalenderaar - 3}-12-31`
                    : `Utfall år ${budget.Kalenderaar - 3}`,
                  spinnerOrElement(nyckeltalvaerdeUtfall, d => getNyckeltalvaerdeUtfallValue(d.UtfallTvaaAarSedan))
                ),
              },
              {
                element: renderLeftPanelValue(
                  isNyckeltalSpecificDate
                    ? `Utfall ${budget.Kalenderaar - 2}-12-31`
                    : `Utfall år ${budget.Kalenderaar - 2}`,
                  spinnerOrElement(nyckeltalvaerdeUtfall, d => getNyckeltalvaerdeUtfallValue(d.UtfallFoeregaaendeAar))
                ),
              },
            ]
          : []),
        {
          element: renderLeftPanelValue(
            isNyckeltalSpecificDate ? 'Aktuellt värde' : 'Senaste 12 mån',
            spinnerOrElement(nyckeltalvaerdeUtfall, d => getNyckeltalvaerdeUtfallValue(d.UtfallSenasteTolvMaanaderna))
          ),
        },
        {
          element: renderLeftPanelValue(
            `Förväntning`,
            renderPanelValue(
              '',
              foervaentning
                ? `${addSpacesToNumber(foervaentning.toString())} ${getEnhetText(nyckeltal.Enhet, budget.AerUtland)}`
                : ''
            )
          ),
        },
        {
          element: renderLeftPanelValue(
            `Förväntning diff`,
            spinnerOrElement(
              nyckeltalVaerde,
              d => (
                <>
                  {foervaentning &&
                    d.Aarsvaerde &&
                    `${addSpacesToNumber((d.Aarsvaerde - foervaentning).toString().replace('-', '- '))} ${getEnhetText(
                      nyckeltal.Enhet,
                      budget.AerUtland
                    )}`}
                </>
              ),
              d => (foervaentning && d.Aarsvaerde && d.Aarsvaerde - foervaentning < 0 ? ' text-fb-color-danger' : '')
            )
          ),
        },
        {
          element: renderBudgeteratVaerde(),
        },
        {
          element: <div></div>,
        },
      ]}
      stickyZIndex={0}
    />
  );
};
