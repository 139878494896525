import { IPublicClientApplication } from '@azure/msal-browser';
import { BudgetWrapper } from 'components/budget/BudgetWrapper';
import { Budgetaar } from 'components/Budgetaar/Budgetaar';
import { BudgetaarList } from 'components/Budgetaar/BudgetaarList';
import { HeaderWrapper } from 'components/HeaderWrapper';
import { Home } from 'components/home/Home';
import { Journal } from 'components/journal/Journal';
import { NotFound } from 'components/NotFound';
import { Unauthorized } from 'components/Unauthorized';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageComponent, selectPage } from 'store/location';

interface SwitchPageProps {
  instance: IPublicClientApplication;
}

export const SwitchPage: React.FC<SwitchPageProps> = ({ instance }) => {
  const page = useSelector(selectPage);

  return (
    <>
      {page !== PageComponent.Unauthorized && <HeaderWrapper instance={instance}></HeaderWrapper>}
      {page === PageComponent.Home && <Home />}
      {page === PageComponent.Budget && <BudgetWrapper />}
      {page === PageComponent.Budgetaar && <Budgetaar />}
      {page === PageComponent.BudgetaarList && <BudgetaarList />}
      {page === PageComponent.Journal && <Journal />}
      {page === PageComponent.NotFound && <NotFound />}
      {page === PageComponent.Unauthorized && <Unauthorized />}
    </>
  );
};
