/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EnhetVO
 */
export interface EnhetVO {
  /**
   *
   * @type {number}
   * @memberof EnhetVO
   */
  KontorId?: number | null;
  /**
   *
   * @type {number}
   * @memberof EnhetVO
   */
  KontorsgruppId?: number | null;
  /**
   *
   * @type {number}
   * @memberof EnhetVO
   */
  BostadsmarknadId?: number | null;
  /**
   *
   * @type {number}
   * @memberof EnhetVO
   */
  PlaneradBudgetId?: number | null;
  /**
   *
   * @type {number}
   * @memberof EnhetVO
   */
  PersonId?: number | null;
}

export function EnhetVOFromJSON(json: any): EnhetVO {
  return EnhetVOFromJSONTyped(json, false);
}

export function EnhetVOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnhetVO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    KontorId: !exists(json, 'KontorId') ? undefined : json['KontorId'],
    KontorsgruppId: !exists(json, 'KontorsgruppId') ? undefined : json['KontorsgruppId'],
    BostadsmarknadId: !exists(json, 'BostadsmarknadId') ? undefined : json['BostadsmarknadId'],
    PlaneradBudgetId: !exists(json, 'PlaneradBudgetId') ? undefined : json['PlaneradBudgetId'],
    PersonId: !exists(json, 'PersonId') ? undefined : json['PersonId'],
  };
}

export function EnhetVOToJSON(value?: EnhetVO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    KontorId: value.KontorId,
    KontorsgruppId: value.KontorsgruppId,
    BostadsmarknadId: value.BostadsmarknadId,
    PlaneradBudgetId: value.PlaneradBudgetId,
    PersonId: value.PersonId,
  };
}
