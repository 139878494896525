import { createWebDataSingletonList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetPK, BudgetSidedrawerNotisAendringarFinnsDTO } from 'generated-models/budgetera';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';

const ActionType = '[SidedrawerNotiser] Get SidedrawerNotiser';

const sidedrawerNotiserQry = createWebDataSingletonList<typeof ActionType, BudgetSidedrawerNotisAendringarFinnsDTO>(
  ActionType,
  'SidedrawerNotiser'
);

type SidedrawerNotiserrQryState = WebDataListState<BudgetSidedrawerNotisAendringarFinnsDTO>;

const mapSidedrawerNotiserQryState: MapState<SidedrawerNotiserrQryState> = rootState =>
  rootState.main.budget.qrySidedrawerNotiser.qrySidedrawerNotiser;

export const selectSidedrawerNotiserQry = () => (state: RootState) =>
  sidedrawerNotiserQry.get(mapSidedrawerNotiserQryState(state));

export const sidedrawerNotiserQryAction =
  (id: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = sidedrawerNotiserQry.get(mapSidedrawerNotiserQryState(getState()));
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(sidedrawerNotiserQry.updating(webData.data));
      } else {
        dispatch(sidedrawerNotiserQry.loading());
      }
      const result = await new ApiClient().SidedrawerNotiserQryApi.getSidedrawerNotiserAendringarFinns({
        budgetSidedrawerNotisAendringarDTOQry: id,
      });
      dispatch(sidedrawerNotiserQry.success(result));
    } catch (err) {
      dispatch(sidedrawerNotiserQry.failure(await parseErrorResponse(err)));
    }
  };

export default sidedrawerNotiserQry.reducer;
