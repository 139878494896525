/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NyckeltalCalculation,
  NyckeltalCalculationFromJSON,
  NyckeltalCalculationFromJSONTyped,
  NyckeltalCalculationToJSON,
} from './NyckeltalCalculation';

/**
 *
 * @export
 * @interface BeraeknadeNyckeltalListDTO
 */
export interface BeraeknadeNyckeltalListDTO {
  /**
   *
   * @type {number}
   * @memberof BeraeknadeNyckeltalListDTO
   */
  NyckeltalId: number;
  /**
   *
   * @type {Array<NyckeltalCalculation>}
   * @memberof BeraeknadeNyckeltalListDTO
   */
  BeraeknadeNyckeltalCalculationList: Array<NyckeltalCalculation>;
}

export function BeraeknadeNyckeltalListDTOFromJSON(json: any): BeraeknadeNyckeltalListDTO {
  return BeraeknadeNyckeltalListDTOFromJSONTyped(json, false);
}

export function BeraeknadeNyckeltalListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BeraeknadeNyckeltalListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: json['NyckeltalId'],
    BeraeknadeNyckeltalCalculationList: (json['BeraeknadeNyckeltalCalculationList'] as Array<any>).map(
      NyckeltalCalculationFromJSON
    ),
  };
}

export function BeraeknadeNyckeltalListDTOToJSON(value?: BeraeknadeNyckeltalListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
    BeraeknadeNyckeltalCalculationList: (value.BeraeknadeNyckeltalCalculationList as Array<any>).map(
      NyckeltalCalculationToJSON
    ),
  };
}
