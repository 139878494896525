/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetaarNyckeltalListDTO,
  BudgetaarNyckeltalListDTOFromJSON,
  BudgetaarNyckeltalListDTOFromJSONTyped,
  BudgetaarNyckeltalListDTOToJSON,
} from './BudgetaarNyckeltalListDTO';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';

/**
 *
 * @export
 * @interface BudgetaarNyckeltalListWrapperDTO
 */
export interface BudgetaarNyckeltalListWrapperDTO {
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof BudgetaarNyckeltalListWrapperDTO
   */
  BudgetperiodPK: BudgetperiodPK;
  /**
   *
   * @type {Array<BudgetaarNyckeltalListDTO>}
   * @memberof BudgetaarNyckeltalListWrapperDTO
   */
  BudgetaarNyckeltalList: Array<BudgetaarNyckeltalListDTO>;
}

export function BudgetaarNyckeltalListWrapperDTOFromJSON(json: any): BudgetaarNyckeltalListWrapperDTO {
  return BudgetaarNyckeltalListWrapperDTOFromJSONTyped(json, false);
}

export function BudgetaarNyckeltalListWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetaarNyckeltalListWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
    BudgetaarNyckeltalList: (json['BudgetaarNyckeltalList'] as Array<any>).map(BudgetaarNyckeltalListDTOFromJSON),
  };
}

export function BudgetaarNyckeltalListWrapperDTOToJSON(value?: BudgetaarNyckeltalListWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
    BudgetaarNyckeltalList: (value.BudgetaarNyckeltalList as Array<any>).map(BudgetaarNyckeltalListDTOToJSON),
  };
}
