/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { EnhetVO, EnhetVOFromJSON, EnhetVOFromJSONTyped, EnhetVOToJSON } from './EnhetVO';

/**
 *
 * @export
 * @interface BudgetEnhetPair
 */
export interface BudgetEnhetPair {
  /**
   *
   * @type {EnhetVO}
   * @memberof BudgetEnhetPair
   */
  EnhetVO: EnhetVO;
  /**
   *
   * @type {number}
   * @memberof BudgetEnhetPair
   */
  BudgetId: number;
}

export function BudgetEnhetPairFromJSON(json: any): BudgetEnhetPair {
  return BudgetEnhetPairFromJSONTyped(json, false);
}

export function BudgetEnhetPairFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetEnhetPair {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    EnhetVO: EnhetVOFromJSON(json['EnhetVO']),
    BudgetId: json['BudgetId'],
  };
}

export function BudgetEnhetPairToJSON(value?: BudgetEnhetPair | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    EnhetVO: EnhetVOToJSON(value.EnhetVO),
    BudgetId: value.BudgetId,
  };
}
