import { BudgetAnvaendareTypVO } from 'generated-models/budgetera';

export type budgetAnvaendareTypDomain = 'budgeterare' | 'godkaennare';

export const getBudgetAnvaendareTypDomain = (vo: BudgetAnvaendareTypVO): budgetAnvaendareTypDomain | null => {
  switch (vo.Typ) {
    case 1:
      return 'budgeterare';
    case 2:
      return 'godkaennare';
    default:
      return null;
  }
};
