/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  BeraeknadeNyckeltalDTOQry,
  BeraeknadeNyckeltalDTOQryFromJSON,
  BeraeknadeNyckeltalDTOQryToJSON,
  BeraeknadeNyckeltalListDTO,
  BeraeknadeNyckeltalListDTOFromJSON,
  BeraeknadeNyckeltalListDTOToJSON,
  BeraeknatNyckeltalFormelDTO,
  BeraeknatNyckeltalFormelDTOFromJSON,
  BeraeknatNyckeltalFormelDTOToJSON,
  BeraeknatNyckeltalFormelDTOQry,
  BeraeknatNyckeltalFormelDTOQryFromJSON,
  BeraeknatNyckeltalFormelDTOQryToJSON,
  NyckeltalVaerdeDTO,
  NyckeltalVaerdeDTOFromJSON,
  NyckeltalVaerdeDTOToJSON,
  NyckeltalvaerdeDTOQry,
  NyckeltalvaerdeDTOQryFromJSON,
  NyckeltalvaerdeDTOQryToJSON,
  NyckeltalvaerdeMaanadBudgetDTOQry,
  NyckeltalvaerdeMaanadBudgetDTOQryFromJSON,
  NyckeltalvaerdeMaanadBudgetDTOQryToJSON,
  NyckeltalvaerdeMaanadBudgetWrapperDTO,
  NyckeltalvaerdeMaanadBudgetWrapperDTOFromJSON,
  NyckeltalvaerdeMaanadBudgetWrapperDTOToJSON,
  NyckeltalvaerdeMaanadDTOQry,
  NyckeltalvaerdeMaanadDTOQryFromJSON,
  NyckeltalvaerdeMaanadDTOQryToJSON,
  NyckeltalvaerdeMaanadWrapperDTO,
  NyckeltalvaerdeMaanadWrapperDTOFromJSON,
  NyckeltalvaerdeMaanadWrapperDTOToJSON,
  NyckeltalvaerdeUtfallDTO,
  NyckeltalvaerdeUtfallDTOFromJSON,
  NyckeltalvaerdeUtfallDTOToJSON,
  NyckeltalvaerdeUtfallDTOQry,
  NyckeltalvaerdeUtfallDTOQryFromJSON,
  NyckeltalvaerdeUtfallDTOQryToJSON,
  NyckeltalvaerdeUtfallMaanadDTOQry,
  NyckeltalvaerdeUtfallMaanadDTOQryFromJSON,
  NyckeltalvaerdeUtfallMaanadDTOQryToJSON,
  NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry,
  NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQryFromJSON,
  NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQryToJSON,
  NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO,
  NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTOFromJSON,
  NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTOToJSON,
  NyckeltalvaerdeUtfallMaanadWrapperDTO,
  NyckeltalvaerdeUtfallMaanadWrapperDTOFromJSON,
  NyckeltalvaerdeUtfallMaanadWrapperDTOToJSON,
  NyckeltalvaerdeUtfallUnderliggandeDTOQry,
  NyckeltalvaerdeUtfallUnderliggandeDTOQryFromJSON,
  NyckeltalvaerdeUtfallUnderliggandeDTOQryToJSON,
  NyckeltalvaerdeUtfallUnderliggandeWrapperDTO,
  NyckeltalvaerdeUtfallUnderliggandeWrapperDTOFromJSON,
  NyckeltalvaerdeUtfallUnderliggandeWrapperDTOToJSON,
} from '../models';

export interface GetBeraeknadeNyckeltalRequest {
  beraeknadeNyckeltalDTOQry?: BeraeknadeNyckeltalDTOQry;
}

export interface GetBeraeknatNyckeltalFormelRequest {
  beraeknatNyckeltalFormelDTOQry?: BeraeknatNyckeltalFormelDTOQry;
}

export interface GetNyckeltalvaerdeRequest {
  nyckeltalvaerdeDTOQry?: NyckeltalvaerdeDTOQry;
}

export interface GetNyckeltalvaerdeMaanadRequest {
  nyckeltalvaerdeMaanadDTOQry?: NyckeltalvaerdeMaanadDTOQry;
}

export interface GetNyckeltalvaerdeMaanadBudgetRequest {
  nyckeltalvaerdeMaanadBudgetDTOQry?: NyckeltalvaerdeMaanadBudgetDTOQry;
}

export interface GetNyckeltalvaerdeUtfallRequest {
  nyckeltalvaerdeUtfallDTOQry?: NyckeltalvaerdeUtfallDTOQry;
}

export interface GetNyckeltalvaerdeUtfallMaanadRequest {
  nyckeltalvaerdeUtfallMaanadDTOQry?: NyckeltalvaerdeUtfallMaanadDTOQry;
}

export interface GetNyckeltalvaerdeUtfallMaanadUnderliggandeRequest {
  nyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry?: NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry;
}

export interface GetNyckeltalvaerdeUtfallUnderliggandeRequest {
  nyckeltalvaerdeUtfallUnderliggandeDTOQry?: NyckeltalvaerdeUtfallUnderliggandeDTOQry;
}

/**
 *
 */
export class NyckeltalvaerdeQryApi extends runtime.BaseAPI {
  /**
   */
  async getBeraeknadeNyckeltalRaw(
    requestParameters: GetBeraeknadeNyckeltalRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BeraeknadeNyckeltalListDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetBeraeknadeNyckeltal`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BeraeknadeNyckeltalDTOQryToJSON(requestParameters.beraeknadeNyckeltalDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BeraeknadeNyckeltalListDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBeraeknadeNyckeltal(
    requestParameters: GetBeraeknadeNyckeltalRequest = {},
    initOverrides?: RequestInit
  ): Promise<BeraeknadeNyckeltalListDTO> {
    const response = await this.getBeraeknadeNyckeltalRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getBeraeknatNyckeltalFormelRaw(
    requestParameters: GetBeraeknatNyckeltalFormelRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BeraeknatNyckeltalFormelDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetBeraeknatNyckeltalFormel`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BeraeknatNyckeltalFormelDTOQryToJSON(requestParameters.beraeknatNyckeltalFormelDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BeraeknatNyckeltalFormelDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBeraeknatNyckeltalFormel(
    requestParameters: GetBeraeknatNyckeltalFormelRequest = {},
    initOverrides?: RequestInit
  ): Promise<BeraeknatNyckeltalFormelDTO> {
    const response = await this.getBeraeknatNyckeltalFormelRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getNyckeltalvaerdeRaw(
    requestParameters: GetNyckeltalvaerdeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<NyckeltalVaerdeDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetNyckeltalvaerde`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NyckeltalvaerdeDTOQryToJSON(requestParameters.nyckeltalvaerdeDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => NyckeltalVaerdeDTOFromJSON(jsonValue));
  }

  /**
   */
  async getNyckeltalvaerde(
    requestParameters: GetNyckeltalvaerdeRequest = {},
    initOverrides?: RequestInit
  ): Promise<NyckeltalVaerdeDTO> {
    const response = await this.getNyckeltalvaerdeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getNyckeltalvaerdeMaanadRaw(
    requestParameters: GetNyckeltalvaerdeMaanadRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<NyckeltalvaerdeMaanadWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetNyckeltalvaerdeMaanad`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NyckeltalvaerdeMaanadDTOQryToJSON(requestParameters.nyckeltalvaerdeMaanadDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => NyckeltalvaerdeMaanadWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getNyckeltalvaerdeMaanad(
    requestParameters: GetNyckeltalvaerdeMaanadRequest = {},
    initOverrides?: RequestInit
  ): Promise<NyckeltalvaerdeMaanadWrapperDTO> {
    const response = await this.getNyckeltalvaerdeMaanadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getNyckeltalvaerdeMaanadBudgetRaw(
    requestParameters: GetNyckeltalvaerdeMaanadBudgetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<NyckeltalvaerdeMaanadBudgetWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetNyckeltalvaerdeMaanadBudget`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NyckeltalvaerdeMaanadBudgetDTOQryToJSON(requestParameters.nyckeltalvaerdeMaanadBudgetDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => NyckeltalvaerdeMaanadBudgetWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getNyckeltalvaerdeMaanadBudget(
    requestParameters: GetNyckeltalvaerdeMaanadBudgetRequest = {},
    initOverrides?: RequestInit
  ): Promise<NyckeltalvaerdeMaanadBudgetWrapperDTO> {
    const response = await this.getNyckeltalvaerdeMaanadBudgetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getNyckeltalvaerdeUtfallRaw(
    requestParameters: GetNyckeltalvaerdeUtfallRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<NyckeltalvaerdeUtfallDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetNyckeltalvaerdeUtfall`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NyckeltalvaerdeUtfallDTOQryToJSON(requestParameters.nyckeltalvaerdeUtfallDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => NyckeltalvaerdeUtfallDTOFromJSON(jsonValue));
  }

  /**
   */
  async getNyckeltalvaerdeUtfall(
    requestParameters: GetNyckeltalvaerdeUtfallRequest = {},
    initOverrides?: RequestInit
  ): Promise<NyckeltalvaerdeUtfallDTO> {
    const response = await this.getNyckeltalvaerdeUtfallRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getNyckeltalvaerdeUtfallMaanadRaw(
    requestParameters: GetNyckeltalvaerdeUtfallMaanadRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<NyckeltalvaerdeUtfallMaanadWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetNyckeltalvaerdeUtfallMaanad`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NyckeltalvaerdeUtfallMaanadDTOQryToJSON(requestParameters.nyckeltalvaerdeUtfallMaanadDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => NyckeltalvaerdeUtfallMaanadWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getNyckeltalvaerdeUtfallMaanad(
    requestParameters: GetNyckeltalvaerdeUtfallMaanadRequest = {},
    initOverrides?: RequestInit
  ): Promise<NyckeltalvaerdeUtfallMaanadWrapperDTO> {
    const response = await this.getNyckeltalvaerdeUtfallMaanadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getNyckeltalvaerdeUtfallMaanadUnderliggandeRaw(
    requestParameters: GetNyckeltalvaerdeUtfallMaanadUnderliggandeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetNyckeltalvaerdeUtfallMaanadUnderliggande`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQryToJSON(
          requestParameters.nyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTOFromJSON(jsonValue)
    );
  }

  /**
   */
  async getNyckeltalvaerdeUtfallMaanadUnderliggande(
    requestParameters: GetNyckeltalvaerdeUtfallMaanadUnderliggandeRequest = {},
    initOverrides?: RequestInit
  ): Promise<NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO> {
    const response = await this.getNyckeltalvaerdeUtfallMaanadUnderliggandeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getNyckeltalvaerdeUtfallUnderliggandeRaw(
    requestParameters: GetNyckeltalvaerdeUtfallUnderliggandeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<NyckeltalvaerdeUtfallUnderliggandeWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/NyckeltalvaerdeQry/GetNyckeltalvaerdeUtfallUnderliggande`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NyckeltalvaerdeUtfallUnderliggandeDTOQryToJSON(
          requestParameters.nyckeltalvaerdeUtfallUnderliggandeDTOQry
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      NyckeltalvaerdeUtfallUnderliggandeWrapperDTOFromJSON(jsonValue)
    );
  }

  /**
   */
  async getNyckeltalvaerdeUtfallUnderliggande(
    requestParameters: GetNyckeltalvaerdeUtfallUnderliggandeRequest = {},
    initOverrides?: RequestInit
  ): Promise<NyckeltalvaerdeUtfallUnderliggandeWrapperDTO> {
    const response = await this.getNyckeltalvaerdeUtfallUnderliggandeRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
