import { clickOutside, FbButton, FbExpandableTextList } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faCheck, faClose, faIndent, faLock, faLockOpen, faPencil, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBudgetAnvaendareTypDomain } from 'domain/budgetAnvaendareTypDomain';
import { BudgetStatusDomain, getBudgetStatusDomain } from 'domain/budgetStatusDomain';
import { getSummaUnderliggandeStatusDomain } from 'domain/SummaUnderliggandeStatusDomain';
import {
  BudgetAnvaendareTypVO,
  BudgetListDTO,
  BudgetnyckeltalListDTO,
  BudgetnyckeltalListWrapperDTO,
  BudgetNyckeltalVaerdeListDTO,
  SummaUnderliggandeListDTO,
} from 'generated-models/budgetera/models';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ButtonDropdown } from 'shared-components/buttonDropdown/ButtonDropdown';
import { FbPopover } from 'shared-components/fbPopover/FbPopover';
import { homeRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import { isAffaersomraade, isKontor } from 'utils/enhet';
import { aerSummaMaanaderAarsvaerde } from 'utils/summaMaanaderUtils';
import { useCommandButtonState } from 'utils/useCommandButtonState';
import { BudgetSideDrawer } from './BudgetSideDrawer';
import { removeBudgetApprovedCmd, selectSetBudgetApprovedCmd, setBudgetApprovedAction } from './cmdSetBudgetApproved';
import {
  removeBudgetComplementCmd,
  selectComplementBudgetCmd,
  setBudgetComplementAction,
} from './cmdSetBudgetComplement';
import { removeSetBudgetDoneCmd, selectSetBudgetDoneCmd, setBudgetDoneAction } from './cmdSetBudgetDone';
import { selectSetBudgetReopenedCmd, setBudgetReopenedAction } from './cmdSetBudgetReopened';
import { removeUndoBudgetDoneCmd, selectUndoBudgetDoneCmd, undoBudgetDoneAction } from './cmdUndoBudgetDone';
import { CopyBudgetPopOver } from './CopyBudgetPopOver';
import { kommentarQryAction } from './kommentar/qryKommentar';
import { selectNyckeltalvaerdeMaanadBudgetQry } from './nyckeltal/nyckeltaltabs/qryNyckeltalvaerdeMaanadBudget';
import { getUnderliggandeNamn } from './nyckeltal/underliggande';
import { foerutsaettningQryAction } from './qryBudgetFoerutsaettningar';
import { selectBudgetnyckeltalListWrapperQry } from './qryBudgetnyckeltalList';
import { selectBudgetNyckeltalVaerdeListQry } from './qryBudgetNyckeltalVaerdeList';
import { selectSidedrawerNotiserQry } from './sidedrawerNotiser/qrySidedrawerNotiser';

interface BudgetHeaderProps {
  budget: BudgetListDTO;
  anveandereTyp: BudgetAnvaendareTypVO;
  budgetNyckeltalVaerdeList: BudgetNyckeltalVaerdeListDTO;
  budgetNyckeltalList: BudgetnyckeltalListWrapperDTO;
  budgetSummaunderliggandeList: SummaUnderliggandeListDTO;
  oeverliggandeFoerutsaettningar: string | null | undefined;
}

export const BudgetHeader: React.FC<BudgetHeaderProps> = ({
  budget,
  anveandereTyp,
  budgetNyckeltalVaerdeList: budgeteratVaerdeList,
  budgetNyckeltalList,
  budgetSummaunderliggandeList,
  oeverliggandeFoerutsaettningar,
}) => {
  const dispatch = useAppDispatch();
  const openPopoverButtonRef = React.useRef<HTMLDivElement | null>(null);
  const {
    ref: popoverConfirmRef,
    isComponentVisible: popoverConfirmOpen,
    setIsComponentVisible: setPopoverConfirmOpen,
  } = clickOutside(false, [openPopoverButtonRef]);
  const {
    ref: popoverApproveRef,
    isComponentVisible: popoverApproveOpen,
    setIsComponentVisible: setPopoverApproveOpen,
  } = clickOutside(false, [openPopoverButtonRef]);
  const {
    ref: popoverReopenRef,
    isComponentVisible: popoverReopenOpen,
    setIsComponentVisible: setPopoverReopenOpen,
  } = clickOutside(false, [openPopoverButtonRef]);
  const {
    ref: popoverComplementRef,
    isComponentVisible: popoverComplementOpen,
    setIsComponentVisible: setPopoverComplementOpen,
  } = clickOutside(false, [openPopoverButtonRef]);
  const setBudgetDoneCmd = useSelector(selectSetBudgetDoneCmd);
  const undoBudgetDoneCmd = useSelector(selectUndoBudgetDoneCmd);
  const setBudgetApprovedCmd = useSelector(selectSetBudgetApprovedCmd);
  const setBudgetReopenedCmd = useSelector(selectSetBudgetReopenedCmd(budget.BudgetPK));
  const setBudgetComplementCmd = useSelector(selectComplementBudgetCmd);
  const buttonState = useCommandButtonState(
    setBudgetDoneCmd,
    undoBudgetDoneCmd,
    setBudgetApprovedCmd,
    setBudgetReopenedCmd,
    setBudgetComplementCmd
  );

  const isButtonDisabled = () => {
    return (
      getBudgetStatusDomain(budget.BudgetStatusVO) === 'approved' &&
      getBudgetAnvaendareTypDomain(anveandereTyp) === 'budgeterare'
    );
  };

  const [aendringarFinns, setAendringarFinns] = React.useState<boolean>(false);
  const [previousButtonText, setPreviousButtonText] = React.useState('');

  const [nyckeltalSomInteStaemmer, setNyckeltalSomInteStaemmer] = React.useState<BudgetnyckeltalListDTO[]>([]);
  const nyckeltalvaerdeMaanadList = useSelector(selectNyckeltalvaerdeMaanadBudgetQry());
  const budgetnyckeltalList = useSelector(selectBudgetnyckeltalListWrapperQry(budget.BudgetPK));
  const budgetNyckeltalVaerdeList = useSelector(selectBudgetNyckeltalVaerdeListQry(budget.BudgetPK));
  const finnsSidedrawerAendringar = useSelector(selectSidedrawerNotiserQry());

  const [nyckeltalSaknarBudgeteratAarsvaerde, setNyckeltalSaknarBudgeteratAarsvaerde] = React.useState<string[]>([]);
  const [nyckeltalOverstigerUnderliggandeSumma, setNyckeltalOverstigerUnderliggandeSumma] = React.useState<string[]>(
    []
  );
  const [NyckeltalUnderFoervaentning, setNyckeltalUnderFoervaentning] = React.useState<string[]>([]);

  const iconRef = React.useRef(null) as React.MutableRefObject<any | null>;

  const buttonText = () => {
    if (previousButtonText) {
      return previousButtonText;
    }

    return getButtonTextForStatus(getBudgetStatusDomain(budget.BudgetStatusVO));
  };

  const getButtonTextForStatus = (budgetStatusDomain: BudgetStatusDomain | null) => {
    switch (budgetStatusDomain) {
      case 'ready':
      case 'for supplementation':
      case 'created':
        return 'Klarmarkera';
      case 'done':
        if (getBudgetAnvaendareTypDomain(anveandereTyp) === 'budgeterare') {
          return 'Avklarmarkera';
        } else {
          return 'Godkänn';
        }
      case 'approved':
        return 'Återöppna';
    }
    return '';
  };

  const {
    ref: sideDrawerRef,
    isComponentVisible: isSideDrawerOpen,
    setIsComponentVisible: setIsSideDrawerOpen,
  } = clickOutside(false, [iconRef]);

  React.useEffect(() => {
    if (RemoteDataFunctions.hasData(finnsSidedrawerAendringar)) {
      setAendringarFinns(finnsSidedrawerAendringar.data.AendringarFinns || false);
    }
  }, [finnsSidedrawerAendringar]);

  React.useEffect(() => {
    // Because of batch handling of states in React 18 we can't be too quick
    // setting the state back to default, we need to give FbButton a few milliseconds to
    // update its internal state. Hence the timeout.
    if (CommandFunctions.isSuccess(setBudgetDoneCmd)) {
      setTimeout(() => {
        dispatch(removeSetBudgetDoneCmd());
      }, 100);
    }
    if (CommandFunctions.isSuccess(undoBudgetDoneCmd)) {
      setTimeout(() => {
        dispatch(removeUndoBudgetDoneCmd());
      }, 100);
    }
    if (CommandFunctions.isSuccess(setBudgetApprovedCmd)) {
      setTimeout(() => {
        dispatch(removeBudgetApprovedCmd());
      }, 100);
    }
    if (CommandFunctions.isSuccess(setBudgetComplementCmd)) {
      setTimeout(() => {
        dispatch(removeBudgetComplementCmd());
      }, 100);
    }
  }, [setBudgetDoneCmd, undoBudgetDoneCmd, setBudgetApprovedCmd, setBudgetComplementCmd, dispatch]);

  React.useEffect(() => {
    if (isSideDrawerOpen) {
      dispatch(kommentarQryAction(budget.BudgetPK));
      dispatch(foerutsaettningQryAction(budget.BudgetPK));
      setAendringarFinns(false);
    }
  }, [budget.BudgetPK, dispatch, isSideDrawerOpen, setAendringarFinns]);

  React.useEffect(() => {
    const budgetNyckeltalAngivet = budgetNyckeltalList.Budgetnyckeltal.filter(
      budgetNyckeltal => budgetNyckeltal.Typ === 'Angivet'
    );
    const budgetNyckeltalVaerde = budgetNyckeltalAngivet
      .map(budgetNyckeltal => {
        const budgetVaerde = budgeteratVaerdeList.BudgetNyckeltalVaerdeList.find(
          budgetVaerde =>
            budgetVaerde.NyckeltalId === budgetNyckeltal.NyckeltalId && budgetVaerde.Aarsvaerde === undefined
        );
        if (budgetVaerde !== undefined) {
          if (budgetNyckeltal.Subkategori === undefined) return `${budgetNyckeltal.Namn}`;
          return `${budgetNyckeltal.Subkategori} / ${budgetNyckeltal.Namn}`;
        }
        return '';
      })
      .filter(namn => !!namn);
    setNyckeltalSaknarBudgeteratAarsvaerde(budgetNyckeltalVaerde);
  }, [budgetNyckeltalList, budgeteratVaerdeList]);

  const setBudgetDone = async () => {
    if (!CommandFunctions.isExecuting(setBudgetDoneCmd)) {
      setPreviousButtonText(getButtonTextForStatus(getBudgetStatusDomain(budget.BudgetStatusVO)));
      dispatch(
        setBudgetDoneAction({
          BudgetPK: budget.BudgetPK,
        })
      );
    }
    setPopoverConfirmOpen(false);
  };

  const undoBudgetDone = async () => {
    if (!CommandFunctions.isExecuting(undoBudgetDoneCmd)) {
      setPreviousButtonText(getButtonTextForStatus(getBudgetStatusDomain(budget.BudgetStatusVO)));
      dispatch(
        undoBudgetDoneAction({
          PrimaryKey: budget.BudgetPK,
        })
      );
    }
  };

  const budgetApprove = async () => {
    if (!CommandFunctions.isExecuting(setBudgetApprovedCmd)) {
      setPreviousButtonText(getButtonTextForStatus(getBudgetStatusDomain(budget.BudgetStatusVO)));
      dispatch(
        setBudgetApprovedAction({
          PrimaryKey: budget.BudgetPK,
        })
      );
    }
    setPopoverApproveOpen(false);
  };

  const budgetReopen = async () => {
    if (!CommandFunctions.isExecuting(setBudgetReopenedCmd)) {
      setPreviousButtonText(getButtonTextForStatus(getBudgetStatusDomain(budget.BudgetStatusVO)));
      dispatch(
        setBudgetReopenedAction({
          BudgetPK: budget.BudgetPK,
        })
      );
    }
    setPopoverReopenOpen(false);
  };

  const budgetComplement = async () => {
    if (!CommandFunctions.isExecuting(setBudgetComplementCmd)) {
      setPreviousButtonText(getButtonTextForStatus(getBudgetStatusDomain(budget.BudgetStatusVO)));
      setPopoverComplementOpen(false);
      dispatch(
        setBudgetComplementAction({
          PrimaryKey: budget.BudgetPK,
        })
      );
    }
  };

  React.useEffect(() => {
    const nyckeltalSomOverstigerUnderliggandeSumma = budgeteratVaerdeList.BudgetNyckeltalVaerdeList.filter(
      budgeteratVaerde => {
        const summaUnderliggandeVaerde = budgetSummaunderliggandeList.SummaUnderliggandeList.find(
          summaUnderliggande => summaUnderliggande.NyckeltalId === budgeteratVaerde.NyckeltalId
        );
        if (
          summaUnderliggandeVaerde?.Summa &&
          getSummaUnderliggandeStatusDomain(summaUnderliggandeVaerde.Status) !== 'should not summeras' &&
          budgeteratVaerde.Aarsvaerde
        )
          return budgeteratVaerde.Aarsvaerde > summaUnderliggandeVaerde.Summa;
        return false;
      }
    );

    const budgetNyckeltalVaerde = budgetNyckeltalList.Budgetnyckeltal.map(budgetNyckeltal => {
      const budgetVaerde = nyckeltalSomOverstigerUnderliggandeSumma.find(
        nyckeltal => budgetNyckeltal.NyckeltalId === nyckeltal.NyckeltalId
      );
      if (budgetVaerde !== undefined) {
        if (budgetNyckeltal.Subkategori === undefined) return `${budgetNyckeltal.Namn}`;
        return `${budgetNyckeltal.Subkategori} / ${budgetNyckeltal.Namn}`;
      }
      return '';
    }).filter(namn => !!namn);
    setNyckeltalOverstigerUnderliggandeSumma(budgetNyckeltalVaerde);
  }, [budgetNyckeltalList, budgetSummaunderliggandeList, budgeteratVaerdeList]);

  React.useEffect(() => {
    const nyckeltalUnderFoervaentning = budgeteratVaerdeList.BudgetNyckeltalVaerdeList.filter(budgeteratVaerde => {
      if (budgeteratVaerde.Aarsvaerde != null && budgeteratVaerde.Foervaentning != null) {
        return budgeteratVaerde.Aarsvaerde < budgeteratVaerde.Foervaentning;
      }
      return false;
    });

    const nyckeltalNamn = budgetNyckeltalList.Budgetnyckeltal.map(budgetNyckeltal => {
      const budgetVaerde = nyckeltalUnderFoervaentning.find(
        nyckeltal => budgetNyckeltal.NyckeltalId === nyckeltal.NyckeltalId
      );
      if (budgetVaerde !== undefined) {
        if (budgetNyckeltal.Subkategori === undefined) return `${budgetNyckeltal.Namn}`;
        return `${budgetNyckeltal.Subkategori} / ${budgetNyckeltal.Namn}`;
      }
      return '';
    }).filter(namn => !!namn);

    setNyckeltalUnderFoervaentning(nyckeltalNamn);
  }, [budgetNyckeltalList.Budgetnyckeltal, budgeteratVaerdeList.BudgetNyckeltalVaerdeList]);

  React.useEffect(() => {
    if (
      RemoteDataFunctions.hasData(nyckeltalvaerdeMaanadList) &&
      RemoteDataFunctions.hasData(budgetnyckeltalList) &&
      RemoteDataFunctions.hasData(budgetNyckeltalVaerdeList)
    ) {
      const felaktigaNyckeltal: BudgetnyckeltalListDTO[] = [];
      budgetnyckeltalList.data.Budgetnyckeltal.forEach(bn => {
        const nyckeltalAarsvaerde = budgetNyckeltalVaerdeList.data.BudgetNyckeltalVaerdeList.find(
          bnv => bnv.NyckeltalId === bn.NyckeltalId
        );
        if (nyckeltalAarsvaerde && nyckeltalAarsvaerde.Aarsvaerde) {
          const nyckeltalvaerdeMaanadWrapper = nyckeltalvaerdeMaanadList.data.NyckeltalvaerdeMaanadWrapperDTOList.find(
            v => v.NyckeltalVaerdePK.NyckeltalvaerdeId === nyckeltalAarsvaerde?.NyckeltalvaerdeId
          );
          if (nyckeltalvaerdeMaanadWrapper) {
            if (
              !aerSummaMaanaderAarsvaerde(
                nyckeltalAarsvaerde.Aarsvaerde,
                nyckeltalvaerdeMaanadWrapper.NyckeltalvaerdeMaanadDTOList
              )
            ) {
              felaktigaNyckeltal.push(bn);
            }
          }
        }
      });
      setNyckeltalSomInteStaemmer(felaktigaNyckeltal);
    }
  }, [nyckeltalvaerdeMaanadList, budgetnyckeltalList, budgetNyckeltalVaerdeList]);

  const getNyckeltalSomBehoverKompletteras = () => {
    return (
      <>
        {nyckeltalSaknarBudgeteratAarsvaerde.length > 0 && (
          <FbExpandableTextList
            listTitle={`${nyckeltalSaknarBudgeteratAarsvaerde.length} nyckeltal saknar budgeterat årsvärde`}
            content={
              <div>
                <ul className="list-disc pl-4">
                  {nyckeltalSaknarBudgeteratAarsvaerde.map(nyckeltal => (
                    <li key={nyckeltal}>{nyckeltal}</li>
                  ))}
                </ul>
              </div>
            }
          />
        )}
        {nyckeltalOverstigerUnderliggandeSumma.length > 0 && (
          <FbExpandableTextList
            listTitle={`${
              nyckeltalOverstigerUnderliggandeSumma.length
            } budgeterade värden överstiger s:a ${getUnderliggandeNamn(budget, false).toLocaleLowerCase()}`}
            content={
              <div>
                <ul className="list-disc pl-4">
                  {nyckeltalOverstigerUnderliggandeSumma.map(nyckeltal => (
                    <li key={nyckeltal}>{nyckeltal}</li>
                  ))}
                </ul>
              </div>
            }
          />
        )}
        {NyckeltalUnderFoervaentning.length > 0 && (
          <FbExpandableTextList
            listTitle={`${NyckeltalUnderFoervaentning.length} budgeterade värden lever inte upp till förväntningarna`}
            content={
              <div>
                <ul className="list-disc pl-4">
                  {NyckeltalUnderFoervaentning.map(nyckeltal => (
                    <li key={nyckeltal}>{nyckeltal}</li>
                  ))}
                </ul>
              </div>
            }
          />
        )}
      </>
    );
  };

  const renderConfirmPopover = () => {
    if (nyckeltalSomInteStaemmer.length > 0) {
      return (
        <FbPopover
          header={'Du har inte möjlighet att klarmarkera budgeten'}
          popoverSize={'medium'}
          showPopover={popoverConfirmOpen}
          popoverRef={popoverConfirmRef}
        >
          <div className="flex flex-row text-xs-increased mb-4 border border-fb-color-danger bg-fb-color-danger/20 rounded p-4 text-fb-color-typography">
            <FontAwesomeIcon icon={faWarning} className="text-fb-color-danger pr-4 mt-1" />
            Summa budgeterade månadsvärden stämmer inte överens med budgeterat årsvärde.
          </div>
          <div style={{ maxHeight: 'calc(100vh - 250px)' }} className="overflow-y-auto custom-scroll">
            <FbExpandableTextList
              listTitle={`${nyckeltalSomInteStaemmer.length} nyckeltal stämmer inte överens`}
              content={
                <div>
                  <ul className="list-disc pl-4">
                    {nyckeltalSomInteStaemmer.map(nyckeltal => {
                      const namn = (nyckeltal.Subkategori ? nyckeltal.Subkategori + ' / ' : '') + nyckeltal.Namn;
                      return <li key={namn}>{namn}</li>;
                    })}
                  </ul>
                </div>
              }
            />
          </div>
          <div className="flex justify-end pt-2">
            <FbButton className="mr-4" text={'Ok'} type={'primary'} onClick={() => setPopoverConfirmOpen(false)} />
          </div>
        </FbPopover>
      );
    }

    return (
      <FbPopover
        header={'Är du säker på att du vill fortsätta?'}
        popoverSize={'medium'}
        showPopover={popoverConfirmOpen}
        popoverRef={popoverConfirmRef}
        className="mr-4"
      >
        <p className="text-xs-increased mb-4">Din budget kommer nu att gå vidare för godkännande.</p>
        <div style={{ maxHeight: 'calc(100vh - 250px)' }} className="overflow-y-auto custom-scroll">
          {getNyckeltalSomBehoverKompletteras()}
        </div>
        <div className="flex justify-end pt-2">
          <FbButton className="mr-4" text={'Avbryt'} type={'secondary'} onClick={() => setPopoverConfirmOpen(false)} />
          <FbButton
            text={'Ja, klarmarkera'}
            type={'primary'}
            onClick={() => {
              setBudgetDone();
            }}
          />
        </div>
      </FbPopover>
    );
  };

  const renderComplementPopover = () => {
    return popover(
      popoverComplementOpen,
      ' mr-4',
      'Budgeten kommer nu att skickas för komplettering.',
      'Ja, begär komplettering',
      e => {
        e.stopPropagation();
        setPopoverComplementOpen(false);
      },
      () => budgetComplement(),
      popoverComplementRef
    );
  };

  const renderApprovePopover = () => {
    return popover(
      popoverApproveOpen,
      ' -mr-4',
      'När du godkänner budgeten kommer den att bli låst och budgetansvarig meddelad.',
      'Ja, godkänn',
      () => setPopoverApproveOpen(false),
      () => budgetApprove(),
      popoverApproveRef
    );
  };

  const renderReopenPopover = () => {
    return popover(
      popoverReopenOpen,
      ' mr-4',
      'Budgeten kommer öppnas upp för redigering. Tidigare inlagda siffror ligger kvar samt sparas undan som historik.',
      'Ja, återöppna',
      () => setPopoverReopenOpen(false),
      () => budgetReopen(),
      popoverReopenRef
    );
  };

  const popover = (
    open: boolean,
    className: string,
    text: string,
    confirmText: string,
    onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    ref: React.MutableRefObject<HTMLDivElement | null>
  ) => {
    return (
      <FbPopover
        header="Är du säker på att du vill fortsätta?"
        popoverSize="medium"
        className={className}
        showPopover={open}
        popoverRef={ref}
      >
        <p className="text-xs-increased mb-4">{text}</p>
        <div className="flex justify-end pt-2">
          <FbButton className="mr-4" text="Avbryt" type="secondary" onClick={onCancel} />
          <FbButton text={confirmText} type="primary" onClick={onConfirm} />
        </div>
      </FbPopover>
    );
  };

  const getButtonIcon = () => {
    switch (buttonText()) {
      case 'Klarmarkera':
        return faCheck;
      case 'Avklarmarkera':
        return faPencil;
      case 'Godkänn':
        return faLock;
      case 'Återöppna':
        return faLockOpen;
    }
    return faCheck;
  };

  return (
    <>
      <div className="sticky z-[100] pl-3 flex justify-between flex-row h-10 text-lg bg-fb-color-blue-light before:content-[''] before:bg-fb-color-primary before:w-1 before:absolute before:top-0 before:left-0 before:h-10">
        <div className="flex flex-col justify-center h-full font-semibold">
          {`Budget ${budget.Namn}${budget.AerInaktivEnhet ? ' (Inaktiv)' : ''}${budget.AerNyEnhet ? ' (Ny)' : ''}${
            budget.EnhetVO.PlaneradBudgetId ? ' (Planerad)' : ''
          } ${budget.Kalenderaar}`}
        </div>
        <div className="flex flex-row">
          <FbButton
            text="Stäng budget"
            icon={faClose}
            type="main-header-secondary"
            onClick={() => dispatch(homeRoute())}
          />
          {budget.EnhetVO.PlaneradBudgetId && budget.EnhetVO.KontorId && (
            <CopyBudgetPopOver
              kontorId={budget.EnhetVO.KontorId}
              budgetPK={budget.BudgetPK}
              budgetPeriodPK={budget.BudgetperiodPK}
            />
          )}
          <div className="relative" ref={openPopoverButtonRef}>
            <FbButton
              text={buttonText()}
              icon={getButtonIcon()}
              type="main-header-primary"
              onClick={() => {
                const budgetStatus = getBudgetStatusDomain(budget.BudgetStatusVO);
                if (budgetStatus === 'ready' || budgetStatus === 'for supplementation') {
                  setPopoverConfirmOpen(!popoverConfirmOpen);
                } else if (budgetStatus === 'done' && getBudgetAnvaendareTypDomain(anveandereTyp) === 'budgeterare') {
                  undoBudgetDone();
                } else if (budgetStatus === 'done' && getBudgetAnvaendareTypDomain(anveandereTyp) === 'godkaennare') {
                  setPopoverApproveOpen(!popoverApproveOpen);
                } else if (budgetStatus === 'approved') {
                  setPopoverReopenOpen(!popoverReopenOpen);
                }
              }}
              buttonState={buttonState}
              onSuccessOrFailureTimeoutCallback={() => {
                setPreviousButtonText('');
              }}
              disabled={isButtonDisabled()}
              tooltipText={isButtonDisabled() ? 'Budgeten kan endast återöppnas av den som godkänt budgeten.' : ''}
              tooltipPosition="below"
              className="peer"
            />
            {renderConfirmPopover()}
            {renderApprovePopover()}
            {renderReopenPopover()}
          </div>
          {getBudgetAnvaendareTypDomain(anveandereTyp) !== 'budgeterare' &&
            getBudgetStatusDomain(budget.BudgetStatusVO) === 'done' &&
            !previousButtonText && (
              <ButtonDropdown
                disableHoverOutside={popoverComplementOpen}
                items={[
                  {
                    text: 'Begär komplettering',
                    onClick: () => {
                      setPopoverComplementOpen(true);
                    },
                    popover: renderComplementPopover(),
                  },
                  {
                    text: 'Avklarmarkera',
                    onClick: () => {
                      undoBudgetDone();
                    },
                  },
                ]}
              />
            )}
          <div
            className="relative w-9 border-r border-r-fb-color-grey-medium-dark cursor-pointer flex items-center justify-center"
            onClick={() => {
              setIsSideDrawerOpen(!isSideDrawerOpen);
            }}
            ref={iconRef}
          >
            <FontAwesomeIcon size="sm" icon={faIndent} />
            {aendringarFinns && !isSideDrawerOpen && (
              <span className="absolute top-0 right-0 rounded-full bg-fb-color-orange w-2 h-2 mt-2 mr-2"></span>
            )}
          </div>
        </div>
      </div>
      {
        <BudgetSideDrawer
          sideDrawerRef={sideDrawerRef}
          open={isSideDrawerOpen}
          isAffaersomraade={isAffaersomraade(budget.EnhetVO)}
          isKontor={isKontor(budget.EnhetVO)}
          budgetPK={budget.BudgetPK}
        ></BudgetSideDrawer>
      }
    </>
  );
};
