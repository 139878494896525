import { BudgetnyckeltalListDTO, NyckeltalCalculation, Operator } from 'generated-models/budgetera';

const getOperator = (operator: Operator) => {
  switch (operator) {
    case Operator.Addition:
      return '+';
    case Operator.Multiplication:
      return '×';
    case Operator.Division:
      return '∕';
    default:
      return '';
  }
};

const getNyckeltalNamn = (budgetNyckeltal: BudgetnyckeltalListDTO): string => {
  return budgetNyckeltal.Namn + ' ' + (budgetNyckeltal.Subkategori ? budgetNyckeltal.Subkategori : '');
};

const getFormulaTextAddMultiply = (usedInCalcs: BudgetnyckeltalListDTO[]) => {
  return (
    '(' +
    usedInCalcs.reduce((p, c, i) => {
      return (
        p +
        getNyckeltalNamn(c) +
        (i === usedInCalcs.length - 2 ? ') × ' : '') +
        (i + 2 < usedInCalcs.length ? ' + ' : '')
      );
    }, '')
  );
};

export const renderFormula = (
  nyckeltal: NyckeltalCalculation,
  budgetNyckeltal: BudgetnyckeltalListDTO[],
  className?: string
) => {
  const usedInCalcs = nyckeltal.NyckeltalIncludedInCalcList.map(
    n => budgetNyckeltal.find(b => b.NyckeltalId === n.NyckeltalId)!
  ).filter(c => c !== undefined);
  return (
    <span className={`text-xs ${className ?? ''}`}>
      Formel:{' '}
      <span className="inline-block">
        {nyckeltal.Operator === Operator.AddMultiplty
          ? getFormulaTextAddMultiply(usedInCalcs)
          : usedInCalcs.map(
              (c, i) =>
                getNyckeltalNamn(c) + (i + 1 < usedInCalcs.length ? ' ' + getOperator(nyckeltal.Operator) + ' ' : '')
            )}
      </span>
    </span>
  );
};
