import { faCircleArrowDown, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ChangeSorteringCellProps {
  text: string;
  upDisabled: boolean;
  downDisabled: boolean;
  index: number;
  onClick: (up: boolean, index: number) => void;
}

export const ChangeSorteringCell: React.FC<ChangeSorteringCellProps> = ({
  text,
  upDisabled,
  downDisabled,
  index,
  onClick,
}) => {
  return (
    <div className="flex group -m-2 p-2">
      <div>{text}</div>
      <FontAwesomeIcon
        className={
          'ml-auto mr-1 invisible group-hover:visible self-center ' +
          (upDisabled
            ? 'text-fb-color-grey-dark hover:cursor-default'
            : 'text-fb-color-primary/50 hover:text-fb-color-primary')
        }
        icon={faCircleArrowUp}
        onClick={e => {
          if (upDisabled) {
            e.stopPropagation();
            return;
          }
          onClick(true, index);
          e.stopPropagation();
        }}
      />
      <FontAwesomeIcon
        className={
          'invisible group-hover:visible self-center ' +
          (downDisabled
            ? 'text-fb-color-grey-dark hover:cursor-default'
            : 'text-fb-color-primary/50 hover:text-fb-color-primary')
        }
        icon={faCircleArrowDown}
        onClick={e => {
          if (downDisabled) {
            e.stopPropagation();
            return;
          }
          onClick(false, index);
          e.stopPropagation();
        }}
      />
    </div>
  );
};
