import { getNyckeltalstypDomain } from 'domain/nyckeltalstypDomain';
import { NyckeltalstypVO, Undersektion } from 'generated-models/budgetera';

interface BudgetaarNyckeltalFrekvensTableProps {
  Utland: boolean;
  Undersektion: Undersektion;
}

export const BudgetaarNyckeltalFrekvensTable: React.FC<BudgetaarNyckeltalFrekvensTableProps> = ({
  Utland,
  Undersektion,
}) => {
  const cellWidth = ['w-32', 'w-28', 'w-20', 'w-20', 'w-32', 'w-28', 'w-20', 'w-28'];

  const getCellText = (NyckeltalstypVO: NyckeltalstypVO, frekvens: string) => {
    let result = '';

    if (frekvens === '-') return '-';

    if (getNyckeltalstypDomain(NyckeltalstypVO) === 'anges') {
      result += 'Anges';
    } else if (getNyckeltalstypDomain(NyckeltalstypVO) === 'beraknas') {
      result += 'Beräknas';
    }

    if (frekvens === 'År') {
      result += ' (Å)';
    } else if (frekvens === 'Månad') {
      result += ' (M)';
    }

    return result;
  };

  return (
    <table className="ml-4 table-fixed border border-fb-color-grey-table-border fb-paragraph-lvl-2 w-1/3">
      {Undersektion.NyckeltalList.map((nycketal, nyckeltalIndex) => (
        <tbody key={nyckeltalIndex} className="odd:bg-fb-color-grey-superlight">
          <tr>
            {nycketal.FrekvensList.map((frekvens, frekvensIndex) =>
              Utland
                ? frekvensIndex >= 4 && (
                    <td
                      className={
                        'pl-2 py-2 border border-fb-color-grey-table-border h-8' +
                        (getNyckeltalstypDomain(nycketal.NyckeltalstypVO) === 'beraknas' || frekvens === '-'
                          ? ' italic text-fb-grey-dark-2'
                          : '') +
                        (' ' + cellWidth[frekvensIndex])
                      }
                      key={frekvensIndex}
                    >
                      {getCellText(nycketal.NyckeltalstypVO, frekvens)}
                    </td>
                  )
                : frekvensIndex < 4 && (
                    <td
                      className={
                        'pl-2 py-2 border border-fb-color-grey-table-border h-8' +
                        (getNyckeltalstypDomain(nycketal.NyckeltalstypVO) === 'beraknas' || frekvens === '-'
                          ? ' italic text-fb-grey-dark-2'
                          : '') +
                        (' ' + cellWidth[frekvensIndex])
                      }
                      key={frekvensIndex}
                    >
                      {getCellText(nycketal.NyckeltalstypVO, frekvens)}
                    </td>
                  )
            )}
          </tr>
        </tbody>
      ))}
    </table>
  );
};
