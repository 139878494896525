import { FbButton, FbButtonProps, FbButtonState, FbFormTextarea } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BudgetPK, KommentarPK } from 'generated-models/budgetera';
import React from 'react';
import { useSelector } from 'react-redux';
import { SideDrawer } from 'shared-components/sidedrawer/SideDrawer';
import { useAppDispatch } from 'store/store';
import {
  removeBudgetFoerutsaettningarCmdAction,
  selectUpdateBudgetFoerutsaettningarCmd,
  updateBudgetFoerutsaettningarCmdAction,
} from './cmdUpdateBudgetFoerutsaettningar';
import {
  createKommentarAction,
  removeKommentarCmdAction,
  selectCreateKommentarCmd,
} from './kommentar/cmdCreateKommentar';
import { deleteKommentarAction, selectDeleteKommentarCmd } from './kommentar/cmdDeleteKommentar';
import { kommentarQryAction, selectKommentarQry } from './kommentar/qryKommentar';
import { foerutsaettningQryAction, selectFoerutsaettningQry } from './qryBudgetFoerutsaettningar';
import { createOrUpdateSidedrawerNotiserAction } from './sidedrawerNotiser/cmdCreateOrUpdateSidedrawerNotiser';

interface BudgetSideDrawerProps {
  readonly sideDrawerRef: React.MutableRefObject<any>;
  readonly open: boolean;
  readonly isAffaersomraade: boolean;
  readonly isKontor: boolean;
  readonly budgetPK: BudgetPK;
}

export const BudgetSideDrawer: React.FC<BudgetSideDrawerProps> = ({
  sideDrawerRef,
  open,
  isAffaersomraade,
  isKontor,
  budgetPK,
}) => {
  const dispatch = useAppDispatch();

  const [foerutsaettningarInputText, setFoerutsaettningarInputText] = React.useState<string>('');
  const [isSparaButtonDisabled, setSparaButtonDisabled] = React.useState<boolean>(true);
  const [isAvbrytButtonDisabled, setAvbrytButtonDisabled] = React.useState<boolean>(true);
  const [isLaeggaTillButtonDisabled, setIsLaeggaTillButtonDisabled] = React.useState<boolean>(true);
  const [isAvbrytKommentarButtonDisabled, setAvbrytKommentarButtonDisabled] = React.useState<boolean>(true);
  const [sparaButtonState, setSparaButtonState] = React.useState<FbButtonState>('default');
  const [laeggaTillButtonState, setLaeggaTillButtonState] = React.useState<FbButtonState>('default');
  const [textKey, setTextKey] = React.useState<number>(0);
  const [kommentarTextKey, setkommentarTextKey] = React.useState<number>(0);
  const [kommentarInputText, setKommentarInputText] = React.useState<string>('');

  const updateBudgetFoerutsaettningarCmd = useSelector(selectUpdateBudgetFoerutsaettningarCmd);
  const laeggaTillKommentarCmd = useSelector(selectCreateKommentarCmd);
  const taBortKommentarCmd = useSelector(selectDeleteKommentarCmd);
  const kommentarList = useSelector(selectKommentarQry(budgetPK));
  const foerutsaettning = useSelector(selectFoerutsaettningQry(budgetPK));

  React.useEffect(() => {
    if (CommandFunctions.isExecuting(updateBudgetFoerutsaettningarCmd)) {
      setSparaButtonState('waiting');
    } else if (CommandFunctions.isSuccess(updateBudgetFoerutsaettningarCmd)) {
      setSparaButtonState('success');
      removeBudgetFoerutsaettningarCmdAction();
      if (budgetPK.BudgetId) {
        dispatch(
          createOrUpdateSidedrawerNotiserAction({
            BudgetId: budgetPK.BudgetId,
          })
        );
      }
    } else if (CommandFunctions.isFailure(updateBudgetFoerutsaettningarCmd)) {
      setSparaButtonState('failure');
    }
  }, [budgetPK.BudgetId, dispatch, updateBudgetFoerutsaettningarCmd]);

  React.useEffect(() => {
    if (open && budgetPK.BudgetId) {
      dispatch(
        createOrUpdateSidedrawerNotiserAction({
          BudgetId: budgetPK.BudgetId,
        })
      );
    }
  }, [budgetPK.BudgetId, dispatch, open]);

  React.useEffect(() => {
    if (CommandFunctions.isExecuting(laeggaTillKommentarCmd)) {
      setLaeggaTillButtonState('waiting');
    } else if (CommandFunctions.isSuccess(laeggaTillKommentarCmd)) {
      setLaeggaTillButtonState('success');
      removeKommentarCmdAction();
      if (budgetPK.BudgetId) {
        dispatch(
          createOrUpdateSidedrawerNotiserAction({
            BudgetId: budgetPK.BudgetId,
          })
        );
      }
    } else if (CommandFunctions.isFailure(laeggaTillKommentarCmd)) {
      setLaeggaTillButtonState('failure');
    }
  }, [budgetPK.BudgetId, dispatch, laeggaTillKommentarCmd]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(kommentarList) || RemoteDataFunctions.isStale(kommentarList)) {
      dispatch(kommentarQryAction(budgetPK));
    }
  }, [kommentarList, dispatch, budgetPK]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(foerutsaettning) || RemoteDataFunctions.isStale(foerutsaettning)) {
      dispatch(foerutsaettningQryAction(budgetPK));
    }
  }, [foerutsaettning, dispatch, budgetPK]);

  React.useEffect(() => {
    if (sparaButtonState === 'failure' || sparaButtonState === 'success') {
      setSparaButtonState('default');
    }
  }, [sparaButtonState]);

  React.useEffect(() => {
    if (laeggaTillButtonState === 'failure' || laeggaTillButtonState === 'success') {
      setLaeggaTillButtonState('default');
    }
  }, [laeggaTillButtonState, sparaButtonState]);

  const getSparaButtonProps = (): FbButtonProps => {
    switch (sparaButtonState) {
      case 'default':
        return {
          text: 'Spara',
          type: 'primary',
          buttonState: sparaButtonState,
          successOrFailureTimeout: 1500,
          disabled: isSparaButtonDisabled,
          onClick: () => {
            updateBudgetFoerutsaettningar();
            setSparaButtonDisabled(true);
            setAvbrytButtonDisabled(true);
          },
        };
      case 'waiting':
      default:
        return {
          text: 'Spara',
          type: 'primary',
          buttonState: sparaButtonState,
          successOrFailureTimeout: 1500,
        };
      case 'success':
        return {
          text: 'Sparat',
          type: 'primary',
          buttonState: sparaButtonState,
          successOrFailureTimeout: 1500,
          disabled: false,
        };
      case 'failure':
        return {
          text: 'Sparning misslyckades',
          type: 'primary',
          buttonState: sparaButtonState,
          successOrFailureTimeout: 1500,
          disabled: false,
        };
    }
  };

  const getLaeggaTillButtonProps = (): FbButtonProps => {
    switch (laeggaTillButtonState) {
      case 'default':
        return {
          text: 'Lägg till',
          type: 'primary',
          buttonState: laeggaTillButtonState,
          disabled: isLaeggaTillButtonDisabled,
          successOrFailureTimeout: 1500,
          onClick: () => {
            laeggaTillKommentar();
            resetKommentarTextArea();
            setIsLaeggaTillButtonDisabled(true);
            setAvbrytKommentarButtonDisabled(true);
          },
        };
      case 'waiting':
      default:
        return {
          text: 'Lägg till',
          type: 'primary',
          buttonState: laeggaTillButtonState,
          successOrFailureTimeout: 1500,
        };
      case 'success':
        return {
          text: 'Lagt till',
          type: 'primary',
          buttonState: laeggaTillButtonState,
          successOrFailureTimeout: 1500,
          disabled: false,
        };
      case 'failure':
        return {
          text: 'Lägga till misslyckades',
          type: 'primary',
          buttonState: laeggaTillButtonState,
          successOrFailureTimeout: 1500,
          disabled: false,
        };
    }
  };

  const updateBudgetFoerutsaettningar = async () => {
    if (!CommandFunctions.isExecuting(updateBudgetFoerutsaettningarCmd)) {
      dispatch(
        updateBudgetFoerutsaettningarCmdAction({
          PrimaryKey: budgetPK,
          Foerutsaettningar: foerutsaettningarInputText,
        })
      );
    }
  };

  const laeggaTillKommentar = async () => {
    if (!CommandFunctions.isExecuting(laeggaTillKommentarCmd)) {
      dispatch(
        createKommentarAction({
          BudgetId: budgetPK.BudgetId!,
          Text: kommentarInputText,
        })
      );
    }
  };

  const taBortKommentar = async (kommentarId: KommentarPK) => {
    if (!CommandFunctions.isExecuting(taBortKommentarCmd)) {
      dispatch(
        deleteKommentarAction({
          PrimaryKey: kommentarId,
        })
      );
    }
  };

  const resetTextArea = () => {
    setTextKey(textKey + 1);
  };

  const resetKommentarTextArea = () => {
    setkommentarTextKey(kommentarTextKey + 1);
  };

  return (
    <SideDrawer topMargin={80} open={open} sideDrawerRef={sideDrawerRef} positionRight={true} className={'w-96'}>
      {(isAffaersomraade || isKontor) && RemoteDataFunctions.hasData(foerutsaettning) && (
        <div className="p-4">
          <h4 className="pb-4">Förutsättningar för budgetåret</h4>
          <FbFormTextarea
            key={textKey}
            defaultValue={foerutsaettning.data.Foerutsaettningar}
            label={'Förutsättningar'}
            id={'1'}
            maxLength={400}
            elastic={true}
            disabled={!isAffaersomraade}
            onInstantChange={e => {
              setFoerutsaettningarInputText(e);
              setSparaButtonDisabled(false);
              setAvbrytButtonDisabled(false);
            }}
          ></FbFormTextarea>
          <div className="flex justify-end gap-4 mt-6">
            <FbButton
              key={textKey}
              text={'Avbryt'}
              type={'secondary'}
              disabled={isAvbrytButtonDisabled}
              onClick={() => {
                resetTextArea();
                setSparaButtonDisabled(true);
                setAvbrytButtonDisabled(true);
              }}
            ></FbButton>
            <FbButton {...getSparaButtonProps()}></FbButton>
          </div>
        </div>
      )}

      <div className="p-4">
        <h4 className="mb-4">Kommentarer</h4>
        {RemoteDataFunctions.hasData(kommentarList) && (
          <div className="border border-fb-color-grey-table-border max-h-80 overflow-y-scroll custom-scroll flex flex-col-reverse">
            <ul>
              {kommentarList.data.KommentarDTOList.map(kommentar => (
                <li
                  key={kommentar.KommentarId}
                  className="relative p-2 even:bg-fb-color-grey-superlight hover:bg-fb-color-blue-superlight group"
                >
                  <div className="text-xs-increased italic text-fb-color-grey-dark-compliment">
                    {kommentar.Personnamn}{' '}
                    {kommentar.Datum.toLocaleDateString('sv-SE', { hour: '2-digit', minute: '2-digit' })}
                  </div>
                  <div className="text-xs-increased">{kommentar.Text}</div>
                  {kommentar.FaarTasBort && (
                    <div className="absolute right-0 top-0 p-2 hidden group-hover:block">
                      <div
                        className="border border-x-fb-color-grey-dark-compliment flex justify-center items-center p-2 bg-fb-color-secondary cursor-pointer"
                        onClick={() => {
                          taBortKommentar(kommentar.KommentarPK);
                        }}
                      >
                        <FontAwesomeIcon className="w-3 h-3" icon={faTrashCan}></FontAwesomeIcon>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
        <FbFormTextarea
          key={kommentarTextKey}
          label={'Ny kommentar'}
          id={'2'}
          onInstantChange={e => {
            setKommentarInputText(e);
            setIsLaeggaTillButtonDisabled(false);
            setAvbrytKommentarButtonDisabled(false);
          }}
        />
        <div className="flex justify-end gap-4 mt-6">
          <FbButton
            text={'Avbryt'}
            type={'secondary'}
            disabled={isAvbrytKommentarButtonDisabled}
            onClick={() => {
              resetKommentarTextArea();
            }}
          />
          <FbButton {...getLaeggaTillButtonProps()} />
        </div>
      </div>
    </SideDrawer>
  );
};
