import React, { useEffect } from 'react';

export interface FbPopoverProps {
  header: string;
  className?: string;
  popoverSize: 'small' | 'medium';
  footer?: JSX.Element;
  showPopover: boolean;
  popoverRef?: React.MutableRefObject<any>;
}

export const FbPopover: React.FC<React.PropsWithChildren<FbPopoverProps>> = ({
  header,
  className,
  children,
  popoverSize,
  footer,
  showPopover,
  popoverRef,
}) => {
  const [animationStyles, setAnimationStyles] = React.useState('scale-0');

  useEffect(() => {
    if (showPopover) {
      setAnimationStyles('scale-100');
    } else {
      setAnimationStyles('scale-0');
    }
  }, [showPopover]);

  const onPopoverSize = () => {
    switch (popoverSize) {
      case 'small':
        return ' w-80';
      case 'medium':
        return ' w-[480px]';
      default:
        return '';
    }
  };
  return (
    <div
      ref={popoverRef}
      className={`${animationStyles} transition-all duration-300 absolute z-50 top-10 translate-x-full right-1/2 w-1/2 `}
    >
      <div
        className="absolute w-full 
      after:rotate-45 after:content-[''] 
      after:absolute after:-top-2 after:h-4 after:w-4 
      after:pointer-events-none after:border-t after:border-l 
      after:bg-fb-color-grey-light after:border-fb-color-grey-medium-dark"
        style={{ top: '2px', right: '1px' }}
      >
        <div
          data-testid="fb-popover-test-id"
          className={`flex flex-col shadow-md border border-fb-color-grey-medium-dark rounded-sm absolute right-0 ${className} ${onPopoverSize()} bg-fb-color-white`}
        >
          <div className="bg-fb-color-grey-light border-b border-b-fb-color-grey-medium-dark rounded-t-sm">
            <div className="px-4 py-2 text-sm">{header}</div>
          </div>
          <div className="bg-fb-color-white p-4 overflow-y-auto text-fb-color-grey-light border-b">{children}</div>
          {footer && <div className="m-4">{footer}</div>}
        </div>
      </div>
    </div>
  );
};
