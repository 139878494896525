/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Nyckeltal, NyckeltalFromJSON, NyckeltalFromJSONTyped, NyckeltalToJSON } from './Nyckeltal';

/**
 *
 * @export
 * @interface Undersektion
 */
export interface Undersektion {
  /**
   *
   * @type {string}
   * @memberof Undersektion
   */
  Undersektionnamn?: string | null;
  /**
   *
   * @type {Array<Nyckeltal>}
   * @memberof Undersektion
   */
  NyckeltalList: Array<Nyckeltal>;
}

export function UndersektionFromJSON(json: any): Undersektion {
  return UndersektionFromJSONTyped(json, false);
}

export function UndersektionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Undersektion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Undersektionnamn: !exists(json, 'Undersektionnamn') ? undefined : json['Undersektionnamn'],
    NyckeltalList: (json['NyckeltalList'] as Array<any>).map(NyckeltalFromJSON),
  };
}

export function UndersektionToJSON(value?: Undersektion | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Undersektionnamn: value.Undersektionnamn,
    NyckeltalList: (value.NyckeltalList as Array<any>).map(NyckeltalToJSON),
  };
}
