import { CommandFunctions, createWebCommandList, SdKeys } from '@decernointernal/websd.shared';
import { BudgetPK, ReopenBudgetSrvcCmd, SdDbOperation } from 'generated-models/budgetera/models';
import { budgetRoute } from 'store/location';
import { ClearBudgetActionType, RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionType = '[Budget] set budget reopened';

const setBudgetReopenedCmd = createWebCommandList<typeof ActionType, ReopenBudgetSrvcCmd, BudgetPK>(
  ActionType,
  v => v.BudgetPK
);

export const selectSetBudgetReopenedCmd = (pk: BudgetPK) => (state: RootState) =>
  setBudgetReopenedCmd.get(state.main.budget.cmdSetBudgetReopened, pk);

export const setBudgetReopenedAction =
  (cmd: ReopenBudgetSrvcCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectSetBudgetReopenedCmd(cmd.BudgetPK)(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(setBudgetReopenedCmd.executing(cmd));

      const result = await new ApiClient().BudgetCmdApi.setBudgetReopened({
        reopenBudgetSrvcCmd: cmd,
      });

      // We don't need to mark the command as success, as we will move the user
      // to the new budget. We will not dispatch any integration events either,
      // as we'd rather fetch a whole new budget from scratch instead.

      for (const r of result) {
        if (r.EventDataType === 'Budget' && r.Operation === SdDbOperation.Insert) {
          window.history.replaceState(window.history.state, '', '/');
          dispatch(budgetRoute(SdKeys.keyToValue<BudgetPK>(r.EventDataKey)));
          dispatch({ type: ClearBudgetActionType });
          break;
        }
      }
    } catch (err) {
      dispatch(setBudgetReopenedCmd.failure(cmd, await parseErrorResponse(err)));
    }
  };

export default setBudgetReopenedCmd.reducer;
