import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { UpdateDeadlineCmd } from 'generated-models/budgetera';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[Deadline] update deadline värde';

const updateDeadlineCmd = createWebCommandSingletonList<typeof ActionType, UpdateDeadlineCmd>(ActionType, ActionType);

export const selectUpdateDeadlineCmd = (state: RootState) => updateDeadlineCmd.get(state.Budgetaar.updateDeadlineCmd);

export const updateDeadlineAction =
  (cmd: UpdateDeadlineCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectUpdateDeadlineCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(updateDeadlineCmd.executing());

      const result = await new ApiClient().BudgetperiodCmdApi.updateDeadline({
        updateDeadlineCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for a command
      dispatch(updateDeadlineCmd.success(cmd));
      //Remove command from commandList
      dispatch(updateDeadlineCmd.remove());
    } catch (err) {
      dispatch(updateDeadlineCmd.failure(await parseErrorResponse(err)));
    }
  };

export default updateDeadlineCmd.reducer;
