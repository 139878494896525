import { CurrentEnhetState } from 'components/user/uiCurrentEnhet';
import { BudgetListDTO } from 'generated-models/budgetera/models/BudgetListDTO';

export const getUnderliggandeNamn = (budget: BudgetListDTO, singular: boolean) => {
  if (budget.EnhetVO.BostadsmarknadId !== undefined) {
    return singular ? 'Affärsområde' : 'Affärsområden';
  }

  if (budget.EnhetVO.KontorsgruppId !== undefined) {
    return 'Kontor';
  }

  if (budget.EnhetVO.KontorId !== undefined) {
    if (budget.AerUtland) {
      return 'Säljare/Intagare';
    }
    return 'Mäklare';
  }

  return '';
};

export const getUnderliggandeNamnBudgetList = (current: CurrentEnhetState) => {
  //  Just nu returnerar vi alltid Mäklarnas och inte Säljarnas/Intagarnas
  if (current.currentEnhet?.BostadsmarknadId !== undefined) {
    return 'Affärsområdena';
  }

  if (current.currentEnhet?.KontorsgruppId !== undefined) {
    return 'Kontoren';
  }

  if (current.currentEnhet?.KontorId !== undefined) {
    return 'Mäklarna';
  }

  return '';
};

export const getUnderliggandeNamnBudgetVyn = (budget: BudgetListDTO) => {
  //  Just nu returnerar vi alltid Mäklarnas och inte Säljarnas/Intagarnas
  if (budget.EnhetVO?.BostadsmarknadId !== undefined) {
    return 'Affärsområdena';
  }

  if (budget.EnhetVO?.KontorsgruppId !== undefined) {
    return 'Kontoren';
  }

  if (budget.EnhetVO?.KontorId !== undefined) {
    return 'Mäklarna';
  }

  return '';
};
