import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { CopyBudgetSrvcCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Budget] copy budget';

const copyBudgetCmd = createWebCommandSingletonList<typeof ActionType, CopyBudgetSrvcCmd>(ActionType, ActionType);

export const selectCopyBudgetCmd = (state: RootState) => copyBudgetCmd.get(state.main.budget.qryCopyBudget);

export const copyBudgetAction =
  (cmd: CopyBudgetSrvcCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectCopyBudgetCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(copyBudgetCmd.executing());

      const result = await new ApiClient().BudgetCmdApi.copyBudget({
        copyBudgetSrvcCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(copyBudgetCmd.success(cmd));
      // Remove command from commandList
      dispatch(copyBudgetCmd.remove());
    } catch (err) {
      dispatch(copyBudgetCmd.failure(await parseErrorResponse(err)));
    }
  };

export default copyBudgetCmd.reducer;
