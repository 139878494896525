import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { UndoBudgetDoneCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Budget] undo budget done';

const undoBudgetDoneCmd = createWebCommandSingletonList<typeof ActionType, UndoBudgetDoneCmd>(ActionType, ActionType);

export const selectUndoBudgetDoneCmd = (state: RootState) => undoBudgetDoneCmd.get(state.main.budget.qryUndoBudgetDone);

export const undoBudgetDoneAction =
  (cmd: UndoBudgetDoneCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectUndoBudgetDoneCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(undoBudgetDoneCmd.executing());

      const result = await new ApiClient().BudgetCmdApi.undoBudgetDone({
        undoBudgetDoneCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(undoBudgetDoneCmd.success(cmd));
    } catch (err) {
      dispatch(undoBudgetDoneCmd.failure(await parseErrorResponse(err)));
    }
  };

export const removeUndoBudgetDoneCmd = (): ThunkAction => async dispatch => {
  // Remove command from commandList
  dispatch(undoBudgetDoneCmd.remove());
};

export default undoBudgetDoneCmd.reducer;
