/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  JournalListDTOQry,
  JournalListDTOQryFromJSON,
  JournalListDTOQryToJSON,
  JournalListWrapperDTO,
  JournalListWrapperDTOFromJSON,
  JournalListWrapperDTOToJSON,
  JournalTypDTO,
  JournalTypDTOFromJSON,
  JournalTypDTOToJSON,
} from '../models';

export interface GetJournalListRequest {
  journalListDTOQry?: JournalListDTOQry;
}

/**
 *
 */
export class JournalQryApi extends runtime.BaseAPI {
  /**
   */
  async getJournalListRaw(
    requestParameters: GetJournalListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<JournalListWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/JournalQry/GetJournalList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: JournalListDTOQryToJSON(requestParameters.journalListDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => JournalListWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getJournalList(
    requestParameters: GetJournalListRequest = {},
    initOverrides?: RequestInit
  ): Promise<JournalListWrapperDTO> {
    const response = await this.getJournalListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getJournalTypRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<JournalTypDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/JournalQry/GetJournalTyp`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(JournalTypDTOFromJSON));
  }

  /**
   */
  async getJournalTyp(initOverrides?: RequestInit): Promise<Array<JournalTypDTO>> {
    const response = await this.getJournalTypRaw(initOverrides);
    return await response.value();
  }
}
