/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetnyckeltalPK,
  BudgetnyckeltalPKFromJSON,
  BudgetnyckeltalPKFromJSONTyped,
  BudgetnyckeltalPKToJSON,
} from './BudgetnyckeltalPK';

/**
 *
 * @export
 * @interface BudgetNyckeltalFoervaentning
 */
export interface BudgetNyckeltalFoervaentning {
  /**
   *
   * @type {BudgetnyckeltalPK}
   * @memberof BudgetNyckeltalFoervaentning
   */
  BudgetNyckeltalPK?: BudgetnyckeltalPK;
  /**
   *
   * @type {number}
   * @memberof BudgetNyckeltalFoervaentning
   */
  FoervaentningVaerde?: number | null;
}

export function BudgetNyckeltalFoervaentningFromJSON(json: any): BudgetNyckeltalFoervaentning {
  return BudgetNyckeltalFoervaentningFromJSONTyped(json, false);
}

export function BudgetNyckeltalFoervaentningFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetNyckeltalFoervaentning {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetNyckeltalPK: !exists(json, 'BudgetNyckeltalPK')
      ? undefined
      : BudgetnyckeltalPKFromJSON(json['BudgetNyckeltalPK']),
    FoervaentningVaerde: !exists(json, 'FoervaentningVaerde') ? undefined : json['FoervaentningVaerde'],
  };
}

export function BudgetNyckeltalFoervaentningToJSON(value?: BudgetNyckeltalFoervaentning | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetNyckeltalPK: BudgetnyckeltalPKToJSON(value.BudgetNyckeltalPK),
    FoervaentningVaerde: value.FoervaentningVaerde,
  };
}
