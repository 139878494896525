import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { UnderliggandeEnheterVaerdeListDTO } from 'generated-models/budgetera';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';

const ActionType = '[budget] Get underliggandeEnheterVaerde';

export interface UnderliggandeEnheterVaerdeIdentifier {
  BudgetId: number;
  NyckeltalId: number;
}
// Slice for fetching budget for the selected enhet
const underliggandeEnheterVaerdeQry = createWebDataList<
  typeof ActionType,
  UnderliggandeEnheterVaerdeListDTO,
  UnderliggandeEnheterVaerdeIdentifier
>(ActionType, k => {
  return { BudgetId: k.BudgetPK.BudgetId!, NyckeltalId: k.NyckeltalId };
});

type UnderliggandeEnheterVaerdeQryState = WebDataListState<UnderliggandeEnheterVaerdeListDTO>;

const mapUnderliggandeEnheterVaerdeQryState: MapState<UnderliggandeEnheterVaerdeQryState> = rootState =>
  rootState.main.budget.qryUnderliggandeEnheterVaerde;

export const selectUnderliggandeEnheterVaerdeQry = (id: UnderliggandeEnheterVaerdeIdentifier) => (state: RootState) =>
  underliggandeEnheterVaerdeQry.get(mapUnderliggandeEnheterVaerdeQryState(state), id);

export const underliggandeEnheterVaerdeQryAction =
  (id: UnderliggandeEnheterVaerdeIdentifier, budgetIdList: number[]): ThunkAction =>
  async (dispatch, getState) => {
    const webData = underliggandeEnheterVaerdeQry.get(mapUnderliggandeEnheterVaerdeQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(underliggandeEnheterVaerdeQry.updating(webData.data));
      } else {
        dispatch(underliggandeEnheterVaerdeQry.loading(id));
      }
      const result = await new ApiClient().BudgetQryApi.getUnderliggandeEnheterVaerde({
        underliggandeEnheterVaerdeDTOQry: {
          BudgetIdList: budgetIdList,
          HuvudBudgetId: id.BudgetId,
          NyckeltalId: id.NyckeltalId,
        },
      });
      if (RemoteDataFunctions.hasData(webData)) {
        webData.data.UnderliggandeEnhetVaerde.forEach(u => {
          const current = result.UnderliggandeEnhetVaerde.find(
            e =>
              e.BudgetnyckeltalPK.BudgetId === u.BudgetnyckeltalPK.BudgetId &&
              e.BudgetnyckeltalPK.BudgetnyckeltalLoepnummer === u.BudgetnyckeltalPK.BudgetnyckeltalLoepnummer
          );
          if (current) {
            u.BudgeteratVaerde = current.BudgeteratVaerde;
            u.FoervaentningVaerde = current.FoervaentningVaerde;
            u.Omsaettningskrav = current.Omsaettningskrav;
            u.SkattadMarknadandel = current.SkattadMarknadandel;
          }
        });
        dispatch(underliggandeEnheterVaerdeQry.success(webData.data));
      } else {
        dispatch(underliggandeEnheterVaerdeQry.success(result));
      }
    } catch (err) {
      dispatch(underliggandeEnheterVaerdeQry.failure(id, await parseErrorResponse(err)));
    }
  };

export default underliggandeEnheterVaerdeQry.reducer;
