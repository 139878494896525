import { combineReducers } from 'redux';
import createJournalCmd from './cmdCreateJournal';
import updateJournalCmd from './cmdUpdateJournal';
import { journalListQryReducer } from './qryJournalList';
import { journalTypQryReducer } from './qryJournalTyp';

export const journalReducer = combineReducers({
  qryJournalList: journalListQryReducer,
  qryJournalTyp: journalTypQryReducer,
  createJournalCmd,
  updateJournalCmd,
});
