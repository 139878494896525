import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { CreateOrUpdateBudgetSidedrawerNotiserSrvcCmd } from 'generated-models/budgetera';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[sidedrawerNotiser] create or update sidedrawerNotiser';

const createOrUpdateSidedrawerNotiserCmd = createWebCommandSingletonList<
  typeof ActionType,
  CreateOrUpdateBudgetSidedrawerNotiserSrvcCmd
>(ActionType, ActionType);

export const selectCreateOrUpdateSidedrawerNotiserCmd = (state: RootState) =>
  createOrUpdateSidedrawerNotiserCmd.get(state.main.budget.qrySidedrawerNotiser.qryCreateOrUpdateSidedrawerNotiser);

export const createOrUpdateSidedrawerNotiserAction =
  (cmd: CreateOrUpdateBudgetSidedrawerNotiserSrvcCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectCreateOrUpdateSidedrawerNotiserCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(createOrUpdateSidedrawerNotiserCmd.executing());

      const result = await new ApiClient().SidedrawerNotiserCmdApi.createOrUpdateBudgetSidedrawerNotiser({
        createOrUpdateBudgetSidedrawerNotiserSrvcCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(createOrUpdateSidedrawerNotiserCmd.success(cmd));
      // Remove command from commandList
      dispatch(createOrUpdateSidedrawerNotiserCmd.remove());
    } catch (err) {
      dispatch(createOrUpdateSidedrawerNotiserCmd.failure(await parseErrorResponse(err)));
    }
  };

export default createOrUpdateSidedrawerNotiserCmd.reducer;
