/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum Enhetstyp {
  Styck = 'Styck',
  Procent = 'Procent',
  Valuta = 'Valuta',
  StyckDecimal = 'StyckDecimal',
}

export function EnhetstypFromJSON(json: any): Enhetstyp {
  return EnhetstypFromJSONTyped(json, false);
}

export function EnhetstypFromJSONTyped(json: any, ignoreDiscriminator: boolean): Enhetstyp {
  return json as Enhetstyp;
}

export function EnhetstypToJSON(value?: Enhetstyp | null): any {
  return value as any;
}
