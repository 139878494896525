/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { JournalPK, JournalPKFromJSON, JournalPKFromJSONTyped, JournalPKToJSON } from './JournalPK';

/**
 *
 * @export
 * @interface JournalListDTO
 */
export interface JournalListDTO {
  /**
   *
   * @type {JournalPK}
   * @memberof JournalListDTO
   */
  JournalPK: JournalPK;
  /**
   *
   * @type {number}
   * @memberof JournalListDTO
   */
  KontorId: number;
  /**
   *
   * @type {number}
   * @memberof JournalListDTO
   */
  JournalTypId: number;
  /**
   *
   * @type {string}
   * @memberof JournalListDTO
   */
  Beskrivning: string;
  /**
   *
   * @type {Date}
   * @memberof JournalListDTO
   */
  SkapadDatum: Date;
  /**
   *
   * @type {Date}
   * @memberof JournalListDTO
   */
  SenastAndradDatum?: Date | null;
  /**
   *
   * @type {string}
   * @memberof JournalListDTO
   */
  JournalTypNamn: string;
}

export function JournalListDTOFromJSON(json: any): JournalListDTO {
  return JournalListDTOFromJSONTyped(json, false);
}

export function JournalListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): JournalListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    JournalPK: JournalPKFromJSON(json['JournalPK']),
    KontorId: json['KontorId'],
    JournalTypId: json['JournalTypId'],
    Beskrivning: json['Beskrivning'],
    SkapadDatum: new Date(json['SkapadDatum']),
    SenastAndradDatum: !exists(json, 'SenastAndradDatum')
      ? undefined
      : json['SenastAndradDatum'] === null
      ? null
      : new Date(json['SenastAndradDatum']),
    JournalTypNamn: json['JournalTypNamn'],
  };
}

export function JournalListDTOToJSON(value?: JournalListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    JournalPK: JournalPKToJSON(value.JournalPK),
    KontorId: value.KontorId,
    JournalTypId: value.JournalTypId,
    Beskrivning: value.Beskrivning,
    SkapadDatum: value.SkapadDatum.toISOString(),
    SenastAndradDatum:
      value.SenastAndradDatum === undefined
        ? undefined
        : value.SenastAndradDatum === null
        ? null
        : value.SenastAndradDatum.toISOString(),
    JournalTypNamn: value.JournalTypNamn,
  };
}
