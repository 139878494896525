/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NyckeltalstypVO,
  NyckeltalstypVOFromJSON,
  NyckeltalstypVOFromJSONTyped,
  NyckeltalstypVOToJSON,
} from './NyckeltalstypVO';

/**
 *
 * @export
 * @interface Nyckeltal
 */
export interface Nyckeltal {
  /**
   *
   * @type {number}
   * @memberof Nyckeltal
   */
  NyckeltalId: number;
  /**
   *
   * @type {string}
   * @memberof Nyckeltal
   */
  Nyckeltalnamn: string;
  /**
   *
   * @type {string}
   * @memberof Nyckeltal
   */
  Enhet: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Nyckeltal
   */
  FrekvensList: Array<string>;
  /**
   *
   * @type {NyckeltalstypVO}
   * @memberof Nyckeltal
   */
  NyckeltalstypVO: NyckeltalstypVO;
}

export function NyckeltalFromJSON(json: any): Nyckeltal {
  return NyckeltalFromJSONTyped(json, false);
}

export function NyckeltalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Nyckeltal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: json['NyckeltalId'],
    Nyckeltalnamn: json['Nyckeltalnamn'],
    Enhet: json['Enhet'],
    FrekvensList: json['FrekvensList'],
    NyckeltalstypVO: NyckeltalstypVOFromJSON(json['NyckeltalstypVO']),
  };
}

export function NyckeltalToJSON(value?: Nyckeltal | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
    Nyckeltalnamn: value.Nyckeltalnamn,
    Enhet: value.Enhet,
    FrekvensList: value.FrekvensList,
    NyckeltalstypVO: NyckeltalstypVOToJSON(value.NyckeltalstypVO),
  };
}
