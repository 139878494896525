/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  BudgetperiodStatusDTOQry,
  BudgetperiodStatusDTOQryFromJSON,
  BudgetperiodStatusDTOQryToJSON,
  BudgetperiodStatusWrapperDTO,
  BudgetperiodStatusWrapperDTOFromJSON,
  BudgetperiodStatusWrapperDTOToJSON,
} from '../models';

export interface GetBudgetperiodStatusRequest {
  budgetperiodStatusDTOQry?: BudgetperiodStatusDTOQry;
}

/**
 *
 */
export class BudgetperiodQryApi extends runtime.BaseAPI {
  /**
   */
  async getBudgetperiodStatusRaw(
    requestParameters: GetBudgetperiodStatusRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetperiodStatusWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetperiodQry/GetBudgetperiodStatus`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetperiodStatusDTOQryToJSON(requestParameters.budgetperiodStatusDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BudgetperiodStatusWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBudgetperiodStatus(
    requestParameters: GetBudgetperiodStatusRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetperiodStatusWrapperDTO> {
    const response = await this.getBudgetperiodStatusRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
