import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { CreateBudgetWithPlannedBudgetSrvcCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[budget] create planned budget';

const createPlannedBudgetCmd = createWebCommandSingletonList<typeof ActionType, CreateBudgetWithPlannedBudgetSrvcCmd>(
  ActionType,
  ActionType
);

export const selectCreatePlannedCmd = (state: RootState) =>
  createPlannedBudgetCmd.get(state.main.home.budgetList.cmdCreatePlanned);

export const createPlannedAction =
  (cmd: CreateBudgetWithPlannedBudgetSrvcCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectCreatePlannedCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(createPlannedBudgetCmd.executing());

      const result = await new ApiClient().BudgetCmdApi.createPlannedBudget({
        createBudgetWithPlannedBudgetSrvcCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(createPlannedBudgetCmd.success(cmd));
      // Remove command from commandList
      dispatch(createPlannedBudgetCmd.remove());
    } catch (err) {
      dispatch(createPlannedBudgetCmd.failure(await parseErrorResponse(err)));
    }
  };

export default createPlannedBudgetCmd.reducer;
