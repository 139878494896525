export const NyckeltalDomain = {
  Smaahus: {
    Totalmarknad: 1,
    AntalAffaerer: 2,
    AntalIntag: 3,
    Marknadsandel: 4,
    Snittprovision: 5,
    Provisionsintaekt: 6,
    IntagsfoersoekPerFoersaeljning: 7,
    IntagsfoersoekPerIntag: 8,
    AntalIntagsfoersoek: 9,
    AntalIntagsfoersoekUtland: 10,
    VisningarPerFoersaeljning: 11,
    AntalVisningar: 12,
  },
  BR: {
    Totalmarknad: 13,
    AntalAffaerer: 14,
    AntalIntag: 15,
    Marknadsandel: 16,
    Snittprovision: 17,
    Provisionsintaekt: 18,
    IntagsfoersoekPerFoersaeljning: 19,
    IntagsfoersoekPerIntag: 20,
    AntalIntagsfoersoek: 21,
    AntalIntagsfoersoekUtland: 22,
    VisningarPerFoersaeljning: 23,
    AntalVisningar: 24,
  },
  Nyprod: {
    Totalmarknad: 25,
    AntalAffaerer: 26,
    AntalIntag: 27,
    Marknadsandel: 28,
    Snittprovision: 29,
    Provisionsintaekt: 30,
    // IntagsfoersoekPerFoersaeljningNyprod with id 31 is currently not used
    // IntagsfoersoekPerIntagNyprod with id 32 is currently not used
    // AntalIntagsfoersoekNyprod with id 33 is currently not used
    // AntalIntagsfoersoekUtlandNyprod with id 34 is currently not used
    VisningarPerFoersaeljning: 35,
    AntalVisningar: 36,
  },
  Oevrig: {
    AntalAffaerer: 37,
    Provisionsintaekt: 38,
    AntalIntagsfoersoek: 39,
  },
  Skrivningar: {
    Antal: 40,
    Snittprovision: 41,
    Provisionsintaekt: 42,
  },
  Vaerderingar: {
    AntalSkriftliga: 43,
    Snittprovision: 44,
    Provisionsintaekt: 45,
    AntalIntagsfoersoek: 46,
  },
  Totalt: {
    Totalmarknad: 48,
    AntalAffaerer: 49,
    AntalIntag: 50,
    Provisionsintaekt: 51,
    AntalIntagsfoersoek: 52,
    AntalIntagsfoersoekUtland: 53,
    AntalVisningar: 54,
  },
  Bank: {
    AntalSkickadeLeads: 55,
    AntalMottagnaLeads: 56,
    Finansieringsgrad: 57,
    Bolaanevolym: 58,
  },
  Antal: {
    Medarbetare: 59,
    Maeklare: 60,
    Nyrekryterade: 61,
    Partners: 62,
    Underkonsulter: 63,
  },
};
