import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { BeraeknadeNyckeltalListDTO } from 'generated-models/budgetera/models';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';

const ActionType = '[Nyckeltal] Get beräknade nyckeltal';

// Slice for fetching budget for the selected enhet
const beraeknadeNyckeltalQry = createWebDataList<typeof ActionType, BeraeknadeNyckeltalListDTO, number>(
  ActionType,
  k => k.NyckeltalId
);

type BeraeknadeNyckeltalQryState = WebDataListState<BeraeknadeNyckeltalListDTO>;

const mapBeraeknadeNyckeltalQryState: MapState<BeraeknadeNyckeltalQryState> = rootState =>
  rootState.main.nyckeltalVaerde.qryBeraeknadeNyckeltal;

export const selectBeraeknadeNyckeltalQry = (id: number) => (state: RootState) =>
  beraeknadeNyckeltalQry.get(mapBeraeknadeNyckeltalQryState(state), id);

export const beraeknadeNyckeltalQryAction =
  (id: number): ThunkAction =>
  async (dispatch, getState) => {
    const webData = beraeknadeNyckeltalQry.get(mapBeraeknadeNyckeltalQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(beraeknadeNyckeltalQry.updating(webData.data));
      } else {
        dispatch(beraeknadeNyckeltalQry.loading(id));
      }
      const result = await new ApiClient().NyckeltalvaerdeQryApi.getBeraeknadeNyckeltal({
        beraeknadeNyckeltalDTOQry: { NyckeltalId: id },
      });
      dispatch(beraeknadeNyckeltalQry.success(result));
    } catch (err) {
      dispatch(beraeknadeNyckeltalQry.failure(id, await parseErrorResponse(err)));
    }
  };

export default beraeknadeNyckeltalQry.reducer;
