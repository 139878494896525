import {
  dateToAAAAMMDD,
  FbButton,
  FbSpinner,
  FbTable,
  FbTableCell,
  FbTableColDef,
  FbTableRow,
} from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { postClientComponentError } from 'components/home/budgetList/BudgetList';
import { JournalListDTO, JournalPK } from 'generated-models/budgetera';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { homeRoute } from 'store/location';
import { RootState, useAppDispatch } from 'store/store';
import { CreateOrEditJournalModal } from './CreateOrEditJournalModal';
import { getJournalListQryAction, selectJournalListQry } from './qryJournalList';

interface JournalListRow extends FbTableRow {
  identifier: JournalPK;
  colList: {
    typ: FbTableCell;
    datum: FbTableCell;
    beskrivning: FbTableCell;
  };
}

const colDefList: FbTableColDef[] = [
  {
    field: 'typ',
    headerName: 'Typ',
    cssClass: 'w-2/12',
    sortId: 1,
    sortFunction: (a: FbTableRow, b: FbTableRow) => {
      return a.colList.typ.text > b.colList.typ.text ? 1 : -1;
    },
  },
  {
    field: 'datum',
    headerName: 'Datum',
    cssClass: 'w-2/12',
    sortId: 2,
    sortFunction: (a: FbTableRow, b: FbTableRow) => {
      return a.colList.datum.text < b.colList.datum.text ? 1 : -1;
    },
  },
  {
    field: 'beskrivning',
    headerName: 'Beskrivning',
    cssClass: 'w-8/12',
  },
];

const mapListRow = (journalList: JournalListDTO[]): JournalListRow[] => {
  return journalList.map(j => ({
    identifier: j.JournalPK,
    colList: {
      typ: {
        text: j.JournalTypNamn,
      },
      datum: {
        text: dateToAAAAMMDD(j.SkapadDatum, '-'),
        cssClass: 'text-right',
      },
      beskrivning: {
        text: j.Beskrivning,
        cssClass: 'overflow-hidden text-ellipsis whitespace-nowrap',
      },
    },
  }));
};

export const Journal: React.FC = () => {
  const kontorId: number = useSelector((state: RootState) => parseInt(state.location.payload.kontorId));
  const journalListQry = useSelector(selectJournalListQry(kontorId));
  const [showJournalModal, setShowJournalModal] = useState<boolean>(false);
  const [journalPKToEdit, setJournalPKToEdit] = useState<JournalPK>({ JournalId: undefined });
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(journalListQry) || RemoteDataFunctions.isStale(journalListQry)) {
      dispatch(getJournalListQryAction({ KontorId: kontorId }));
    }
  }, [dispatch, journalListQry, kontorId]);

  return (
    <>
      <div className="sticky z-[100] pl-3 flex justify-between flex-row h-10 text-lg bg-fb-color-blue-light before:content-[''] before:bg-fb-color-primary before:w-1 before:absolute before:top-0 before:left-0 before:h-10">
        <div className="flex flex-col justify-center h-full font-semibold">
          {RemoteDataFunctions.hasData(journalListQry) ? 'Journal ' + journalListQry.data.KontorNamn : ''}
        </div>
        <div className="flex flex-row">
          <FbButton
            text="Stäng journal"
            icon={faClose}
            type="main-header-secondary"
            onClick={() => {
              dispatch(homeRoute());
            }}
          />
        </div>
      </div>
      <div className="p-4 min-h-min h-full">
        {RemoteDataFunctions.hasData(journalListQry) ? (
          <div className="min-h-min bg-fb-color-secondary rounded p-2 pb-4">
            <div className="flex justify-end">
              <FbButton
                text="Skapa ny anteckning"
                type="secondary"
                onClick={() => {
                  setJournalPKToEdit({ JournalId: undefined });
                  setShowJournalModal(true);
                }}
              />
            </div>
            <div className="mt-4">
              {journalListQry.data.JournalList.length > 0 ? (
                <FbTable
                  tableType="fixed"
                  colDefList={colDefList}
                  rowList={mapListRow(journalListQry.data.JournalList)}
                  onRowSelected={(selectedRow: FbTableRow) => {
                    setJournalPKToEdit(selectedRow.identifier);
                    setShowJournalModal(true);
                  }}
                  postClientComponentError={(componentName, message, componentProps) =>
                    postClientComponentError(componentName, message, componentProps)
                  }
                  className="w-full"
                  isSortable={true}
                  defaultSortId={2}
                />
              ) : (
                <div className="flex justify-center italic">Det finns inga anteckningar att visa</div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center w-full h-full bg-fb-color-secondary rounded pt-10">
            <FbSpinner size="large" />
          </div>
        )}
      </div>
      {RemoteDataFunctions.hasData(journalListQry) && showJournalModal && (
        <CreateOrEditJournalModal
          onClose={() => setShowJournalModal(false)}
          initialJournalState={journalListQry.data.JournalList.find(
            j => j.JournalPK.JournalId === journalPKToEdit.JournalId
          )}
          kontorId={kontorId}
        />
      )}
    </>
  );
};
