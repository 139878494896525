/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetnyckeltalPK,
  BudgetnyckeltalPKFromJSON,
  BudgetnyckeltalPKFromJSONTyped,
  BudgetnyckeltalPKToJSON,
} from './BudgetnyckeltalPK';

/**
 *
 * @export
 * @interface UpdateBudgetNyckeltalFoervaentningCmd
 */
export interface UpdateBudgetNyckeltalFoervaentningCmd {
  /**
   *
   * @type {number}
   * @memberof UpdateBudgetNyckeltalFoervaentningCmd
   */
  FoervaentningVaerde?: number | null;
  /**
   *
   * @type {BudgetnyckeltalPK}
   * @memberof UpdateBudgetNyckeltalFoervaentningCmd
   */
  PrimaryKey: BudgetnyckeltalPK;
}

export function UpdateBudgetNyckeltalFoervaentningCmdFromJSON(json: any): UpdateBudgetNyckeltalFoervaentningCmd {
  return UpdateBudgetNyckeltalFoervaentningCmdFromJSONTyped(json, false);
}

export function UpdateBudgetNyckeltalFoervaentningCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateBudgetNyckeltalFoervaentningCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    FoervaentningVaerde: !exists(json, 'FoervaentningVaerde') ? undefined : json['FoervaentningVaerde'],
    PrimaryKey: BudgetnyckeltalPKFromJSON(json['PrimaryKey']),
  };
}

export function UpdateBudgetNyckeltalFoervaentningCmdToJSON(value?: UpdateBudgetNyckeltalFoervaentningCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    FoervaentningVaerde: value.FoervaentningVaerde,
    PrimaryKey: BudgetnyckeltalPKToJSON(value.PrimaryKey),
  };
}
