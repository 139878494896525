export interface ScrollingCategory {
  text: string;
  scrolledPast: boolean;
}

export interface ScrollingSubcategory {
  category: string;
  text: string;
  scrolledPast: boolean;
}

export const getScrollingCategory = (gridHeaders: ScrollingSubcategory[] | ScrollingCategory[]) => {
  let indexToUse = 0;
  for (let i = gridHeaders.length - 1; i >= 0; i--) {
    if (gridHeaders[i].scrolledPast) {
      indexToUse = i;
      break;
    }
  }
  return gridHeaders.length > indexToUse ? gridHeaders[indexToUse] : undefined;
};

export const getCorrectScrollingSubcategory = (gridHeader: string, gridSubheaders: ScrollingSubcategory[]) => {
  const subs = gridSubheaders.filter(s => s.category === gridHeader);
  if (subs.length === 0) {
    return '';
  }
  const cat = getScrollingCategory(subs);
  return cat?.text ?? '';
};

export const isScrollingCategory = (obj: ScrollingCategory | ScrollingSubcategory): obj is ScrollingCategory => {
  if ('category' in obj) {
    return false;
  }
  return true;
};
