/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  BudgetaarNyckeltalListDTOQry,
  BudgetaarNyckeltalListDTOQryFromJSON,
  BudgetaarNyckeltalListDTOQryToJSON,
  BudgetaarNyckeltalListWrapperDTO,
  BudgetaarNyckeltalListWrapperDTOFromJSON,
  BudgetaarNyckeltalListWrapperDTOToJSON,
  BudgetaarPeriodiseringsprofilListDTOQry,
  BudgetaarPeriodiseringsprofilListDTOQryFromJSON,
  BudgetaarPeriodiseringsprofilListDTOQryToJSON,
  BudgetaarPeriodiseringsprofilListWrapperDTO,
  BudgetaarPeriodiseringsprofilListWrapperDTOFromJSON,
  BudgetaarPeriodiseringsprofilListWrapperDTOToJSON,
  BudgetperiodDTO,
  BudgetperiodDTOFromJSON,
  BudgetperiodDTOToJSON,
} from '../models';

export interface GetBudgetaarRequest {
  budgetaarNyckeltalListDTOQry?: BudgetaarNyckeltalListDTOQry;
}

export interface GetBudgetaarPeriodiseringsprofilListRequest {
  budgetaarPeriodiseringsprofilListDTOQry?: BudgetaarPeriodiseringsprofilListDTOQry;
}

/**
 *
 */
export class BudgetaarQryApi extends runtime.BaseAPI {
  /**
   */
  async getBudgetaarRaw(
    requestParameters: GetBudgetaarRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetaarNyckeltalListWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetaarQry/GetBudgetaarNyckeltalList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetaarNyckeltalListDTOQryToJSON(requestParameters.budgetaarNyckeltalListDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BudgetaarNyckeltalListWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBudgetaar(
    requestParameters: GetBudgetaarRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetaarNyckeltalListWrapperDTO> {
    const response = await this.getBudgetaarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getBudgetaarBudgetperiodListRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<BudgetperiodDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetaarQry/GetBudgetaarBudgetperiodList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(BudgetperiodDTOFromJSON));
  }

  /**
   */
  async getBudgetaarBudgetperiodList(initOverrides?: RequestInit): Promise<Array<BudgetperiodDTO>> {
    const response = await this.getBudgetaarBudgetperiodListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async getBudgetaarPeriodiseringsprofilListRaw(
    requestParameters: GetBudgetaarPeriodiseringsprofilListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetaarPeriodiseringsprofilListWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetaarQry/GetBudgetaarPeriodiseringsprofilList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetaarPeriodiseringsprofilListDTOQryToJSON(requestParameters.budgetaarPeriodiseringsprofilListDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      BudgetaarPeriodiseringsprofilListWrapperDTOFromJSON(jsonValue)
    );
  }

  /**
   */
  async getBudgetaarPeriodiseringsprofilList(
    requestParameters: GetBudgetaarPeriodiseringsprofilListRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetaarPeriodiseringsprofilListWrapperDTO> {
    const response = await this.getBudgetaarPeriodiseringsprofilListRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
