/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PeriodiseringsprofilPK
 */
export interface PeriodiseringsprofilPK {
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilPK
   */
  PeriodiseringsprofilId?: number | null;
}

export function PeriodiseringsprofilPKFromJSON(json: any): PeriodiseringsprofilPK {
  return PeriodiseringsprofilPKFromJSONTyped(json, false);
}

export function PeriodiseringsprofilPKFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodiseringsprofilPK {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PeriodiseringsprofilId: !exists(json, 'PeriodiseringsprofilId') ? undefined : json['PeriodiseringsprofilId'],
  };
}

export function PeriodiseringsprofilPKToJSON(value?: PeriodiseringsprofilPK | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PeriodiseringsprofilId: value.PeriodiseringsprofilId,
  };
}
