import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { DeleteKommentarCmd } from 'generated-models/budgetera';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[Kommentar] delete kommentar';

const deleteKommentarCmd = createWebCommandSingletonList<typeof ActionType, DeleteKommentarCmd>(ActionType, ActionType);

export const selectDeleteKommentarCmd = (state: RootState) =>
  deleteKommentarCmd.get(state.main.budget.qryKommentar.qryDeleteKommentar);

export const deleteKommentarAction =
  (cmd: DeleteKommentarCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectDeleteKommentarCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(deleteKommentarCmd.executing());

      const result = await new ApiClient().KommentarCmdApi.deleteKommentar({
        deleteKommentarCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(deleteKommentarCmd.success(cmd));

      //Remove command for commandList
      dispatch(deleteKommentarCmd.remove());
    } catch (err) {
      dispatch(deleteKommentarCmd.failure(await parseErrorResponse(err)));
    }
  };

export default deleteKommentarCmd.reducer;
