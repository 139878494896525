import {
  createWebDataSingletonList,
  RemoteDataFunctions,
  RemoteDataStatus,
  WebDataListState,
} from '@decernointernal/websd.shared';
import {
  BudgetPK,
  NyckeltalvaerdeMaanadBudgetWrapperDTO,
  NyckeltalvaerdeMaanadWrapperDTO,
  SdDbOperation,
} from 'generated-models/budgetera/models';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType } from 'utils/eventDispatcher';

const ActionType = '[Nyckeltal] Get nyckeltal värde månad för budget';

// Slice for fetching nyckeltalvaerdeMaanad for the given nyckeltalvaerde/nyckeltalvaerdeMaanad
const nyckeltalvaerdeMaanadBudgetQry = createWebDataSingletonList<
  typeof ActionType,
  NyckeltalvaerdeMaanadBudgetWrapperDTO
>(ActionType, ActionType);

type NyckeltalvaerdeMaanadBudgetQryState = WebDataListState<NyckeltalvaerdeMaanadBudgetWrapperDTO>;

const mapNyckeltalvaerdeMaanadBudgetQryState: MapState<NyckeltalvaerdeMaanadBudgetQryState> = rootState =>
  rootState.main.nyckeltalVaerde.qryNyckeltalvaerdeMaanadBudget;

export const selectNyckeltalvaerdeMaanadBudgetQry = () => (state: RootState) =>
  nyckeltalvaerdeMaanadBudgetQry.get(mapNyckeltalvaerdeMaanadBudgetQryState(state));

export const nyckeltalvaerdeMaanadBudgetQryAction =
  (id: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = nyckeltalvaerdeMaanadBudgetQry.get(mapNyckeltalvaerdeMaanadBudgetQryState(getState()));
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(nyckeltalvaerdeMaanadBudgetQry.updating(webData.data));
      } else {
        dispatch(nyckeltalvaerdeMaanadBudgetQry.loading());
      }
      const result = await new ApiClient().NyckeltalvaerdeQryApi.getNyckeltalvaerdeMaanadBudget({
        nyckeltalvaerdeMaanadBudgetDTOQry: id,
      });
      dispatch(nyckeltalvaerdeMaanadBudgetQry.success(result));
    } catch (err) {
      dispatch(nyckeltalvaerdeMaanadBudgetQry.failure(await parseErrorResponse(err)));
    }
  };

// React to integration events
export const nyckeltalvaerdeMaanadBudgetQryReducer: Reducer<
  NyckeltalvaerdeMaanadBudgetQryState,
  IntegrationEventAction
> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.NyckeltalvaerdeMaanadDTO: {
      // Since we react to a DTO event, we will recieve the data we need to update redux directly
      const webData = nyckeltalvaerdeMaanadBudgetQry.get(state);
      if (RemoteDataFunctions.hasData(webData)) {
        const updated = [...webData.data.NyckeltalvaerdeMaanadWrapperDTOList];
        const toUpdate = updated.findIndex(
          n => n.NyckeltalVaerdePK.NyckeltalvaerdeId === action.payload.eventData.NyckeltalvaerdeId
        );
        if (toUpdate >= 0) {
          // Insert updated månad into list.
          const maanadList = updated[toUpdate].NyckeltalvaerdeMaanadDTOList;
          const maanadListIndex = maanadList.findIndex(
            n =>
              n.NyckeltalvaerdeMaanadPK.NyckeltalvaerdeMaanadId === action.payload.eventDataKey.NyckeltalvaerdeMaanadId
          );
          if (maanadListIndex >= 0) {
            if (action.payload.operation === SdDbOperation.Delete) {
              maanadList.splice(maanadListIndex);
            } else {
              maanadList[maanadListIndex] = action.payload.eventData;
            }
          } else if (action.payload.operation !== SdDbOperation.Delete) {
            maanadList.push(action.payload.eventData);
          }
        } else {
          // Insert whole new nyckeltalvärde into list
          const maanadWrapper: NyckeltalvaerdeMaanadWrapperDTO = {
            NyckeltalVaerdePK: { NyckeltalvaerdeId: action.payload.eventData.NyckeltalvaerdeId },
            NyckeltalvaerdeMaanadDTOList: [action.payload.eventData],
          };
          updated.push(maanadWrapper);
        }
        return {
          ...state,
          [ActionType]: {
            data: { ...webData.data, NyckeltalvaerdeMaanadWrapperDTOList: updated },
            status: RemoteDataStatus.Success,
          },
        };
      }
    }
  }

  return nyckeltalvaerdeMaanadBudgetQry.reducer(state, action);
};
