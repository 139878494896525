import { FbAlert } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { getBudgetperiodStatusDomain } from 'domain/budgetperiodStatusDomain';
import { BudgetperiodPK } from 'generated-models/budgetera';
import { BudgetperiodStatusVO } from 'generated-models/budgetera/models/BudgetperiodStatusVO';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { budgetperiodStatusAction, selectBudgetperiodStatus } from './qryStartaBudgetaar';

interface BudgetperiodStatusProps {
  budgetperiodPK: BudgetperiodPK;
}

export const BudgetperiodStatus: React.FC<BudgetperiodStatusProps> = ({ budgetperiodPK }) => {
  const dispatch = useAppDispatch();
  const budgetperiodStatus = useSelector(selectBudgetperiodStatus(budgetperiodPK));
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(budgetperiodStatus) || RemoteDataFunctions.isStale(budgetperiodStatus)) {
      dispatch(budgetperiodStatusAction(budgetperiodPK));
    }
  }, [budgetperiodStatus, dispatch, budgetperiodPK]);

  const clearTimer = useCallback(() => {
    if (timeoutRef && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    if (RemoteDataFunctions.hasData(budgetperiodStatus)) {
      const status = getBudgetperiodStatusDomain(budgetperiodStatus.data.BudgetperiodDTO.BudgetperiodStatusVO);
      if (status === 'ready to start' || status === 'starting') {
        timeoutRef.current = setTimeout(() => {
          dispatch(budgetperiodStatusAction(budgetperiodPK));
        }, 10000);
      }
    } else {
      clearTimer();
    }
    return function cleanup() {
      clearTimer();
    };
  }, [budgetperiodStatus, clearTimer, dispatch, budgetperiodPK]);

  const getAlertType = (status: BudgetperiodStatusVO) => {
    switch (getBudgetperiodStatusDomain(status)) {
      case 'ready to start':
        return <FbAlert type="info" text="Arbetet med att skapa upp budgetar ska påbörjas." />;
      case 'starting':
        return <FbAlert type="info" text="Arbetet med att skapa upp budgetar pågår." />;
      case 'started':
        return <FbAlert type="success" text="Budgetar har skapats. Ladda om budgetera efter en lyckad uppstart." />;
      case 'failed to start':
        return <FbAlert type="danger" text="Något gick fel och alla budgetar kunde inte skapas." />;
      default:
        return null;
    }
  };

  if (RemoteDataFunctions.hasData(budgetperiodStatus)) {
    return getAlertType(budgetperiodStatus.data.BudgetperiodDTO.BudgetperiodStatusVO);
  }

  return null;
};
