import { CommandFunctions, ErrorMessage, RemoteData, RemoteDataFunctions } from '@decernointernal/websd.shared';
import {
  createNyckeltalVaerdeAction,
  selectCreateNyckeltalVaerdeCmd,
} from 'components/budget/nyckeltal/cmdCreateNyckeltalVaerde';
import {
  selectUpdateNyckeltalVaerdeCmd,
  updateNyckeltalVaerdeAction,
} from 'components/budget/nyckeltal/cmdUpdateNyckeltalVaerde';
import { selectNyckeltalvaerdeQry } from 'components/budget/nyckeltal/qryNyckeltalVaerde';
import { BudgetnyckeltalListDTO, NyckeltalVaerdeDTO } from 'generated-models/budgetera';
import { useSelector } from 'react-redux';
import { ThunkAction } from 'store/store';

export const useUpdateNyckeltalVaerde = (
  nyckeltal: BudgetnyckeltalListDTO
): [
  (value?: number, periodiseringsprofilId?: number | null) => ThunkAction | undefined,
  (value?: number, periodiseringsprofilId?: number | null) => ThunkAction | undefined,
  RemoteData<NyckeltalVaerdeDTO, ErrorMessage>
] => {
  const createNyckeltalVaerdeCmd = useSelector(selectCreateNyckeltalVaerdeCmd);
  const updateNyckeltalVaerdeCmd = useSelector(selectUpdateNyckeltalVaerdeCmd);
  const pk = {
    BudgetId: nyckeltal.BudgetId,
    BudgetnyckeltalLoepnummer: nyckeltal.BudgetnyckeltalLoepnummer,
  };
  const nyckeltalVaerde = useSelector(selectNyckeltalvaerdeQry(pk));

  const createNyckeltalVaerde = (value?: number, periodiseringsprofilId?: number | null): ThunkAction | undefined => {
    if (
      !CommandFunctions.isExecuting(createNyckeltalVaerdeCmd) &&
      RemoteDataFunctions.isSuccess(nyckeltalVaerde) &&
      !nyckeltalVaerde.data.NyckeltalvaerdeId
    ) {
      return createNyckeltalVaerdeAction({
        Aarsvaerde: value,
        BudgetId: nyckeltal.BudgetId,
        BudgetnyckeltalLoepnummer: nyckeltal.BudgetnyckeltalLoepnummer,
        PeriodiseringsprofilId:
          periodiseringsprofilId !== undefined ? periodiseringsprofilId : nyckeltalVaerde.data.PeriodiseringsprofilId,
      });
    }
    return undefined;
  };

  const updateNyckeltalVaerde = (value?: number, periodiseringsprofilId?: number | null): ThunkAction | undefined => {
    if (
      !CommandFunctions.isExecuting(updateNyckeltalVaerdeCmd) &&
      RemoteDataFunctions.isSuccess(nyckeltalVaerde) &&
      nyckeltalVaerde.data.NyckeltalvaerdeId
    ) {
      return updateNyckeltalVaerdeAction({
        Aarsvaerde: value,
        PeriodiseringsprofilId:
          periodiseringsprofilId !== undefined ? periodiseringsprofilId : nyckeltalVaerde.data.PeriodiseringsprofilId,
        PrimaryKey: { NyckeltalvaerdeId: nyckeltalVaerde.data.NyckeltalvaerdeId, ...nyckeltal },
      });
    }
    return undefined;
  };

  return [createNyckeltalVaerde, updateNyckeltalVaerde, nyckeltalVaerde];
};
