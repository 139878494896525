import { addNotifikation } from 'components/overlay/FbOverlayReducer';
import store from 'store/store';
import { ApiClient, sendClientCrashReport } from './apiClient';

export const logClientComponentErrorAsRedBalloon = (componentName: string, message: string) => {
  const felkod = getFelkod();

  new ApiClient().KibanaApi.postClientComponentError({
    clientComponentErrorMessage: {
      ComponentName: componentName,
      Message: message,
      ErrorCode: felkod,
    },
  });
  store.dispatch(
    addNotifikation({
      titel: 'Hej! Något i Budgetera gick precis snett.',
      text: [message],
      felkod: felkod,
      nivaa: 'error',
    })
  );
};

export const logClientCrashReport = (
  error: Error,
  componentStack?: string,
  currentUrl?: string,
  userAgentInfo?: Navigator
) => {
  const felkod = getFelkod();

  sendClientCrashReport(error, felkod, componentStack, currentUrl, userAgentInfo);
  store.dispatch(
    addNotifikation({
      titel: 'Hej! Något i Budgetera gick precis snett.',
      text: ['Du borde ladda om Budgetera. Blir det alltid fel när du gör samma sak borde du kontakta support.'],
      felkod: felkod,
      nivaa: 'error',
    })
  );
};

const getFelkod = (): string => {
  return 'B' + Math.floor(Math.random() * 2147483647).toString();
};
