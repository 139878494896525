import { BudgetperiodPK, BudgetPK, EnhetVO } from 'generated-models/budgetera/models';
import { RouteObject, RoutesMap, RouteString } from 'redux-first-router';
import { RootState } from './store';

export enum LocationActionType {
  Home = '[location] Home',
  Budget = '[location] Budget',
  NotFound = '@@redux-first-router/NOT_FOUND',
  Budgetaar = '[location] Budgetår',
  Unauthorized = '[location] Unauthorized',
  BudgetaarList = '[location] BudgetårList',
  Journal = '[location] Journal',
}

export const LocationActionTypeList = Object.keys(LocationActionType).map(key => (LocationActionType as any)[key]);

const confirmLeave = (state: object, _2: object) => {
  const rootState = state as RootState;
  const navigateAway = rootState.main.navigateAway;

  return navigateAway.fields.length > 0 ? 'Sparar...' : '';
};

export const routesMap: RoutesMap<RouteString | RouteObject> = {
  [LocationActionType.Home]: '/',
  [LocationActionType.Budget]: { path: '/Budget/:budgetId', confirmLeave },
  [LocationActionType.Budgetaar]: { path: '/Budgetar/:budgetperiodId', confirmLeave },
  [LocationActionType.BudgetaarList]: '/Budgetar',
  [LocationActionType.Journal]: '/Journal/:kontorId',
  [LocationActionType.Unauthorized]: '/Obehorig',
};

export const homeRoute = () => ({ type: LocationActionType.Home });
export const budgetRoute = (budgetPK: BudgetPK) => ({
  type: LocationActionType.Budget,
  payload: { budgetId: budgetPK.BudgetId },
});
export const BudgetaarRoute = (budgetperiodPK: BudgetperiodPK) => ({
  type: LocationActionType.Budgetaar,
  payload: { budgetperiodId: budgetperiodPK.BudgetperiodId },
});

export const UnauthorizedRoute = () => ({ type: LocationActionType.Unauthorized });
export const BudgetaarListRoute = () => ({
  type: LocationActionType.BudgetaarList,
});
export const JournalRoute = (enhetVO: EnhetVO) => ({
  type: LocationActionType.Journal,
  payload: { kontorId: enhetVO.KontorId },
});

export enum PageComponent {
  Home = 'Home',
  Budget = 'Budget',
  NotFound = 'NotFound',
  Budgetaar = 'Budgetaar',
  BudgetaarList = 'BudgetaarList',
  Journal = 'Journal',
  Unauthorized = 'Unauthorized',
}

const components: { [key in LocationActionType]: PageComponent } = {
  [LocationActionType.Home]: PageComponent.Home,
  [LocationActionType.Budget]: PageComponent.Budget,
  [LocationActionType.Budgetaar]: PageComponent.Budgetaar,
  [LocationActionType.BudgetaarList]: PageComponent.BudgetaarList,
  [LocationActionType.Journal]: PageComponent.Journal,
  [LocationActionType.NotFound]: PageComponent.NotFound,
  [LocationActionType.Unauthorized]: PageComponent.Unauthorized,
};

export const selectPage = (state: RootState) => state.page;

export const pageReducer = (state = PageComponent.Home, action: { type: LocationActionType }) =>
  components[action.type] || state;
