/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';

/**
 *
 * @export
 * @interface CopyBudgetSrvcCmd
 */
export interface CopyBudgetSrvcCmd {
  /**
   *
   * @type {BudgetPK}
   * @memberof CopyBudgetSrvcCmd
   */
  FromBudgetPK: BudgetPK;
  /**
   *
   * @type {BudgetPK}
   * @memberof CopyBudgetSrvcCmd
   */
  ToBudgetPK: BudgetPK;
}

export function CopyBudgetSrvcCmdFromJSON(json: any): CopyBudgetSrvcCmd {
  return CopyBudgetSrvcCmdFromJSONTyped(json, false);
}

export function CopyBudgetSrvcCmdFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyBudgetSrvcCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    FromBudgetPK: BudgetPKFromJSON(json['FromBudgetPK']),
    ToBudgetPK: BudgetPKFromJSON(json['ToBudgetPK']),
  };
}

export function CopyBudgetSrvcCmdToJSON(value?: CopyBudgetSrvcCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    FromBudgetPK: BudgetPKToJSON(value.FromBudgetPK),
    ToBudgetPK: BudgetPKToJSON(value.ToBudgetPK),
  };
}
