/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NyckeltalvaerdeMaanadPK,
  NyckeltalvaerdeMaanadPKFromJSON,
  NyckeltalvaerdeMaanadPKFromJSONTyped,
  NyckeltalvaerdeMaanadPKToJSON,
} from './NyckeltalvaerdeMaanadPK';

/**
 *
 * @export
 * @interface NyckeltalvaerdeMaanadDTO
 */
export interface NyckeltalvaerdeMaanadDTO {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeMaanadDTO
   */
  NyckeltalvaerdeId: number;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeMaanadDTO
   */
  Vaerde: number;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeMaanadDTO
   */
  Maanad: number;
  /**
   *
   * @type {NyckeltalvaerdeMaanadPK}
   * @memberof NyckeltalvaerdeMaanadDTO
   */
  NyckeltalvaerdeMaanadPK: NyckeltalvaerdeMaanadPK;
}

export function NyckeltalvaerdeMaanadDTOFromJSON(json: any): NyckeltalvaerdeMaanadDTO {
  return NyckeltalvaerdeMaanadDTOFromJSONTyped(json, false);
}

export function NyckeltalvaerdeMaanadDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeMaanadDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalvaerdeId: json['NyckeltalvaerdeId'],
    Vaerde: json['Vaerde'],
    Maanad: json['Maanad'],
    NyckeltalvaerdeMaanadPK: NyckeltalvaerdeMaanadPKFromJSON(json['NyckeltalvaerdeMaanadPK']),
  };
}

export function NyckeltalvaerdeMaanadDTOToJSON(value?: NyckeltalvaerdeMaanadDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalvaerdeId: value.NyckeltalvaerdeId,
    Vaerde: value.Vaerde,
    Maanad: value.Maanad,
    NyckeltalvaerdeMaanadPK: NyckeltalvaerdeMaanadPKToJSON(value.NyckeltalvaerdeMaanadPK),
  };
}
