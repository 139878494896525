import React from 'react';

interface SideDrawerProps {
  positionRight?: boolean;
  open: boolean;
  className?: string;
  sideDrawerRef?: React.MutableRefObject<any>;
  topMargin: number;
}

export const SideDrawer: React.FC<React.PropsWithChildren<SideDrawerProps>> = ({
  positionRight,
  open,
  className,
  children,
  sideDrawerRef,
  topMargin,
}) => {
  return (
    <div
      className={
        (className ?? '') +
        ' fixed bg-fb-color-white z-[101] transition-transform duration-300' +
        (positionRight
          ? ' right-0 border-l border-l-fb-color-grey-medium-dark'
          : ' left-0 border-r border-r-fb-color-grey-medium-dark') +
        (open ? ' translate-x-0' : positionRight ? ' translate-x-full' : ' -translate-x-full')
      }
      style={{
        height: `calc(100% - ${topMargin}px)`,
        top: `${topMargin}px`,
      }}
      ref={sideDrawerRef}
    >
      {children}
    </div>
  );
};
