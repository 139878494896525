/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';

/**
 *
 * @export
 * @interface ConfirmBudgetSrvcCmd
 */
export interface ConfirmBudgetSrvcCmd {
  /**
   *
   * @type {BudgetPK}
   * @memberof ConfirmBudgetSrvcCmd
   */
  BudgetPK: BudgetPK;
}

export function ConfirmBudgetSrvcCmdFromJSON(json: any): ConfirmBudgetSrvcCmd {
  return ConfirmBudgetSrvcCmdFromJSONTyped(json, false);
}

export function ConfirmBudgetSrvcCmdFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmBudgetSrvcCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
  };
}

export function ConfirmBudgetSrvcCmdToJSON(value?: ConfirmBudgetSrvcCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
  };
}
