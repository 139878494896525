import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import {
  UnderliggandeEnheterVaerdeIdentifier,
  underliggandeEnheterVaerdeQryAction,
} from 'components/budget/qryUnderliggandeEnheterVaerde';
import { UpdateBudgetNyckeltalFoervaentningSrvcCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[Nyckeltal] update nyckeltal förväntning';

const updateBudgetNyckeltalFoervaentningarCmd = createWebCommandSingletonList<
  typeof ActionType,
  UpdateBudgetNyckeltalFoervaentningSrvcCmd
>(ActionType, ActionType);

export const selectUpdateBudgetNyckeltalFoervaentningarCmd = (state: RootState) =>
  updateBudgetNyckeltalFoervaentningarCmd.get(state.main.nyckeltalVaerde.qryUpdateFoervaentningarVaerde);

export const updateBudgetNyckeltalFoervaentningarAction =
  (
    cmd: UpdateBudgetNyckeltalFoervaentningSrvcCmd,
    id: UnderliggandeEnheterVaerdeIdentifier,
    budgetIdList: number[]
  ): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectUpdateBudgetNyckeltalFoervaentningarCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(updateBudgetNyckeltalFoervaentningarCmd.executing());

      const result = await new ApiClient().BudgetCmdApi.setBudgetNyckeltalFoervaentningar({
        updateBudgetNyckeltalFoervaentningSrvcCmd: cmd,
      });

      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(updateBudgetNyckeltalFoervaentningarCmd.success(cmd));

      // Since we dont know the huvudBudgetId in the integration event, we refetch the data
      // after the cmd is successful
      dispatch(underliggandeEnheterVaerdeQryAction(id, budgetIdList));
    } catch (err) {
      dispatch(updateBudgetNyckeltalFoervaentningarCmd.failure(await parseErrorResponse(err)));
    }
  };

export const removeBudgetNyckeltalFoervaentningarCmdAction = (): ThunkAction => async dispatch => {
  // Remove command from commandList
  dispatch(updateBudgetNyckeltalFoervaentningarCmd.remove());
};

export default updateBudgetNyckeltalFoervaentningarCmd.reducer;
