import { FbFormInput } from '@decernointernal/fb-interna-komponenter';
import { AnyAction } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { FbTableGridInputCell } from 'shared-components/grid/table/FbTableGridInputCell';
import store, { RootState, ThunkAction } from 'store/store';
import { addOrUpdateField, removeField } from './navigateAway/navigateAwayReducer';

export interface IRequiredProps {
  readonly id: string;
  readonly defaultValue?: string;
  readonly onSave: (val: any) => ThunkAction | undefined;
}

export function autosaveHoc<T>(WrappedComponent: ComponentType<T>) {
  return (props: Omit<T & IRequiredProps, 'onBlur' | 'onChange' | 'onInstantChange'>) => {
    const dispatch = store.dispatch as ThunkDispatch<RootState, {}, AnyAction>;

    const addOrUpdateNavigateAwayField = (isValid: boolean, action?: any) => {
      dispatch(addOrUpdateField({ id: props.id, isValid, action }));
    };

    const removeNavigateAwayField = () => {
      dispatch(removeField({ id: props.id }));
    };

    const hasChanged = (defaultValue?: string | Date, value?: string | Date) => {
      return defaultValue !== value;
    };

    let componentSpecificProps = {};

    switch (WrappedComponent.name) {
      case FbFormInput.name:
        componentSpecificProps = {
          onInstantChange: (value: string, isValid: boolean) => {
            if (hasChanged(props.defaultValue, value)) {
              addOrUpdateNavigateAwayField(isValid, isValid ? props.onSave(value) : undefined);
            }
          },
          onBlur: (value: string | undefined, isValid: boolean) => {
            if (isValid && hasChanged(props.defaultValue, value)) {
              const cmd = props.onSave(value);
              cmd && dispatch(cmd);
              removeNavigateAwayField();
            }
          },
        };
        break;
      case FbTableGridInputCell.name:
        componentSpecificProps = {
          onChange: (value: string) => {
            if (hasChanged(props.defaultValue, value)) {
              addOrUpdateNavigateAwayField(true, props.onSave(value));
            }
          },
          onBlur: (value: string | undefined) => {
            if (hasChanged(props.defaultValue, value)) {
              const cmd = props.onSave(value);
              cmd && dispatch(cmd);
              removeNavigateAwayField();
            }
          },
        };
        break;
      default:
    }

    return <WrappedComponent {...(props as T)} {...componentSpecificProps} />;
  };
}
