import { AnyAction, combineReducers, Reducer } from 'redux';
import { LocationActionType } from 'store/location';
import { ClearBudgetActionType } from 'store/store';
import { budgetReducer } from './budget/budgetReducer';
import { nyckeltalVaerdeReducer } from './budget/nyckeltal/nyckeltalVaerdeReducer';
import { homeReducer } from './home/homeReducer';
import { journalReducer } from './journal/journalReducer';
import navigateAwayReducer from './navigateAway/navigateAwayReducer';
import qryBudgetperiodList from './qryBudgetperiodList';
import uiCurrentBudgetperiodPK from './uiCurrentBudgetperiodPK';

const combined = combineReducers({
  home: homeReducer,
  budget: budgetReducer,
  nyckeltalVaerde: nyckeltalVaerdeReducer,
  uiCurrentBudgetperiodPK,
  qryBudgetperiodList,
  navigateAway: navigateAwayReducer,
  journal: journalReducer,
});
type MainState = ReturnType<typeof combined>;

export const mainReducer: Reducer<MainState, AnyAction> = (state, action) => {
  const combo = combined(state, action);
  if (action.type === LocationActionType.Home || action.type === ClearBudgetActionType) {
    // Reset state for budget and nyckeltalVaerde
    return {
      ...combo,
      budget: budgetReducer(undefined, action),
      nyckeltalVaerde: nyckeltalVaerdeReducer(undefined, action),
    };
  }
  return combo;
};
