/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PeriodiseringsprofilListDTOQry
 */
export interface PeriodiseringsprofilListDTOQry {
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilListDTOQry
   */
  BudgetperiodId: number;
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilListDTOQry
   */
  BudgetId?: number | null;
}

export function PeriodiseringsprofilListDTOQryFromJSON(json: any): PeriodiseringsprofilListDTOQry {
  return PeriodiseringsprofilListDTOQryFromJSONTyped(json, false);
}

export function PeriodiseringsprofilListDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PeriodiseringsprofilListDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodId: json['BudgetperiodId'],
    BudgetId: !exists(json, 'BudgetId') ? undefined : json['BudgetId'],
  };
}

export function PeriodiseringsprofilListDTOQryToJSON(value?: PeriodiseringsprofilListDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodId: value.BudgetperiodId,
    BudgetId: value.BudgetId,
  };
}
