/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BudgetperiodStatusDTOQry
 */
export interface BudgetperiodStatusDTOQry {
  /**
   *
   * @type {number}
   * @memberof BudgetperiodStatusDTOQry
   */
  BudgetperiodId?: number | null;
}

export function BudgetperiodStatusDTOQryFromJSON(json: any): BudgetperiodStatusDTOQry {
  return BudgetperiodStatusDTOQryFromJSONTyped(json, false);
}

export function BudgetperiodStatusDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetperiodStatusDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodId: !exists(json, 'BudgetperiodId') ? undefined : json['BudgetperiodId'],
  };
}

export function BudgetperiodStatusDTOQryToJSON(value?: BudgetperiodStatusDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodId: value.BudgetperiodId,
  };
}
