/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateNyckeltalvaerdeMaanadCmd
 */
export interface CreateNyckeltalvaerdeMaanadCmd {
  /**
   *
   * @type {number}
   * @memberof CreateNyckeltalvaerdeMaanadCmd
   */
  BudgetId: number;
  /**
   *
   * @type {number}
   * @memberof CreateNyckeltalvaerdeMaanadCmd
   */
  NyckeltalvaerdeId: number;
  /**
   *
   * @type {number}
   * @memberof CreateNyckeltalvaerdeMaanadCmd
   */
  Vaerde: number;
  /**
   *
   * @type {number}
   * @memberof CreateNyckeltalvaerdeMaanadCmd
   */
  Maanad: number;
}

export function CreateNyckeltalvaerdeMaanadCmdFromJSON(json: any): CreateNyckeltalvaerdeMaanadCmd {
  return CreateNyckeltalvaerdeMaanadCmdFromJSONTyped(json, false);
}

export function CreateNyckeltalvaerdeMaanadCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateNyckeltalvaerdeMaanadCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetId: json['BudgetId'],
    NyckeltalvaerdeId: json['NyckeltalvaerdeId'],
    Vaerde: json['Vaerde'],
    Maanad: json['Maanad'],
  };
}

export function CreateNyckeltalvaerdeMaanadCmdToJSON(value?: CreateNyckeltalvaerdeMaanadCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetId: value.BudgetId,
    NyckeltalvaerdeId: value.NyckeltalvaerdeId,
    Vaerde: value.Vaerde,
    Maanad: value.Maanad,
  };
}
