/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
  StartBudgetperiodCmd,
  StartBudgetperiodCmdFromJSON,
  StartBudgetperiodCmdToJSON,
  UpdateDeadlineCmd,
  UpdateDeadlineCmdFromJSON,
  UpdateDeadlineCmdToJSON,
} from '../models';

export interface StartBudgetperiodRequest {
  startBudgetperiodCmd?: StartBudgetperiodCmd;
}

export interface UpdateDeadlineRequest {
  updateDeadlineCmd?: UpdateDeadlineCmd;
}

/**
 *
 */
export class BudgetperiodCmdApi extends runtime.BaseAPI {
  /**
   */
  async createBudgetarRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetperiodCmd/CreateBudgetar`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async createBudgetar(initOverrides?: RequestInit): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createBudgetarRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async resetBudgeteraResetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetperiodCmd/ResetBudgeteraReset`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async resetBudgeteraReset(initOverrides?: RequestInit): Promise<Array<SdIntegrationEvent>> {
    const response = await this.resetBudgeteraResetRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async startBudgetperiodRaw(
    requestParameters: StartBudgetperiodRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetperiodCmd/StartBudgetperiod`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: StartBudgetperiodCmdToJSON(requestParameters.startBudgetperiodCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async startBudgetperiod(
    requestParameters: StartBudgetperiodRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.startBudgetperiodRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateDeadlineRaw(
    requestParameters: UpdateDeadlineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetperiodCmd/UpdateDeadline`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDeadlineCmdToJSON(requestParameters.updateDeadlineCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async updateDeadline(
    requestParameters: UpdateDeadlineRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.updateDeadlineRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
