/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  KommentarDTOQry,
  KommentarDTOQryFromJSON,
  KommentarDTOQryToJSON,
  KommentarWrapperDTO,
  KommentarWrapperDTOFromJSON,
  KommentarWrapperDTOToJSON,
} from '../models';

export interface GetKommentarListRequest {
  kommentarDTOQry?: KommentarDTOQry;
}

/**
 *
 */
export class KommentarQryApi extends runtime.BaseAPI {
  /**
   */
  async getKommentarListRaw(
    requestParameters: GetKommentarListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<KommentarWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/KommentarQry/GetKommentarList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: KommentarDTOQryToJSON(requestParameters.kommentarDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => KommentarWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getKommentarList(
    requestParameters: GetKommentarListRequest = {},
    initOverrides?: RequestInit
  ): Promise<KommentarWrapperDTO> {
    const response = await this.getKommentarListRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
