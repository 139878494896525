import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { CreateJournalCmd } from 'generated-models/budgetera';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[Journal] create journal';

const createJournalCmd = createWebCommandSingletonList<typeof ActionType, CreateJournalCmd>(ActionType, ActionType);

export const selectCreateJournalCmd = (state: RootState) => createJournalCmd.get(state.main.journal.createJournalCmd);

export const createJournalAction =
  (cmd: CreateJournalCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectCreateJournalCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(createJournalCmd.executing());

      const result = await new ApiClient().JournalCmdApi.createJournal({
        createJournalCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(createJournalCmd.success(cmd));
    } catch (err) {
      dispatch(createJournalCmd.failure(await parseErrorResponse(err)));
    }
  };

export const removeJournalAction = (): ThunkAction => async dispatch => {
  // Remove command from commandList
  dispatch(createJournalCmd.remove());
};

export default createJournalCmd.reducer;
