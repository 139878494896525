/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateKommentarCmd
 */
export interface CreateKommentarCmd {
  /**
   *
   * @type {number}
   * @memberof CreateKommentarCmd
   */
  BudgetId: number;
  /**
   *
   * @type {string}
   * @memberof CreateKommentarCmd
   */
  Text: string;
}

export function CreateKommentarCmdFromJSON(json: any): CreateKommentarCmd {
  return CreateKommentarCmdFromJSONTyped(json, false);
}

export function CreateKommentarCmdFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateKommentarCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetId: json['BudgetId'],
    Text: json['Text'],
  };
}

export function CreateKommentarCmdToJSON(value?: CreateKommentarCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetId: value.BudgetId,
    Text: value.Text,
  };
}
