import { clickOutside, FbButton, FbButtonState, FbTabs, FbTabsType } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faClose, faHourglass3 } from '@fortawesome/free-solid-svg-icons';
import { getBudgetperiodStatusDomain } from 'domain/budgetperiodStatusDomain';
import { BudgetaarNyckeltalListDTO, BudgetperiodPK } from 'generated-models/budgetera';
import React from 'react';
import { useSelector } from 'react-redux';
import { FbPopover } from 'shared-components/fbPopover/FbPopover';
import { BudgetaarListRoute } from 'store/location';
import { RootState, useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import { BudgetaarDeadline } from './Deadline/BudgetaarDeadline';
import { BudgetaarNyckeltal } from './Nyckeltal/BudgetaarNyckeltal';
import { BudgetaarPeriodiseringsProfil } from './Periodiseringsprofiler/BudgetaarPeriodiseringsProfil';
import { getBudgetaarNyckeltalListQryAction, selectBudgetaarNyckeltalListQry } from './qryBudgetaarNyckeltalList';
import { BudgetperiodStatus } from './startaBudgetaar/BudgetperiodStatus';
import {
  removeStartBudgetperiodCmdAction,
  selectStartBudgetperiodCmd,
  startaBudgetperiodQryAction,
} from './startaBudgetaar/cmdStartaBudgetaar';
import { budgetperiodStatusAction, selectBudgetperiodStatus } from './startaBudgetaar/qryStartaBudgetaar';

export const Budgetaar: React.FC = () => {
  const budgetperiodPK = VOCreate<BudgetperiodPK>({
    BudgetperiodId: useSelector((state: RootState) => parseInt(state.location.payload.budgetperiodId)),
  });
  const budgetaarNyckeltalListQry = useSelector(selectBudgetaarNyckeltalListQry(budgetperiodPK));
  const cmdStartaBudgetaar = useSelector(selectStartBudgetperiodCmd);
  const budgetperiodStatus = useSelector(selectBudgetperiodStatus(budgetperiodPK));
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<Element | null>(null);

  const popoverConfirmOpenButtonRef = React.useRef<HTMLDivElement | null>(null);
  const {
    ref: popoverConfirmOpenRef,
    isComponentVisible: popoverConfirmOpen,
    setIsComponentVisible: setPopoverConfirmOpen,
  } = clickOutside(false, [popoverConfirmOpenButtonRef]);

  const getTabs = (BudgetaarNyckeltalList: BudgetaarNyckeltalListDTO[]): FbTabsType[] => {
    return [
      {
        headingTitle: 'Nyckeltal',
        content: <BudgetaarNyckeltal BudgetaarNyckeltalList={BudgetaarNyckeltalList} containerRef={containerRef} />,
      },
      {
        headingTitle: 'Periodiseringsprofiler',
        content: <BudgetaarPeriodiseringsProfil budgetperiodPK={budgetperiodPK} />,
      },
      {
        headingTitle: 'Deadline',
        content: RemoteDataFunctions.hasData(budgetperiodStatus) ? (
          <BudgetaarDeadline budgetperiodPK={budgetperiodStatus.data.BudgetperiodPK} />
        ) : (
          <div></div>
        ),
      },
    ];
  };

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(budgetperiodStatus) || RemoteDataFunctions.isStale(budgetperiodStatus)) {
      dispatch(budgetperiodStatusAction(budgetperiodPK));
    }
  }, [budgetperiodStatus, dispatch, budgetperiodPK]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(budgetaarNyckeltalListQry)) {
      dispatch(getBudgetaarNyckeltalListQryAction(budgetperiodPK));
    }
  }, [budgetaarNyckeltalListQry, dispatch, budgetperiodPK]);

  const startBudgetaar = React.useCallback(() => {
    if (CommandFunctions.isNotExecuted(cmdStartaBudgetaar) && RemoteDataFunctions.hasData(budgetperiodStatus)) {
      dispatch(
        startaBudgetperiodQryAction({
          PrimaryKey: budgetperiodStatus.data.BudgetperiodPK,
        })
      );
    }
    setPopoverConfirmOpen(false);
  }, [budgetperiodStatus, cmdStartaBudgetaar, setPopoverConfirmOpen, dispatch]);

  const startBudgetaarStatusClick = (): FbButtonState | undefined => {
    if (CommandFunctions.isExecuting(cmdStartaBudgetaar)) {
      return 'waiting';
    } else if (CommandFunctions.isFailure(cmdStartaBudgetaar)) {
      return 'failure';
    } else if (CommandFunctions.isSuccess(cmdStartaBudgetaar)) {
      removeStartBudgetperiodCmdAction();
      return 'success';
    }
    return undefined;
  };

  return RemoteDataFunctions.hasData(budgetaarNyckeltalListQry) && RemoteDataFunctions.hasData(budgetperiodStatus) ? (
    <>
      <div className="flex w-full bg-fb-color-blue-light border-l-4 border-fb-color-primary relative">
        <p className="font-semibold leading-10 pl-3 text-lg h-10 flex-grow">
          Starta budgetår {budgetperiodStatus.data.BudgetperiodDTO.Kalenderaar}
        </p>
        <div className="relative flex">
          <FbButton
            text="Stäng budgetår"
            icon={faClose}
            type="main-header-secondary"
            onClick={() => dispatch(BudgetaarListRoute())}
          />
          <div className="relative" ref={popoverConfirmOpenButtonRef}>
            <FbButton
              text="Starta budgetår"
              type="primary"
              icon={faHourglass3}
              className="h-10 right-0 rounded-none"
              disabled={
                getBudgetperiodStatusDomain(budgetperiodStatus.data.BudgetperiodDTO.BudgetperiodStatusVO) !==
                'not started'
              }
              onClick={() => setPopoverConfirmOpen(!popoverConfirmOpen)}
              buttonState={startBudgetaarStatusClick()}
            />
            <FbPopover
              header="Är du säker på att du vill fortsätta?"
              className="mr-4"
              popoverSize="small"
              showPopover={popoverConfirmOpen}
              popoverRef={popoverConfirmOpenRef}
            >
              <p className="text-xs-increased">Nya budgetar kommer att skapas upp för alla enheter och mäklare.</p>
              <div className="flex justify-end pt-4">
                <FbButton
                  className="mr-4"
                  text={'Avbryt'}
                  type={'secondary'}
                  onClick={() => setPopoverConfirmOpen(false)}
                />
                <FbButton text={'Starta'} type={'primary'} onClick={startBudgetaar} />
              </div>
            </FbPopover>
          </div>
        </div>
      </div>
      <div ref={r => (containerRef.current = r)} className="px-4 pb-4 overflow-y-scroll h-screen">
        <div className="py-4">
          <BudgetperiodStatus budgetperiodPK={budgetperiodPK} />
        </div>
        <FbTabs
          tabs={getTabs(budgetaarNyckeltalListQry.data.BudgetaarNyckeltalList)}
          tabsMenuClassName=" sticky top-0 z-10"
          colorScheme={'primary'}
        />
      </div>
    </>
  ) : (
    <></>
  );
};
