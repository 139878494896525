import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { BeraeknatNyckeltalFormelDTO } from 'generated-models/budgetera/models';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';

const ActionType = '[Nyckeltal] Get beräknat nyckeltal formel';

const beraeknatNyckeltalFormelQry = createWebDataList<typeof ActionType, BeraeknatNyckeltalFormelDTO, number>(
  ActionType,
  k => k.NyckeltalId
);

type BeraeknatNyckeltalFormelQryState = WebDataListState<BeraeknatNyckeltalFormelDTO>;

const mapBeraeknatNyckeltalFormelQryState: MapState<BeraeknatNyckeltalFormelQryState> = rootState =>
  rootState.main.nyckeltalVaerde.qryBeraeknatNyckeltalFormel;

export const selectBeraeknatNyckeltalFormelQry = (id: number) => (state: RootState) =>
  beraeknatNyckeltalFormelQry.get(mapBeraeknatNyckeltalFormelQryState(state), id);

export const beraeknatNyckeltalFormelQryAction =
  (id: number): ThunkAction =>
  async (dispatch, getState) => {
    const webData = beraeknatNyckeltalFormelQry.get(mapBeraeknatNyckeltalFormelQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(beraeknatNyckeltalFormelQry.updating(webData.data));
      } else {
        dispatch(beraeknatNyckeltalFormelQry.loading(id));
      }
      const result = await new ApiClient().NyckeltalvaerdeQryApi.getBeraeknatNyckeltalFormel({
        beraeknatNyckeltalFormelDTOQry: { NyckeltalId: id },
      });
      dispatch(beraeknatNyckeltalFormelQry.success(result));
    } catch (err) {
      dispatch(beraeknatNyckeltalFormelQry.failure(id, await parseErrorResponse(err)));
    }
  };

export default beraeknatNyckeltalFormelQry.reducer;
