/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';
import {
  UnderliggandeEnhetDTO,
  UnderliggandeEnhetDTOFromJSON,
  UnderliggandeEnhetDTOFromJSONTyped,
  UnderliggandeEnhetDTOToJSON,
} from './UnderliggandeEnhetDTO';

/**
 *
 * @export
 * @interface UnderliggandeEnheterDTO
 */
export interface UnderliggandeEnheterDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof UnderliggandeEnheterDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {Array<UnderliggandeEnhetDTO>}
   * @memberof UnderliggandeEnheterDTO
   */
  UnderliggandeEnheter: Array<UnderliggandeEnhetDTO>;
  /**
   *
   * @type {Array<number>}
   * @memberof UnderliggandeEnheterDTO
   */
  UnderliggandeNyckeltalId: Array<number>;
}

export function UnderliggandeEnheterDTOFromJSON(json: any): UnderliggandeEnheterDTO {
  return UnderliggandeEnheterDTOFromJSONTyped(json, false);
}

export function UnderliggandeEnheterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnderliggandeEnheterDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    UnderliggandeEnheter: (json['UnderliggandeEnheter'] as Array<any>).map(UnderliggandeEnhetDTOFromJSON),
    UnderliggandeNyckeltalId: json['UnderliggandeNyckeltalId'],
  };
}

export function UnderliggandeEnheterDTOToJSON(value?: UnderliggandeEnheterDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    UnderliggandeEnheter: (value.UnderliggandeEnheter as Array<any>).map(UnderliggandeEnhetDTOToJSON),
    UnderliggandeNyckeltalId: value.UnderliggandeNyckeltalId,
  };
}
