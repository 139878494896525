import { createWebDataSingletonList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { setCurrentBudgetperiodPK } from 'components/uiCurrentBudgetperiodPK';
import { BudgetperiodDTO } from 'generated-models/budgetera';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionType = '[Budgetaar] Get BudgetperiodList';

const budgetperiodListQry = createWebDataSingletonList<typeof ActionType, BudgetperiodDTO[]>(ActionType, 'Budgetaar');

type budgetperiodListQryState = WebDataListState<BudgetperiodDTO[]>;

const mapBudgetperiodListQryState: MapState<budgetperiodListQryState> = rootState => rootState.main.qryBudgetperiodList;

export const selectbudgetperiodListQry = () => (state: RootState) =>
  budgetperiodListQry.get(mapBudgetperiodListQryState(state));

export const getBudgetperiodListQryAction = (): ThunkAction => async (dispatch, getState) => {
  const webData = budgetperiodListQry.get(mapBudgetperiodListQryState(getState()));
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(budgetperiodListQry.updating(webData.data));
    } else {
      dispatch(budgetperiodListQry.loading());
    }
    const result = await new ApiClient().BudgetQryApi.getBudgetperiodList();

    dispatch(budgetperiodListQry.success(result));

    // Set the latest budgetperiodId
    dispatch(setCurrentBudgetperiodPK(result[0].BudgetperiodPK));
  } catch (err) {
    dispatch(budgetperiodListQry.failure(await parseErrorResponse(err)));
  }
};

export default budgetperiodListQry.reducer;
