/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DeadlineEnhetstyp {
  Maeklare = 'Maeklare',
  Kontor = 'Kontor',
  Affaersomraade = 'Affaersomraade',
  Bostadsmarknad = 'Bostadsmarknad',
}

export function DeadlineEnhetstypFromJSON(json: any): DeadlineEnhetstyp {
  return DeadlineEnhetstypFromJSONTyped(json, false);
}

export function DeadlineEnhetstypFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeadlineEnhetstyp {
  return json as DeadlineEnhetstyp;
}

export function DeadlineEnhetstypToJSON(value?: DeadlineEnhetstyp | null): any {
  return value as any;
}
