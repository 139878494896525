import { BudgetaarNyckeltalListDTO } from 'generated-models/budgetera/models/BudgetaarNyckeltalListDTO';
import React from 'react';
import { FbScrollingCategory } from 'shared-components/category/FbScrollingCategory';
import { FbScrollingCategoryHeader } from 'shared-components/category/FbScrollingCategoryHeader';
import { ScrollingCategory, ScrollingSubcategory } from 'shared-components/category/FbScrollingCategoryUtils';
import { BudgetaarNyckeltalFrekvensTable } from './BudgetaarNyckeltalFrekvensTable';
import { BudgetaarNyckeltalHeaders } from './BudgetaarNyckeltalHeaders';
import { BudgetaarNyckeltalTable } from './BudgetaarNyckeltalTable';

interface BudgetaarNyckeltalProps {
  BudgetaarNyckeltalList: BudgetaarNyckeltalListDTO[];
  containerRef?: React.MutableRefObject<Element | null>;
}

export const BudgetaarNyckeltal: React.FC<BudgetaarNyckeltalProps> = ({ BudgetaarNyckeltalList, containerRef }) => {
  const [title, setTitle] = React.useState<string>('');
  const [subtitle, setSubtitle] = React.useState<string>('');
  const [categories] = React.useState<ScrollingCategory[]>([]);
  const [subcategories] = React.useState<ScrollingSubcategory[]>([]);

  const setTitleCallback = (v: string) => {
    setTitle(v);
  };
  const setSubtitleCallback = (v: string) => {
    setSubtitle(v);
  };

  return (
    <>
      <div className="px-4 py-6 w-full flex bg-fb-color-white text-center sticky top-10 z-10">
        <div className="w-1/3"></div>
        <h3 className="w-1/3 font-bold">Sverige</h3>
        <h3 className="w-1/3 font-bold">Utland</h3>
      </div>
      <div className="w-full flex sticky px-4 top-24 z-20 bg-fb-color-white border-fb-color-grey-table-border border-b">
        <FbScrollingCategoryHeader zIndex={40} title={title} subtitle={subtitle} className="w-1/3 flex py-1" />
        <BudgetaarNyckeltalHeaders Utland={false} />
        <BudgetaarNyckeltalHeaders Utland={true} />
      </div>
      {BudgetaarNyckeltalList.map((sektion, sektionIndex) => (
        <div key={sektionIndex}>
          {sektion.UndersektionList.map((undersektion, undersektionIndex) => (
            <div key={undersektionIndex}>
              <div className={'w-full flex px-4  bg-fb-color-white'}>
                <div className="w-1/3 text-left flex">
                  {undersektionIndex === 0 && (
                    <FbScrollingCategory
                      topOffset={88}
                      category={{ text: sektion.Sektionnamn, scrolledPast: false }}
                      scrollableContainer={containerRef}
                      categories={categories}
                      subcategories={subcategories}
                      className={sektionIndex === 0 ? 'invisible pt-0 pb-0 h-0' : ''}
                      setTitle={setTitleCallback}
                      setSubtitle={setSubtitleCallback}
                    >
                      <h4 className="font-bold text-sm pt-3 pb-4">{sektion.Sektionnamn}</h4>
                    </FbScrollingCategory>
                  )}
                  {undersektion.Undersektionnamn && (
                    <FbScrollingCategory
                      topOffset={88}
                      category={{
                        text: undersektion.Undersektionnamn,
                        category: sektion.Sektionnamn,
                        scrolledPast: false,
                      }}
                      scrollableContainer={containerRef}
                      categories={categories}
                      subcategories={subcategories}
                      className={sektionIndex === 0 && undersektionIndex === 0 ? 'invisible pt-0 pb-0 h-0' : ''}
                      setTitle={setTitleCallback}
                      setSubtitle={setSubtitleCallback}
                    >
                      <h5 className="pt-3 pb-4 ml-4 font-semibold text-xs-increased whitespace-nowrap">
                        {undersektion.Undersektionnamn}
                      </h5>
                    </FbScrollingCategory>
                  )}
                </div>
              </div>
              <div
                className={
                  'px-4 bg-fb-color-white w-full flex' +
                  (BudgetaarNyckeltalList.length - 1 === sektionIndex &&
                  sektion.UndersektionList.length - 1 === undersektionIndex
                    ? ' pb-4'
                    : '')
                }
              >
                <BudgetaarNyckeltalTable
                  FirstUndersektion={undersektionIndex === 0}
                  Undersektion={undersektion}
                  SektionNamn={sektion.Sektionnamn}
                />
                <BudgetaarNyckeltalFrekvensTable Utland={false} Undersektion={undersektion} />
                <BudgetaarNyckeltalFrekvensTable Utland={true} Undersektion={undersektion} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
