import { NyckeltalstypVO } from 'generated-models/budgetera';

export type NyckeltalstypDomain = 'anges' | 'beraknas';

export const getNyckeltalstypDomain = (vo: NyckeltalstypVO): NyckeltalstypDomain | null => {
  switch (vo.Typ) {
    case 1:
      return 'anges';
    case 2:
      return 'beraknas';
    default:
      return null;
  }
};
