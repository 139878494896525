import { IPublicClientApplication } from '@azure/msal-browser';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import React from 'react';
import { useSelector } from 'react-redux';
import { Header } from 'shared-components/header/Header';
import { homeRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { getBudgetperiodListQryAction, selectbudgetperiodListQry } from './qryBudgetperiodList';
import { getUserQryAction, selectUserQry } from './user/qryUser';
import { selectCurrentEnhet, setCurrentEnhet } from './user/uiCurrentEnhet';

interface HeaderWrapperProps {
  instance: IPublicClientApplication;
}

export const HeaderWrapper: React.FC<HeaderWrapperProps> = instance => {
  const userQry = useSelector(selectUserQry());
  const currentEnhet = useSelector(selectCurrentEnhet());
  const budgetperiodList = useSelector(selectbudgetperiodListQry());
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(userQry)) {
      dispatch(getUserQryAction());
    }
  }, [userQry, dispatch]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(budgetperiodList)) {
      // Get the data when we log in as we need it to know which budgetperiod we want to fetch
      dispatch(getBudgetperiodListQryAction());
    }
  }, [budgetperiodList, dispatch]);

  return RemoteDataFunctions.hasData(userQry) ? (
    <>
      <Header
        logoutOnClick={async () => {
          const accounts = instance.instance.getAllAccounts();
          if (accounts) {
            await instance.instance.logoutRedirect({ account: accounts[0] });
          } else {
            // This seems highly unlikely as this would only happen if you have no active account,
            // and should thus already be logged out and not be able to click on the logout button.
            new ApiClient().KibanaApi.postClientComponentError({
              clientComponentErrorMessage: {
                ComponentName: 'HeaderWrapper: logoutOnClick',
                Message: 'AzureAD Warning: No account found when trying to use logoutRedirect.',
              },
            });
          }
        }}
        currentEnhet={currentEnhet.currentEnhet}
        enhetList={userQry.data.HeaderEnhetList.length > 0 ? userQry.data.HeaderEnhetList : undefined}
        changeEnhetOnClick={enhetVO => {
          dispatch(setCurrentEnhet(enhetVO));
          dispatch(homeRoute());
        }}
        name={userQry.data.Name}
        avatarImageUrl={
          userQry.data.MotivId
            ? (process.env.REACT_APP_MOTIV_URL as string) + userQry.data.MotivId + '.jpg?Bredd=64'
            : undefined
        }
        role={userQry.data.Role}
      />
    </>
  ) : (
    <Header
      logoutOnClick={() => null}
      currentEnhet={undefined}
      enhetList={undefined}
      changeEnhetOnClick={() => null}
      name={undefined}
      avatarImageUrl={undefined}
      role={undefined}
    />
  );
};
