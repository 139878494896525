/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PeriodiseringsprofilMaanadItem,
  PeriodiseringsprofilMaanadItemFromJSON,
  PeriodiseringsprofilMaanadItemFromJSONTyped,
  PeriodiseringsprofilMaanadItemToJSON,
} from './PeriodiseringsprofilMaanadItem';
import {
  PeriodiseringsprofilPK,
  PeriodiseringsprofilPKFromJSON,
  PeriodiseringsprofilPKFromJSONTyped,
  PeriodiseringsprofilPKToJSON,
} from './PeriodiseringsprofilPK';

/**
 *
 * @export
 * @interface ChangePeriodiseringsprofilCmd
 */
export interface ChangePeriodiseringsprofilCmd {
  /**
   *
   * @type {string}
   * @memberof ChangePeriodiseringsprofilCmd
   */
  Namn: string;
  /**
   *
   * @type {number}
   * @memberof ChangePeriodiseringsprofilCmd
   */
  readonly BostadsmarknadId?: number | null;
  /**
   *
   * @type {Array<PeriodiseringsprofilMaanadItem>}
   * @memberof ChangePeriodiseringsprofilCmd
   */
  PeriodiseringsprofilMaanadList: Array<PeriodiseringsprofilMaanadItem>;
  /**
   *
   * @type {PeriodiseringsprofilPK}
   * @memberof ChangePeriodiseringsprofilCmd
   */
  PrimaryKey: PeriodiseringsprofilPK;
}

export function ChangePeriodiseringsprofilCmdFromJSON(json: any): ChangePeriodiseringsprofilCmd {
  return ChangePeriodiseringsprofilCmdFromJSONTyped(json, false);
}

export function ChangePeriodiseringsprofilCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChangePeriodiseringsprofilCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Namn: json['Namn'],
    BostadsmarknadId: !exists(json, 'BostadsmarknadId') ? undefined : json['BostadsmarknadId'],
    PeriodiseringsprofilMaanadList: (json['PeriodiseringsprofilMaanadList'] as Array<any>).map(
      PeriodiseringsprofilMaanadItemFromJSON
    ),
    PrimaryKey: PeriodiseringsprofilPKFromJSON(json['PrimaryKey']),
  };
}

export function ChangePeriodiseringsprofilCmdToJSON(value?: ChangePeriodiseringsprofilCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Namn: value.Namn,
    PeriodiseringsprofilMaanadList: (value.PeriodiseringsprofilMaanadList as Array<any>).map(
      PeriodiseringsprofilMaanadItemToJSON
    ),
    PrimaryKey: PeriodiseringsprofilPKToJSON(value.PrimaryKey),
  };
}
