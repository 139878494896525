import { CommandFunctions, createWebCommandList } from '@decernointernal/websd.shared';
import { CreateNyckeltalvaerdeMaanadCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Nyckeltal] create nyckeltal värde månad';

interface CreateNyckeltalvaerdeMaanadCmdKey {
  NyckeltalvaerdeId: number;
  Maanad: number;
}

const createNyckeltalvaerdeMaanadCmd = createWebCommandList<typeof ActionType, CreateNyckeltalvaerdeMaanadCmd>(
  ActionType,
  cmd => constructKey(cmd)
);

const constructKey = (cmd: CreateNyckeltalvaerdeMaanadCmd): CreateNyckeltalvaerdeMaanadCmdKey => {
  return {
    NyckeltalvaerdeId: cmd.NyckeltalvaerdeId,
    Maanad: cmd.Maanad,
  };
};

export const selectCreateNyckeltalvaerdeMaanadCmd = (state: RootState, id: CreateNyckeltalvaerdeMaanadCmdKey) =>
  createNyckeltalvaerdeMaanadCmd.get(state.main.nyckeltalVaerde.qryCreateNyckeltalvaerdeMaanad, id);

export const createNyckeltalvaerdeMaanadAction =
  (cmd: CreateNyckeltalvaerdeMaanadCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectCreateNyckeltalvaerdeMaanadCmd(state, constructKey(cmd));
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(createNyckeltalvaerdeMaanadCmd.executing(cmd));

      const result = await new ApiClient().NyckeltalvaerdeMaanadCmdApi.createNyckeltalVaerdeMaanad({
        createNyckeltalvaerdeMaanadCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(createNyckeltalvaerdeMaanadCmd.success(cmd));
      // Remove command from commandList
      dispatch(createNyckeltalvaerdeMaanadCmd.remove(cmd));
    } catch (err) {
      dispatch(createNyckeltalvaerdeMaanadCmd.failure(cmd, await parseErrorResponse(err)));
    }
  };

export default createNyckeltalvaerdeMaanadCmd.reducer;
