/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';

/**
 *
 * @export
 * @interface NyckeltalvaerdeUtfallUnderliggandeDTO
 */
export interface NyckeltalvaerdeUtfallUnderliggandeDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTO
   */
  NyckeltalId?: number;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTO
   */
  UtfallFoeregaaendeAar?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTO
   */
  UtfallTvaaAarSedan?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTO
   */
  UtfallSenasteTolvMaanaderna?: number | null;
}

export function NyckeltalvaerdeUtfallUnderliggandeDTOFromJSON(json: any): NyckeltalvaerdeUtfallUnderliggandeDTO {
  return NyckeltalvaerdeUtfallUnderliggandeDTOFromJSONTyped(json, false);
}

export function NyckeltalvaerdeUtfallUnderliggandeDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeUtfallUnderliggandeDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    NyckeltalId: !exists(json, 'NyckeltalId') ? undefined : json['NyckeltalId'],
    UtfallFoeregaaendeAar: !exists(json, 'UtfallFoeregaaendeAar') ? undefined : json['UtfallFoeregaaendeAar'],
    UtfallTvaaAarSedan: !exists(json, 'UtfallTvaaAarSedan') ? undefined : json['UtfallTvaaAarSedan'],
    UtfallSenasteTolvMaanaderna: !exists(json, 'UtfallSenasteTolvMaanaderna')
      ? undefined
      : json['UtfallSenasteTolvMaanaderna'],
  };
}

export function NyckeltalvaerdeUtfallUnderliggandeDTOToJSON(value?: NyckeltalvaerdeUtfallUnderliggandeDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    NyckeltalId: value.NyckeltalId,
    UtfallFoeregaaendeAar: value.UtfallFoeregaaendeAar,
    UtfallTvaaAarSedan: value.UtfallTvaaAarSedan,
    UtfallSenasteTolvMaanaderna: value.UtfallSenasteTolvMaanaderna,
  };
}
