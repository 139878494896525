/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NyckeltalKategoriSaeljdriv {
  Foersaeljning = 'FOERSAELJNING',
  Intagsfoersoek = 'INTAGSFOERSOEK',
  Provision = 'PROVISION',
  Visningar = 'VISNINGAR',
  Uppdrag = 'UPPDRAG',
}

export function NyckeltalKategoriSaeljdrivFromJSON(json: any): NyckeltalKategoriSaeljdriv {
  return NyckeltalKategoriSaeljdrivFromJSONTyped(json, false);
}

export function NyckeltalKategoriSaeljdrivFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalKategoriSaeljdriv {
  return json as NyckeltalKategoriSaeljdriv;
}

export function NyckeltalKategoriSaeljdrivToJSON(value?: NyckeltalKategoriSaeljdriv | null): any {
  return value as any;
}
