/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CreateNyckeltalVaerdeCmd,
  CreateNyckeltalVaerdeCmdFromJSON,
  CreateNyckeltalVaerdeCmdToJSON,
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
  UpdateNyckeltalVaerdeCmd,
  UpdateNyckeltalVaerdeCmdFromJSON,
  UpdateNyckeltalVaerdeCmdToJSON,
} from '../models';

export interface CreateNyckeltalVaerdeRequest {
  createNyckeltalVaerdeCmd?: CreateNyckeltalVaerdeCmd;
}

export interface UpdateNyckeltalVaerdeRequest {
  updateNyckeltalVaerdeCmd?: UpdateNyckeltalVaerdeCmd;
}

/**
 *
 */
export class NyckeltalvaerdeCmdApi extends runtime.BaseAPI {
  /**
   */
  async createNyckeltalVaerdeRaw(
    requestParameters: CreateNyckeltalVaerdeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/NyckeltalvaerdeCmd/CreateNyckeltalVaerde`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateNyckeltalVaerdeCmdToJSON(requestParameters.createNyckeltalVaerdeCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async createNyckeltalVaerde(
    requestParameters: CreateNyckeltalVaerdeRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createNyckeltalVaerdeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateNyckeltalVaerdeRaw(
    requestParameters: UpdateNyckeltalVaerdeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/NyckeltalvaerdeCmd/UpdateNyckeltalVaerde`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateNyckeltalVaerdeCmdToJSON(requestParameters.updateNyckeltalVaerdeCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async updateNyckeltalVaerde(
    requestParameters: UpdateNyckeltalVaerdeRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.updateNyckeltalVaerdeRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
