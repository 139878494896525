import { NyckeltalvaerdeMaanadDTO } from 'generated-models/budgetera';

export const getSummaMaanader = (maanader?: NyckeltalvaerdeMaanadDTO[]) => {
  if (!maanader) {
    return undefined;
  }
  return Math.round(maanader.reduce((a, b) => a + b.Vaerde, 0));
};

export const aerSummaMaanaderAarsvaerde = (aarsvaerde: number, maanader?: NyckeltalvaerdeMaanadDTO[]) => {
  const maanaderSum = getSummaMaanader(maanader);
  return Math.round(aarsvaerde) === maanaderSum;
};
