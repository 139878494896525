/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Undersektion, UndersektionFromJSON, UndersektionFromJSONTyped, UndersektionToJSON } from './Undersektion';

/**
 *
 * @export
 * @interface BudgetaarNyckeltalListDTO
 */
export interface BudgetaarNyckeltalListDTO {
  /**
   *
   * @type {string}
   * @memberof BudgetaarNyckeltalListDTO
   */
  Sektionnamn: string;
  /**
   *
   * @type {Array<Undersektion>}
   * @memberof BudgetaarNyckeltalListDTO
   */
  UndersektionList: Array<Undersektion>;
}

export function BudgetaarNyckeltalListDTOFromJSON(json: any): BudgetaarNyckeltalListDTO {
  return BudgetaarNyckeltalListDTOFromJSONTyped(json, false);
}

export function BudgetaarNyckeltalListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetaarNyckeltalListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Sektionnamn: json['Sektionnamn'],
    UndersektionList: (json['UndersektionList'] as Array<any>).map(UndersektionFromJSON),
  };
}

export function BudgetaarNyckeltalListDTOToJSON(value?: BudgetaarNyckeltalListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Sektionnamn: value.Sektionnamn,
    UndersektionList: (value.UndersektionList as Array<any>).map(UndersektionToJSON),
  };
}
