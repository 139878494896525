/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ChangePeriodiseringsprofilCmd,
  ChangePeriodiseringsprofilCmdFromJSON,
  ChangePeriodiseringsprofilCmdToJSON,
  ChangeSorteringPeriodiseringsprofilSrvcCmd,
  ChangeSorteringPeriodiseringsprofilSrvcCmdFromJSON,
  ChangeSorteringPeriodiseringsprofilSrvcCmdToJSON,
  CreatePeriodiseringsprofilSrvcCmd,
  CreatePeriodiseringsprofilSrvcCmdFromJSON,
  CreatePeriodiseringsprofilSrvcCmdToJSON,
  DeletePeriodiseringsprofilCmd,
  DeletePeriodiseringsprofilCmdFromJSON,
  DeletePeriodiseringsprofilCmdToJSON,
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
} from '../models';

export interface ChangePeriodiseringsprofilRequest {
  changePeriodiseringsprofilCmd?: ChangePeriodiseringsprofilCmd;
}

export interface ChangeSorteringPeriodiseringsprofilRequest {
  changeSorteringPeriodiseringsprofilSrvcCmd?: ChangeSorteringPeriodiseringsprofilSrvcCmd;
}

export interface CreatePeriodiseringsprofilRequest {
  createPeriodiseringsprofilSrvcCmd?: CreatePeriodiseringsprofilSrvcCmd;
}

export interface DeletePeriodiseringsprofilRequest {
  deletePeriodiseringsprofilCmd?: DeletePeriodiseringsprofilCmd;
}

/**
 *
 */
export class PeriodiseringsprofilCmdApi extends runtime.BaseAPI {
  /**
   */
  async changePeriodiseringsprofilRaw(
    requestParameters: ChangePeriodiseringsprofilRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/PeriodiseringsprofilCmd/ChangePeriodiseringsprofil`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChangePeriodiseringsprofilCmdToJSON(requestParameters.changePeriodiseringsprofilCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async changePeriodiseringsprofil(
    requestParameters: ChangePeriodiseringsprofilRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.changePeriodiseringsprofilRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async changeSorteringPeriodiseringsprofilRaw(
    requestParameters: ChangeSorteringPeriodiseringsprofilRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/PeriodiseringsprofilCmd/ChangeSorteringPeriodiseringsprofil`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChangeSorteringPeriodiseringsprofilSrvcCmdToJSON(
          requestParameters.changeSorteringPeriodiseringsprofilSrvcCmd
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async changeSorteringPeriodiseringsprofil(
    requestParameters: ChangeSorteringPeriodiseringsprofilRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.changeSorteringPeriodiseringsprofilRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async createPeriodiseringsprofilRaw(
    requestParameters: CreatePeriodiseringsprofilRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/PeriodiseringsprofilCmd/CreatePeriodiseringsprofil`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreatePeriodiseringsprofilSrvcCmdToJSON(requestParameters.createPeriodiseringsprofilSrvcCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async createPeriodiseringsprofil(
    requestParameters: CreatePeriodiseringsprofilRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createPeriodiseringsprofilRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async deletePeriodiseringsprofilRaw(
    requestParameters: DeletePeriodiseringsprofilRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/PeriodiseringsprofilCmd/DeletePeriodiseringsprofil`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeletePeriodiseringsprofilCmdToJSON(requestParameters.deletePeriodiseringsprofilCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async deletePeriodiseringsprofil(
    requestParameters: DeletePeriodiseringsprofilRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.deletePeriodiseringsprofilRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
