/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetEnhetPair,
  BudgetEnhetPairFromJSON,
  BudgetEnhetPairFromJSONTyped,
  BudgetEnhetPairToJSON,
} from './BudgetEnhetPair';

/**
 *
 * @export
 * @interface NyckeltalvaerdeUtfallUnderliggandeDTOQry
 */
export interface NyckeltalvaerdeUtfallUnderliggandeDTOQry {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTOQry
   */
  NyckeltalId: number;
  /**
   *
   * @type {Array<BudgetEnhetPair>}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTOQry
   */
  BudgetEnhetPairList: Array<BudgetEnhetPair>;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTOQry
   */
  Kalenderaar: number;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeDTOQry
   */
  BudgetId?: number | null;
}

export function NyckeltalvaerdeUtfallUnderliggandeDTOQryFromJSON(json: any): NyckeltalvaerdeUtfallUnderliggandeDTOQry {
  return NyckeltalvaerdeUtfallUnderliggandeDTOQryFromJSONTyped(json, false);
}

export function NyckeltalvaerdeUtfallUnderliggandeDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeUtfallUnderliggandeDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: json['NyckeltalId'],
    BudgetEnhetPairList: (json['BudgetEnhetPairList'] as Array<any>).map(BudgetEnhetPairFromJSON),
    Kalenderaar: json['Kalenderaar'],
    BudgetId: !exists(json, 'BudgetId') ? undefined : json['BudgetId'],
  };
}

export function NyckeltalvaerdeUtfallUnderliggandeDTOQryToJSON(
  value?: NyckeltalvaerdeUtfallUnderliggandeDTOQry | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
    BudgetEnhetPairList: (value.BudgetEnhetPairList as Array<any>).map(BudgetEnhetPairToJSON),
    Kalenderaar: value.Kalenderaar,
    BudgetId: value.BudgetId,
  };
}
