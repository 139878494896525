/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CreateJournalCmd,
  CreateJournalCmdFromJSON,
  CreateJournalCmdToJSON,
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
  UpdateJournalCmd,
  UpdateJournalCmdFromJSON,
  UpdateJournalCmdToJSON,
} from '../models';

export interface CreateJournalRequest {
  createJournalCmd?: CreateJournalCmd;
}

export interface UpdateJournalRequest {
  updateJournalCmd?: UpdateJournalCmd;
}

/**
 *
 */
export class JournalCmdApi extends runtime.BaseAPI {
  /**
   */
  async createJournalRaw(
    requestParameters: CreateJournalRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/JournalCmd/CreateJournal`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateJournalCmdToJSON(requestParameters.createJournalCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async createJournal(
    requestParameters: CreateJournalRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createJournalRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateJournalRaw(
    requestParameters: UpdateJournalRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/JournalCmd/UpdateJournal`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateJournalCmdToJSON(requestParameters.updateJournalCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async updateJournal(
    requestParameters: UpdateJournalRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.updateJournalRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
