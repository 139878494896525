import { createWebDataList, RemoteDataFunctions, SdKeys, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetPK } from 'generated-models/budgetera/models';
import { BudgetListWrapperDTO } from 'generated-models/budgetera/models/BudgetListWrapperDTO';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType, webDataSetStale } from 'utils/eventDispatcher';
const ActionType = '[budget] Get budget';

// Slice for fetching budget for the selected enhet
const budgetQry = createWebDataList<typeof ActionType, BudgetListWrapperDTO, BudgetPK>(
  ActionType,
  k => k.Budget.BudgetPK
);

type BudgetQryState = WebDataListState<BudgetListWrapperDTO>;

const mapBudgetQryState: MapState<BudgetQryState> = rootState => rootState.main.budget.qryBudget;

export const selectBudgetQry = (id: BudgetPK) => (state: RootState) => budgetQry.get(mapBudgetQryState(state), id);

export const budgetQryAction =
  (id: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = budgetQry.get(mapBudgetQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(budgetQry.updating(webData.data));
      } else {
        dispatch(budgetQry.loading(id));
      }
      const result = await new ApiClient().BudgetQryApi.getBudget({ budgetDTOQry: id });
      dispatch(budgetQry.success(result));
    } catch (err) {
      dispatch(budgetQry.failure(id, await parseErrorResponse(err)));
    }
  };

// React to integration events which invalidates this qry (set it stale)
export const budgetQryReducer: Reducer<BudgetQryState, IntegrationEventAction> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.Budget: {
      const pk = action.payload.eventDataKey;
      const webData = budgetQry.get(state, pk);
      return {
        ...state,
        [SdKeys.keyToString(pk)]: webDataSetStale(webData),
      };
    }
  }
  return budgetQry.reducer(state, action);
};
