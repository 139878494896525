import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';

const ActionType = '[Deadline] Create budgetår';

const createBudgetaarCmd = createWebCommandSingletonList<typeof ActionType, {}>(ActionType, ActionType);

export const selectCreateBudgetaarCmd = (state: RootState) =>
  createBudgetaarCmd.get(state.Budgetaar.createBudgetaarCmd);

export const createBudgetaarAction = (): ThunkAction => async (dispatch, getState) => {
  const state = getState();
  const webCommand = selectCreateBudgetaarCmd(state);
  if (CommandFunctions.isExecuting(webCommand)) {
    // Cancel execution if command is already executing
    return;
  }
  // Call api and handle result
  try {
    // Set executing status
    dispatch(createBudgetaarCmd.executing());

    const result = await new ApiClient().BudgetperiodCmdApi.createBudgetar();
    // Dispatch integration events returned from the server
    dispatchIntegrationEvents(dispatch, result);

    // Handle success response for a command
    dispatch(createBudgetaarCmd.success({}));
  } catch (err) {
    dispatch(createBudgetaarCmd.failure(await parseErrorResponse(err)));
  }
};

export default createBudgetaarCmd.reducer;
