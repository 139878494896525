import { combineReducers } from 'redux';
import cmdCreatePlanned from './cmdCreatePlanned';
import cmdOpenUnderliggande from './cmdOpenUnderliggande';
import { budgetListQryReducer, budgetListUnderlyingQryReducer } from './qryBudgetList';

export const budgetListReducer = combineReducers({
  qryBudgetList: budgetListQryReducer,
  qryBudgetListUnderlying: budgetListUnderlyingQryReducer,
  cmdOpenUnderliggande: cmdOpenUnderliggande,
  cmdCreatePlanned: cmdCreatePlanned,
});
