import { createWebDataList, RemoteDataFunctions, SdKeys, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetnyckeltalListWrapperDTO, BudgetPK } from 'generated-models/budgetera/models';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType, webDataSetStale } from 'utils/eventDispatcher';

const ActionType = '[budget] Get budgetnyckeltalList';

// Slice for fetching budget for the selected enhet
const budgetnyckeltalListWrapperQry = createWebDataList<typeof ActionType, BudgetnyckeltalListWrapperDTO, BudgetPK>(
  ActionType,
  k => k.BudgetPK
);

type BudgetnyckeltalListWrapperQryState = WebDataListState<BudgetnyckeltalListWrapperDTO>;

const mapBudgetnyckeltalListWrapperQryState: MapState<BudgetnyckeltalListWrapperQryState> = rootState =>
  rootState.main.budget.qryBudgetnyckeltalList;

export const selectBudgetnyckeltalListWrapperQry = (id: BudgetPK) => (state: RootState) =>
  budgetnyckeltalListWrapperQry.get(mapBudgetnyckeltalListWrapperQryState(state), id);

export const budgetnyckeltalListQryAction =
  (id: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = budgetnyckeltalListWrapperQry.get(mapBudgetnyckeltalListWrapperQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(budgetnyckeltalListWrapperQry.updating(webData.data));
      } else {
        dispatch(budgetnyckeltalListWrapperQry.loading(id));
      }
      const result = await new ApiClient().BudgetQryApi.getBudgetnyckeltalList({ budgetnyckeltalListDTOQry: id });
      dispatch(budgetnyckeltalListWrapperQry.success(result));
    } catch (err) {
      dispatch(budgetnyckeltalListWrapperQry.failure(id, await parseErrorResponse(err)));
    }
  };

// React to integration events which invalidates this qry (set it stale)
export const budgetnyckeltalListWrapperQryReducer: Reducer<
  BudgetnyckeltalListWrapperQryState,
  IntegrationEventAction
> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.Budget: {
      const pk = action.payload.eventDataKey;
      const webData = budgetnyckeltalListWrapperQry.get(state, pk);
      return {
        ...state,
        [SdKeys.keyToString(pk)]: webDataSetStale(webData),
      };
    }
  }
  return budgetnyckeltalListWrapperQry.reducer(state, action);
};
