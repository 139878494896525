/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NyckeltalCalculation,
  NyckeltalCalculationFromJSON,
  NyckeltalCalculationFromJSONTyped,
  NyckeltalCalculationToJSON,
} from './NyckeltalCalculation';

/**
 *
 * @export
 * @interface BeraeknatNyckeltalFormelDTO
 */
export interface BeraeknatNyckeltalFormelDTO {
  /**
   *
   * @type {number}
   * @memberof BeraeknatNyckeltalFormelDTO
   */
  NyckeltalId: number;
  /**
   *
   * @type {NyckeltalCalculation}
   * @memberof BeraeknatNyckeltalFormelDTO
   */
  NyckeltalCalculation?: NyckeltalCalculation;
}

export function BeraeknatNyckeltalFormelDTOFromJSON(json: any): BeraeknatNyckeltalFormelDTO {
  return BeraeknatNyckeltalFormelDTOFromJSONTyped(json, false);
}

export function BeraeknatNyckeltalFormelDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BeraeknatNyckeltalFormelDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: json['NyckeltalId'],
    NyckeltalCalculation: !exists(json, 'NyckeltalCalculation')
      ? undefined
      : NyckeltalCalculationFromJSON(json['NyckeltalCalculation']),
  };
}

export function BeraeknatNyckeltalFormelDTOToJSON(value?: BeraeknatNyckeltalFormelDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
    NyckeltalCalculation: NyckeltalCalculationToJSON(value.NyckeltalCalculation),
  };
}
