/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum Operator {
  Addition = 'Addition',
  Multiplication = 'Multiplication',
  Division = 'Division',
  AddMultiplty = 'AddMultiplty',
}

export function OperatorFromJSON(json: any): Operator {
  return OperatorFromJSONTyped(json, false);
}

export function OperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Operator {
  return json as Operator;
}

export function OperatorToJSON(value?: Operator | null): any {
  return value as any;
}
