/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';
import {
  SummaUnderliggande,
  SummaUnderliggandeFromJSON,
  SummaUnderliggandeFromJSONTyped,
  SummaUnderliggandeToJSON,
} from './SummaUnderliggande';

/**
 *
 * @export
 * @interface SummaUnderliggandeListDTO
 */
export interface SummaUnderliggandeListDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof SummaUnderliggandeListDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {Array<SummaUnderliggande>}
   * @memberof SummaUnderliggandeListDTO
   */
  SummaUnderliggandeList: Array<SummaUnderliggande>;
}

export function SummaUnderliggandeListDTOFromJSON(json: any): SummaUnderliggandeListDTO {
  return SummaUnderliggandeListDTOFromJSONTyped(json, false);
}

export function SummaUnderliggandeListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SummaUnderliggandeListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    SummaUnderliggandeList: (json['SummaUnderliggandeList'] as Array<any>).map(SummaUnderliggandeFromJSON),
  };
}

export function SummaUnderliggandeListDTOToJSON(value?: SummaUnderliggandeListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    SummaUnderliggandeList: (value.SummaUnderliggandeList as Array<any>).map(SummaUnderliggandeToJSON),
  };
}
