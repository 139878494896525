import {
  createWebDataList,
  RemoteDataFunctions,
  RemoteDataStatus,
  SdKeys,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { BudgetPK, KommentarWrapperDTO } from 'generated-models/budgetera';
import { Reducer } from 'react';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType } from 'utils/eventDispatcher';
import { VOCreate } from 'utils/ValueObjectHelpers';

const ActionType = '[Kommentar] Get kommentar';

const kommentarQry = createWebDataList<typeof ActionType, KommentarWrapperDTO, BudgetPK>(ActionType, k => {
  return k.BudgetPK;
});

type KommentarQryState = WebDataListState<KommentarWrapperDTO>;

const mapKommentarQryState: MapState<KommentarQryState> = rootState => rootState.main.budget.qryKommentar.qryKommentar;

export const selectKommentarQry = (id: BudgetPK) => (state: RootState) =>
  kommentarQry.get(mapKommentarQryState(state), id);

export const kommentarQryAction =
  (id: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = kommentarQry.get(mapKommentarQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(kommentarQry.updating(webData.data));
      } else {
        dispatch(kommentarQry.loading(id));
      }
      const result = await new ApiClient().KommentarQryApi.getKommentarList({ kommentarDTOQry: id });
      dispatch(kommentarQry.success(result));
    } catch (err) {
      dispatch(kommentarQry.failure(id, await parseErrorResponse(err)));
    }
  };

// React to integration events
export const kommentarQryReducer: Reducer<KommentarQryState, IntegrationEventAction> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.Kommentar: {
      // Since we react to a DTO event, we will recieve the data we need to update redux directly
      const pk = VOCreate<BudgetPK>({ BudgetId: action.payload.eventData.BudgetId });
      const webData = kommentarQry.get(state, pk);
      if (RemoteDataFunctions.hasData(webData)) {
        // Only update redux if we actually have data
        return {
          ...state,
          [SdKeys.keyToString(pk)]: {
            ...webData,
            status: RemoteDataStatus.Stale,
          },
        };
      }
    }
  }

  return kommentarQry.reducer(state, action);
};
