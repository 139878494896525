import { BudgetStatusVO } from 'generated-models/budgetera';

export type BudgetStatusDomain = 'created' | 'ready' | 'done' | 'approved' | 'for supplementation';

export const getBudgetStatusDomain = (vo: BudgetStatusVO): BudgetStatusDomain | null => {
  switch (vo.Status) {
    case 1:
      return 'created';
    case 2:
      return 'ready';
    case 3:
      return 'done';
    case 4:
      return 'approved';
    case 5:
      return 'for supplementation';
    default:
      return null;
  }
};

export const getBudgetStatusText = (status: BudgetStatusVO): string | null => {
  switch (getBudgetStatusDomain(status)) {
    case 'created':
      return 'Skapad';
    case 'ready':
      return 'Redo för budgetering';
    case 'done':
      return 'Klarmarkerad';
    case 'approved':
      return 'Godkänd';
    case 'for supplementation':
      return 'För komplettering';
    default:
      return null;
  }
};
