import {
  createWebDataList,
  RemoteDataFunctions,
  RemoteDataStatus,
  SdKeys,
  WebDataListState,
} from '@decernointernal/websd.shared';
import {
  BudgetaarPeriodiseringsprofilListDTOQry,
  BudgetaarPeriodiseringsprofilListWrapperDTO,
  BudgetperiodPK,
  SdDbOperation,
} from 'generated-models/budgetera';
import { Reducer } from 'react';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType, webDataSetStale } from 'utils/eventDispatcher';
const ActionType = '[Budgetaar] GetPeriodiseringsprofil';

const BudgetaarPeriodiseringsprofilQry = createWebDataList<
  typeof ActionType,
  BudgetaarPeriodiseringsprofilListWrapperDTO,
  BudgetperiodPK
>(ActionType, k => k.BudgetperiodPK);

type BudgetaarPeriodiseringsprofilQryState = WebDataListState<BudgetaarPeriodiseringsprofilListWrapperDTO>;

const mapBudgetaarPeriodiseringsprofilQryState: MapState<BudgetaarPeriodiseringsprofilQryState> = rootState =>
  rootState.Budgetaar.periodiseringsprofilQry;

export const selectBudgetaarPeriodiseringsprofilQry = (id: BudgetperiodPK) => (state: RootState) =>
  BudgetaarPeriodiseringsprofilQry.get(mapBudgetaarPeriodiseringsprofilQryState(state), id);

export const getBudgetaarPeriodiseringsprofilQryAction =
  (qry: BudgetaarPeriodiseringsprofilListDTOQry): ThunkAction =>
  async (dispatch, getState) => {
    const webData = BudgetaarPeriodiseringsprofilQry.get(mapBudgetaarPeriodiseringsprofilQryState(getState()), {
      BudgetperiodId: qry.BudgetperiodId,
    });
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(BudgetaarPeriodiseringsprofilQry.updating(webData.data));
      } else {
        dispatch(BudgetaarPeriodiseringsprofilQry.loading({ BudgetperiodId: qry.BudgetperiodId }));
      }
      const result = await new ApiClient().BudgetaarQryApi.getBudgetaarPeriodiseringsprofilList({
        budgetaarPeriodiseringsprofilListDTOQry: qry,
      });

      dispatch(BudgetaarPeriodiseringsprofilQry.success(result));
    } catch (err) {
      dispatch(
        BudgetaarPeriodiseringsprofilQry.failure({ BudgetperiodId: qry.BudgetperiodId }, await parseErrorResponse(err))
      );
    }
  };

export const BudgetaarPeriodiseringsprofilQryReducer: Reducer<
  BudgetaarPeriodiseringsprofilQryState,
  IntegrationEventAction
> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.Periodiseringsprofil: {
      const pk = action.payload.eventData.BudgetperiodPK;
      const webData = BudgetaarPeriodiseringsprofilQry.get(state, pk);
      if (RemoteDataFunctions.hasData(webData)) {
        if (action.payload.operation === SdDbOperation.Update) {
          const periodiseringsprofilIndex = webData.data.BudgetaarPeriodiseringsprofilList.findIndex(
            p => p.PeriodiseringsprofilPK.PeriodiseringsprofilId === action.payload.eventDataKey.PeriodiseringsprofilId
          );
          const updatedPeriodiseringsprofil = {
            ...webData.data.BudgetaarPeriodiseringsprofilList[periodiseringsprofilIndex],
            Sortering: action.payload.eventData.Sortering,
            Namn: action.payload.eventData.Namn,
            BostadsmarknadId: action.payload.eventData.BostadsmarknadId,
          };
          return {
            ...state,
            [SdKeys.keyToString(pk)]: {
              data: {
                BudgetperiodPK: webData.data.BudgetperiodPK,
                BudgetaarPeriodiseringsprofilList: [
                  ...webData.data.BudgetaarPeriodiseringsprofilList.slice(0, periodiseringsprofilIndex),
                  updatedPeriodiseringsprofil,
                  ...webData.data.BudgetaarPeriodiseringsprofilList.slice(periodiseringsprofilIndex + 1),
                ],
              },
              status: RemoteDataStatus.Success,
            },
          };
        } else if (
          action.payload.operation === SdDbOperation.Insert ||
          action.payload.operation === SdDbOperation.Delete
        ) {
          return {
            ...state,
            [SdKeys.keyToString(pk)]: webDataSetStale(webData),
          };
        }
      }
    }
  }
  return BudgetaarPeriodiseringsprofilQry.reducer(state, action);
};
