/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ApproveBudgetCmd,
  ApproveBudgetCmdFromJSON,
  ApproveBudgetCmdToJSON,
  ComplementBudgetCmd,
  ComplementBudgetCmdFromJSON,
  ComplementBudgetCmdToJSON,
  ConfirmBudgetSrvcCmd,
  ConfirmBudgetSrvcCmdFromJSON,
  ConfirmBudgetSrvcCmdToJSON,
  CopyBudgetSrvcCmd,
  CopyBudgetSrvcCmdFromJSON,
  CopyBudgetSrvcCmdToJSON,
  CreateBudgetWithPlannedBudgetSrvcCmd,
  CreateBudgetWithPlannedBudgetSrvcCmdFromJSON,
  CreateBudgetWithPlannedBudgetSrvcCmdToJSON,
  OpenUnderliggandeBudgetSrvcCmd,
  OpenUnderliggandeBudgetSrvcCmdFromJSON,
  OpenUnderliggandeBudgetSrvcCmdToJSON,
  ReopenBudgetSrvcCmd,
  ReopenBudgetSrvcCmdFromJSON,
  ReopenBudgetSrvcCmdToJSON,
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
  UndoBudgetDoneCmd,
  UndoBudgetDoneCmdFromJSON,
  UndoBudgetDoneCmdToJSON,
  UpdateBudgetFoerutsaettningarCmd,
  UpdateBudgetFoerutsaettningarCmdFromJSON,
  UpdateBudgetFoerutsaettningarCmdToJSON,
  UpdateBudgetNyckeltalFoervaentningCmd,
  UpdateBudgetNyckeltalFoervaentningCmdFromJSON,
  UpdateBudgetNyckeltalFoervaentningCmdToJSON,
  UpdateBudgetNyckeltalFoervaentningSrvcCmd,
  UpdateBudgetNyckeltalFoervaentningSrvcCmdFromJSON,
  UpdateBudgetNyckeltalFoervaentningSrvcCmdToJSON,
} from '../models';

export interface CopyBudgetRequest {
  copyBudgetSrvcCmd?: CopyBudgetSrvcCmd;
}

export interface CreatePlannedBudgetRequest {
  createBudgetWithPlannedBudgetSrvcCmd?: CreateBudgetWithPlannedBudgetSrvcCmd;
}

export interface OpenUnderliggandeBudgetRequest {
  openUnderliggandeBudgetSrvcCmd?: OpenUnderliggandeBudgetSrvcCmd;
}

export interface SetBudgetComplementRequest {
  complementBudgetCmd?: ComplementBudgetCmd;
}

export interface SetBudgetDoneRequest {
  confirmBudgetSrvcCmd?: ConfirmBudgetSrvcCmd;
}

export interface SetBudgetFoerutsaettningarRequest {
  updateBudgetFoerutsaettningarCmd?: UpdateBudgetFoerutsaettningarCmd;
}

export interface SetBudgetGodkaendRequest {
  approveBudgetCmd?: ApproveBudgetCmd;
}

export interface SetBudgetNyckeltalFoervaentningRequest {
  updateBudgetNyckeltalFoervaentningCmd?: UpdateBudgetNyckeltalFoervaentningCmd;
}

export interface SetBudgetNyckeltalFoervaentningarRequest {
  updateBudgetNyckeltalFoervaentningSrvcCmd?: UpdateBudgetNyckeltalFoervaentningSrvcCmd;
}

export interface SetBudgetReopenedRequest {
  reopenBudgetSrvcCmd?: ReopenBudgetSrvcCmd;
}

export interface UndoBudgetDoneRequest {
  undoBudgetDoneCmd?: UndoBudgetDoneCmd;
}

/**
 *
 */
export class BudgetCmdApi extends runtime.BaseAPI {
  /**
   */
  async copyBudgetRaw(
    requestParameters: CopyBudgetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/CopyBudget`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CopyBudgetSrvcCmdToJSON(requestParameters.copyBudgetSrvcCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async copyBudget(
    requestParameters: CopyBudgetRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.copyBudgetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async createPlannedBudgetRaw(
    requestParameters: CreatePlannedBudgetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/CreatePlannedBudget`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateBudgetWithPlannedBudgetSrvcCmdToJSON(requestParameters.createBudgetWithPlannedBudgetSrvcCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async createPlannedBudget(
    requestParameters: CreatePlannedBudgetRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createPlannedBudgetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async openUnderliggandeBudgetRaw(
    requestParameters: OpenUnderliggandeBudgetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/OpenUnderliggandeBudget`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OpenUnderliggandeBudgetSrvcCmdToJSON(requestParameters.openUnderliggandeBudgetSrvcCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async openUnderliggandeBudget(
    requestParameters: OpenUnderliggandeBudgetRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.openUnderliggandeBudgetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async setBudgetComplementRaw(
    requestParameters: SetBudgetComplementRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/SetBudgetComplement`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ComplementBudgetCmdToJSON(requestParameters.complementBudgetCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async setBudgetComplement(
    requestParameters: SetBudgetComplementRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.setBudgetComplementRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async setBudgetDoneRaw(
    requestParameters: SetBudgetDoneRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/SetBudgetDone`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ConfirmBudgetSrvcCmdToJSON(requestParameters.confirmBudgetSrvcCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async setBudgetDone(
    requestParameters: SetBudgetDoneRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.setBudgetDoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async setBudgetFoerutsaettningarRaw(
    requestParameters: SetBudgetFoerutsaettningarRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/SetBudgetFoerutsaettningar`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateBudgetFoerutsaettningarCmdToJSON(requestParameters.updateBudgetFoerutsaettningarCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async setBudgetFoerutsaettningar(
    requestParameters: SetBudgetFoerutsaettningarRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.setBudgetFoerutsaettningarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async setBudgetGodkaendRaw(
    requestParameters: SetBudgetGodkaendRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/SetBudgetGodkaend`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApproveBudgetCmdToJSON(requestParameters.approveBudgetCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async setBudgetGodkaend(
    requestParameters: SetBudgetGodkaendRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.setBudgetGodkaendRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async setBudgetNyckeltalFoervaentningRaw(
    requestParameters: SetBudgetNyckeltalFoervaentningRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/SetBudgetNyckeltalFoervaentning`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateBudgetNyckeltalFoervaentningCmdToJSON(requestParameters.updateBudgetNyckeltalFoervaentningCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async setBudgetNyckeltalFoervaentning(
    requestParameters: SetBudgetNyckeltalFoervaentningRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.setBudgetNyckeltalFoervaentningRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async setBudgetNyckeltalFoervaentningarRaw(
    requestParameters: SetBudgetNyckeltalFoervaentningarRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/SetBudgetNyckeltalFoervaentningar`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateBudgetNyckeltalFoervaentningSrvcCmdToJSON(
          requestParameters.updateBudgetNyckeltalFoervaentningSrvcCmd
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async setBudgetNyckeltalFoervaentningar(
    requestParameters: SetBudgetNyckeltalFoervaentningarRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.setBudgetNyckeltalFoervaentningarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async setBudgetReopenedRaw(
    requestParameters: SetBudgetReopenedRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/SetBudgetReopened`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ReopenBudgetSrvcCmdToJSON(requestParameters.reopenBudgetSrvcCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async setBudgetReopened(
    requestParameters: SetBudgetReopenedRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.setBudgetReopenedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async undoBudgetDoneRaw(
    requestParameters: UndoBudgetDoneRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/BudgetCmd/UndoBudgetDone`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UndoBudgetDoneCmdToJSON(requestParameters.undoBudgetDoneCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async undoBudgetDone(
    requestParameters: UndoBudgetDoneRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.undoBudgetDoneRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
