/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';
import {
  PeriodiseringsprofilDTO,
  PeriodiseringsprofilDTOFromJSON,
  PeriodiseringsprofilDTOFromJSONTyped,
  PeriodiseringsprofilDTOToJSON,
} from './PeriodiseringsprofilDTO';

/**
 *
 * @export
 * @interface PeriodiseringsprofilListDTO
 */
export interface PeriodiseringsprofilListDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof PeriodiseringsprofilListDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {Array<PeriodiseringsprofilDTO>}
   * @memberof PeriodiseringsprofilListDTO
   */
  Periodiseringsprofiler: Array<PeriodiseringsprofilDTO>;
}

export function PeriodiseringsprofilListDTOFromJSON(json: any): PeriodiseringsprofilListDTO {
  return PeriodiseringsprofilListDTOFromJSONTyped(json, false);
}

export function PeriodiseringsprofilListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PeriodiseringsprofilListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    Periodiseringsprofiler: (json['Periodiseringsprofiler'] as Array<any>).map(PeriodiseringsprofilDTOFromJSON),
  };
}

export function PeriodiseringsprofilListDTOToJSON(value?: PeriodiseringsprofilListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    Periodiseringsprofiler: (value.Periodiseringsprofiler as Array<any>).map(PeriodiseringsprofilDTOToJSON),
  };
}
