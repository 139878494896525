import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import {
  BudgetEnhetPair,
  BudgetPK,
  NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO,
} from 'generated-models/budgetera/models';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionType = '[UnderliggandeUtfall] Get underliggandeUtfall';

interface UnderliggandeUtfallMaanadIdentifier {
  BudgetId: number;
  NyckeltalId: number;
  Type: 'underliggandeUtfall';
}

const underliggandeUtfallMaanadQry = createWebDataList<
  typeof ActionType,
  NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO,
  UnderliggandeUtfallMaanadIdentifier
>(ActionType, k => {
  return { BudgetId: k.BudgetPK.BudgetId!, NyckeltalId: k.nyckeltalId, Type: 'underliggandeUtfall' };
});

type underliggandeUtfallMaanadQryState = WebDataListState<NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO>;

const mapUnderliggandeUtfallMaanadQryState: MapState<underliggandeUtfallMaanadQryState> = rootState =>
  rootState.main.nyckeltalVaerde.qryUnderliggandeUtfallMaanad;

export const selectUnderliggandeUtfallMaanadQry = (id: UnderliggandeUtfallMaanadIdentifier) => (state: RootState) =>
  underliggandeUtfallMaanadQry.get(mapUnderliggandeUtfallMaanadQryState(state), id);

export const getUnderliggandeUtfallMaanadQryAction =
  (nyckeltalId: number, budgetId: BudgetPK, budgetEnhetPairList: BudgetEnhetPair[]): ThunkAction =>
  async (dispatch, getState) => {
    const webData = underliggandeUtfallMaanadQry.get(mapUnderliggandeUtfallMaanadQryState(getState()), {
      BudgetId: budgetId.BudgetId!,
      NyckeltalId: nyckeltalId,
      Type: 'underliggandeUtfall',
    });
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(underliggandeUtfallMaanadQry.updating(webData.data));
      } else {
        dispatch(
          underliggandeUtfallMaanadQry.loading({
            BudgetId: budgetId.BudgetId!,
            NyckeltalId: nyckeltalId,
            Type: 'underliggandeUtfall',
          })
        );
      }
      const result = await new ApiClient().NyckeltalvaerdeQryApi.getNyckeltalvaerdeUtfallMaanadUnderliggande({
        nyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry: {
          BudgetId: budgetId.BudgetId,
          NyckeltalId: nyckeltalId,
          BudgetEnhetPairList: budgetEnhetPairList,
        },
      });

      dispatch(underliggandeUtfallMaanadQry.success(result));
    } catch (err) {
      dispatch(
        underliggandeUtfallMaanadQry.failure(
          { BudgetId: budgetId.BudgetId!, NyckeltalId: nyckeltalId, Type: 'underliggandeUtfall' },
          await parseErrorResponse(err)
        )
      );
    }
  };

export default underliggandeUtfallMaanadQry.reducer;
