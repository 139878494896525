/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Enhetstyp, EnhetstypFromJSON, EnhetstypFromJSONTyped, EnhetstypToJSON } from './Enhetstyp';
import { Typ, TypFromJSON, TypFromJSONTyped, TypToJSON } from './Typ';

/**
 *
 * @export
 * @interface BudgetnyckeltalListDTO
 */
export interface BudgetnyckeltalListDTO {
  /**
   *
   * @type {number}
   * @memberof BudgetnyckeltalListDTO
   */
  NyckeltalId: number;
  /**
   *
   * @type {number}
   * @memberof BudgetnyckeltalListDTO
   */
  BudgetId: number;
  /**
   *
   * @type {number}
   * @memberof BudgetnyckeltalListDTO
   */
  BudgetnyckeltalLoepnummer: number;
  /**
   *
   * @type {string}
   * @memberof BudgetnyckeltalListDTO
   */
  Kategori: string;
  /**
   *
   * @type {number}
   * @memberof BudgetnyckeltalListDTO
   */
  KategoriSortering: number;
  /**
   *
   * @type {string}
   * @memberof BudgetnyckeltalListDTO
   */
  Subkategori: string;
  /**
   *
   * @type {number}
   * @memberof BudgetnyckeltalListDTO
   */
  SubkategoriSortering: number;
  /**
   *
   * @type {string}
   * @memberof BudgetnyckeltalListDTO
   */
  Namn: string;
  /**
   *
   * @type {Typ}
   * @memberof BudgetnyckeltalListDTO
   */
  Typ: Typ;
  /**
   *
   * @type {Enhetstyp}
   * @memberof BudgetnyckeltalListDTO
   */
  Enhet: Enhetstyp;
  /**
   *
   * @type {number}
   * @memberof BudgetnyckeltalListDTO
   */
  PeriodiseringsprofilId: number;
  /**
   *
   * @type {boolean}
   * @memberof BudgetnyckeltalListDTO
   */
  SkaSummeras: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BudgetnyckeltalListDTO
   */
  NotIncludedInBudget: boolean;
}

export function BudgetnyckeltalListDTOFromJSON(json: any): BudgetnyckeltalListDTO {
  return BudgetnyckeltalListDTOFromJSONTyped(json, false);
}

export function BudgetnyckeltalListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetnyckeltalListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: json['NyckeltalId'],
    BudgetId: json['BudgetId'],
    BudgetnyckeltalLoepnummer: json['BudgetnyckeltalLoepnummer'],
    Kategori: json['Kategori'],
    KategoriSortering: json['KategoriSortering'],
    Subkategori: json['Subkategori'],
    SubkategoriSortering: json['SubkategoriSortering'],
    Namn: json['Namn'],
    Typ: TypFromJSON(json['Typ']),
    Enhet: EnhetstypFromJSON(json['Enhet']),
    PeriodiseringsprofilId: json['PeriodiseringsprofilId'],
    SkaSummeras: json['SkaSummeras'],
    NotIncludedInBudget: json['NotIncludedInBudget'],
  };
}

export function BudgetnyckeltalListDTOToJSON(value?: BudgetnyckeltalListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
    BudgetId: value.BudgetId,
    BudgetnyckeltalLoepnummer: value.BudgetnyckeltalLoepnummer,
    Kategori: value.Kategori,
    KategoriSortering: value.KategoriSortering,
    Subkategori: value.Subkategori,
    SubkategoriSortering: value.SubkategoriSortering,
    Namn: value.Namn,
    Typ: TypToJSON(value.Typ),
    Enhet: EnhetstypToJSON(value.Enhet),
    PeriodiseringsprofilId: value.PeriodiseringsprofilId,
    SkaSummeras: value.SkaSummeras,
    NotIncludedInBudget: value.NotIncludedInBudget,
  };
}
