import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetPK, SummaUnderliggandeListDTO } from 'generated-models/budgetera/models';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionType = '[SummaUnderliggandeList] Get SummaUnderliggandeList';

const summaUnderliggandeListQry = createWebDataList<typeof ActionType, SummaUnderliggandeListDTO, BudgetPK>(
  ActionType,
  k => k.BudgetPK
);

type SummaUnderliggandeListQryState = WebDataListState<SummaUnderliggandeListDTO>;

const mapSummaUnderliggandeListQryState: MapState<SummaUnderliggandeListQryState> = rootState =>
  rootState.main.budget.qrySummaUnderliggandeList;

export const selectSummaUnderliggandeListQry = (id: BudgetPK) => (state: RootState) =>
  summaUnderliggandeListQry.get(mapSummaUnderliggandeListQryState(state), id);

export const getSummaUnderliggandeListQryAction =
  (budgetId: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = summaUnderliggandeListQry.get(mapSummaUnderliggandeListQryState(getState()), budgetId);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(summaUnderliggandeListQry.updating(webData.data));
      } else {
        dispatch(summaUnderliggandeListQry.loading(budgetId));
      }
      const result = await new ApiClient().BudgetQryApi.getBudgetSummaUnderliggande({
        summaUnderliggandeListDTOQry: budgetId,
      });

      dispatch(summaUnderliggandeListQry.success(result));
    } catch (err) {
      dispatch(summaUnderliggandeListQry.failure(budgetId, await parseErrorResponse(err)));
    }
  };

export default summaUnderliggandeListQry.reducer;
