/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { EnhetVO, EnhetVOFromJSON, EnhetVOFromJSONTyped, EnhetVOToJSON } from './EnhetVO';

/**
 *
 * @export
 * @interface BudgetListDTOQry
 */
export interface BudgetListDTOQry {
  /**
   *
   * @type {boolean}
   * @memberof BudgetListDTOQry
   */
  ShowUnderlyingEnhet: boolean;
  /**
   *
   * @type {EnhetVO}
   * @memberof BudgetListDTOQry
   */
  EnhetVO: EnhetVO;
  /**
   *
   * @type {number}
   * @memberof BudgetListDTOQry
   */
  BudgetperiodId?: number | null;
}

export function BudgetListDTOQryFromJSON(json: any): BudgetListDTOQry {
  return BudgetListDTOQryFromJSONTyped(json, false);
}

export function BudgetListDTOQryFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetListDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ShowUnderlyingEnhet: json['ShowUnderlyingEnhet'],
    EnhetVO: EnhetVOFromJSON(json['EnhetVO']),
    BudgetperiodId: !exists(json, 'BudgetperiodId') ? undefined : json['BudgetperiodId'],
  };
}

export function BudgetListDTOQryToJSON(value?: BudgetListDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ShowUnderlyingEnhet: value.ShowUnderlyingEnhet,
    EnhetVO: EnhetVOToJSON(value.EnhetVO),
    BudgetperiodId: value.BudgetperiodId,
  };
}
