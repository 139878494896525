/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { KommentarPK, KommentarPKFromJSON, KommentarPKFromJSONTyped, KommentarPKToJSON } from './KommentarPK';

/**
 *
 * @export
 * @interface KommentarDTO
 */
export interface KommentarDTO {
  /**
   *
   * @type {number}
   * @memberof KommentarDTO
   */
  KommentarId: number;
  /**
   *
   * @type {number}
   * @memberof KommentarDTO
   */
  BudgetId: number;
  /**
   *
   * @type {string}
   * @memberof KommentarDTO
   */
  Text: string;
  /**
   *
   * @type {number}
   * @memberof KommentarDTO
   */
  FoerfattarPersonId: number;
  /**
   *
   * @type {Date}
   * @memberof KommentarDTO
   */
  Datum: Date;
  /**
   *
   * @type {boolean}
   * @memberof KommentarDTO
   */
  AerHistorisk: boolean;
  /**
   *
   * @type {boolean}
   * @memberof KommentarDTO
   */
  FaarTasBort: boolean;
  /**
   *
   * @type {string}
   * @memberof KommentarDTO
   */
  Personnamn: string;
  /**
   *
   * @type {KommentarPK}
   * @memberof KommentarDTO
   */
  KommentarPK: KommentarPK;
}

export function KommentarDTOFromJSON(json: any): KommentarDTO {
  return KommentarDTOFromJSONTyped(json, false);
}

export function KommentarDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): KommentarDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    KommentarId: json['KommentarId'],
    BudgetId: json['BudgetId'],
    Text: json['Text'],
    FoerfattarPersonId: json['FoerfattarPersonId'],
    Datum: new Date(json['Datum']),
    AerHistorisk: json['AerHistorisk'],
    FaarTasBort: json['FaarTasBort'],
    Personnamn: json['Personnamn'],
    KommentarPK: KommentarPKFromJSON(json['KommentarPK']),
  };
}

export function KommentarDTOToJSON(value?: KommentarDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    KommentarId: value.KommentarId,
    BudgetId: value.BudgetId,
    Text: value.Text,
    FoerfattarPersonId: value.FoerfattarPersonId,
    Datum: value.Datum.toISOString(),
    AerHistorisk: value.AerHistorisk,
    FaarTasBort: value.FaarTasBort,
    Personnamn: value.Personnamn,
    KommentarPK: KommentarPKToJSON(value.KommentarPK),
  };
}
