import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { CreateNyckeltalVaerdeCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Nyckeltal] create nyckeltal värde';

const createNyckeltalVaerdeCmd = createWebCommandSingletonList<typeof ActionType, CreateNyckeltalVaerdeCmd>(
  ActionType,
  ActionType
);

export const selectCreateNyckeltalVaerdeCmd = (state: RootState) =>
  createNyckeltalVaerdeCmd.get(state.main.nyckeltalVaerde.qryCreateNyckeltalVaerde);

export const createNyckeltalVaerdeAction =
  (cmd: CreateNyckeltalVaerdeCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectCreateNyckeltalVaerdeCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(createNyckeltalVaerdeCmd.executing());

      const result = await new ApiClient().NyckeltalVaerdeCmdApi.createNyckeltalVaerde({
        createNyckeltalVaerdeCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(createNyckeltalVaerdeCmd.success(cmd));
      // Remove command from commandList
      dispatch(createNyckeltalVaerdeCmd.remove());
    } catch (err) {
      dispatch(createNyckeltalVaerdeCmd.failure(await parseErrorResponse(err)));
    }
  };

export default createNyckeltalVaerdeCmd.reducer;
