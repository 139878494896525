import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { DeletePeriodiseringsprofilCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Budgetaar] Delete Periodiseringsprofil';

const deletePeriodiseringsprofilCmd = createWebCommandSingletonList<typeof ActionType, DeletePeriodiseringsprofilCmd>(
  ActionType,
  ActionType
);

export const selectDeletePeriodiseringsprofilCmd = (state: RootState) =>
  deletePeriodiseringsprofilCmd.get(state.Budgetaar.deletePeriodiseringsprofilCmd);

export const deletePeriodiseringsprofilAction =
  (cmd: DeletePeriodiseringsprofilCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectDeletePeriodiseringsprofilCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(deletePeriodiseringsprofilCmd.executing());

      const result = await new ApiClient().PeriodiseringsprofilCmdApi.deletePeriodiseringsprofil({
        deletePeriodiseringsprofilCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(deletePeriodiseringsprofilCmd.success(cmd));
      // Remove command from commandList
      dispatch(deletePeriodiseringsprofilCmd.remove());
    } catch (err) {
      dispatch(deletePeriodiseringsprofilCmd.failure(await parseErrorResponse(err)));
    }
  };

export default deletePeriodiseringsprofilCmd.reducer;
