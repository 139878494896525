/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Enhet, EnhetFromJSON, EnhetFromJSONTyped, EnhetToJSON } from './Enhet';

/**
 *
 * @export
 * @interface UserHeaderDTO
 */
export interface UserHeaderDTO {
  /**
   *
   * @type {number}
   * @memberof UserHeaderDTO
   */
  MotivId: number;
  /**
   *
   * @type {string}
   * @memberof UserHeaderDTO
   */
  Name: string;
  /**
   *
   * @type {Array<Enhet>}
   * @memberof UserHeaderDTO
   */
  HeaderEnhetList: Array<Enhet>;
  /**
   *
   * @type {string}
   * @memberof UserHeaderDTO
   */
  Role: string;
}

export function UserHeaderDTOFromJSON(json: any): UserHeaderDTO {
  return UserHeaderDTOFromJSONTyped(json, false);
}

export function UserHeaderDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserHeaderDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    MotivId: json['MotivId'],
    Name: json['Name'],
    HeaderEnhetList: (json['HeaderEnhetList'] as Array<any>).map(EnhetFromJSON),
    Role: json['Role'],
  };
}

export function UserHeaderDTOToJSON(value?: UserHeaderDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    MotivId: value.MotivId,
    Name: value.Name,
    HeaderEnhetList: (value.HeaderEnhetList as Array<any>).map(EnhetToJSON),
    Role: value.Role,
  };
}
