/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetEnhetPair,
  BudgetEnhetPairFromJSON,
  BudgetEnhetPairFromJSONTyped,
  BudgetEnhetPairToJSON,
} from './BudgetEnhetPair';

/**
 *
 * @export
 * @interface NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry
 */
export interface NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry
   */
  NyckeltalId: number;
  /**
   *
   * @type {Array<BudgetEnhetPair>}
   * @memberof NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry
   */
  BudgetEnhetPairList: Array<BudgetEnhetPair>;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry
   */
  BudgetId?: number | null;
}

export function NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQryFromJSON(
  json: any
): NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry {
  return NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQryFromJSONTyped(json, false);
}

export function NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: json['NyckeltalId'],
    BudgetEnhetPairList: (json['BudgetEnhetPairList'] as Array<any>).map(BudgetEnhetPairFromJSON),
    BudgetId: !exists(json, 'BudgetId') ? undefined : json['BudgetId'],
  };
}

export function NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQryToJSON(
  value?: NyckeltalvaerdeUtfallMaanadUnderliggandeDTOQry | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
    BudgetEnhetPairList: (value.BudgetEnhetPairList as Array<any>).map(BudgetEnhetPairToJSON),
    BudgetId: value.BudgetId,
  };
}
