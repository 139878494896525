/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  BudgetFoerutsaettningDTO,
  BudgetFoerutsaettningDTOFromJSON,
  BudgetFoerutsaettningDTOToJSON,
  BudgetFoerutsaettningDTOQry,
  BudgetFoerutsaettningDTOQryFromJSON,
  BudgetFoerutsaettningDTOQryToJSON,
  BudgetSidedrawerNotisAendringarDTOQry,
  BudgetSidedrawerNotisAendringarDTOQryFromJSON,
  BudgetSidedrawerNotisAendringarDTOQryToJSON,
  BudgetSidedrawerNotisAendringarFinnsDTO,
  BudgetSidedrawerNotisAendringarFinnsDTOFromJSON,
  BudgetSidedrawerNotisAendringarFinnsDTOToJSON,
} from '../models';

export interface GetBudgetFoerutsaettningRequest {
  budgetFoerutsaettningDTOQry?: BudgetFoerutsaettningDTOQry;
}

export interface GetSidedrawerNotiserAendringarFinnsRequest {
  budgetSidedrawerNotisAendringarDTOQry?: BudgetSidedrawerNotisAendringarDTOQry;
}

/**
 *
 */
export class SidedrawerNotiserQryApi extends runtime.BaseAPI {
  /**
   */
  async getBudgetFoerutsaettningRaw(
    requestParameters: GetBudgetFoerutsaettningRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetFoerutsaettningDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/SidedrawerNotiserQry/GetBudgetFoerutsaettningar`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetFoerutsaettningDTOQryToJSON(requestParameters.budgetFoerutsaettningDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BudgetFoerutsaettningDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBudgetFoerutsaettning(
    requestParameters: GetBudgetFoerutsaettningRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetFoerutsaettningDTO> {
    const response = await this.getBudgetFoerutsaettningRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getSidedrawerNotiserAendringarFinnsRaw(
    requestParameters: GetSidedrawerNotiserAendringarFinnsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetSidedrawerNotisAendringarFinnsDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/SidedrawerNotiserQry/GetSidedrawerNotiserAendringarFinns`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetSidedrawerNotisAendringarDTOQryToJSON(requestParameters.budgetSidedrawerNotisAendringarDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      BudgetSidedrawerNotisAendringarFinnsDTOFromJSON(jsonValue)
    );
  }

  /**
   */
  async getSidedrawerNotiserAendringarFinns(
    requestParameters: GetSidedrawerNotiserAendringarFinnsRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetSidedrawerNotisAendringarFinnsDTO> {
    const response = await this.getSidedrawerNotiserAendringarFinnsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
