/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateNyckeltalVaerdeCmd
 */
export interface CreateNyckeltalVaerdeCmd {
  /**
   *
   * @type {number}
   * @memberof CreateNyckeltalVaerdeCmd
   */
  Aarsvaerde?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateNyckeltalVaerdeCmd
   */
  BudgetId: number;
  /**
   *
   * @type {number}
   * @memberof CreateNyckeltalVaerdeCmd
   */
  BudgetnyckeltalLoepnummer: number;
  /**
   *
   * @type {number}
   * @memberof CreateNyckeltalVaerdeCmd
   */
  PeriodiseringsprofilId?: number | null;
}

export function CreateNyckeltalVaerdeCmdFromJSON(json: any): CreateNyckeltalVaerdeCmd {
  return CreateNyckeltalVaerdeCmdFromJSONTyped(json, false);
}

export function CreateNyckeltalVaerdeCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateNyckeltalVaerdeCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Aarsvaerde: !exists(json, 'Aarsvaerde') ? undefined : json['Aarsvaerde'],
    BudgetId: json['BudgetId'],
    BudgetnyckeltalLoepnummer: json['BudgetnyckeltalLoepnummer'],
    PeriodiseringsprofilId: !exists(json, 'PeriodiseringsprofilId') ? undefined : json['PeriodiseringsprofilId'],
  };
}

export function CreateNyckeltalVaerdeCmdToJSON(value?: CreateNyckeltalVaerdeCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Aarsvaerde: value.Aarsvaerde,
    BudgetId: value.BudgetId,
    BudgetnyckeltalLoepnummer: value.BudgetnyckeltalLoepnummer,
    PeriodiseringsprofilId: value.PeriodiseringsprofilId,
  };
}
