import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface FbCollapsibleGridRowProps {
  isCollapsed: boolean;
  scrollableContainer: React.MutableRefObject<HTMLElement | null>;
  containerId?: string;
  texts: (isCollapsed: boolean) => JSX.Element;
  onCollapse: () => void;
  onExpand: () => void;
}

export const FbCollapsibleGridRow: React.FC<React.PropsWithChildren<FbCollapsibleGridRowProps>> = ({
  isCollapsed,
  scrollableContainer,
  containerId,
  texts,
  onCollapse,
  onExpand,
  children,
}) => {
  const setCollapsed = (collapsed: boolean) => {
    if (collapsed) {
      onCollapse();
    } else {
      onExpand();
    }
  };

  const collapseDurationMs = 600;

  const [isCollapsing, setIsCollapsing] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isCollapsing) {
      window.setTimeout(() => {
        setIsCollapsing(false);
      }, collapseDurationMs);
    }
  }, [isCollapsing]);

  const tDetails = texts(isCollapsed);
  return (
    <>
      <div
        className="whitespace-nowrap cursor-pointer contents"
        id={containerId}
        onClick={() => {
          setCollapsed(!isCollapsed);
          setIsCollapsing(true);
        }}
      >
        {tDetails}
        <div
          className={`flex justify-end items-center transition-all duration-300 ${
            isCollapsed ? 'bg-fb-color-white' : 'bg-fb-color-primary'
          } py-2 pr-2 mr-4 z-10`}
        >
          <span
            className={`${isCollapsed ? 'text-fb-color-typography' : 'text-fb-color-white'} fb-paragraph-lvl-2 pr-4`}
          >
            {isCollapsed ? 'Visa' : 'Dölj'}
          </span>
          <FontAwesomeIcon
            size="xs"
            className={`transition-all duration-300 ${isCollapsed ? 'rotate-180' : 'text-fb-color-white'}`}
            icon={faChevronUp}
          />
        </div>
      </div>
      <div
        className={`col-start-1 col-end-[-1] h-0 mx-4 ${isCollapsed ? 'opacity-100 shadow-in-grid' : 'opacity-0'}`}
      />
      <div
        className={`col-start-1 col-end-[-1] mx-4 flex transition-all flex-col mb-1 overflow-clip`}
        style={{ transitionDuration: `${collapseDurationMs}ms` }}
      >
        <div
          className={`bg-fb-color-white shadow transition-all ${
            isCollapsed ? '-translate-y-full mb-[-50%]' : 'translate-y-0 mb-1'
          }`}
          style={{ transitionDuration: `${collapseDurationMs}ms` }}
        >
          {(!isCollapsed || isCollapsing) && (
            <>
              {children}

              <div className="h-px w-full bg-fb-color-bg-main-compliment" />
              <div
                className="flex justify-between py-2 px-4 cursor-pointer"
                onClick={() => {
                  setCollapsed(!isCollapsed);
                  setIsCollapsing(true);
                }}
                data-testid="fb-collapsible-bottom-test-id"
              >
                <span />
                <span>
                  <span className="text-fb-color-typography fb-paragraph-lvl-2 pr-4">Dölj</span>
                  <FontAwesomeIcon size="xs" icon={faChevronUp} />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
