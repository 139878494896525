import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { ChangePeriodiseringsprofilCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Budgetaar] Change Periodiseringsprofil';

const changePeriodiseringsprofilCmd = createWebCommandSingletonList<typeof ActionType, ChangePeriodiseringsprofilCmd>(
  ActionType,
  ActionType
);

export const selectChangePeriodiseringsprofilCmd = (state: RootState) =>
  changePeriodiseringsprofilCmd.get(state.Budgetaar.changePeriodiseringsprofilCmd);

export const changePeriodiseringsprofilAction =
  (cmd: ChangePeriodiseringsprofilCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectChangePeriodiseringsprofilCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(changePeriodiseringsprofilCmd.executing());

      const result = await new ApiClient().PeriodiseringsprofilCmdApi.changePeriodiseringsprofil({
        changePeriodiseringsprofilCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(changePeriodiseringsprofilCmd.success(cmd));
      // Remove command from commandList
      dispatch(changePeriodiseringsprofilCmd.remove());
    } catch (err) {
      dispatch(changePeriodiseringsprofilCmd.failure(await parseErrorResponse(err)));
    }
  };

export default changePeriodiseringsprofilCmd.reducer;
