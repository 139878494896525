/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';
import {
  UnderliggandeEnhetVaerdeDTO,
  UnderliggandeEnhetVaerdeDTOFromJSON,
  UnderliggandeEnhetVaerdeDTOFromJSONTyped,
  UnderliggandeEnhetVaerdeDTOToJSON,
} from './UnderliggandeEnhetVaerdeDTO';

/**
 *
 * @export
 * @interface UnderliggandeEnheterVaerdeListDTO
 */
export interface UnderliggandeEnheterVaerdeListDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof UnderliggandeEnheterVaerdeListDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {number}
   * @memberof UnderliggandeEnheterVaerdeListDTO
   */
  NyckeltalId: number;
  /**
   *
   * @type {Array<UnderliggandeEnhetVaerdeDTO>}
   * @memberof UnderliggandeEnheterVaerdeListDTO
   */
  UnderliggandeEnhetVaerde: Array<UnderliggandeEnhetVaerdeDTO>;
}

export function UnderliggandeEnheterVaerdeListDTOFromJSON(json: any): UnderliggandeEnheterVaerdeListDTO {
  return UnderliggandeEnheterVaerdeListDTOFromJSONTyped(json, false);
}

export function UnderliggandeEnheterVaerdeListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UnderliggandeEnheterVaerdeListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    NyckeltalId: json['NyckeltalId'],
    UnderliggandeEnhetVaerde: (json['UnderliggandeEnhetVaerde'] as Array<any>).map(UnderliggandeEnhetVaerdeDTOFromJSON),
  };
}

export function UnderliggandeEnheterVaerdeListDTOToJSON(value?: UnderliggandeEnheterVaerdeListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    NyckeltalId: value.NyckeltalId,
    UnderliggandeEnhetVaerde: (value.UnderliggandeEnhetVaerde as Array<any>).map(UnderliggandeEnhetVaerdeDTOToJSON),
  };
}
