import {
  clickOutside,
  dateToAAAAMMDD,
  FbButton,
  FbButtonExpanded,
  FbButtonProps,
  FbButtonState,
  FbCollapsible,
  FbSpinner,
  FbTable,
  FbTableCell,
  FbTableColDef,
  FbTableRow,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { getUnderliggandeNamnBudgetList } from 'components/budget/nyckeltal/underliggande';
import { selectCurrentBudgetperiodPK, setCurrentBudgetperiodPK } from 'components/uiCurrentBudgetperiodPK';
import { selectCurrentEnhet } from 'components/user/uiCurrentEnhet';
import { getBudgetStatusDomain, getBudgetStatusText } from 'domain/budgetStatusDomain';
import { BudgetListDTO, BudgetperiodPK, BudgetPK, BudgetStatusVO, EnhetVO } from 'generated-models/budgetera/models';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FbPopover } from 'shared-components/fbPopover/FbPopover';
import { budgetRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { isAffaersomraade } from 'utils/enhet';
import { selectbudgetperiodListQry } from '../../qryBudgetperiodList';
import { BudgetNameCell } from './BudgetNameCell';
import {
  openUnderliggandeAction,
  removeOpenUnderliggandeCmdAction,
  selectOpenUnderliggandeCmd,
} from './cmdOpenUnderliggande';
import { CreatePlannedBudgetModal } from './CreatePlannedBudgetModal';
import {
  budgetListQryAction,
  budgetListUnderlyingQryAction,
  selectBudgetListQry,
  selectBudgetListUnderlyingQry,
} from './qryBudgetList';

interface BudgetListRow extends FbTableRow {
  identifier: BudgetPK;
  colList: {
    budget: FbTableCell;
    status: FbTableCell;
    nyckeltal: FbTableCell;
    deadline: FbTableCell;
  };
}

const colDefList: FbTableColDef[] = [
  {
    field: 'budget',
    headerName: 'Budget',
    cssClass: 'w-5/12',
    sortId: 1,
    sortFunction: (a: FbTableRow, b: FbTableRow) => {
      const namnA: string = a.colList.budget.text.props.BudgetList.Namn;
      const namnB: string = b.colList.budget.text.props.BudgetList.Namn;
      return namnA.localeCompare(namnB, 'sv');
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    cssClass: 'w-3/12',
    sortId: 2,
    sortFunction: (a: FbTableRow, b: FbTableRow) => {
      return a.colList.status.text.budgetStatus.Status > b.colList.status.text.budgetStatus.Status ? 1 : -1;
    },
    displayFunction: (a: { budgetStatus: BudgetStatusVO; statusAendradDatum: Date }) => {
      return getBudgetStatusTextWithDate(a.statusAendradDatum, a.budgetStatus);
    },
  },
  {
    field: 'nyckeltal',
    headerName: 'Budgeterade nyckeltal',
    cssClass: 'w-2/12 text-right',
    sortId: 3,
    sortFunction: (a: FbTableRow, b: FbTableRow) => {
      return a.colList.nyckeltal.text > b.colList.nyckeltal.text ? 1 : -1;
    },
    displayFunction: (a: number) => {
      return a + ' %';
    },
  },
  { field: 'deadline', headerName: 'Deadline', cssClass: 'w-2/12 text-right' },
];

export const postClientComponentError = (componentName?: string, message?: string, componentProps?: string) => {
  return new ApiClient().KibanaApi.postClientComponentError({
    clientComponentErrorMessage: {
      ComponentName: componentName ?? '',
      Message: message ?? '',
      ComponentProps: componentProps,
    },
  });
};

const getTooltipForRow = (budget: BudgetListDTO, list: BudgetListDTO[]) => {
  if (budget.EnhetVO.PlaneradBudgetId) {
    return 'Denna budget avser en planerad rekrytering och kan kopplas till en ny medarbetare när den börjat.';
  }
  if (budget.AerNyEnhet && list.some(c => c.EnhetVO.PlaneradBudgetId)) {
    return 'Du har redan en budget för en planerad rekrytering. Öppna den och koppla den till en ny medarbetare för att använda budgetsiffror som lagts in tidigare.';
  }
  return undefined;
};

const mapListRow = (budgetList: BudgetListDTO[]): BudgetListRow[] => {
  return budgetList.map(b => ({
    identifier: b.BudgetPK,
    colList: {
      budget: {
        text: <BudgetNameCell BudgetList={b} />,
        tooltip: getTooltipForRow(b, budgetList),
        tooltipMaxWidth: b.AerNyEnhet ? 320 : 300,
      },
      status: {
        text: { budgetStatus: b.BudgetStatusVO, statusAendradDatum: b.StatusAendradDatum },
        cssClass: `${b.AerInaktivEnhet ? ' text-fb-grey-dark-2 italic' : ''}`,
      },
      nyckeltal: {
        text: Math.round((b.AngivnaNyckeltal / b.TotalNyckeltal) * 100),
        cssClass: `text-right${b.AerInaktivEnhet ? ' text-fb-grey-dark-2 italic' : ''}`,
      },
      deadline: {
        text: b.Deadline ? b.Deadline.toISOString().split('T')[0] : '',
        cssClass: `text-right${b.AerInaktivEnhet ? ' text-fb-grey-dark-2 italic' : ''}`,
      },
    },
  }));
};

const getBudgetStatusTextWithDate = (date: Date, status: BudgetStatusVO): string => {
  return `${getBudgetStatusText(status)} (${dateToAAAAMMDD(date, '-')})`;
};

export const BudgetList: React.FC = () => {
  const selectedEnhet = useSelector(selectCurrentEnhet());
  const selectedBudgetperiodPK = useSelector(selectCurrentBudgetperiodPK());
  const budgetList = useSelector(selectBudgetListQry(selectedBudgetperiodPK.currentBudgetperiodPK));
  const budgetListUnderlying = useSelector(selectBudgetListUnderlyingQry(selectedBudgetperiodPK.currentBudgetperiodPK));
  const openUnderliggande = useSelector(selectOpenUnderliggandeCmd);
  const budgetperiodList = useSelector(selectbudgetperiodListQry());
  const dispatch = useAppDispatch();

  const [localSelectedEnhet, setLocalSelectedEnhet] = useState<EnhetVO | undefined>(undefined);
  const [localSelectedBudgetperiodPK, setLocalSelectedBudgetperiodPK] = useState<BudgetperiodPK>({
    BudgetperiodId: undefined,
  });
  const [openUnderliggandeStatus, setOpenUnderliggandeStatus] = useState<FbButtonState>('default');

  const {
    ref: popoverConfirmRef,
    isComponentVisible: popoverConfirmOpen,
    setIsComponentVisible: setPopoverConfirmOpen,
  } = clickOutside(false, []);

  const dispatchOpenUnderliggande = () => {
    if (selectedEnhet.currentEnhet) {
      dispatch(
        openUnderliggandeAction({
          EnhetVO: selectedEnhet.currentEnhet,
          BudgetperiodId: selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId!,
        })
      );
    }
  };

  const openUnderliggandeBudget = () => {
    if (selectedEnhet.currentEnhet) {
      if (
        isAffaersomraade(selectedEnhet.currentEnhet) &&
        RemoteDataFunctions.hasData(budgetList) &&
        budgetList.data.BudgetListDTO.length > 0 &&
        budgetList.data.BudgetListDTO.some(b => !b.Foerutsaettningar)
      ) {
        setPopoverConfirmOpen(true);
      } else {
        dispatchOpenUnderliggande();
      }
    }
  };

  const getOpenUnderliggandeButtonProps = (budgetList: BudgetListDTO[]): FbButtonProps => {
    switch (openUnderliggandeStatus) {
      case 'default':
        const existsBudgetWithStatusCreated = budgetList.some(
          bu => getBudgetStatusDomain(bu.BudgetStatusVO) === 'created'
        );
        return {
          text: 'Öppna för budgetering',
          type: 'primary',
          onClick: existsBudgetWithStatusCreated ? openUnderliggandeBudget : undefined,
          buttonState: openUnderliggandeStatus,
          successOrFailureTimeout: 1500,
          disabled: existsBudgetWithStatusCreated ? false : true,
        };
      case 'waiting':
      default:
        return {
          text: 'Öppna för budgetering',
          type: 'primary',
          onClick: undefined,
          buttonState: openUnderliggandeStatus,
          successOrFailureTimeout: 1500,
        };
      case 'success':
        return {
          text: 'Budgetar öppnade',
          type: 'primary',
          onClick: undefined,
          buttonState: openUnderliggandeStatus,
          successOrFailureTimeout: 1500,
          disabled: false,
        };
      case 'failure':
        return {
          text: 'Öppning misslyckades',
          type: 'primary',
          onClick: undefined,
          buttonState: openUnderliggandeStatus,
          successOrFailureTimeout: 1500,
          disabled: false,
        };
    }
  };

  // This use effect handles the first fetch and subsequent fetch:es when currentEnhet changes
  // We need to maintain a local state to know if we need to update
  useEffect(() => {
    if (
      selectedEnhet.currentEnhet &&
      selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId !== undefined &&
      (selectedEnhet.currentEnhet !== localSelectedEnhet ||
        localSelectedBudgetperiodPK.BudgetperiodId !== selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId)
    ) {
      if (!RemoteDataFunctions.isLoading(budgetList) && !RemoteDataFunctions.isUpdating(budgetList)) {
        dispatch(
          budgetListQryAction({
            EnhetVO: selectedEnhet.currentEnhet,
            ShowUnderlyingEnhet: false,
            BudgetperiodId: selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId,
          })
        );
      }
      if (
        !RemoteDataFunctions.isLoading(budgetListUnderlying) &&
        !RemoteDataFunctions.isUpdating(budgetListUnderlying)
      ) {
        dispatch(
          budgetListUnderlyingQryAction({
            EnhetVO: selectedEnhet.currentEnhet,
            ShowUnderlyingEnhet: true,
            BudgetperiodId: selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId,
          })
        );
      }
      setLocalSelectedEnhet(selectedEnhet.currentEnhet);
      setLocalSelectedBudgetperiodPK(selectedBudgetperiodPK.currentBudgetperiodPK);
    }
  }, [
    budgetList,
    budgetListUnderlying,
    dispatch,
    localSelectedEnhet,
    selectedEnhet,
    selectedBudgetperiodPK,
    localSelectedBudgetperiodPK,
  ]);

  // This use effect handles invalidation from backend based on integration events
  useEffect(() => {
    if (selectedEnhet.currentEnhet && selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId !== undefined) {
      if (RemoteDataFunctions.isStale(budgetList)) {
        dispatch(
          budgetListQryAction({
            EnhetVO: selectedEnhet.currentEnhet,
            ShowUnderlyingEnhet: false,
            BudgetperiodId: selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId,
          })
        );
      }
      if (RemoteDataFunctions.isStale(budgetListUnderlying) || RemoteDataFunctions.isNotAsked(budgetListUnderlying)) {
        dispatch(
          budgetListUnderlyingQryAction({
            EnhetVO: selectedEnhet.currentEnhet,
            ShowUnderlyingEnhet: true,
            BudgetperiodId: selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId,
          })
        );
      }
    }
  }, [budgetList, budgetListUnderlying, dispatch, selectedEnhet.currentEnhet, selectedBudgetperiodPK]);

  useEffect(() => {
    if (CommandFunctions.isExecuting(openUnderliggande)) {
      setOpenUnderliggandeStatus('waiting');
    } else if (CommandFunctions.isSuccess(openUnderliggande)) {
      removeOpenUnderliggandeCmdAction();
      setOpenUnderliggandeStatus('success');
    } else if (CommandFunctions.isFailure(openUnderliggande)) {
      setOpenUnderliggandeStatus('failure');
    }
  }, [openUnderliggande]);

  useEffect(() => {
    if (selectedEnhet.currentEnhet) {
      setOpenUnderliggandeStatus('default');
    }
  }, [selectedEnhet.currentEnhet]);

  useEffect(() => {
    if (openUnderliggandeStatus === 'failure' || openUnderliggandeStatus === 'success') {
      setOpenUnderliggandeStatus('default');
    }
  }, [openUnderliggandeStatus, selectedEnhet.currentEnhet]);

  const renderBudgetperiodButtonExpanded = () => {
    if (!RemoteDataFunctions.hasData(budgetperiodList)) {
      return undefined;
    }
    const budgetperiod = budgetperiodList.data.find(
      budgetperiod => budgetperiod.BudgetperiodPK === selectedBudgetperiodPK.currentBudgetperiodPK
    );
    if (!budgetperiod) {
      return undefined;
    }
    return (
      <FbButtonExpanded
        defaultValue={budgetperiod.Kalenderaar.toString()}
        expandedList={budgetperiodList.data.map(budgetperiod => {
          return budgetperiod.Kalenderaar.toString();
        })}
        onExpandedRowClick={(value: string) => {
          const budgetperiodPK = budgetperiodList.data.find(
            budgetperiod => budgetperiod.Kalenderaar.toString() === value
          )!.BudgetperiodPK;
          dispatch(setCurrentBudgetperiodPK(budgetperiodPK));
        }}
        buttonClassName="ml-2"
        expandedClassName="ml-2 z-10"
      />
    );
  };

  return (
    <div className="py-4 ">
      <FbCollapsible
        headingTitle="Budgetera"
        isCollapsible={false}
        headingLeftSlotComponent={renderBudgetperiodButtonExpanded()}
      >
        <div className="pb-2 w-full 2xl:w-2/3">
          <h3 className="pb-4">Mina budgetar</h3>
          {RemoteDataFunctions.hasData(budgetList) &&
            !RemoteDataFunctions.isUpdating(budgetList) &&
            budgetList.data.BudgetListDTO.length > 0 && (
              <FbTable
                tableType="fixed"
                colDefList={colDefList}
                rowList={mapListRow(budgetList.data.BudgetListDTO)}
                onRowSelected={(selectedRow: FbTableRow) => dispatch(budgetRoute(selectedRow.identifier))}
                postClientComponentError={(componentName, message, componentProps) =>
                  postClientComponentError(componentName, message, componentProps)
                }
                className="w-full"
              />
            )}
          {RemoteDataFunctions.hasData(budgetList) &&
            budgetList.data.BudgetListDTO.length === 0 &&
            'Du har inga aktiva budgetar ännu'}
          {(RemoteDataFunctions.isLoading(budgetList) || RemoteDataFunctions.isUpdating(budgetList)) && (
            <FbSpinner size="medium" />
          )}
          <div className="flex flex-row justify-between items-center pt-6 pb-4">
            <h3 className="">
              {selectedEnhet.currentEnhet && `${getUnderliggandeNamnBudgetList(selectedEnhet)}s budgetar`}
            </h3>
            {RemoteDataFunctions.hasData(budgetListUnderlying) && budgetListUnderlying.data.BudgetListDTO.length > 0 && (
              <div className="relative">
                <FbButton
                  {...getOpenUnderliggandeButtonProps(budgetListUnderlying.data.BudgetListDTO)}
                  className={'peer'}
                />
                <FbPopover
                  header={'Är du säker på att du vill fortsätta?'}
                  popoverSize={'medium'}
                  showPopover={popoverConfirmOpen}
                  popoverRef={popoverConfirmRef}
                  className="mr-4"
                >
                  <p className="text-xs-increased mb-4">
                    Du kommer att öppna upp för budgetering utan att ha fyllt i förutsättningar för årets budgetar.
                  </p>
                  <div className="flex justify-end pt-2">
                    <FbButton
                      className="mr-4"
                      text={'Avbryt'}
                      type={'secondary'}
                      onClick={() => setPopoverConfirmOpen(false)}
                    />
                    <FbButton
                      text={'Ja, öppna'}
                      type={'primary'}
                      onClick={() => {
                        setPopoverConfirmOpen(false);
                        dispatchOpenUnderliggande();
                      }}
                    />
                  </div>
                </FbPopover>
              </div>
            )}
          </div>
          {RemoteDataFunctions.hasData(budgetListUnderlying) &&
            !RemoteDataFunctions.isUpdating(budgetListUnderlying) &&
            budgetListUnderlying.data.BudgetListDTO.length > 0 && (
              <FbTable
                tableType="fixed"
                colDefList={colDefList}
                rowList={mapListRow(budgetListUnderlying.data.BudgetListDTO)}
                onRowSelected={(selectedRow: FbTableRow) => dispatch(budgetRoute(selectedRow.identifier))}
                postClientComponentError={(componentName, message, componentProps) =>
                  postClientComponentError(componentName, message, componentProps)
                }
                className="w-full"
                isSortable={true}
                defaultSortId={1}
                classNameRows="group"
              />
            )}
          {RemoteDataFunctions.hasData(budgetListUnderlying) &&
            budgetListUnderlying.data.BudgetListDTO.length === 0 &&
            `${getUnderliggandeNamnBudgetList(selectedEnhet)} har inga aktiva budgetar ännu.`}
          {(RemoteDataFunctions.isLoading(budgetListUnderlying) ||
            RemoteDataFunctions.isUpdating(budgetListUnderlying)) && <FbSpinner size="medium" />}
          {RemoteDataFunctions.hasData(budgetList) &&
            budgetList.data.BudgetListDTO.length > 0 &&
            budgetList.data.BudgetListDTO.some((c: BudgetListDTO) => c.EnhetVO.KontorId !== undefined) && (
              <>
                <CreatePlannedBudgetModal
                  mainBudget={budgetList.data.BudgetListDTO[0]}
                  underlyingNames={
                    RemoteDataFunctions.hasData(budgetListUnderlying)
                      ? budgetListUnderlying.data.BudgetListDTO.map(c => c.Namn)
                      : []
                  }
                />
              </>
            )}
        </div>
      </FbCollapsible>
    </div>
  );
};
