/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApiErrorMessageHttp
 */
export interface ApiErrorMessageHttp {
  /**
   *
   * @type {string}
   * @memberof ApiErrorMessageHttp
   */
  Method?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiErrorMessageHttp
   */
  Endpoint?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApiErrorMessageHttp
   */
  Status?: string | null;
}

export function ApiErrorMessageHttpFromJSON(json: any): ApiErrorMessageHttp {
  return ApiErrorMessageHttpFromJSONTyped(json, false);
}

export function ApiErrorMessageHttpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiErrorMessageHttp {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Method: !exists(json, 'Method') ? undefined : json['Method'],
    Endpoint: !exists(json, 'Endpoint') ? undefined : json['Endpoint'],
    Status: !exists(json, 'Status') ? undefined : json['Status'],
  };
}

export function ApiErrorMessageHttpToJSON(value?: ApiErrorMessageHttp | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Method: value.Method,
    Endpoint: value.Endpoint,
    Status: value.Status,
  };
}
