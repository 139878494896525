import {
  ActionUnion,
  createAction,
  RemoteDataFunctions,
  RemoteDataStatus,
  SdKeys,
  WebData,
} from '@decernointernal/websd.shared';
import {
  BudgetaarPeriodiseringsprofilListDTO,
  BudgetNyckeltalVaerdeListDTO,
  BudgetperiodPK,
  BudgetPK,
  JournalListDTO,
  JournalPK,
  KommentarDTO,
  KommentarPK,
  NyckeltalVaerdeDTO,
  NyckeltalvaerdeMaanadDTO,
  NyckeltalvaerdeMaanadPK,
  NyckeltalVaerdePK,
  PeriodiseringsprofilPK,
  SdDbOperation,
  SdIntegrationEvent,
} from 'generated-models/budgetera/models';
import { Dispatch } from 'redux';

/**
 * This is our definition of which integration events the client handles at the moment
 */
export enum IntegrationEventActionType {
  Budget = '[integration-event] Budget',
  NyckeltalVaerdeDTO = '[integration-event] NyckeltalVaerdeDTO',
  Budgetperiod = '[integration-event] Budgetperiodstatus',
  BudgetNyckeltalVaerdeListDTO = '[integration-event] BudgetNyckeltalVaerdeListDTO',
  NyckeltalvaerdeMaanadDTO = '[integration-event] NyckeltalvaerdeMaanadDTO',
  Budgetnyckeltal = '[integration-event] Budgetnyckeltal',
  Periodiseringsprofil = '[integration-event] Periodiseringsprofil',
  Kommentar = '[integration-event] Kommentar',
  SidedrawerNotiser = '[integration-event] SidedrawerNotiser',
  JournalListDTO = '[integration-event] JournalListDTO',
}

type IntegrationEvent<EventDataKeyType> = {
  eventType: string;
  eventDataKey: EventDataKeyType;
  operation: SdDbOperation;
};

type IntegrationEventWithDTO<EventDataType, EventDataKeyType> = {
  eventType: string;
  eventData: EventDataType;
  eventDataKey: EventDataKeyType;
  operation: SdDbOperation;
};

/**
 * Used to parse events which are automatically generated by websd based on the modified entity
 * @param eventType
 * @param event
 * @returns
 */
export function parseEvent<EventDataKeyType extends object>(
  eventType: string,
  event: SdIntegrationEvent
): IntegrationEvent<EventDataKeyType> {
  const eventDataKey = SdKeys.keyToValue<EventDataKeyType>(event.EventDataKey);
  return { eventType, eventDataKey, operation: event.Operation };
}

/**
 * Use to parse events which are transformed into DTO:s for the client and the result can therefore be directly
 * applied to the redux store
 * @param eventType
 * @param event
 * @returns
 */
export function parseEventWithDTO<EventDataType extends object, EventDataKeyType extends object>(
  eventType: string,
  event: SdIntegrationEvent
): IntegrationEventWithDTO<EventDataType, EventDataKeyType> {
  const eventData = event.EventData ? JSON.parse(event.EventData) : undefined;
  const eventDataKey = SdKeys.keyToValue<EventDataKeyType>(event.EventDataKey);
  return { eventType, eventData, eventDataKey, operation: event.Operation };
}

/**
 * Update this with new integration events which the client need to react on
 */
const integrationEventAction = (_eventType: string, eventDataType: string, event: SdIntegrationEvent) => {
  switch (eventDataType) {
    case 'Budget':
      return createAction(IntegrationEventActionType.Budget, parseEvent<BudgetPK>(eventDataType, event));
    case 'NyckeltalVaerdeDTO':
      return createAction(
        IntegrationEventActionType.NyckeltalVaerdeDTO,
        parseEventWithDTO<NyckeltalVaerdeDTO, NyckeltalVaerdePK>(eventDataType, event)
      );
    case 'BudgetNyckeltalVaerdeListDTO':
      return createAction(
        IntegrationEventActionType.BudgetNyckeltalVaerdeListDTO,
        parseEventWithDTO<BudgetNyckeltalVaerdeListDTO, BudgetPK>(eventDataType, event)
      );
    case 'Budgetperiod':
      return createAction(IntegrationEventActionType.Budgetperiod, parseEvent<BudgetperiodPK>(eventDataType, event));
    case 'NyckeltalvaerdeMaanadDTO':
      return createAction(
        IntegrationEventActionType.NyckeltalvaerdeMaanadDTO,
        parseEventWithDTO<NyckeltalvaerdeMaanadDTO, NyckeltalvaerdeMaanadPK>(eventDataType, event)
      );
    case 'Budgetnyckeltal':
      return createAction('undefined');
    case 'Periodiseringsprofil':
      return createAction(
        IntegrationEventActionType.Periodiseringsprofil,
        parseEventWithDTO<BudgetaarPeriodiseringsprofilListDTO, PeriodiseringsprofilPK>(eventDataType, event)
      );
    case 'PeriodiseringsprofilMaanad':
      return createAction('undefined');
    case 'Nyckeltalsdefinition':
      return createAction('undefined');
    case 'Kommentar':
      return createAction(
        IntegrationEventActionType.Kommentar,
        parseEventWithDTO<KommentarDTO, KommentarPK>(eventDataType, event)
      );
    case 'BudgetSidedrawerNotiser':
      return createAction('undefined');
    case 'JournalListDTO':
      return createAction(
        IntegrationEventActionType.JournalListDTO,
        parseEventWithDTO<JournalListDTO, JournalPK>(eventDataType, event)
      );
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unregognized EventDataType: '${eventDataType}'`);
      return createAction('undefined');
  }
};

/**
 * Dispatch events returned from a command to invalidate queries
 * @param dispatch reference to dispatch function from redux
 * @param serializedEvents the list of serialized events
 */
export const dispatchIntegrationEvents = (
  dispatch: Dispatch,
  serializedEvents: ReadonlyArray<SdIntegrationEvent>
): void => {
  const eventActions = serializedEvents.map(event =>
    integrationEventAction(event.EventDataType, event.EventDataType, event)
  );
  eventActions.filter(x => x.type !== 'undefined').map(dispatch);
};

export type IntegrationEventAction = ActionUnion<typeof integrationEventAction>;

/**
 * Sets a webdata slice to stale to show that data needs to be refetched
 * @param webData
 * @returns
 */
export function webDataSetStale<DT>(webData: WebData<DT>): WebData<DT> {
  if (
    !RemoteDataFunctions.hasData(webData) ||
    RemoteDataFunctions.isStale(webData) ||
    RemoteDataFunctions.isUpdating(webData)
  ) {
    return webData;
  }
  return {
    ...webData,
    status: RemoteDataStatus.Stale,
  };
}
