import {
  clickOutside,
  FbAlert,
  FbButton,
  AriaCombobox,
  AriaListItem,
  FbInfoTooltip,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { budgetListUnderlyingQryAction, selectBudgetListUnderlyingQry } from 'components/home/budgetList/qryBudgetList';
import { selectCurrentBudgetperiodPK } from 'components/uiCurrentBudgetperiodPK';
import { getBudgetStatusDomain } from 'domain/budgetStatusDomain';
import { BudgetperiodPK, BudgetPK } from 'generated-models/budgetera';
import React from 'react';
import { useSelector } from 'react-redux';
import { FbPopover } from 'shared-components/fbPopover/FbPopover';
import { budgetRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import { copyBudgetAction, selectCopyBudgetCmd } from './cmdCopyBudget';

interface CopyBudgetPopOverProps {
  readonly kontorId: number;
  readonly budgetPK: BudgetPK;
  readonly budgetPeriodPK: BudgetperiodPK;
}

export const CopyBudgetPopOver: React.FC<CopyBudgetPopOverProps> = ({ kontorId, budgetPK, budgetPeriodPK }) => {
  const dispatch = useAppDispatch();
  const connectButtonRef = React.useRef<HTMLDivElement | null>(null);
  const {
    ref: showConnectBudgetModalRef,
    isComponentVisible: showConnectBudgetModal,
    setIsComponentVisible: setShowConnectBudgetModal,
  } = clickOutside(false, [connectButtonRef]);
  const [selectedTargetBudget, setSelectedTargetBudget] = React.useState<number | undefined>(undefined);
  const [mappedValues, setMappedValues] = React.useState<AriaListItem[]>([]);
  const selectedBudgetperiodPK = useSelector(selectCurrentBudgetperiodPK());
  const copyBudgetCmd = useSelector(selectCopyBudgetCmd);
  const budgetListUnderlying = useSelector(selectBudgetListUnderlyingQry(selectedBudgetperiodPK.currentBudgetperiodPK));

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(budgetListUnderlying) || RemoteDataFunctions.isStale(budgetListUnderlying)) {
      dispatch(
        budgetListUnderlyingQryAction({
          EnhetVO: { KontorId: kontorId },
          BudgetperiodId: budgetPeriodPK.BudgetperiodId,
          ShowUnderlyingEnhet: true,
        })
      );
    }
    if (RemoteDataFunctions.isSuccess(budgetListUnderlying)) {
      setMappedValues(
        budgetListUnderlying.data.BudgetListDTO.filter(
          b =>
            b.AerNyEnhet &&
            b.BudgetPK.BudgetId &&
            getBudgetStatusDomain(b.BudgetStatusVO) !== 'approved' &&
            getBudgetStatusDomain(b.BudgetStatusVO) !== 'done'
        ).map(c => {
          return { id: c.BudgetPK.BudgetId || 0, value: c.Namn };
        })
      );
    }
  }, [budgetListUnderlying, budgetPeriodPK.BudgetperiodId, dispatch, kontorId]);

  const copyBudget = async () => {
    if (!CommandFunctions.isExecuting(copyBudgetCmd)) {
      dispatch(
        copyBudgetAction({
          FromBudgetPK: budgetPK,
          ToBudgetPK: { BudgetId: selectedTargetBudget },
        })
      );
    }
    setShowConnectBudgetModal(false);
    dispatch(budgetRoute({ BudgetId: selectedTargetBudget }));
  };

  const renderConnectPopover = () => {
    if (RemoteDataFunctions.hasData(budgetListUnderlying)) {
      return (
        <FbPopover
          header={'Välj medarbetare att koppla budget till'}
          popoverSize={'small'}
          showPopover={showConnectBudgetModal}
          popoverRef={showConnectBudgetModalRef}
        >
          <AriaCombobox
            expandScroll
            expandScrollClassName="max-h-32"
            label="Nyanställd medarbetare"
            id="comp"
            className="mb-2 text-fb-color-typography relative z-10 w-full"
            onItemSelected={v => {
              if (Number(v?.id)) {
                setSelectedTargetBudget(Number(v?.id));
              } else if (v?.id === undefined) {
                setSelectedTargetBudget(undefined);
              }
            }}
            values={mappedValues}
            shouldFlipOptions={false}
          />
          <div className="text-xs-increased text-fb-color-typography mb-4">
            <FbAlert
              type="info"
              text="När budgetarna kopplas ihop följer budgeterade värden med från denna budget och endast en blir kvar. Se över och klarmarkera den budgeten."
            ></FbAlert>
          </div>
          <div className="flex justify-end pt-2">
            <FbButton
              className="mr-4"
              text={'Avbryt'}
              type={'secondary'}
              onClick={() => setShowConnectBudgetModal(false)}
            />
            <FbButton
              text={'Koppla budget'}
              type={'primary'}
              disabled={selectedTargetBudget === undefined}
              onClick={() => {
                copyBudget();
              }}
            />
          </div>
        </FbPopover>
      );
    }
    return null;
  };

  return (
    <div id="copyBudgetPopover" className="relative group" ref={connectButtonRef}>
      <FbButton
        text="Koppla till ny medarbetare"
        icon={faLink}
        disabled={
          !RemoteDataFunctions.hasData(budgetListUnderlying) ||
          budgetListUnderlying.data.BudgetListDTO.every(c => !c.AerNyEnhet)
        }
        type="main-header-secondary"
        onClick={() => {
          setShowConnectBudgetModal(!showConnectBudgetModal);
        }}
      />
      {!RemoteDataFunctions.hasData(budgetListUnderlying) ||
        (budgetListUnderlying.data.BudgetListDTO.every(c => !c.AerNyEnhet) && (
          <FbInfoTooltip
            targetElement={'copyBudgetPopover'}
            trigger="hover"
            text={'Du kan tilldela denna budget när en budget för en nyanställd medarbetare har skapats'}
            position={'below'}
            maxWidth={320}
            className="transition-all ease-in-out"
          />
        ))}
      {renderConnectPopover()}
    </div>
  );
};
