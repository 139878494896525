/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetNyckeltalFoervaentning,
  BudgetNyckeltalFoervaentningFromJSON,
  BudgetNyckeltalFoervaentningFromJSONTyped,
  BudgetNyckeltalFoervaentningToJSON,
} from './BudgetNyckeltalFoervaentning';

/**
 *
 * @export
 * @interface UpdateBudgetNyckeltalFoervaentningSrvcCmd
 */
export interface UpdateBudgetNyckeltalFoervaentningSrvcCmd {
  /**
   *
   * @type {Array<BudgetNyckeltalFoervaentning>}
   * @memberof UpdateBudgetNyckeltalFoervaentningSrvcCmd
   */
  BudgetNyckeltalFoervaentningPairList?: Array<BudgetNyckeltalFoervaentning> | null;
}

export function UpdateBudgetNyckeltalFoervaentningSrvcCmdFromJSON(
  json: any
): UpdateBudgetNyckeltalFoervaentningSrvcCmd {
  return UpdateBudgetNyckeltalFoervaentningSrvcCmdFromJSONTyped(json, false);
}

export function UpdateBudgetNyckeltalFoervaentningSrvcCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateBudgetNyckeltalFoervaentningSrvcCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetNyckeltalFoervaentningPairList: !exists(json, 'BudgetNyckeltalFoervaentningPairList')
      ? undefined
      : json['BudgetNyckeltalFoervaentningPairList'] === null
      ? null
      : (json['BudgetNyckeltalFoervaentningPairList'] as Array<any>).map(BudgetNyckeltalFoervaentningFromJSON),
  };
}

export function UpdateBudgetNyckeltalFoervaentningSrvcCmdToJSON(
  value?: UpdateBudgetNyckeltalFoervaentningSrvcCmd | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetNyckeltalFoervaentningPairList:
      value.BudgetNyckeltalFoervaentningPairList === undefined
        ? undefined
        : value.BudgetNyckeltalFoervaentningPairList === null
        ? null
        : (value.BudgetNyckeltalFoervaentningPairList as Array<any>).map(BudgetNyckeltalFoervaentningToJSON),
  };
}
