import { createWebDataSingletonList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { UserHeaderDTO } from 'generated-models/budgetera/models/UserHeaderDTO';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { setCurrentEnhet } from './uiCurrentEnhet';
const ActionType = '[User] Get User';

const userQry = createWebDataSingletonList<typeof ActionType, UserHeaderDTO>(ActionType, 'UserHeader');

type UserQryState = WebDataListState<UserHeaderDTO>;

const mapUserQryState: MapState<UserQryState> = rootState => rootState.user.qryUser;

export const selectUserQry = () => (state: RootState) => userQry.get(mapUserQryState(state));

export const getUserQryAction = (): ThunkAction => async (dispatch, getState) => {
  const webData = userQry.get(mapUserQryState(getState()));
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(userQry.updating(webData.data));
    } else {
      dispatch(userQry.loading());
    }
    const result = await new ApiClient().UserQryApi.getUserHeader();

    if (result.HeaderEnhetList.length > 0) {
      result.HeaderEnhetList.sort((a, b) => a.EnhetName.localeCompare(b.EnhetName, 'sv'));
      dispatch(setCurrentEnhet(result.HeaderEnhetList[0].EnhetVO));
    }
    dispatch(userQry.success(result));
  } catch (err) {
    dispatch(userQry.failure(await parseErrorResponse(err)));
  }
};

export default userQry.reducer;
