/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CreateNyckeltalvaerdeMaanadCmd,
  CreateNyckeltalvaerdeMaanadCmdFromJSON,
  CreateNyckeltalvaerdeMaanadCmdToJSON,
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
  UpdateNyckeltalvaerdeMaanadCmd,
  UpdateNyckeltalvaerdeMaanadCmdFromJSON,
  UpdateNyckeltalvaerdeMaanadCmdToJSON,
} from '../models';

export interface CreateNyckeltalVaerdeMaanadRequest {
  createNyckeltalvaerdeMaanadCmd?: CreateNyckeltalvaerdeMaanadCmd;
}

export interface UpdateNyckeltalVaerdeMaanadRequest {
  updateNyckeltalvaerdeMaanadCmd?: UpdateNyckeltalvaerdeMaanadCmd;
}

/**
 *
 */
export class NyckeltalvaerdeMaanadCmdApi extends runtime.BaseAPI {
  /**
   */
  async createNyckeltalVaerdeMaanadRaw(
    requestParameters: CreateNyckeltalVaerdeMaanadRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/NyckeltalvaerdeMaanadCmd/CreateNyckeltalvaerdeMaanad`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateNyckeltalvaerdeMaanadCmdToJSON(requestParameters.createNyckeltalvaerdeMaanadCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async createNyckeltalVaerdeMaanad(
    requestParameters: CreateNyckeltalVaerdeMaanadRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createNyckeltalVaerdeMaanadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateNyckeltalVaerdeMaanadRaw(
    requestParameters: UpdateNyckeltalVaerdeMaanadRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/NyckeltalvaerdeMaanadCmd/UpdateNyckeltalvaerdeMaanad`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateNyckeltalvaerdeMaanadCmdToJSON(requestParameters.updateNyckeltalvaerdeMaanadCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async updateNyckeltalVaerdeMaanad(
    requestParameters: UpdateNyckeltalVaerdeMaanadRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.updateNyckeltalVaerdeMaanadRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
