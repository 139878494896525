import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { UpdateBudgetNyckeltalFoervaentningCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
import {
  UnderliggandeEnheterVaerdeIdentifier,
  underliggandeEnheterVaerdeQryAction,
} from './qryUnderliggandeEnheterVaerde';
const ActionType = '[Nyckeltal] update nyckeltal foervaentning';

const updateBudgetNyckeltalFoervaentningCmd = createWebCommandSingletonList<
  typeof ActionType,
  UpdateBudgetNyckeltalFoervaentningCmd
>(ActionType, ActionType);

export const selectUpdateBudgetNyckeltalFoervaentningCmd = (state: RootState) =>
  updateBudgetNyckeltalFoervaentningCmd.get(state.main.budget.qryUpdateBudgetNyckeltalFoervaentning);

export const updateBudgetNyckeltalFoervaentningAction =
  (
    cmd: UpdateBudgetNyckeltalFoervaentningCmd,
    id: UnderliggandeEnheterVaerdeIdentifier,
    budgetIdList: number[]
  ): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectUpdateBudgetNyckeltalFoervaentningCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(updateBudgetNyckeltalFoervaentningCmd.executing());

      const result = await new ApiClient().BudgetCmdApi.setBudgetNyckeltalFoervaentning({
        updateBudgetNyckeltalFoervaentningCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(updateBudgetNyckeltalFoervaentningCmd.success(cmd));
      // Remove command from commandList
      dispatch(updateBudgetNyckeltalFoervaentningCmd.remove());

      // Since we dont know the huvudBudgetId in the integration event, we refetch the data
      // after the cmd is successful
      dispatch(underliggandeEnheterVaerdeQryAction(id, budgetIdList));
    } catch (err) {
      dispatch(updateBudgetNyckeltalFoervaentningCmd.failure(await parseErrorResponse(err)));
    }
  };

export default updateBudgetNyckeltalFoervaentningCmd.reducer;
