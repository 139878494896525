import {
  addSuffix,
  cleanValue,
  correctCursor,
  formatValue,
  InputType,
  isValidCharacter,
  removeHiddenCharacters,
  removeSuffix,
} from '@decernointernal/fb-interna-komponenter';
import React from 'react';
import { ThunkAction } from 'store/store';

export interface FbTableGridInputCellProps {
  label?: string;
  id: string;
  defaultValue?: string;
  suffix?: string;
  type?: InputType;
  noSpace?: boolean;
  allowNegative?: boolean;
  minLength?: number;
  maxLength?: number;
  decimalLimit?: number;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  autoFocusDelay?: number;
  className?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: (value: string | undefined) => void;
  removeZeroOnFocus?: boolean;
  onSave?: (val: any) => ThunkAction | undefined;
}

export const FbTableGridInputCell: React.FC<FbTableGridInputCellProps> = ({
  label,
  id,
  defaultValue,
  suffix,
  type,
  noSpace,
  allowNegative,
  minLength,
  maxLength,
  decimalLimit,
  disabled,
  required,
  autoFocus,
  autoFocusDelay,
  className,
  onChange,
  onFocus,
  onBlur,
  removeZeroOnFocus,
}) => {
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (autoFocus && autoFocusDelay) {
      window.setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.focus();
        }
      }, autoFocusDelay);
    }
  }, [id, autoFocus, autoFocusDelay]);

  React.useEffect(() => {
    if (defaultValue) {
      setCurrentValue(addSuffix(formatValue(defaultValue, type, noSpace), type, suffix));
    }
  }, [defaultValue, noSpace, suffix, type]);

  return (
    <input
      className={`
      p-2 leading-4 font-sans text-xs-increased border border-t-transparent border-l-transparent border-fb-color-grey-table-border text-fb-color-typography
      select-none outline-none text-right
      hover:border-fb-color-info hover:shadow-fb-color-info/20 hover:shadow-square hover:rounded-sm  hover:bg-fb-color-white hover:z-10
      focus:border-fb-color-info focus:shadow-fb-color-info/20 focus:shadow-square focus:rounded-sm  focus:bg-fb-color-white focus:z-10
      ${className || ''}`}
      id={id}
      data-testid={id}
      placeholder={label}
      autoFocus={autoFocus && !autoFocusDelay}
      value={currentValue || ''}
      minLength={minLength}
      maxLength={maxLength || (type === 'postnr' ? (noSpace ? 5 : 6) : undefined)}
      onChange={e => {
        const originalValue = removeHiddenCharacters(e.currentTarget.value);
        let newValue = originalValue;
        if (type) {
          const cleanedValue = cleanValue(originalValue, type, allowNegative);
          newValue = formatValue(cleanedValue, type, noSpace);
          correctCursor(e.currentTarget, newValue, originalValue);
        }

        setCurrentValue(newValue);
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={() => {
        if (onBlur) {
          onBlur(removeSuffix(currentValue, type, suffix));
        }
        if (removeZeroOnFocus && removeSuffix(currentValue, type, suffix) === '') {
          setCurrentValue(addSuffix('0', type, suffix));
          return;
        }
        setCurrentValue(addSuffix(currentValue, type, suffix));
      }}
      onFocus={() => {
        if (onFocus) {
          onFocus();
        }
        if (removeZeroOnFocus && removeSuffix(currentValue, type, suffix) === '0') {
          setCurrentValue('');
          return;
        }
        setCurrentValue(removeSuffix(currentValue, type, suffix));
      }}
      onKeyPress={e => {
        if (
          !isValidCharacter(e.key, e.currentTarget.selectionStart || 0, type, currentValue, allowNegative, decimalLimit)
        ) {
          e.preventDefault();
        }
      }}
      type={!allowNegative && (type === 'number' || type === 'postnr') ? 'tel' : undefined}
      disabled={disabled}
      required={required}
    ></input>
  );
};
