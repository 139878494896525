import { FbInfoTooltip, FbInfoTooltipPosition } from '@decernointernal/fb-interna-komponenter';
import { useRef } from 'react';

export interface ButtonIconProps {
  imageSrc: string;
  imageAlt?: string;
  disabled?: boolean;
  tooltipText?: string;
  tooltipPosition?: FbInfoTooltipPosition;
  tooltipMaxwidth?: number;
  className?: string;
  onClick?: () => void;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  imageSrc,
  imageAlt,
  disabled,
  tooltipText,
  tooltipPosition,
  tooltipMaxwidth,
  className,
  onClick,
}) => {
  var ref = useRef(null);
  return (
    <div ref={ref} className={`relative ${className ?? ''}`}>
      <button
        disabled={disabled}
        className="peer border p-1 rounded-sm
          border-fb-color-secondary-border bg-fb-color-secondary
          hover:border-fb-color-secondary-border-compliment hover:bg-fb-color-secondary-compliment
          disabled:bg-fb-color-secondary/40
        "
      >
        <img className="cursor-pointer" src={imageSrc} alt={imageAlt} onClick={onClick}></img>
      </button>
      {tooltipText && (
        <FbInfoTooltip
          targetElement={ref}
          trigger="hover"
          text={tooltipText}
          position={tooltipPosition ?? 'above'}
          maxWidth={tooltipMaxwidth}
          className={`opacity-0 peer-hover:opacity-100 transition-all ease-in-out`}
        />
      )}
    </div>
  );
};
