/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetNyckeltalVaerdeDTO,
  BudgetNyckeltalVaerdeDTOFromJSON,
  BudgetNyckeltalVaerdeDTOFromJSONTyped,
  BudgetNyckeltalVaerdeDTOToJSON,
} from './BudgetNyckeltalVaerdeDTO';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';

/**
 *
 * @export
 * @interface BudgetNyckeltalVaerdeListDTO
 */
export interface BudgetNyckeltalVaerdeListDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof BudgetNyckeltalVaerdeListDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {Array<BudgetNyckeltalVaerdeDTO>}
   * @memberof BudgetNyckeltalVaerdeListDTO
   */
  BudgetNyckeltalVaerdeList: Array<BudgetNyckeltalVaerdeDTO>;
}

export function BudgetNyckeltalVaerdeListDTOFromJSON(json: any): BudgetNyckeltalVaerdeListDTO {
  return BudgetNyckeltalVaerdeListDTOFromJSONTyped(json, false);
}

export function BudgetNyckeltalVaerdeListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetNyckeltalVaerdeListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    BudgetNyckeltalVaerdeList: (json['BudgetNyckeltalVaerdeList'] as Array<any>).map(BudgetNyckeltalVaerdeDTOFromJSON),
  };
}

export function BudgetNyckeltalVaerdeListDTOToJSON(value?: BudgetNyckeltalVaerdeListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    BudgetNyckeltalVaerdeList: (value.BudgetNyckeltalVaerdeList as Array<any>).map(BudgetNyckeltalVaerdeDTOToJSON),
  };
}
