import {
  createWebDataList,
  ErrorMessage,
  RemoteData,
  RemoteDataFunctions,
  RemoteDataList,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { BudgetListBudgetperiodWrapperDTO, BudgetListDTOQry, BudgetperiodPK } from 'generated-models/budgetera/models';
import { AnyAction, Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType } from 'utils/eventDispatcher';
const ActionTypeBudgetList = '[budget] Get budgetlist';

// Slice for fetching budget for the selected enhet
const budgetListQry = createWebDataList<typeof ActionTypeBudgetList, BudgetListBudgetperiodWrapperDTO, BudgetperiodPK>(
  ActionTypeBudgetList,
  k => k.BudgetperiodPK
);

const mapBudgetListQryState: MapState<WebDataListState<BudgetListBudgetperiodWrapperDTO>> = rootState =>
  rootState.main.home.budgetList.qryBudgetList;

export const selectBudgetListQry = (id: BudgetperiodPK) => (state: RootState) =>
  budgetListQry.get(mapBudgetListQryState(state), id);

export const budgetListQryAction =
  (qry: BudgetListDTOQry): ThunkAction =>
  async (dispatch, getState) => {
    const webData = budgetListQry.get(mapBudgetListQryState(getState()), { BudgetperiodId: qry.BudgetperiodId });
    budgetListInternalQryAction(qry, webData, budgetListQry, dispatch);
  };

export const budgetListQryReducer = budgetListQry.reducer;

const ActionTypeBudgetListUnderlying = '[budget] Get budgetlistunderlying';
// Slice for fetching budget for budgets which are underlying budgets to the selected enhet
const budgetListUnderlyingQry = createWebDataList<
  typeof ActionTypeBudgetListUnderlying,
  BudgetListBudgetperiodWrapperDTO,
  BudgetperiodPK
>(ActionTypeBudgetListUnderlying, k => k.BudgetperiodPK);

const mapBudgetListUnderlyingQryState: MapState<WebDataListState<BudgetListBudgetperiodWrapperDTO>> = rootState =>
  rootState.main.home.budgetList.qryBudgetListUnderlying;

export const selectBudgetListUnderlyingQry = (id: BudgetperiodPK) => (state: RootState) =>
  budgetListUnderlyingQry.get(mapBudgetListUnderlyingQryState(state), id);

export const budgetListUnderlyingQryAction =
  (qry: BudgetListDTOQry): ThunkAction =>
  async (dispatch, getState) => {
    const webData = budgetListUnderlyingQry.get(mapBudgetListUnderlyingQryState(getState()), {
      BudgetperiodId: qry.BudgetperiodId,
    });
    budgetListInternalQryAction(qry, webData, budgetListUnderlyingQry, dispatch);
  };

// React to integration events which invalidates this qry (set it stale)
export const budgetListUnderlyingQryReducer: Reducer<
  WebDataListState<BudgetListBudgetperiodWrapperDTO>,
  IntegrationEventAction
> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.Budget: {
      // Since we throw away the whole state we only need to do it once
      if (Object.keys(state).length !== 0) {
        return budgetListUnderlyingQry.reducer(undefined, action);
      }
    }
  }
  return budgetListUnderlyingQry.reducer(state, action);
};

// A common thunk/api call for both selected enhet and underlying
const budgetListInternalQryAction = async (
  qry: BudgetListDTOQry,
  webData: RemoteData<BudgetListBudgetperiodWrapperDTO, ErrorMessage>,
  webSdQry: RemoteDataList<string, BudgetListBudgetperiodWrapperDTO, BudgetperiodPK, ErrorMessage>,
  dispatch: (action: AnyAction) => void
) => {
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(webSdQry.updating(webData.data));
    } else {
      dispatch(webSdQry.loading({ BudgetperiodId: qry.BudgetperiodId }));
    }

    const result = await new ApiClient().BudgetQryApi.getBudgetList({ budgetListDTOQry: qry });
    dispatch(webSdQry.success(result));
  } catch (error) {
    dispatch(webSdQry.failure({ BudgetperiodId: qry.BudgetperiodId }, await parseErrorResponse(error)));
  }
};
