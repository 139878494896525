import React from 'react';
import { NavLink } from 'redux-first-router-link';

interface SideDrawerLinkProps {
  text: string;
  className?: string;
  routeTo: () => string | object | string[];
  onClick?: () => void;
}

export const SideDrawerLink: React.FC<SideDrawerLinkProps> = ({ text, className, routeTo, onClick }) => {
  return (
    <NavLink to={routeTo()} onClick={onClick} className="hover:no-underline">
      <div
        className={
          'border-b border-b-fb-color-grey-medium-dark hover:bg-fb-color-blue-light py-4 pl-4 ' + (className ?? '')
        }
      >
        <p className="font-bold text-base">{text}</p>
      </div>
    </NavLink>
  );
};
