import { createWebDataList, RemoteDataFunctions, SdKeys, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetperiodPK, BudgetperiodStatusDTOQry, BudgetperiodStatusWrapperDTO } from 'generated-models/budgetera';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType, webDataSetStale } from 'utils/eventDispatcher';
const ActionType = '[Budgetaar] Get BudgetperiodStatus';

const budgetperiodStatusQry = createWebDataList<typeof ActionType, BudgetperiodStatusWrapperDTO, BudgetperiodPK>(
  ActionType,
  k => k.BudgetperiodPK
);

type BudgetperiodStatusState = WebDataListState<BudgetperiodStatusWrapperDTO>;

const mapBudgetperiodStatusState: MapState<BudgetperiodStatusState> = rootState =>
  rootState.Budgetaar.startaBudgetaar.qryStartaBudgetaar;

export const selectBudgetperiodStatus = (id: BudgetperiodPK) => (state: RootState) =>
  budgetperiodStatusQry.get(mapBudgetperiodStatusState(state), id);

export const budgetperiodStatusAction =
  (qry: BudgetperiodStatusDTOQry): ThunkAction =>
  async (dispatch, getState) => {
    const webData = budgetperiodStatusQry.get(mapBudgetperiodStatusState(getState()), {
      BudgetperiodId: qry.BudgetperiodId,
    });
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(budgetperiodStatusQry.updating(webData.data));
      } else {
        dispatch(budgetperiodStatusQry.loading({ BudgetperiodId: qry.BudgetperiodId }));
      }
      const result = await new ApiClient().BudgetperiodQryApi.getBudgetperiodStatus({
        budgetperiodStatusDTOQry: qry,
      });
      dispatch(budgetperiodStatusQry.success(result));
    } catch (error) {
      dispatch(budgetperiodStatusQry.failure({ BudgetperiodId: qry.BudgetperiodId }, await parseErrorResponse(error)));
    }
  };

// React to integration events which invalidates this qry (set it stale)
export const budgetperiodStatusQryReducer: Reducer<BudgetperiodStatusState, IntegrationEventAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case IntegrationEventActionType.Budgetperiod: {
      const pk = action.payload.eventDataKey;
      const webData = budgetperiodStatusQry.get(state, pk);
      return {
        ...state,
        [SdKeys.keyToString(pk)]: webDataSetStale(webData),
      };
    }
  }
  return budgetperiodStatusQry.reducer(state, action);
};
