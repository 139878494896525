/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { SdDbOperation, SdDbOperationFromJSON, SdDbOperationFromJSONTyped, SdDbOperationToJSON } from './SdDbOperation';

/**
 *
 * @export
 * @interface SdIntegrationEvent
 */
export interface SdIntegrationEvent {
  /**
   *
   * @type {string}
   * @memberof SdIntegrationEvent
   */
  EventDataKey: string;
  /**
   *
   * @type {string}
   * @memberof SdIntegrationEvent
   */
  EventData: string;
  /**
   *
   * @type {string}
   * @memberof SdIntegrationEvent
   */
  EventDataType: string;
  /**
   *
   * @type {string}
   * @memberof SdIntegrationEvent
   */
  EventKeyDataType: string;
  /**
   *
   * @type {SdDbOperation}
   * @memberof SdIntegrationEvent
   */
  Operation: SdDbOperation;
  /**
   *
   * @type {Date}
   * @memberof SdIntegrationEvent
   */
  PointInTime: Date;
}

export function SdIntegrationEventFromJSON(json: any): SdIntegrationEvent {
  return SdIntegrationEventFromJSONTyped(json, false);
}

export function SdIntegrationEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdIntegrationEvent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    EventDataKey: json['EventDataKey'],
    EventData: json['EventData'],
    EventDataType: json['EventDataType'],
    EventKeyDataType: json['EventKeyDataType'],
    Operation: SdDbOperationFromJSON(json['Operation']),
    PointInTime: new Date(json['PointInTime']),
  };
}

export function SdIntegrationEventToJSON(value?: SdIntegrationEvent | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    EventDataKey: value.EventDataKey,
    EventData: value.EventData,
    EventDataType: value.EventDataType,
    EventKeyDataType: value.EventKeyDataType,
    Operation: SdDbOperationToJSON(value.Operation),
    PointInTime: value.PointInTime.toISOString(),
  };
}
