import {
  createWebDataList,
  RemoteDataFunctions,
  RemoteDataStatus,
  SdKeys,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { BudgetNyckeltalVaerdeListDTO, BudgetPK } from 'generated-models/budgetera';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType } from 'utils/eventDispatcher';
const ActionType = '[BudgetNyckeltalVaerdeList] Get BudgetNyckeltalVaerdeList';

const budgetNyckeltalVaerdeListQry = createWebDataList<typeof ActionType, BudgetNyckeltalVaerdeListDTO, BudgetPK>(
  ActionType,
  k => k.BudgetPK
);

type BudgetNyckeltalVaerdeListQryState = WebDataListState<BudgetNyckeltalVaerdeListDTO>;

const mapBudgetNyckeltalVaerdeListQryState: MapState<BudgetNyckeltalVaerdeListQryState> = rootState =>
  rootState.main.budget.qryBudgetNyckeltalVaerdeList;

export const selectBudgetNyckeltalVaerdeListQry = (id: BudgetPK) => (state: RootState) =>
  budgetNyckeltalVaerdeListQry.get(mapBudgetNyckeltalVaerdeListQryState(state), id);

export const getBudgetNyckeltalVaerdeListQryAction =
  (budgetId: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = budgetNyckeltalVaerdeListQry.get(mapBudgetNyckeltalVaerdeListQryState(getState()), budgetId);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(budgetNyckeltalVaerdeListQry.updating(webData.data));
      } else {
        dispatch(budgetNyckeltalVaerdeListQry.loading(budgetId));
      }
      const result = await new ApiClient().BudgetQryApi.getBudgetNyckeltalVaerdeList({
        budgetNyckeltalVaerdeListDTOQry: budgetId,
      });

      dispatch(budgetNyckeltalVaerdeListQry.success(result));
    } catch (err) {
      dispatch(budgetNyckeltalVaerdeListQry.failure(budgetId, await parseErrorResponse(err)));
    }
  };

// React to integration events
export const budgetNyckeltalVaerdeListQryReducer: Reducer<BudgetNyckeltalVaerdeListQryState, IntegrationEventAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case IntegrationEventActionType.BudgetNyckeltalVaerdeListDTO: {
      // Since we react to a DTO event, we will recieve the data we need to update redux directly
      const budgetPK = action.payload.eventDataKey;
      const webData = budgetNyckeltalVaerdeListQry.get(state, budgetPK);
      if (RemoteDataFunctions.hasData(webData)) {
        // Only update redux if we actually have data
        return {
          ...state,
          [SdKeys.keyToString(budgetPK)]: {
            ...webData,
            data: action.payload.eventData,
            status: RemoteDataStatus.Success,
          },
        };
      }
    }
  }
  return budgetNyckeltalVaerdeListQry.reducer(state, action);
};
