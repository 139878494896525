import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetPK, PeriodiseringsprofilListDTOQry } from 'generated-models/budgetera/models';
import { PeriodiseringsprofilListDTO } from 'generated-models/budgetera/models/PeriodiseringsprofilListDTO';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction } from 'utils/eventDispatcher';

const ActionType = '[budget] Get periodiseringsprofilList';

// Slice for fetching periodiseringsprofiler for the active budget
const periodiseringsprofilListQry = createWebDataList<typeof ActionType, PeriodiseringsprofilListDTO, BudgetPK>(
  ActionType,
  k => k.BudgetPK
);

type PeriodiseringsprofilListQryState = WebDataListState<PeriodiseringsprofilListDTO>;

const mapPeriodiseringsprofilListQryState: MapState<PeriodiseringsprofilListQryState> = rootState =>
  rootState.main.budget.qryPeriodiseringsprofilList;

export const selectPeriodiseringsprofilListQry = (id: BudgetPK) => (state: RootState) =>
  periodiseringsprofilListQry.get(mapPeriodiseringsprofilListQryState(state), id);

export const periodiseringsprofilListQryAction =
  (qry: PeriodiseringsprofilListDTOQry): ThunkAction =>
  async (dispatch, getState) => {
    const webData = periodiseringsprofilListQry.get(mapPeriodiseringsprofilListQryState(getState()), {
      BudgetId: qry.BudgetId,
    });
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(periodiseringsprofilListQry.updating(webData.data));
      } else {
        dispatch(periodiseringsprofilListQry.loading(qry));
      }
      const result = await new ApiClient().BudgetQryApi.getPeriodiseringsprofilList({
        periodiseringsprofilListDTOQry: qry,
      });
      dispatch(periodiseringsprofilListQry.success(result));
    } catch (err) {
      dispatch(periodiseringsprofilListQry.failure(qry, await parseErrorResponse(err)));
    }
  };

export const periodiseringsprofilListQryReducer: Reducer<PeriodiseringsprofilListQryState, IntegrationEventAction> = (
  state = {},
  action
) => {
  return periodiseringsprofilListQry.reducer(state, action);
};
