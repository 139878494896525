import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Home] reset budgetera reset';

const resetBugeteraResetCmd = createWebCommandSingletonList<typeof ActionType, {}>(ActionType, ActionType);

export const selectResetBudgeteraReset = (state: RootState) =>
  resetBugeteraResetCmd.get(state.main.home.reset.cmdResetBudgeteraReset);

export const resetBudgeteraResetAction = (): ThunkAction => async (dispatch, getState) => {
  const state = getState();
  const webCommand = selectResetBudgeteraReset(state);
  if (CommandFunctions.isExecuting(webCommand)) {
    // Cancel execution if command is already executing
    return;
  }
  // Call api and handle result
  try {
    dispatch(resetBugeteraResetCmd.executing());

    const result = await new ApiClient().BudgetperiodCmdApi.resetBudgeteraReset();
    // Dispatch integration events returned from the server
    dispatchIntegrationEvents(dispatch, result);

    // Handle success response for command
    dispatch(resetBugeteraResetCmd.success({}));
  } catch (err) {
    dispatch(resetBugeteraResetCmd.failure(await parseErrorResponse(err)));
  }
};

export const removeBudgeteraResetAction = (): ThunkAction => async dispatch => {
  // Remove command from commandList
  dispatch(resetBugeteraResetCmd.remove());
};

export default resetBugeteraResetCmd.reducer;
