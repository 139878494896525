import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface DropdownItem {
  text: string;
  popover?: JSX.Element;
  onClick: () => void;
}

export interface ButtonDropdownProps {
  items: DropdownItem[];
  disableHoverOutside?: boolean;
}

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({ items, disableHoverOutside }) => {
  return (
    <div className={'relative group bg-fb-color-primary border-fb-color-primary-border'}>
      <div
        className={
          'flex items-center h-full border-y border-r border-transparent group-hover:bg-fb-color-primary-compliment group-hover:border-fb-color-primary-border-compliment'
        }
      >
        <FontAwesomeIcon
          className="px-2 w-4 text-fb-color-white block group-hover:hidden"
          size="xs"
          icon={faCaretDown}
        />
        <FontAwesomeIcon className="px-2 w-4 text-fb-color-white hidden group-hover:block" size="xs" icon={faCaretUp} />
      </div>
      <div
        className={`transition-all origin-top-right pt-[2px] absolute right-0 top-full ${
          disableHoverOutside ? 'scale-100' : 'scale-0'
        } group-hover:scale-100`}
      >
        <ul className="whitespace-nowrap rounded-sm bg-fb-color-primary outline-none relative">
          {items.map((item, i) => (
            <li
              className="px-4 h-10 flex items-center border border-transparent cursor-pointer first:rounded-t-sm last:rounded-b-sm hover:bg-fb-color-primary-compliment hover:border-fb-color-primary-border-compliment"
              onClick={item.onClick}
              key={i}
            >
              <p className="text-sm font-sans text-fb-color-white">{item.text}</p>
              {item.popover}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
