import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetnyckeltalPK, NyckeltalvaerdeUtfallMaanadWrapperDTO } from 'generated-models/budgetera';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionType = '[Nyckeltal] Get nyckeltalvärde utfall månad';

const nyckeltalvaerdeUtfallMaanadQry = createWebDataList<
  typeof ActionType,
  NyckeltalvaerdeUtfallMaanadWrapperDTO,
  BudgetnyckeltalPK
>(ActionType, k => k.BudgetnyckeltalPK);

const mapNyckeltalvaerdeUtfallMaanadQry: MapState<
  WebDataListState<NyckeltalvaerdeUtfallMaanadWrapperDTO>
> = rootState => rootState.main.nyckeltalVaerde.qryNyckeltalvaerdeUtfallMaanad;

export const selectNyckeltalvaerdeUtfallMaanadQry = (id: BudgetnyckeltalPK) => (state: RootState) =>
  nyckeltalvaerdeUtfallMaanadQry.get(mapNyckeltalvaerdeUtfallMaanadQry(state), id);

export const nyckeltalvaerdeUtfallMaanadQryAction =
  (id: BudgetnyckeltalPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = nyckeltalvaerdeUtfallMaanadQry.get(mapNyckeltalvaerdeUtfallMaanadQry(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(nyckeltalvaerdeUtfallMaanadQry.updating(webData.data));
      } else {
        dispatch(nyckeltalvaerdeUtfallMaanadQry.loading(id));
      }
      const result = await new ApiClient().NyckeltalvaerdeQryApi.getNyckeltalvaerdeUtfallMaanad({
        nyckeltalvaerdeUtfallMaanadDTOQry: id,
      });
      dispatch(nyckeltalvaerdeUtfallMaanadQry.success(result));
    } catch (err) {
      dispatch(nyckeltalvaerdeUtfallMaanadQry.failure(id, await parseErrorResponse(err)));
    }
  };

export default nyckeltalvaerdeUtfallMaanadQry.reducer;
