/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BudgetSidedrawerNotisAendringarDTOQry
 */
export interface BudgetSidedrawerNotisAendringarDTOQry {
  /**
   *
   * @type {number}
   * @memberof BudgetSidedrawerNotisAendringarDTOQry
   */
  BudgetId?: number | null;
}

export function BudgetSidedrawerNotisAendringarDTOQryFromJSON(json: any): BudgetSidedrawerNotisAendringarDTOQry {
  return BudgetSidedrawerNotisAendringarDTOQryFromJSONTyped(json, false);
}

export function BudgetSidedrawerNotisAendringarDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetSidedrawerNotisAendringarDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetId: !exists(json, 'BudgetId') ? undefined : json['BudgetId'],
  };
}

export function BudgetSidedrawerNotisAendringarDTOQryToJSON(value?: BudgetSidedrawerNotisAendringarDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetId: value.BudgetId,
  };
}
