/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';
import { EnhetVO, EnhetVOFromJSON, EnhetVOFromJSONTyped, EnhetVOToJSON } from './EnhetVO';

/**
 *
 * @export
 * @interface UnderliggandeEnhetDTO
 */
export interface UnderliggandeEnhetDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof UnderliggandeEnhetDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {string}
   * @memberof UnderliggandeEnhetDTO
   */
  Namn: string;
  /**
   *
   * @type {EnhetVO}
   * @memberof UnderliggandeEnhetDTO
   */
  EnhetVO: EnhetVO;
  /**
   *
   * @type {boolean}
   * @memberof UnderliggandeEnhetDTO
   */
  AerNyEnhet: boolean;
}

export function UnderliggandeEnhetDTOFromJSON(json: any): UnderliggandeEnhetDTO {
  return UnderliggandeEnhetDTOFromJSONTyped(json, false);
}

export function UnderliggandeEnhetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnderliggandeEnhetDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    Namn: json['Namn'],
    EnhetVO: EnhetVOFromJSON(json['EnhetVO']),
    AerNyEnhet: json['AerNyEnhet'],
  };
}

export function UnderliggandeEnhetDTOToJSON(value?: UnderliggandeEnhetDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    Namn: value.Namn,
    EnhetVO: EnhetVOToJSON(value.EnhetVO),
    AerNyEnhet: value.AerNyEnhet,
  };
}
