import { FbButton, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import {
  BudgetaarPeriodiseringsprofilListDTO,
  BudgetperiodPK,
  ChangeSorteringPeriodiseringsprofilSrvcCmd,
} from 'generated-models/budgetera';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FbGridCol } from 'shared-components/grid/FbGrid';
import { FbGridColumnHeaderStyles, FbGridColumnHeaderWrapperStyles } from 'shared-components/grid/FbGridHeader';
import { FbTableGrid, FbTableGridRowDef } from 'shared-components/grid/table/FbTableGrid';
import { useAppDispatch } from 'store/store';
import { selectBudgetperiodStatus } from '../startaBudgetaar/qryStartaBudgetaar';
import { BudgetaarPeriodiseringsProfilModal } from './BudgetaarPeriodiseringsProfilModal';
import { ChangeSorteringCell } from './ChangeSorteringCell';
import {
  changeSorteringPeriodiseringsprofilAction,
  selectChangeSorteringPeriodiseringsprofilCmd,
} from './cmdChangeSorteringPeriodiseringsprofil';
import {
  getBudgetaarPeriodiseringsprofilQryAction,
  selectBudgetaarPeriodiseringsprofilQry,
} from './qryBudgetaarPeriodiseringsprofil';

interface BudgetaarPeriodiseringsProfilProps {
  budgetperiodPK: BudgetperiodPK;
}

export const BudgetaarPeriodiseringsProfil: React.FC<BudgetaarPeriodiseringsProfilProps> = ({ budgetperiodPK }) => {
  const dispatch = useAppDispatch();
  const BudgetaarPeriodiseringsprofilQry = useSelector(selectBudgetaarPeriodiseringsprofilQry(budgetperiodPK));
  const changeSorteringPeriodiseringsprofilCmd = useSelector(selectChangeSorteringPeriodiseringsprofilCmd);
  const selectBudgetperiodStatusQry = useSelector(selectBudgetperiodStatus(budgetperiodPK));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chosenPeriodiseringsProfil, setChosenPeriodiseringsProfil] = useState<
    BudgetaarPeriodiseringsprofilListDTO | undefined
  >(undefined);

  const getColumns = () => {
    const maanader = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
    let columns: FbGridCol[] = [
      {
        columnTemplate: 'minmax(364px, 25%)',
        header: [
          {
            type: 'value',
            className: 'border-fb-color-grey-table-border border-l' + FbGridColumnHeaderStyles.table,
            value: 'Namn',
            wrapperClassName: '' + FbGridColumnHeaderWrapperStyles.table,
          },
        ],
      },
    ];
    maanader.forEach(m => {
      columns.push({
        columnTemplate: 'minmax(70px, 7%)',
        header: [
          {
            type: 'value',
            className: 'p-2',
            value: m,
            wrapperClassName: '' + FbGridColumnHeaderWrapperStyles.table,
          },
        ],
      });
    });
    return columns;
  };

  const columns = React.useMemo(() => getColumns(), []);

  React.useEffect(() => {
    if (
      (RemoteDataFunctions.isNotAsked(BudgetaarPeriodiseringsprofilQry) ||
        RemoteDataFunctions.isStale(BudgetaarPeriodiseringsprofilQry)) &&
      RemoteDataFunctions.hasData(selectBudgetperiodStatusQry)
    ) {
      dispatch(getBudgetaarPeriodiseringsprofilQryAction(selectBudgetperiodStatusQry.data.BudgetperiodPK));
    }
  }, [BudgetaarPeriodiseringsprofilQry, dispatch, selectBudgetperiodStatusQry]);

  const changeSorteringCmd = (up: boolean, index: number) => {
    if (
      RemoteDataFunctions.hasData(BudgetaarPeriodiseringsprofilQry) &&
      !CommandFunctions.isExecuting(changeSorteringPeriodiseringsprofilCmd)
    ) {
      const index2 = up ? index - 1 : index + 1;
      const periodiseringsprofil1 = BudgetaarPeriodiseringsprofilQry.data.BudgetaarPeriodiseringsprofilList[index];
      const periodiseringsprofil2 = BudgetaarPeriodiseringsprofilQry.data.BudgetaarPeriodiseringsprofilList[index2];
      const cmd: ChangeSorteringPeriodiseringsprofilSrvcCmd = {
        PeriodiseringsprofilId1: periodiseringsprofil1.PeriodiseringsprofilPK.PeriodiseringsprofilId!,
        PeriodiseringsprofilId2: periodiseringsprofil2.PeriodiseringsprofilPK.PeriodiseringsprofilId!,
      };
      dispatch(
        changeSorteringPeriodiseringsprofilAction(cmd, periodiseringsprofil1.Sortering, periodiseringsprofil2.Sortering)
      );
    }
  };

  return RemoteDataFunctions.hasData(BudgetaarPeriodiseringsprofilQry) &&
    RemoteDataFunctions.hasData(selectBudgetperiodStatusQry) ? (
    <div className="w-full bg-fb-color-white max-h-full p-4">
      <div className="text-left text-sm font-bold pb-2">Tillgängliga periodiseringsprofiler</div>
      <FbTableGrid
        className="w-full"
        columns={columns}
        rows={BudgetaarPeriodiseringsprofilQry.data.BudgetaarPeriodiseringsprofilList.sort(
          (a, b) => a.Sortering - b.Sortering
        ).map((periodiseringsprofil, pIndex) => {
          let rows: FbTableGridRowDef = {
            values: [
              {
                className: 'border-l border-fb-color-grey-table-border hover:cursor-pointer',
                onClick: () => {
                  setChosenPeriodiseringsProfil(periodiseringsprofil);
                  setIsModalOpen(true);
                },
                content: (
                  <ChangeSorteringCell
                    text={periodiseringsprofil.Namn}
                    upDisabled={pIndex === 0}
                    downDisabled={
                      pIndex + 1 === BudgetaarPeriodiseringsprofilQry.data.BudgetaarPeriodiseringsprofilList.length
                    }
                    index={pIndex}
                    onClick={(up, index) => changeSorteringCmd(up, index)}
                  />
                ),
              },
            ],
          };
          periodiseringsprofil.PeriodiseringsprofilMaanadList?.forEach(vaerde => {
            rows.values.push({
              value: (vaerde.Vaerde * 100).toFixed(1).replaceAll('.', ',').replaceAll(',0', '') + ' %',
              className: 'hover:cursor-pointer',
              onClick: () => {
                setChosenPeriodiseringsProfil(periodiseringsprofil);
                setIsModalOpen(true);
              },
            });
          });
          return rows;
        })}
      />
      <FbButton
        text="Lägg till periodiseringsprofil"
        type="secondary"
        className="mt-4"
        onClick={() => setIsModalOpen(true)}
      />
      {isModalOpen && (
        <BudgetaarPeriodiseringsProfilModal
          onCancel={() => {
            setIsModalOpen(false);
            setChosenPeriodiseringsProfil(undefined);
          }}
          periodiseringsProfilToEdit={chosenPeriodiseringsProfil}
          budgetperiodStatus={selectBudgetperiodStatusQry.data.BudgetperiodDTO.BudgetperiodStatusVO}
          budgetperiodPK={selectBudgetperiodStatusQry.data.BudgetperiodPK}
        />
      )}
    </div>
  ) : (
    <div className="flex flex-col justify-center">
      <FbSpinner size={'large'} />
    </div>
  );
};
