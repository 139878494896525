import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnhetVO } from 'generated-models/budgetera';
import { Enhet } from 'generated-models/budgetera/models/Enhet';
import React, { useEffect } from 'react';
import { HeaderEnhetListExpanded } from './HeaderEnhetListExpanded';

interface HeaderExpandedProps {
  logoutOnClick: () => void;
  currentEnhet?: EnhetVO;
  enhetList?: Enhet[];
  changeEnhetOnClick: (enhetVO: EnhetVO) => void;
  name: string;
  role: string;
  showHeader: boolean;
}

export const HeaderExpanded: React.FC<HeaderExpandedProps> = ({
  logoutOnClick,
  currentEnhet,
  enhetList,
  changeEnhetOnClick,
  name,
  role,
  showHeader,
}) => {
  const [animationStyles, setAnimationStyles] = React.useState('scale-0');

  useEffect(() => {
    if (showHeader) {
      setAnimationStyles('scale-100');
    } else {
      setAnimationStyles('scale-0');
    }
  }, [showHeader]);

  return (
    <div className={'absolute w-64 z-50 transition-all origin-top-left ' + animationStyles}>
      <div
        className="
          shadow border border-fb-color-grey-medium-dark relative rounded-sm
          after:rotate-45 after:content-[''] 
          after:absolute after:-top-1.5 after:h-3 after:w-3 after:left-2
          after:pointer-events-none after:border-t after:border-l 
          after:bg-fb-color-grey-light after:border-fb-color-grey-medium-dark
        "
      >
        <div className="bg-fb-color-grey-light h-14 pl-2 py-2 border-b border-b-fb-color-grey-medium-dark rounded-t-sm">
          <p className="text-xs-increased">Inloggad som</p>
          <p className="font-bold text-xs-increased">{name}</p>
        </div>
        <HeaderEnhetListExpanded
          currentEnhet={currentEnhet}
          enhetList={enhetList}
          changeEnhetOnClick={changeEnhetOnClick}
          role={role}
        />
        <div
          className="bg-fb-color-white h-8 p-2 flex items-center hover:bg-fb-color-blue-light cursor-pointer rounded-b-sm"
          onClick={() => logoutOnClick()}
        >
          <p className="text-xs-increased" data-testid="headerExpandedLogout-test-id">
            Logga ut
          </p>
          <FontAwesomeIcon className="absolute right-2" size="xs" icon={faRightFromBracket} />
        </div>
      </div>
    </div>
  );
};
