import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { NyckeltalvaerdeUtfallDTO, NyckeltalvaerdeUtfallDTOQry } from 'generated-models/budgetera';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionType = '[Nyckeltal] Get nyckeltalvärde utfall';

export interface NyckeltalvaerdeUtfallIdentifier {
  BudgetId: number;
  NyckeltalId: number;
}

const nyckeltalvaerdeUtfallQry = createWebDataList<
  typeof ActionType,
  NyckeltalvaerdeUtfallDTO,
  NyckeltalvaerdeUtfallIdentifier
>(ActionType, k => {
  return { NyckeltalId: k.NyckeltalId, BudgetId: k.BudgetId };
});

const mapNyckeltalvaerdeUtfallQry: MapState<WebDataListState<NyckeltalvaerdeUtfallDTO>> = rootState =>
  rootState.main.nyckeltalVaerde.qryNyckeltalvaerdeUtfall;

export const selectNyckeltalvaerdeUtfallQry = (id: NyckeltalvaerdeUtfallIdentifier) => (state: RootState) =>
  nyckeltalvaerdeUtfallQry.get(mapNyckeltalvaerdeUtfallQry(state), id);

export const nyckeltalvaerdeUtfallQryAction =
  (id: NyckeltalvaerdeUtfallDTOQry): ThunkAction =>
  async (dispatch, getState) => {
    const identifier = { BudgetId: id.BudgetId!, NyckeltalId: id.NyckeltalId! };
    const webData = nyckeltalvaerdeUtfallQry.get(mapNyckeltalvaerdeUtfallQry(getState()), identifier);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(nyckeltalvaerdeUtfallQry.updating(webData.data));
      } else {
        dispatch(nyckeltalvaerdeUtfallQry.loading(identifier));
      }
      const result = await new ApiClient().NyckeltalvaerdeQryApi.getNyckeltalvaerdeUtfall({
        nyckeltalvaerdeUtfallDTOQry: id,
      });
      dispatch(nyckeltalvaerdeUtfallQry.success(result));
    } catch (err) {
      dispatch(nyckeltalvaerdeUtfallQry.failure(identifier, await parseErrorResponse(err)));
    }
  };

export default nyckeltalvaerdeUtfallQry.reducer;
