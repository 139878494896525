import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { OpenUnderliggandeBudgetSrvcCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[budget] open underliggande budget';

const openUnderliggandeCmd = createWebCommandSingletonList<typeof ActionType, OpenUnderliggandeBudgetSrvcCmd>(
  ActionType,
  ActionType
);

export const selectOpenUnderliggandeCmd = (state: RootState) =>
  openUnderliggandeCmd.get(state.main.home.budgetList.cmdOpenUnderliggande);

export const openUnderliggandeAction =
  (cmd: OpenUnderliggandeBudgetSrvcCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectOpenUnderliggandeCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(openUnderliggandeCmd.executing());

      const result = await new ApiClient().BudgetCmdApi.openUnderliggandeBudget({
        openUnderliggandeBudgetSrvcCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(openUnderliggandeCmd.success(cmd));
    } catch (err) {
      dispatch(openUnderliggandeCmd.failure(await parseErrorResponse(err)));
    }
  };

export const removeOpenUnderliggandeCmdAction = (): ThunkAction => async dispatch => {
  // Remove command from commandList
  dispatch(openUnderliggandeCmd.remove());
};

export default openUnderliggandeCmd.reducer;
