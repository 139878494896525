interface FbScrollingCategoryHeaderProps {
  title: string;
  subtitle: string;
  className: string;
  zIndex: number;
}

export const FbScrollingCategoryHeader: React.FC<FbScrollingCategoryHeaderProps> = ({
  title,
  subtitle,
  className,
  zIndex,
}) => {
  return (
    <div className={`flex flex-row justify-start sticky top-0 ${className}`} style={{ zIndex: zIndex }}>
      <h4 className="font-bold text-sm whitespace-nowrap">{title}</h4>
      {subtitle && <span className="font-semibold text-xs-increased px-1">-</span>}
      <h5 className="font-semibold text-xs-increased whitespace-nowrap">{subtitle}</h5>
    </div>
  );
};
