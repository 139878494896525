/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';
import {
  BudgetStatusVO,
  BudgetStatusVOFromJSON,
  BudgetStatusVOFromJSONTyped,
  BudgetStatusVOToJSON,
} from './BudgetStatusVO';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';
import { EnhetVO, EnhetVOFromJSON, EnhetVOFromJSONTyped, EnhetVOToJSON } from './EnhetVO';

/**
 *
 * @export
 * @interface BudgetListDTO
 */
export interface BudgetListDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof BudgetListDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {EnhetVO}
   * @memberof BudgetListDTO
   */
  EnhetVO: EnhetVO;
  /**
   *
   * @type {string}
   * @memberof BudgetListDTO
   */
  Namn: string;
  /**
   *
   * @type {boolean}
   * @memberof BudgetListDTO
   */
  AerUtland: boolean;
  /**
   *
   * @type {number}
   * @memberof BudgetListDTO
   */
  Kalenderaar: number;
  /**
   *
   * @type {BudgetStatusVO}
   * @memberof BudgetListDTO
   */
  BudgetStatusVO: BudgetStatusVO;
  /**
   *
   * @type {Date}
   * @memberof BudgetListDTO
   */
  StatusAendradDatum: Date;
  /**
   *
   * @type {boolean}
   * @memberof BudgetListDTO
   */
  AerNyEnhet: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BudgetListDTO
   */
  AerInaktivEnhet: boolean;
  /**
   *
   * @type {Date}
   * @memberof BudgetListDTO
   */
  Deadline?: Date | null;
  /**
   *
   * @type {number}
   * @memberof BudgetListDTO
   */
  TotalNyckeltal: number;
  /**
   *
   * @type {number}
   * @memberof BudgetListDTO
   */
  AngivnaNyckeltal: number;
  /**
   *
   * @type {string}
   * @memberof BudgetListDTO
   */
  Foerutsaettningar?: string | null;
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof BudgetListDTO
   */
  BudgetperiodPK: BudgetperiodPK;
}

export function BudgetListDTOFromJSON(json: any): BudgetListDTO {
  return BudgetListDTOFromJSONTyped(json, false);
}

export function BudgetListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    EnhetVO: EnhetVOFromJSON(json['EnhetVO']),
    Namn: json['Namn'],
    AerUtland: json['AerUtland'],
    Kalenderaar: json['Kalenderaar'],
    BudgetStatusVO: BudgetStatusVOFromJSON(json['BudgetStatusVO']),
    StatusAendradDatum: new Date(json['StatusAendradDatum']),
    AerNyEnhet: json['AerNyEnhet'],
    AerInaktivEnhet: json['AerInaktivEnhet'],
    Deadline: !exists(json, 'Deadline') ? undefined : json['Deadline'] === null ? null : new Date(json['Deadline']),
    TotalNyckeltal: json['TotalNyckeltal'],
    AngivnaNyckeltal: json['AngivnaNyckeltal'],
    Foerutsaettningar: !exists(json, 'Foerutsaettningar') ? undefined : json['Foerutsaettningar'],
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
  };
}

export function BudgetListDTOToJSON(value?: BudgetListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    EnhetVO: EnhetVOToJSON(value.EnhetVO),
    Namn: value.Namn,
    AerUtland: value.AerUtland,
    Kalenderaar: value.Kalenderaar,
    BudgetStatusVO: BudgetStatusVOToJSON(value.BudgetStatusVO),
    StatusAendradDatum: value.StatusAendradDatum.toISOString(),
    AerNyEnhet: value.AerNyEnhet,
    AerInaktivEnhet: value.AerInaktivEnhet,
    Deadline: value.Deadline === undefined ? undefined : value.Deadline === null ? null : value.Deadline.toISOString(),
    TotalNyckeltal: value.TotalNyckeltal,
    AngivnaNyckeltal: value.AngivnaNyckeltal,
    Foerutsaettningar: value.Foerutsaettningar,
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
  };
}
