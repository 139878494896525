import {
  createWebDataList,
  RemoteDataFunctions,
  RemoteDataStatus,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { JournalListDTO, JournalListDTOQry, JournalListWrapperDTO, SdDbOperation } from 'generated-models/budgetera';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType } from 'utils/eventDispatcher';

const ActionType = '[journal] Get JournalList';

const journalListQry = createWebDataList<typeof ActionType, JournalListWrapperDTO, number>(ActionType, k => k.KontorId);

type JournalListQryState = WebDataListState<JournalListWrapperDTO>;

const mapJournalListQryState: MapState<JournalListQryState> = rootState => rootState.main.journal.qryJournalList;

export const selectJournalListQry = (kontorId: number) => (state: RootState) =>
  journalListQry.get(mapJournalListQryState(state), kontorId);

export const getJournalListQryAction =
  (qry: JournalListDTOQry): ThunkAction =>
  async (dispatch, getState) => {
    const webData = journalListQry.get(mapJournalListQryState(getState()), qry.KontorId);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(journalListQry.updating(webData.data));
      } else {
        dispatch(journalListQry.loading(qry.KontorId));
      }
      const result = await new ApiClient().JournalQryApi.getJournalList({ journalListDTOQry: qry });
      dispatch(journalListQry.success(result));
    } catch (err) {
      dispatch(journalListQry.failure(qry.KontorId, await parseErrorResponse(err)));
    }
  };

export const journalListQryReducer: Reducer<JournalListQryState, IntegrationEventAction> = (state = {}, action) => {
  switch (action.type) {
    case IntegrationEventActionType.JournalListDTO: {
      const pk = action.payload.eventData.KontorId;
      const webData = journalListQry.get(state, pk);
      if (RemoteDataFunctions.hasData(webData)) {
        if (action.payload.operation === SdDbOperation.Insert) {
          const journal: JournalListDTO = {
            JournalPK: action.payload.eventData.JournalPK,
            KontorId: action.payload.eventData.KontorId,
            JournalTypId: action.payload.eventData.JournalTypId,
            JournalTypNamn: action.payload.eventData.JournalTypNamn,
            Beskrivning: action.payload.eventData.Beskrivning,
            SkapadDatum: new Date(action.payload.eventData.SkapadDatum.toString()),
            SenastAndradDatum: action.payload.eventData.SenastAndradDatum,
          };
          webData.data.JournalList.push(journal);
          return {
            ...state,
            [pk.toString()]: {
              data: {
                KontorId: webData.data.KontorId,
                KontorNamn: webData.data.KontorNamn,
                JournalList: webData.data.JournalList,
              },
              status: RemoteDataStatus.Success,
            },
          };
        } else if (action.payload.operation === SdDbOperation.Update) {
          const journalIndex = webData.data.JournalList.findIndex(
            j => j.JournalPK.JournalId === action.payload.eventData.JournalPK.JournalId
          );
          const updatedJournal: JournalListDTO = {
            JournalPK: action.payload.eventData.JournalPK,
            KontorId: action.payload.eventData.KontorId,
            JournalTypId: action.payload.eventData.JournalTypId,
            JournalTypNamn: action.payload.eventData.JournalTypNamn,
            Beskrivning: action.payload.eventData.Beskrivning,
            SkapadDatum: new Date(action.payload.eventData.SkapadDatum.toString()),
            SenastAndradDatum: action.payload.eventData.SenastAndradDatum,
          };
          return {
            ...state,
            [pk.toString()]: {
              data: {
                KontorId: webData.data.KontorId,
                KontorNamn: webData.data.KontorNamn,
                JournalList: [
                  ...webData.data.JournalList.slice(0, journalIndex),
                  updatedJournal,
                  ...webData.data.JournalList.slice(journalIndex + 1),
                ],
              },
              status: RemoteDataStatus.Success,
            },
          };
        }
      }
    }
  }
  return journalListQry.reducer(state, action);
};
