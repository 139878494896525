import { combineReducers } from 'redux';
import createBudgetaarCmd from './cmdCreateBudgetaar';
import updateDeadlineCmd from './Deadline/cmdUpdateDeadline';
import changePeriodiseringsprofilCmd from './Periodiseringsprofiler/cmdChangePeriodiseringsprofil';
import changeSorteringPeriodiseringsprofilCmd from './Periodiseringsprofiler/cmdChangeSorteringPeriodiseringsprofil';
import createPeriodiseringsprofilCmd from './Periodiseringsprofiler/cmdCreatePeriodiseringsprofil';
import deletePeriodiseringsprofilCmd from './Periodiseringsprofiler/cmdDeletePeriodiseringsprofil';
import { BudgetaarPeriodiseringsprofilQryReducer } from './Periodiseringsprofiler/qryBudgetaarPeriodiseringsprofil';
import { budgetaarListQryReducer } from './qryBudgetaarList';
import BudgetaarNyckeltalListQry from './qryBudgetaarNyckeltalList';
import { startaBudgetaarReducer } from './startaBudgetaar/startaBudgetaarReducer';

export const BudgetaarReducer = combineReducers({
  BudgetaarNyckeltalListQry,
  startaBudgetaar: startaBudgetaarReducer,
  periodiseringsprofilQry: BudgetaarPeriodiseringsprofilQryReducer,
  createPeriodiseringsprofilCmd,
  changePeriodiseringsprofilCmd,
  deletePeriodiseringsprofilCmd,
  changeSorteringPeriodiseringsprofilCmd,
  budgetaarListQry: budgetaarListQryReducer,
  updateDeadlineCmd: updateDeadlineCmd,
  createBudgetaarCmd: createBudgetaarCmd,
});
