import * as React from 'react';

export interface FbGridColumnFooterElement {
  type: 'element';
  className?: string;
  element: JSX.Element;
  zIndex?: number;
}
export type FbGridColumnFooter = FbGridColumnFooterElement;

interface FbGridFooterProps {
  footers: FbGridColumnFooter[];
  zIndex: number;
}

export const FbGridFooter: React.FC<FbGridFooterProps> = ({ footers, zIndex }) => {
  return (
    <>
      {footers.map((f, index) => {
        if (f.type === 'element') {
          return (
            <div
              className={`sticky h-full w-full bottom-0 ${f.className}`}
              style={{ zIndex: f.zIndex !== undefined ? f.zIndex : zIndex }}
              key={index}
            >
              {f.element}
            </div>
          );
        }
        return <div />;
      })}
    </>
  );
};
