/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BudgetSidedrawerNotisAendringarFinnsDTO
 */
export interface BudgetSidedrawerNotisAendringarFinnsDTO {
  /**
   *
   * @type {boolean}
   * @memberof BudgetSidedrawerNotisAendringarFinnsDTO
   */
  AendringarFinns?: boolean;
}

export function BudgetSidedrawerNotisAendringarFinnsDTOFromJSON(json: any): BudgetSidedrawerNotisAendringarFinnsDTO {
  return BudgetSidedrawerNotisAendringarFinnsDTOFromJSONTyped(json, false);
}

export function BudgetSidedrawerNotisAendringarFinnsDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetSidedrawerNotisAendringarFinnsDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    AendringarFinns: !exists(json, 'AendringarFinns') ? undefined : json['AendringarFinns'],
  };
}

export function BudgetSidedrawerNotisAendringarFinnsDTOToJSON(
  value?: BudgetSidedrawerNotisAendringarFinnsDTO | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    AendringarFinns: value.AendringarFinns,
  };
}
