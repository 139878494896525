/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NyckeltalvaerdeUtfallDTOQry
 */
export interface NyckeltalvaerdeUtfallDTOQry {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallDTOQry
   */
  BudgetId?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallDTOQry
   */
  NyckeltalId?: number | null;
}

export function NyckeltalvaerdeUtfallDTOQryFromJSON(json: any): NyckeltalvaerdeUtfallDTOQry {
  return NyckeltalvaerdeUtfallDTOQryFromJSONTyped(json, false);
}

export function NyckeltalvaerdeUtfallDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeUtfallDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetId: !exists(json, 'BudgetId') ? undefined : json['BudgetId'],
    NyckeltalId: !exists(json, 'NyckeltalId') ? undefined : json['NyckeltalId'],
  };
}

export function NyckeltalvaerdeUtfallDTOQryToJSON(value?: NyckeltalvaerdeUtfallDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetId: value.BudgetId,
    NyckeltalId: value.NyckeltalId,
  };
}
