import { CommandFunctions, createWebCommandList } from '@decernointernal/websd.shared';
import { NyckeltalvaerdeMaanadPK, UpdateNyckeltalvaerdeMaanadCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Nyckeltal] update nyckeltal värde månad';

const updateNyckeltalvaerdeMaanadCmd = createWebCommandList<typeof ActionType, UpdateNyckeltalvaerdeMaanadCmd>(
  ActionType,
  maanad => maanad.PrimaryKey
);

export const selectUpdateNyckeltalvaerdeMaanadCmd = (id: NyckeltalvaerdeMaanadPK) => (state: RootState) =>
  updateNyckeltalvaerdeMaanadCmd.get(state.main.nyckeltalVaerde.qryUpdateNyckeltalvaerdeMaanad, id);

export const updateNyckeltalvaerdeMaanadAction =
  (cmd: UpdateNyckeltalvaerdeMaanadCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectUpdateNyckeltalvaerdeMaanadCmd(cmd.PrimaryKey);
    if (CommandFunctions.isExecuting(webCommand(state))) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(updateNyckeltalvaerdeMaanadCmd.executing(cmd));

      const result = await new ApiClient().NyckeltalvaerdeMaanadCmdApi.updateNyckeltalVaerdeMaanad({
        updateNyckeltalvaerdeMaanadCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(updateNyckeltalvaerdeMaanadCmd.success(cmd));
      // Remove command from commandList
      dispatch(updateNyckeltalvaerdeMaanadCmd.remove(cmd));
    } catch (err) {
      dispatch(updateNyckeltalvaerdeMaanadCmd.failure(cmd, await parseErrorResponse(err)));
    }
  };

export default updateNyckeltalvaerdeMaanadCmd.reducer;
