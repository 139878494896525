/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PeriodiseringsprofilMaanadItem
 */
export interface PeriodiseringsprofilMaanadItem {
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilMaanadItem
   */
  Vaerde?: number;
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilMaanadItem
   */
  Maanad?: number;
}

export function PeriodiseringsprofilMaanadItemFromJSON(json: any): PeriodiseringsprofilMaanadItem {
  return PeriodiseringsprofilMaanadItemFromJSONTyped(json, false);
}

export function PeriodiseringsprofilMaanadItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PeriodiseringsprofilMaanadItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Vaerde: !exists(json, 'Vaerde') ? undefined : json['Vaerde'],
    Maanad: !exists(json, 'Maanad') ? undefined : json['Maanad'],
  };
}

export function PeriodiseringsprofilMaanadItemToJSON(value?: PeriodiseringsprofilMaanadItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Vaerde: value.Vaerde,
    Maanad: value.Maanad,
  };
}
