/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChangeSorteringPeriodiseringsprofilSrvcCmd
 */
export interface ChangeSorteringPeriodiseringsprofilSrvcCmd {
  /**
   *
   * @type {number}
   * @memberof ChangeSorteringPeriodiseringsprofilSrvcCmd
   */
  PeriodiseringsprofilId1: number;
  /**
   *
   * @type {number}
   * @memberof ChangeSorteringPeriodiseringsprofilSrvcCmd
   */
  PeriodiseringsprofilId2: number;
}

export function ChangeSorteringPeriodiseringsprofilSrvcCmdFromJSON(
  json: any
): ChangeSorteringPeriodiseringsprofilSrvcCmd {
  return ChangeSorteringPeriodiseringsprofilSrvcCmdFromJSONTyped(json, false);
}

export function ChangeSorteringPeriodiseringsprofilSrvcCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChangeSorteringPeriodiseringsprofilSrvcCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PeriodiseringsprofilId1: json['PeriodiseringsprofilId1'],
    PeriodiseringsprofilId2: json['PeriodiseringsprofilId2'],
  };
}

export function ChangeSorteringPeriodiseringsprofilSrvcCmdToJSON(
  value?: ChangeSorteringPeriodiseringsprofilSrvcCmd | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PeriodiseringsprofilId1: value.PeriodiseringsprofilId1,
    PeriodiseringsprofilId2: value.PeriodiseringsprofilId2,
  };
}
