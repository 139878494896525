/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ClientComponentErrorMessage
 */
export interface ClientComponentErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ClientComponentErrorMessage
   */
  Message: string;
  /**
   *
   * @type {string}
   * @memberof ClientComponentErrorMessage
   */
  ComponentName: string;
  /**
   *
   * @type {string}
   * @memberof ClientComponentErrorMessage
   */
  ApiCall?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClientComponentErrorMessage
   */
  ComponentProps?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClientComponentErrorMessage
   */
  ComponentState?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClientComponentErrorMessage
   */
  ErrorCode?: string | null;
}

export function ClientComponentErrorMessageFromJSON(json: any): ClientComponentErrorMessage {
  return ClientComponentErrorMessageFromJSONTyped(json, false);
}

export function ClientComponentErrorMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClientComponentErrorMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Message: json['Message'],
    ComponentName: json['ComponentName'],
    ApiCall: !exists(json, 'ApiCall') ? undefined : json['ApiCall'],
    ComponentProps: !exists(json, 'ComponentProps') ? undefined : json['ComponentProps'],
    ComponentState: !exists(json, 'ComponentState') ? undefined : json['ComponentState'],
    ErrorCode: !exists(json, 'ErrorCode') ? undefined : json['ErrorCode'],
  };
}

export function ClientComponentErrorMessageToJSON(value?: ClientComponentErrorMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Message: value.Message,
    ComponentName: value.ComponentName,
    ApiCall: value.ApiCall,
    ComponentProps: value.ComponentProps,
    ComponentState: value.ComponentState,
    ErrorCode: value.ErrorCode,
  };
}
