import { BudgetperiodStatusVO } from 'generated-models/budgetera';

export enum BudgetperiodStatus {
  NotStarted = 1,
  ReadyToStart = 2,
  Starting = 3,
  Started = 4,
  FailedToStart = 5,
  Closed = 6,
}

export type BudgetperiodStatusDomain =
  | 'not started'
  | 'ready to start'
  | 'starting'
  | 'started'
  | 'failed to start'
  | 'closed';

export const getBudgetperiodStatusDomain = (vo: BudgetperiodStatusVO): BudgetperiodStatusDomain | null => {
  switch (vo.Status) {
    case 1:
      return 'not started';
    case 2:
      return 'ready to start';
    case 3:
      return 'starting';
    case 4:
      return 'started';
    case 5:
      return 'failed to start';
    case 6:
      return 'closed';
    default:
      return null;
  }
};

export const getBudgetperiodStatusTextInSwedish = (vo: BudgetperiodStatusVO): string => {
  switch (vo.Status) {
    case 1:
      return 'Ej startat';
    case 2:
      return 'Redo att starta';
    case 3:
      return 'Startar';
    case 4:
      return 'Startad';
    case 5:
      return 'Misslyckades att starta';
    case 6:
      return 'Avslutat';
    default:
      return '';
  }
};
