import { addSpacesToNumber, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import {
  BudgetnyckeltalListDTO,
  BudgetNyckeltalVaerdeDTO,
  BudgetPK,
  NyckeltalCalculation,
} from 'generated-models/budgetera';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { FbGrid, FbGridRow } from 'shared-components/grid/FbGrid';
import { renderFormula } from 'utils/formulaUtils';
import { getEnhetText } from 'utils/nyckeltalsUtils';
import { selectBudgetnyckeltalListWrapperQry } from '../../qryBudgetnyckeltalList';
import { selectBudgetNyckeltalVaerdeListQry } from '../../qryBudgetNyckeltalVaerdeList';
import { selectBeraeknadeNyckeltalQry } from './qryBeraeknadeNyckeltal';

interface BeraeknadeNyckeltalProps {
  budgetPK: BudgetPK;
  nyckeltalId: number;
  aerUtland: boolean;
}

export const BeraeknadeNyckeltal: React.FC<BeraeknadeNyckeltalProps> = ({ budgetPK, nyckeltalId, aerUtland }) => {
  const beraeknadeNyckeltal = useSelector(selectBeraeknadeNyckeltalQry(nyckeltalId));
  const budgetNyckeltalList = useSelector(selectBudgetnyckeltalListWrapperQry(budgetPK));
  const budgetNyckeltalVaerdeList = useSelector(selectBudgetNyckeltalVaerdeListQry(budgetPK));

  const renderNyckeltal = (
    nyckeltal: NyckeltalCalculation,
    vaerdeDtos: BudgetNyckeltalVaerdeDTO[],
    budgetNyckeltal: BudgetnyckeltalListDTO[]
  ): FbGridRow[] => {
    const vaerde = vaerdeDtos.find(v => v.NyckeltalId === nyckeltal.NyckeltalToCalc.NyckeltalId);
    const budgNyck = budgetNyckeltal.find(v => v.NyckeltalId === nyckeltal.NyckeltalToCalc.NyckeltalId);
    if (!vaerde || !budgNyck) {
      // Can get here if the nyckeltal calculated is not visible in the current budget
      return [];
    }

    const diffToFoervaentning =
      vaerde.Aarsvaerde !== undefined &&
      vaerde.Foervaentning !== undefined &&
      vaerde.Aarsvaerde !== null &&
      vaerde.Foervaentning !== null
        ? vaerde.Aarsvaerde - vaerde.Foervaentning
        : '-';
    return [
      {
        element: (
          <div className="flex flex-col w-full pt-4 mr-6">
            <span
              className="text-xs-increased text-fb-color-link-active pb-2 cursor-pointer hover:underline hover:text-fb-color-link-hover"
              onClick={() => {
                const collapsible = document.getElementById(`collapsibleNyckeltal${budgNyck.NyckeltalId}`);
                if (collapsible) {
                  collapsible.click();
                }
              }}
            >
              {(budgNyck.Subkategori ? budgNyck.Subkategori + ' - ' : '') +
                budgNyck.Namn +
                ' (' +
                getEnhetText(budgNyck.Enhet, aerUtland) +
                ')'}
            </span>
            {renderFormula(nyckeltal, budgetNyckeltal)}
          </div>
        ),
      },
      {
        element: (
          <div className="text-right font-semibold text-xs-increased pt-4 mr-6">
            {vaerde.Aarsvaerde !== undefined
              ? addSpacesToNumber(String(vaerde.Aarsvaerde)) + ' ' + getEnhetText(budgNyck.Enhet, aerUtland)
              : '-'}
          </div>
        ),
      },
      {
        element: (
          <div
            className={`text-right font-semibold text-xs-increased pt-4 ${
              diffToFoervaentning !== '-' && diffToFoervaentning < 0 ? 'text-fb-color-danger' : ''
            }`}
          >
            {diffToFoervaentning !== '-'
              ? addSpacesToNumber(String(diffToFoervaentning)) + ' ' + getEnhetText(budgNyck.Enhet, aerUtland)
              : '-'}
          </div>
        ),
      },
    ];
  };

  if (
    !RemoteDataFunctions.hasData(beraeknadeNyckeltal) ||
    !RemoteDataFunctions.hasData(budgetNyckeltalVaerdeList) ||
    !RemoteDataFunctions.hasData(budgetNyckeltalList)
  ) {
    return (
      <div className="flex flex-col justify-center">
        <FbSpinner size={'large'} />
      </div>
    );
  }

  return (
    <FbGrid
      columns={[
        {
          header: {
            type: 'value',
            value: 'Nyckeltal',
            className: ' font-semibold text-xs-increased',
            wrapperClassName: ' mr-6',
          },
          columnTemplate: 'minmax(auto, 344px)', // 320px + 1.5rem
        },
        {
          header: {
            type: 'value',
            value: 'Beräknat värde',
            className: ' font-semibold text-xs-increased text-right',
            wrapperClassName: ' mr-6',
          },
          columnTemplate: 'minmax(auto, 160px)', // 136px + 1.5rem
        },
        {
          header: {
            type: 'value',
            value: 'Diff mot förväntning',
            className: ' font-semibold text-xs-increased text-right',
            wrapperClassName: '',
          },
          columnTemplate: 'minmax(auto, 136px)',
        },
      ]}
      rows={beraeknadeNyckeltal.data.BeraeknadeNyckeltalCalculationList.map(nyckeltal =>
        renderNyckeltal(
          nyckeltal,
          budgetNyckeltalVaerdeList.data.BudgetNyckeltalVaerdeList,
          budgetNyckeltalList.data.Budgetnyckeltal
        )
      ).flat()}
      stickyZIndex={30}
      displayHeaderShadow={false}
      className={'w-full'}
    />
  );
};
