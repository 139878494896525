/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetAnvaendareTypVO,
  BudgetAnvaendareTypVOFromJSON,
  BudgetAnvaendareTypVOFromJSONTyped,
  BudgetAnvaendareTypVOToJSON,
} from './BudgetAnvaendareTypVO';
import { BudgetListDTO, BudgetListDTOFromJSON, BudgetListDTOFromJSONTyped, BudgetListDTOToJSON } from './BudgetListDTO';

/**
 *
 * @export
 * @interface BudgetListWrapperDTO
 */
export interface BudgetListWrapperDTO {
  /**
   *
   * @type {BudgetListDTO}
   * @memberof BudgetListWrapperDTO
   */
  Budget: BudgetListDTO;
  /**
   *
   * @type {boolean}
   * @memberof BudgetListWrapperDTO
   */
  IsViewable: boolean;
  /**
   *
   * @type {BudgetAnvaendareTypVO}
   * @memberof BudgetListWrapperDTO
   */
  AnvaendareTyp: BudgetAnvaendareTypVO;
  /**
   *
   * @type {string}
   * @memberof BudgetListWrapperDTO
   */
  OeverliggandeFoerutsaettningar?: string | null;
}

export function BudgetListWrapperDTOFromJSON(json: any): BudgetListWrapperDTO {
  return BudgetListWrapperDTOFromJSONTyped(json, false);
}

export function BudgetListWrapperDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetListWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Budget: BudgetListDTOFromJSON(json['Budget']),
    IsViewable: json['IsViewable'],
    AnvaendareTyp: BudgetAnvaendareTypVOFromJSON(json['AnvaendareTyp']),
    OeverliggandeFoerutsaettningar: !exists(json, 'OeverliggandeFoerutsaettningar')
      ? undefined
      : json['OeverliggandeFoerutsaettningar'],
  };
}

export function BudgetListWrapperDTOToJSON(value?: BudgetListWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Budget: BudgetListDTOToJSON(value.Budget),
    IsViewable: value.IsViewable,
    AnvaendareTyp: BudgetAnvaendareTypVOToJSON(value.AnvaendareTyp),
    OeverliggandeFoerutsaettningar: value.OeverliggandeFoerutsaettningar,
  };
}
