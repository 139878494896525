import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { ComplementBudgetCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Budget] complement budget';

const complementBudgetCmd = createWebCommandSingletonList<typeof ActionType, ComplementBudgetCmd>(
  ActionType,
  ActionType
);

export const selectComplementBudgetCmd = (state: RootState) =>
  complementBudgetCmd.get(state.main.budget.qrySetBudgetComplement);

export const setBudgetComplementAction =
  (cmd: ComplementBudgetCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectComplementBudgetCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(complementBudgetCmd.executing());

      const result = await new ApiClient().BudgetCmdApi.setBudgetComplement({
        complementBudgetCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(complementBudgetCmd.success(cmd));
    } catch (err) {
      dispatch(complementBudgetCmd.failure(await parseErrorResponse(err)));
    }
  };

export const removeBudgetComplementCmd = (): ThunkAction => async dispatch => {
  // Remove command from commandList
  dispatch(complementBudgetCmd.remove());
};

export default complementBudgetCmd.reducer;
