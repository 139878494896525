/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  JournalListDTO,
  JournalListDTOFromJSON,
  JournalListDTOFromJSONTyped,
  JournalListDTOToJSON,
} from './JournalListDTO';

/**
 *
 * @export
 * @interface JournalListWrapperDTO
 */
export interface JournalListWrapperDTO {
  /**
   *
   * @type {number}
   * @memberof JournalListWrapperDTO
   */
  KontorId: number;
  /**
   *
   * @type {string}
   * @memberof JournalListWrapperDTO
   */
  KontorNamn: string;
  /**
   *
   * @type {Array<JournalListDTO>}
   * @memberof JournalListWrapperDTO
   */
  JournalList: Array<JournalListDTO>;
}

export function JournalListWrapperDTOFromJSON(json: any): JournalListWrapperDTO {
  return JournalListWrapperDTOFromJSONTyped(json, false);
}

export function JournalListWrapperDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): JournalListWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    KontorId: json['KontorId'],
    KontorNamn: json['KontorNamn'],
    JournalList: (json['JournalList'] as Array<any>).map(JournalListDTOFromJSON),
  };
}

export function JournalListWrapperDTOToJSON(value?: JournalListWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    KontorId: value.KontorId,
    KontorNamn: value.KontorNamn,
    JournalList: (value.JournalList as Array<any>).map(JournalListDTOToJSON),
  };
}
