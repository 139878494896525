/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NyckeltalvaerdeMaanadDTOQry
 */
export interface NyckeltalvaerdeMaanadDTOQry {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeMaanadDTOQry
   */
  NyckeltalvaerdeId?: number | null;
}

export function NyckeltalvaerdeMaanadDTOQryFromJSON(json: any): NyckeltalvaerdeMaanadDTOQry {
  return NyckeltalvaerdeMaanadDTOQryFromJSONTyped(json, false);
}

export function NyckeltalvaerdeMaanadDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeMaanadDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalvaerdeId: !exists(json, 'NyckeltalvaerdeId') ? undefined : json['NyckeltalvaerdeId'],
  };
}

export function NyckeltalvaerdeMaanadDTOQryToJSON(value?: NyckeltalvaerdeMaanadDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalvaerdeId: value.NyckeltalvaerdeId,
  };
}
