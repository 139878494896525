import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetaarNyckeltalListWrapperDTO, BudgetperiodPK } from 'generated-models/budgetera';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionType = '[Budgetaar] Get BudgetaarNyckeltalList';

const BudgetaarNyckeltalListQry = createWebDataList<
  typeof ActionType,
  BudgetaarNyckeltalListWrapperDTO,
  BudgetperiodPK
>(ActionType, k => k.BudgetperiodPK);

type BudgetaarNyckeltalListQryState = WebDataListState<BudgetaarNyckeltalListWrapperDTO>;

const mapBudgetaarNyckeltalListQryState: MapState<BudgetaarNyckeltalListQryState> = rootState =>
  rootState.Budgetaar.BudgetaarNyckeltalListQry;

export const selectBudgetaarNyckeltalListQry = (id: BudgetperiodPK) => (state: RootState) =>
  BudgetaarNyckeltalListQry.get(mapBudgetaarNyckeltalListQryState(state), id);

export const getBudgetaarNyckeltalListQryAction =
  (budgetperiodPK: BudgetperiodPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = BudgetaarNyckeltalListQry.get(mapBudgetaarNyckeltalListQryState(getState()), budgetperiodPK);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(BudgetaarNyckeltalListQry.updating(webData.data));
      } else {
        dispatch(BudgetaarNyckeltalListQry.loading(budgetperiodPK));
      }
      const result = await new ApiClient().BudgetaarQryApi.getBudgetaar({
        budgetaarNyckeltalListDTOQry: budgetperiodPK,
      });

      dispatch(BudgetaarNyckeltalListQry.success(result));
    } catch (err) {
      dispatch(BudgetaarNyckeltalListQry.failure(budgetperiodPK, await parseErrorResponse(err)));
    }
  };

export default BudgetaarNyckeltalListQry.reducer;
