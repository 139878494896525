import { createWebDataList, RemoteDataFunctions, SdKeys, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetPK } from 'generated-models/budgetera/models';
import { UnderliggandeEnheterDTO } from 'generated-models/budgetera/models/UnderliggandeEnheterDTO';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType, webDataSetStale } from 'utils/eventDispatcher';

const ActionType = '[budget] Get underliggandeEnheter';

// Slice for fetching budget for the selected enhet
const underliggandeEnheterQry = createWebDataList<typeof ActionType, UnderliggandeEnheterDTO, BudgetPK>(
  ActionType,
  k => k.BudgetPK
);

type UnderliggandeEnheterQryState = WebDataListState<UnderliggandeEnheterDTO>;

const mapUnderliggandeEnheterQryState: MapState<UnderliggandeEnheterQryState> = rootState =>
  rootState.main.budget.qryUnderliggandeEnheter;

export const selectUnderliggandeEnheterQry = (id: BudgetPK) => (state: RootState) =>
  underliggandeEnheterQry.get(mapUnderliggandeEnheterQryState(state), id);

export const underliggandeEnheterQryAction =
  (id: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = underliggandeEnheterQry.get(mapUnderliggandeEnheterQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(underliggandeEnheterQry.updating(webData.data));
      } else {
        dispatch(underliggandeEnheterQry.loading(id));
      }
      const result = await new ApiClient().BudgetQryApi.getUnderliggandeEnheter({ underligganeEnheterDTOQry: id });
      dispatch(underliggandeEnheterQry.success(result));
    } catch (err) {
      dispatch(underliggandeEnheterQry.failure(id, await parseErrorResponse(err)));
    }
  };

// React to integration events which invalidates this qry (set it stale)
export const underliggandeEnheterQryReducer: Reducer<UnderliggandeEnheterQryState, IntegrationEventAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case IntegrationEventActionType.Budget: {
      const pk = action.payload.eventDataKey;
      const webData = underliggandeEnheterQry.get(state, pk);
      return {
        ...state,
        [SdKeys.keyToString(pk)]: webDataSetStale(webData),
      };
    }
  }
  return underliggandeEnheterQry.reducer(state, action);
};
