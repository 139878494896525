import App from 'App';
import { publicClientApplication } from 'azureAD/msalConfig';
import { NavigateAway } from 'components/navigateAway/navigateAway';
import { FbOverlay } from 'components/overlay/FbOverlay';
import { createRoot } from 'react-dom/client';
import { RenderWrapper } from 'RenderWrapper';
import reportWebVitals from './reportWebVitals';
import './tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <RenderWrapper>
    <FbOverlay />
    <NavigateAway />
    <App msalInstance={publicClientApplication} />
  </RenderWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
