import { combineReducers } from 'redux';
import createNyckeltalVaerdeCmd from './cmdCreateNyckeltalVaerde';
import updateNyckeltalVaerdeCmd from './cmdUpdateNyckeltalVaerde';
import createNyckeltalvaerdeMaanadCmd from './nyckeltaltabs/cmdCreateNyckeltalvaerdeMaanad';
import updateBudgetNyckeltalFoervaentningCmd from './nyckeltaltabs/cmdUpdateBudgetNyckeltalFoervaentningar';
import updateNyckeltalvaerdeMaanadCmd from './nyckeltaltabs/cmdUpdateNyckeltalvaerdeMaanad';
import { default as beraeknadeNyckeltalReducer } from './nyckeltaltabs/qryBeraeknadeNyckeltal';
import { nyckeltalvaerdeMaanadBudgetQryReducer } from './nyckeltaltabs/qryNyckeltalvaerdeMaanadBudget';
import { default as nyckeltalvaerdeUtfallMaanadReducer } from './nyckeltaltabs/qryNyckeltalvaerdeUtfallMaanad';
import { default as UnderliggandeUtfallReducer } from './nyckeltaltabs/qryUnderliggandeUtfall';
import qryUnderliggandeUtfallMaanad from './nyckeltaltabs/qryUnderliggandeUtfallMaanad';
import { default as beraeknatNyckeltalFormelReducer } from './qryBeraeknatNyckeltalFormel';
import { nyckeltalvaerdeQryReducer } from './qryNyckeltalVaerde';
import { default as nyckeltalvaerdeUtfallReducer } from './qryNyckeltalvaerdeUtfall';

export const nyckeltalVaerdeReducer = combineReducers({
  qryCreateNyckeltalVaerde: createNyckeltalVaerdeCmd,
  qryUpdateNyckeltalVaerde: updateNyckeltalVaerdeCmd,
  qryNyckeltalVaerde: nyckeltalvaerdeQryReducer,
  qryCreateNyckeltalvaerdeMaanad: createNyckeltalvaerdeMaanadCmd,
  qryUpdateNyckeltalvaerdeMaanad: updateNyckeltalvaerdeMaanadCmd,
  qryNyckeltalvaerdeMaanadBudget: nyckeltalvaerdeMaanadBudgetQryReducer,
  qryNyckeltalvaerdeUtfall: nyckeltalvaerdeUtfallReducer,
  qryNyckeltalvaerdeUtfallMaanad: nyckeltalvaerdeUtfallMaanadReducer,
  qryUnderliggandeUtfall: UnderliggandeUtfallReducer,
  qryBeraeknadeNyckeltal: beraeknadeNyckeltalReducer,
  qryUpdateFoervaentningarVaerde: updateBudgetNyckeltalFoervaentningCmd,
  qryBeraeknatNyckeltalFormel: beraeknatNyckeltalFormelReducer,
  qryUnderliggandeUtfallMaanad: qryUnderliggandeUtfallMaanad,
});
