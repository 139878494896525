import { FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { selectCurrentBudgetperiodPK } from 'components/uiCurrentBudgetperiodPK';
import { NyckeltalDomain } from 'domain/nyckeltalDomain';
import { BudgetPK } from 'generated-models/budgetera/models';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { FbModal } from 'shared-components/fbModal/FbModal';
import { FbCollapsibleGrid, FbCollapsibleGridRowDef } from 'shared-components/grid/collapsible/FbCollapsibleGrid';
import { FbGridColumnHeaderStyles, FbGridColumnHeaderWrapperStyles } from 'shared-components/grid/FbGridHeader';
import { homeRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import { isKontor } from 'utils/enhet';
import { getSummaMaanader } from 'utils/summaMaanaderUtils';
import { BudgetHeader } from './BudgetHeader';
import { Nyckeltal } from './nyckeltal/Nyckeltal';
import { NyckeltalHeader } from './nyckeltal/NyckeltalHeader';
import {
  nyckeltalvaerdeMaanadBudgetQryAction,
  selectNyckeltalvaerdeMaanadBudgetQry,
} from './nyckeltal/nyckeltaltabs/qryNyckeltalvaerdeMaanadBudget';
import { getUnderliggandeNamn } from './nyckeltal/underliggande';
import { budgetQryAction, selectBudgetQry } from './qryBudget';
import { budgetnyckeltalListQryAction, selectBudgetnyckeltalListWrapperQry } from './qryBudgetnyckeltalList';
import {
  getBudgetNyckeltalVaerdeListQryAction,
  selectBudgetNyckeltalVaerdeListQry,
} from './qryBudgetNyckeltalVaerdeList';
import { periodiseringsprofilListQryAction, selectPeriodiseringsprofilListQry } from './qryPeriodiseringsprofilList';
import { getSummaUnderliggandeListQryAction, selectSummaUnderliggandeListQry } from './qrySummaUnderliggande';
import { selectUnderliggandeEnheterQry, underliggandeEnheterQryAction } from './qryUnderliggandeEnheter';
import { sidedrawerNotiserQryAction } from './sidedrawerNotiser/qrySidedrawerNotiser';

export const Budget: React.FC<{ readonly pk: BudgetPK }> = ({ pk }) => {
  const selectedBudgetperiodPK = useSelector(selectCurrentBudgetperiodPK());
  const budget = useSelector(selectBudgetQry(pk));
  const budgetList = useSelector(selectBudgetnyckeltalListWrapperQry(pk));
  const budgetNyckeltalVaerdeList = useSelector(selectBudgetNyckeltalVaerdeListQry(pk));
  const summaUnderliggandeList = useSelector(selectSummaUnderliggandeListQry(pk));
  const nyckeltalvaerdeMaanad = useSelector(selectNyckeltalvaerdeMaanadBudgetQry());
  const underliggandeEnheter = useSelector(selectUnderliggandeEnheterQry(pk)); // Vi använder inte denna data i denna komponent, men vill att den första hämtningen ska ske när budgetvyn visas
  const periodiseringsprofilList = useSelector(selectPeriodiseringsprofilListQry(pk)); // Vi använder inte denna data i denna komponent, men vill att den första hämtningen ska ske när budgetvyn visas
  const dispatch = useAppDispatch();

  const [isUnauthorized, setIsUnauthorized] = React.useState(false);

  React.useEffect(() => {
    if (
      RemoteDataFunctions.isNotAsked(budgetNyckeltalVaerdeList) ||
      RemoteDataFunctions.isStale(budgetNyckeltalVaerdeList)
    ) {
      dispatch(getBudgetNyckeltalVaerdeListQryAction(pk));
    }
  }, [budgetNyckeltalVaerdeList, pk, dispatch]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(budgetList) || RemoteDataFunctions.isStale(budgetList)) {
      dispatch(budgetnyckeltalListQryAction(pk));
    }
  }, [budgetList, pk, dispatch]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(sidedrawerNotiserQryAction(pk));
    }, 1000 * 60 * 5);
    dispatch(sidedrawerNotiserQryAction(pk));
    return () => clearInterval(interval);
  }, [pk, dispatch]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(summaUnderliggandeList) || RemoteDataFunctions.isStale(summaUnderliggandeList)) {
      dispatch(getSummaUnderliggandeListQryAction(pk));
    }
  }, [pk, dispatch, summaUnderliggandeList]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(budget) || RemoteDataFunctions.isStale(budget)) {
      dispatch(budgetQryAction(pk));
    }
  }, [budget, pk, dispatch]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(underliggandeEnheter) || RemoteDataFunctions.isStale(underliggandeEnheter)) {
      dispatch(underliggandeEnheterQryAction(pk));
    }
  }, [underliggandeEnheter, pk, dispatch]);

  React.useEffect(() => {
    if (
      (RemoteDataFunctions.isNotAsked(periodiseringsprofilList) ||
        RemoteDataFunctions.isStale(periodiseringsprofilList)) &&
      selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId !== undefined
    ) {
      dispatch(
        periodiseringsprofilListQryAction({
          BudgetId: pk.BudgetId,
          BudgetperiodId: selectedBudgetperiodPK.currentBudgetperiodPK.BudgetperiodId!,
        })
      );
    }
  }, [periodiseringsprofilList, pk, dispatch, selectedBudgetperiodPK]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(nyckeltalvaerdeMaanad)) {
      dispatch(nyckeltalvaerdeMaanadBudgetQryAction(pk));
    }
  }, [nyckeltalvaerdeMaanad, pk, dispatch]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isFailure(budget) && budget.err.http?.status === 403) {
      setIsUnauthorized(true);
    }
  }, [budget]);

  return (
    <>
      {(!RemoteDataFunctions.hasData(budget) ||
        !RemoteDataFunctions.hasData(budgetList) ||
        !RemoteDataFunctions.hasData(budgetNyckeltalVaerdeList) ||
        !RemoteDataFunctions.hasData(summaUnderliggandeList) ||
        !RemoteDataFunctions.hasData(nyckeltalvaerdeMaanad)) &&
        !isUnauthorized && (
          <div className="flex w-full justify-center pt-4">
            <FbSpinner size={'large'} />
          </div>
        )}
      {RemoteDataFunctions.hasData(budget) &&
        budget.data.IsViewable &&
        RemoteDataFunctions.hasData(budgetList) &&
        RemoteDataFunctions.hasData(budgetNyckeltalVaerdeList) &&
        RemoteDataFunctions.hasData(summaUnderliggandeList) &&
        RemoteDataFunctions.hasData(nyckeltalvaerdeMaanad) && (
          <div className="flex flex-col overflow-y-hidden">
            <BudgetHeader
              budget={budget.data.Budget}
              anveandereTyp={budget.data.AnvaendareTyp}
              budgetNyckeltalVaerdeList={budgetNyckeltalVaerdeList.data}
              budgetNyckeltalList={budgetList.data}
              budgetSummaunderliggandeList={summaUnderliggandeList.data}
              oeverliggandeFoerutsaettningar={budget.data.OeverliggandeFoerutsaettningar}
            />

            <FbCollapsibleGrid
              columns={[
                { columnTemplate: 'minmax(321px,min-content)', header: { type: 'scrollingHeader' } },
                {
                  columnTemplate: 'minmax(150px,auto)',
                  header: {
                    type: 'value',
                    value: 'Budgeterat',
                    className: 'mr-4' + FbGridColumnHeaderStyles.collapsibleGrid,
                    wrapperClassName: FbGridColumnHeaderWrapperStyles.collapsibleGrid,
                  },
                },
                {
                  columnTemplate: 'minmax(123px,auto)',
                  header: {
                    type: 'value',
                    value: 'Förväntning',
                    className: 'mr-1' + FbGridColumnHeaderStyles.collapsibleGrid,
                    wrapperClassName: FbGridColumnHeaderWrapperStyles.collapsibleGrid,
                  },
                },
                {
                  columnTemplate: 'minmax(123px,auto)',
                  header: {
                    type: 'value',
                    value: 'Differens',
                    className: 'mr-4' + FbGridColumnHeaderStyles.collapsibleGrid,
                    wrapperClassName: FbGridColumnHeaderWrapperStyles.collapsibleGrid,
                  },
                },
                {
                  columnTemplate: 'minmax(129px,auto)',
                  header:
                    budget.data.Budget.EnhetVO.PersonId === undefined &&
                    budget.data.Budget.EnhetVO.PlaneradBudgetId === undefined
                      ? {
                          type: 'value',
                          value: `S:a ${getUnderliggandeNamn(budget.data.Budget, false).toLocaleLowerCase()}`,
                          className: 'mr-1' + FbGridColumnHeaderStyles.collapsibleGrid,
                          wrapperClassName: FbGridColumnHeaderWrapperStyles.collapsibleGrid,
                        }
                      : {
                          type: 'value',
                          value: '',
                          className: '',
                          wrapperClassName: 'bg-fb-color-bg-main',
                        },
                },
                {
                  columnTemplate: 'minmax(123px,auto)',
                  header:
                    budget.data.Budget.EnhetVO.PersonId === undefined &&
                    budget.data.Budget.EnhetVO.PlaneradBudgetId === undefined
                      ? {
                          type: 'value',
                          value: 'Differens',
                          className: 'mr-4' + FbGridColumnHeaderStyles.collapsibleGrid,
                          wrapperClassName: FbGridColumnHeaderWrapperStyles.collapsibleGrid,
                        }
                      : {
                          type: 'value',
                          value: '',
                          className: '',
                          wrapperClassName: 'bg-fb-color-bg-main',
                        },
                },
                {
                  columnTemplate: 'minmax(10%, max-content)',
                  header: {
                    type: 'value',
                    value: '',
                    className: '',
                    wrapperClassName: 'bg-fb-color-bg-main',
                  },
                },
              ]}
              rows={budgetList.data.Budgetnyckeltal.map<FbCollapsibleGridRowDef>(nt => {
                const budgetnyckeltalVaerde = budgetNyckeltalVaerdeList.data.BudgetNyckeltalVaerdeList.find(
                  v => v.NyckeltalId === nt.NyckeltalId
                );
                return {
                  containerId: `collapsibleNyckeltal${nt.NyckeltalId}`,
                  group: nt.Kategori,
                  subgroup: nt.Subkategori,
                  texts: (isCollapsed: boolean) => (
                    <NyckeltalHeader
                      nyckeltal={nt}
                      budget={budget.data.Budget}
                      isCollapsed={isCollapsed}
                      budgeteratValue={budgetnyckeltalVaerde?.Aarsvaerde}
                      summaUnderliggande={summaUnderliggandeList.data.SummaUnderliggandeList.find(
                        s => s.NyckeltalId === nt.NyckeltalId
                      )}
                      foervaentning={
                        isKontor(budget.data.Budget.EnhetVO) &&
                        (nt.NyckeltalId === NyckeltalDomain.BR.Marknadsandel ||
                          nt.NyckeltalId === NyckeltalDomain.Smaahus.Marknadsandel)
                          ? budgetNyckeltalVaerdeList.data.BudgetNyckeltalVaerdeList.find(
                              budget => budget.NyckeltalId === nt.NyckeltalId
                            )?.Omsaettningskrav
                          : budgetNyckeltalVaerdeList.data.BudgetNyckeltalVaerdeList.find(
                              budget => budget.NyckeltalId === nt.NyckeltalId
                            )?.Foervaentning
                      }
                      summaMaanader={getSummaMaanader(
                        nyckeltalvaerdeMaanad.data.NyckeltalvaerdeMaanadWrapperDTOList.find(
                          nvm => nvm.NyckeltalVaerdePK.NyckeltalvaerdeId === budgetnyckeltalVaerde?.NyckeltalvaerdeId
                        )?.NyckeltalvaerdeMaanadDTOList
                      )}
                    />
                  ),
                  details: (
                    <Nyckeltal
                      nyckeltal={nt}
                      budget={budget.data.Budget}
                      foervaentning={
                        isKontor(budget.data.Budget.EnhetVO) &&
                        (nt.NyckeltalId === NyckeltalDomain.BR.Marknadsandel ||
                          nt.NyckeltalId === NyckeltalDomain.Smaahus.Marknadsandel)
                          ? budgetNyckeltalVaerdeList.data.BudgetNyckeltalVaerdeList.find(
                              budget => budget.NyckeltalId === nt.NyckeltalId
                            )?.Omsaettningskrav
                          : budgetNyckeltalVaerdeList.data.BudgetNyckeltalVaerdeList.find(
                              budget => budget.NyckeltalId === nt.NyckeltalId
                            )?.Foervaentning
                      }
                    />
                  ),
                };
              })}
              maxDetailsOpen={3}
            />
          </div>
        )}
      {((RemoteDataFunctions.hasData(budget) && !budget.data.IsViewable) || isUnauthorized) && (
        <div className="flex h-screen">
          <FbModal
            titel="Behörighet till budget saknas"
            text="Du saknar behörighet för att komma åt och redigera den här budgeten."
            buttonText="Tillbaka till start"
            onClick={() => dispatch(homeRoute())}
            className="m-auto w-96"
          ></FbModal>
        </div>
      )}
    </>
  );
};
