/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NyckeltalvaerdeUtfallMaanadDTOQry
 */
export interface NyckeltalvaerdeUtfallMaanadDTOQry {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallMaanadDTOQry
   */
  NyckeltalvaerdeId?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallMaanadDTOQry
   */
  BudgetId?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallMaanadDTOQry
   */
  BudgetnyckeltalLoepnummer?: number | null;
}

export function NyckeltalvaerdeUtfallMaanadDTOQryFromJSON(json: any): NyckeltalvaerdeUtfallMaanadDTOQry {
  return NyckeltalvaerdeUtfallMaanadDTOQryFromJSONTyped(json, false);
}

export function NyckeltalvaerdeUtfallMaanadDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeUtfallMaanadDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalvaerdeId: !exists(json, 'NyckeltalvaerdeId') ? undefined : json['NyckeltalvaerdeId'],
    BudgetId: !exists(json, 'BudgetId') ? undefined : json['BudgetId'],
    BudgetnyckeltalLoepnummer: !exists(json, 'BudgetnyckeltalLoepnummer')
      ? undefined
      : json['BudgetnyckeltalLoepnummer'],
  };
}

export function NyckeltalvaerdeUtfallMaanadDTOQryToJSON(value?: NyckeltalvaerdeUtfallMaanadDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalvaerdeId: value.NyckeltalvaerdeId,
    BudgetId: value.BudgetId,
    BudgetnyckeltalLoepnummer: value.BudgetnyckeltalLoepnummer,
  };
}
