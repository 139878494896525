import { FbModal, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectNavigateAway } from './navigateAwayReducer';

export const NavigateAway: FC = () => {
  const navigateAway = useSelector(selectNavigateAway());

  useEffect(() => {
    const alertUser = (ev: BeforeUnloadEvent) => {
      if (navigateAway.fields.length > 0 || navigateAway.ongoingTransactionCount > 0) {
        ev.preventDefault();
        ev.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [navigateAway.fields, navigateAway.ongoingTransactionCount]);

  return navigateAway.showModal ? (
    <FbModal hideBottomBorder={true} titel="Sparar ändringar...">
      <div className="text-center h-36">
        <p className="text-3xl pb-4">{navigateAway.message}</p>
        <div>
          <FbSpinner size={'large'} />
        </div>
      </div>
    </FbModal>
  ) : (
    <></>
  );
};
