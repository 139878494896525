/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum Typ {
  Angivet = 'Angivet',
  Beraeknat = 'Beraeknat',
}

export function TypFromJSON(json: any): Typ {
  return TypFromJSONTyped(json, false);
}

export function TypFromJSONTyped(json: any, ignoreDiscriminator: boolean): Typ {
  return json as Typ;
}

export function TypToJSON(value?: Typ | null): any {
  return value as any;
}
