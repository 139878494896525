/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';

/**
 *
 * @export
 * @interface NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO
 */
export interface NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO
   */
  nyckeltalId: number;
  /**
   *
   * @type {BudgetPK}
   * @memberof NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {Array<number>}
   * @memberof NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO
   */
  NyckeltalvaerdeUtfallMaanadUnderliggandeList: Array<number>;
}

export function NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTOFromJSON(
  json: any
): NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO {
  return NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTOFromJSONTyped(json, false);
}

export function NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    nyckeltalId: json['nyckeltalId'],
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    NyckeltalvaerdeUtfallMaanadUnderliggandeList: json['NyckeltalvaerdeUtfallMaanadUnderliggandeList'],
  };
}

export function NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTOToJSON(
  value?: NyckeltalvaerdeUtfallMaanadUnderliggandeWrapperDTO | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    nyckeltalId: value.nyckeltalId,
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    NyckeltalvaerdeUtfallMaanadUnderliggandeList: value.NyckeltalvaerdeUtfallMaanadUnderliggandeList,
  };
}
