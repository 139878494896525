import { FbButton, FbButtonState, FbCollapsible } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions } from '@decernointernal/websd.shared';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import {
  removeBudgeteraResetAction,
  resetBudgeteraResetAction,
  selectResetBudgeteraReset,
} from './cmdResetBudgeteraReset';

export const ResetBudgetera: React.FC<{}> = () => {
  const budgeteraResetCmd = useSelector(selectResetBudgeteraReset);
  const dispatch = useAppDispatch();

  const [isReset, setIsReset] = useState<boolean>(false);

  const reset = useCallback(() => {
    if (CommandFunctions.isNotExecuted(budgeteraResetCmd)) {
      dispatch(resetBudgeteraResetAction());
    }
  }, [budgeteraResetCmd, dispatch]);

  const getButtonState = useCallback((): FbButtonState => {
    if (CommandFunctions.isExecuting(budgeteraResetCmd)) {
      return 'waiting';
    } else if (CommandFunctions.isSuccess(budgeteraResetCmd) || isReset) {
      return 'success';
    } else if (CommandFunctions.isFailure(budgeteraResetCmd)) {
      return 'failure';
    }
    return 'default';
  }, [budgeteraResetCmd, isReset]);

  useEffect(() => {
    if (CommandFunctions.isSuccess(budgeteraResetCmd)) {
      removeBudgeteraResetAction();
      setIsReset(true);
    }
  }, [budgeteraResetCmd]);

  return (
    <div className="pb-4">
      <FbCollapsible headingTitle="Välkommen till en resetmiljö!" isCollapsible={false}>
        <div className="pb-4">
          {isReset
            ? 'Du har nu resetat miljön, vänligen ladda om sidan för att se den reset:ade miljön'
            : 'Du är nu i en resetmiljö där det går att återställa miljön till starten på ett budgetår. Görs detta rensas alla budgetar och annat data och en kopia av uppsättningen för året kopieras från relaterad miljö.'}
        </div>
        <FbButton
          text="Reset Budgetera"
          type="primary"
          disabled={isReset}
          onClick={reset}
          buttonState={getButtonState()}
        />
      </FbCollapsible>
    </div>
  );
};
