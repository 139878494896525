import { BudgetPK } from 'generated-models/budgetera';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { budgetRoute } from 'store/location';
import { RootState, useAppDispatch } from 'store/store';
import { VOCreate } from 'utils/ValueObjectHelpers';
import { Budget } from './Budget';

/**
 * This is an ugly wrapper to fix our id problem which occured in production. All budgetid:s must start on 100 000 to not collide
 * with the id:s from Mål & Budget (the old budget system), but this was missed in production. Therefore this will reroute id:s lower than 100 000
 * @returns
 */
export const BudgetWrapper: React.FC = () => {
  const budgetId = useSelector((state: RootState) => parseInt(state.location.payload.budgetId));
  const dispatch = useAppDispatch();

  const isBudgetIdToLow = useCallback(() => {
    return budgetId < 100000;
  }, [budgetId]);

  useEffect(() => {
    if (isBudgetIdToLow()) {
      dispatch(budgetRoute({ BudgetId: budgetId + 100000 }));
    }
  }, [budgetId, dispatch, isBudgetIdToLow]);

  if (isBudgetIdToLow()) {
    return null;
  }
  return <Budget pk={VOCreate<BudgetPK>({ BudgetId: budgetId })} />;
};
