/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface JournalTypDTO
 */
export interface JournalTypDTO {
  /**
   *
   * @type {number}
   * @memberof JournalTypDTO
   */
  JournalTypId: number;
  /**
   *
   * @type {string}
   * @memberof JournalTypDTO
   */
  JournalTypNamn: string;
}

export function JournalTypDTOFromJSON(json: any): JournalTypDTO {
  return JournalTypDTOFromJSONTyped(json, false);
}

export function JournalTypDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): JournalTypDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    JournalTypId: json['JournalTypId'],
    JournalTypNamn: json['JournalTypNamn'],
  };
}

export function JournalTypDTOToJSON(value?: JournalTypDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    JournalTypId: value.JournalTypId,
    JournalTypNamn: value.JournalTypNamn,
  };
}
