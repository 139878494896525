/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';
import {
  NyckeltalvaerdeUtfallUnderliggandeDTO,
  NyckeltalvaerdeUtfallUnderliggandeDTOFromJSON,
  NyckeltalvaerdeUtfallUnderliggandeDTOFromJSONTyped,
  NyckeltalvaerdeUtfallUnderliggandeDTOToJSON,
} from './NyckeltalvaerdeUtfallUnderliggandeDTO';

/**
 *
 * @export
 * @interface NyckeltalvaerdeUtfallUnderliggandeWrapperDTO
 */
export interface NyckeltalvaerdeUtfallUnderliggandeWrapperDTO {
  /**
   *
   * @type {number}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeWrapperDTO
   */
  nyckeltalId: number;
  /**
   *
   * @type {BudgetPK}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeWrapperDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {Array<NyckeltalvaerdeUtfallUnderliggandeDTO>}
   * @memberof NyckeltalvaerdeUtfallUnderliggandeWrapperDTO
   */
  NyckeltalvaerdeUtfallUnderliggandeList: Array<NyckeltalvaerdeUtfallUnderliggandeDTO>;
}

export function NyckeltalvaerdeUtfallUnderliggandeWrapperDTOFromJSON(
  json: any
): NyckeltalvaerdeUtfallUnderliggandeWrapperDTO {
  return NyckeltalvaerdeUtfallUnderliggandeWrapperDTOFromJSONTyped(json, false);
}

export function NyckeltalvaerdeUtfallUnderliggandeWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeUtfallUnderliggandeWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    nyckeltalId: json['nyckeltalId'],
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    NyckeltalvaerdeUtfallUnderliggandeList: (json['NyckeltalvaerdeUtfallUnderliggandeList'] as Array<any>).map(
      NyckeltalvaerdeUtfallUnderliggandeDTOFromJSON
    ),
  };
}

export function NyckeltalvaerdeUtfallUnderliggandeWrapperDTOToJSON(
  value?: NyckeltalvaerdeUtfallUnderliggandeWrapperDTO | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    nyckeltalId: value.nyckeltalId,
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    NyckeltalvaerdeUtfallUnderliggandeList: (value.NyckeltalvaerdeUtfallUnderliggandeList as Array<any>).map(
      NyckeltalvaerdeUtfallUnderliggandeDTOToJSON
    ),
  };
}
