import { FbCollapsible, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserQry } from '../user/qryUser';
import { Roles } from '../user/Roles';
import { BudgetList } from './budgetList/BudgetList';
import { ResetBudgetera } from './reset/ResetBudgetera';

export const Home: React.FC = () => {
  const userQry = useSelector(selectUserQry());

  if (!RemoteDataFunctions.hasData(userQry)) {
    return (
      <div className="w-full p-8 text-center">
        <FbSpinner size="large" />
      </div>
    );
  }

  return (
    <div className="p-4">
      {RemoteDataFunctions.hasData(userQry) && userQry.data.Role === Roles.ADMINISTRATOER ? (
        <>
          {process.env.REACT_APP_IS_RESET && <ResetBudgetera />}
          <FbCollapsible headingTitle="Välkommen administratör i Budgetera" isCollapsible={false}>
            Här kan du som administratör starta budgetår, hantera deadlines och periodiseringsprofiler för
            budgetprocessen samt se och redigera alla budgetar.
          </FbCollapsible>
        </>
      ) : (
        <FbCollapsible headingTitle="Välkommen till Budgetera" isDefaultCollapsed={true} isCollapsible={true}>
          <div className="whitespace-pre-line">
            Budgetera är Fastighetsbyråns verktyg för att lägga budget för mäklare, kontor, affärsområden och
            bostadsmarknader. Varje budget omfattar bland annat intagsförsök, affärer och provisionsintäkter. Kontorens
            budgetar innehåller också sådant som rör banksamarbete och medarbetare.
            {'\n\n'}
            På Byrålådan hittar du instruktioner om hur du utför olika moment Budgetera. Behöver du ytterligare hjälp
            kan du vända dig till supporten för våra interna system som ligger hos leverantören Decerno. Se Byrålådan
            för kontaktvägar och öppettider.
          </div>
        </FbCollapsible>
      )}
      <BudgetList />
    </div>
  );
};
