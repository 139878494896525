import { combineReducers } from 'redux';
import qryCopyBudgetCmd from './cmdCopyBudget';
import qrySetBudgetApprovedCmd from './cmdSetBudgetApproved';
import qrySetBudgetComplementCmd from './cmdSetBudgetComplement';
import qrySetBudgetDoneCmd from './cmdSetBudgetDone';
import budgetReopenedCmdReducer from './cmdSetBudgetReopened';
import qryUndoBudgetDoneCmd from './cmdUndoBudgetDone';
import qryUpdateBudgetFoerutsaettningarCmd from './cmdUpdateBudgetFoerutsaettningar';
import qryUpdateBudgetNyckeltalFoervaentningCmd from './cmdUpdateBudgetNyckeltalFoervaentning';
import { kommentarReducer } from './kommentar/kommentarReducer';
import { budgetQryReducer } from './qryBudget';
import foerutsaettningQryReducer from './qryBudgetFoerutsaettningar';
import { budgetnyckeltalListWrapperQryReducer } from './qryBudgetnyckeltalList';
import { budgetNyckeltalVaerdeListQryReducer } from './qryBudgetNyckeltalVaerdeList';
import { periodiseringsprofilListQryReducer } from './qryPeriodiseringsprofilList';
import qrySummaUnderliggande from './qrySummaUnderliggande';
import { underliggandeEnheterQryReducer } from './qryUnderliggandeEnheter';
import qryUnderliggandeEnheterVaerde from './qryUnderliggandeEnheterVaerde';
import { sidedrawerNotiserReducer } from './sidedrawerNotiser/sidedrawerNotiserReducer';

export const budgetReducer = combineReducers({
  qryBudgetnyckeltalList: budgetnyckeltalListWrapperQryReducer,
  qryBudget: budgetQryReducer,
  qrySetBudgetDone: qrySetBudgetDoneCmd,
  qryUndoBudgetDone: qryUndoBudgetDoneCmd,
  qrySetBudgetApproved: qrySetBudgetApprovedCmd,
  cmdSetBudgetReopened: budgetReopenedCmdReducer,
  qrySetBudgetComplement: qrySetBudgetComplementCmd,
  qryBudgetNyckeltalVaerdeList: budgetNyckeltalVaerdeListQryReducer,
  qrySummaUnderliggandeList: qrySummaUnderliggande,
  qryUnderliggandeEnheter: underliggandeEnheterQryReducer,
  qryPeriodiseringsprofilList: periodiseringsprofilListQryReducer,
  qryUnderliggandeEnheterVaerde: qryUnderliggandeEnheterVaerde,
  qryUpdateBudgetNyckeltalFoervaentning: qryUpdateBudgetNyckeltalFoervaentningCmd,
  qryCopyBudget: qryCopyBudgetCmd,
  qryUpdateBudgetFoerutsaettningar: qryUpdateBudgetFoerutsaettningarCmd,
  qryKommentar: kommentarReducer,
  qrySidedrawerNotiser: sidedrawerNotiserReducer,
  qryBudgetFoerutsaettning: foerutsaettningQryReducer,
});
