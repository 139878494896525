/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetaarPeriodiseringsprofilListDTO,
  BudgetaarPeriodiseringsprofilListDTOFromJSON,
  BudgetaarPeriodiseringsprofilListDTOFromJSONTyped,
  BudgetaarPeriodiseringsprofilListDTOToJSON,
} from './BudgetaarPeriodiseringsprofilListDTO';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';

/**
 *
 * @export
 * @interface BudgetaarPeriodiseringsprofilListWrapperDTO
 */
export interface BudgetaarPeriodiseringsprofilListWrapperDTO {
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof BudgetaarPeriodiseringsprofilListWrapperDTO
   */
  BudgetperiodPK: BudgetperiodPK;
  /**
   *
   * @type {Array<BudgetaarPeriodiseringsprofilListDTO>}
   * @memberof BudgetaarPeriodiseringsprofilListWrapperDTO
   */
  BudgetaarPeriodiseringsprofilList: Array<BudgetaarPeriodiseringsprofilListDTO>;
}

export function BudgetaarPeriodiseringsprofilListWrapperDTOFromJSON(
  json: any
): BudgetaarPeriodiseringsprofilListWrapperDTO {
  return BudgetaarPeriodiseringsprofilListWrapperDTOFromJSONTyped(json, false);
}

export function BudgetaarPeriodiseringsprofilListWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetaarPeriodiseringsprofilListWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
    BudgetaarPeriodiseringsprofilList: (json['BudgetaarPeriodiseringsprofilList'] as Array<any>).map(
      BudgetaarPeriodiseringsprofilListDTOFromJSON
    ),
  };
}

export function BudgetaarPeriodiseringsprofilListWrapperDTOToJSON(
  value?: BudgetaarPeriodiseringsprofilListWrapperDTO | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
    BudgetaarPeriodiseringsprofilList: (value.BudgetaarPeriodiseringsprofilList as Array<any>).map(
      BudgetaarPeriodiseringsprofilListDTOToJSON
    ),
  };
}
