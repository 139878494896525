/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DefaultPeriodiseringsprofilListDTO
 */
export interface DefaultPeriodiseringsprofilListDTO {
  /**
   *
   * @type {number}
   * @memberof DefaultPeriodiseringsprofilListDTO
   */
  BudgetperiodId: number;
  /**
   *
   * @type {number}
   * @memberof DefaultPeriodiseringsprofilListDTO
   */
  PeriodiseringsprofilId: number;
  /**
   *
   * @type {string}
   * @memberof DefaultPeriodiseringsprofilListDTO
   */
  Nyckeltalnamn: string;
  /**
   *
   * @type {string}
   * @memberof DefaultPeriodiseringsprofilListDTO
   */
  NyckeltalKategori: string;
  /**
   *
   * @type {string}
   * @memberof DefaultPeriodiseringsprofilListDTO
   */
  NyckeltalSubkategori: string;
  /**
   *
   * @type {number}
   * @memberof DefaultPeriodiseringsprofilListDTO
   */
  BostadsmarknadId?: number | null;
  /**
   *
   * @type {number}
   * @memberof DefaultPeriodiseringsprofilListDTO
   */
  NyckeltalId: number;
}

export function DefaultPeriodiseringsprofilListDTOFromJSON(json: any): DefaultPeriodiseringsprofilListDTO {
  return DefaultPeriodiseringsprofilListDTOFromJSONTyped(json, false);
}

export function DefaultPeriodiseringsprofilListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DefaultPeriodiseringsprofilListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodId: json['BudgetperiodId'],
    PeriodiseringsprofilId: json['PeriodiseringsprofilId'],
    Nyckeltalnamn: json['Nyckeltalnamn'],
    NyckeltalKategori: json['NyckeltalKategori'],
    NyckeltalSubkategori: json['NyckeltalSubkategori'],
    BostadsmarknadId: !exists(json, 'BostadsmarknadId') ? undefined : json['BostadsmarknadId'],
    NyckeltalId: json['NyckeltalId'],
  };
}

export function DefaultPeriodiseringsprofilListDTOToJSON(value?: DefaultPeriodiseringsprofilListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodId: value.BudgetperiodId,
    PeriodiseringsprofilId: value.PeriodiseringsprofilId,
    Nyckeltalnamn: value.Nyckeltalnamn,
    NyckeltalKategori: value.NyckeltalKategori,
    NyckeltalSubkategori: value.NyckeltalSubkategori,
    BostadsmarknadId: value.BostadsmarknadId,
    NyckeltalId: value.NyckeltalId,
  };
}
