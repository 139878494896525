import {
  createWebDataList,
  RemoteDataFunctions,
  RemoteDataStatus,
  SdKeys,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { NyckeltalVaerdeDTO, NyckeltalVaerdePK } from 'generated-models/budgetera/models';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType } from 'utils/eventDispatcher';

const ActionType = '[Nyckeltal] Get nyckeltal värde';

// Slice for fetching budget for the selected enhet
const nyckeltalvaerdeQry = createWebDataList<typeof ActionType, NyckeltalVaerdeDTO, NyckeltalVaerdePK>(
  ActionType,
  k => {
    return deconstructKey(k);
  }
);

type NyckeltalvaerdeQryState = WebDataListState<NyckeltalVaerdeDTO>;

const deconstructKey = (pk: NyckeltalVaerdePK) => {
  const { BudgetId, BudgetnyckeltalLoepnummer } = pk;
  return { BudgetId, BudgetnyckeltalLoepnummer };
};

const mapNyckeltalvaerdeQryState: MapState<NyckeltalvaerdeQryState> = rootState =>
  rootState.main.nyckeltalVaerde.qryNyckeltalVaerde;

export const selectNyckeltalvaerdeQry =
  (id: { BudgetId: number; BudgetnyckeltalLoepnummer: number }) => (state: RootState) =>
    nyckeltalvaerdeQry.get(mapNyckeltalvaerdeQryState(state), id);

export const nyckeltalvaerdeQryAction =
  (id: { BudgetId: number; BudgetnyckeltalLoepnummer: number }): ThunkAction =>
  async (dispatch, getState) => {
    const webData = nyckeltalvaerdeQry.get(mapNyckeltalvaerdeQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(nyckeltalvaerdeQry.updating(webData.data));
      } else {
        dispatch(nyckeltalvaerdeQry.loading(id));
      }
      const result = await new ApiClient().NyckeltalvaerdeQryApi.getNyckeltalvaerde({ nyckeltalvaerdeDTOQry: id });
      dispatch(nyckeltalvaerdeQry.success(result));
    } catch (err) {
      dispatch(nyckeltalvaerdeQry.failure(id, await parseErrorResponse(err)));
    }
  };

// React to integration events
export const nyckeltalvaerdeQryReducer: Reducer<NyckeltalvaerdeQryState, IntegrationEventAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case IntegrationEventActionType.NyckeltalVaerdeDTO: {
      // Since we react to a DTO event, we will recieve the data we need to update redux directly
      const pk = action.payload.eventDataKey;
      const webData = nyckeltalvaerdeQry.get(state, deconstructKey(pk));
      if (RemoteDataFunctions.hasData(webData)) {
        // Only update redux if we actually have data
        return {
          ...state,
          [SdKeys.keyToString(deconstructKey(pk))]: {
            ...webData,
            data: action.payload.eventData,
            status: RemoteDataStatus.Success,
          },
        };
      }
    }
  }

  return nyckeltalvaerdeQry.reducer(state, action);
};
