import { Undersektion } from 'generated-models/budgetera';

interface BudgetaarNyckeltalTableProps {
  FirstUndersektion: boolean;
  Undersektion: Undersektion;
  SektionNamn: string;
}

export const BudgetaarNyckeltalTable: React.FC<BudgetaarNyckeltalTableProps> = ({
  FirstUndersektion,
  Undersektion,
  SektionNamn,
}) => {
  return (
    <table className="fb-paragraph-lvl-2 w-1/3 text-left">
      <tbody>
        {Undersektion.NyckeltalList.map((nyckeltal, nyckeltalIndex) => (
          <tr className={'odd:bg-fb-color-grey-superlight'} key={nyckeltalIndex}>
            <td className="h-8 pl-2 py-2 border border-fb-color-grey-table-border">
              {nyckeltal.Nyckeltalnamn + ' (' + nyckeltal.Enhet + ')'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
