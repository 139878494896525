import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnhetVO } from 'generated-models/budgetera';
import { RootState } from 'store/store';

export interface CurrentEnhetState {
  currentEnhet?: EnhetVO;
}

const initialState: CurrentEnhetState = {
  currentEnhet: undefined,
};

export const CurrentEnhetSlice = createSlice({
  name: 'CurrentEnhet',
  initialState,
  reducers: {
    setCurrentEnhet: (state, action: PayloadAction<EnhetVO>) => {
      state.currentEnhet = action.payload;
    },
    removeCurrentEnhet: state => {
      state.currentEnhet = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentEnhet, removeCurrentEnhet } = CurrentEnhetSlice.actions;

export const selectCurrentEnhet = () => (state: RootState) => state.user.uiCurrentEnhet;

export default CurrentEnhetSlice.reducer;
