/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetListDTO, BudgetListDTOFromJSON, BudgetListDTOFromJSONTyped, BudgetListDTOToJSON } from './BudgetListDTO';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';

/**
 *
 * @export
 * @interface BudgetListBudgetperiodWrapperDTO
 */
export interface BudgetListBudgetperiodWrapperDTO {
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof BudgetListBudgetperiodWrapperDTO
   */
  BudgetperiodPK: BudgetperiodPK;
  /**
   *
   * @type {Array<BudgetListDTO>}
   * @memberof BudgetListBudgetperiodWrapperDTO
   */
  BudgetListDTO: Array<BudgetListDTO>;
}

export function BudgetListBudgetperiodWrapperDTOFromJSON(json: any): BudgetListBudgetperiodWrapperDTO {
  return BudgetListBudgetperiodWrapperDTOFromJSONTyped(json, false);
}

export function BudgetListBudgetperiodWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetListBudgetperiodWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
    BudgetListDTO: (json['BudgetListDTO'] as Array<any>).map(BudgetListDTOFromJSON),
  };
}

export function BudgetListBudgetperiodWrapperDTOToJSON(value?: BudgetListBudgetperiodWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
    BudgetListDTO: (value.BudgetListDTO as Array<any>).map(BudgetListDTOToJSON),
  };
}
