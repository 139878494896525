import { NyckeltalDomain } from 'domain/nyckeltalDomain';
import { Enhetstyp } from 'generated-models/budgetera';

export const getEnhetText = (enhet: Enhetstyp, aerUtland: boolean) => {
  switch (enhet) {
    case Enhetstyp.Styck:
    case Enhetstyp.StyckDecimal:
      return 'st';
    case Enhetstyp.Procent:
      return '%';
    case Enhetstyp.Valuta:
      return aerUtland ? 'EUR' : 'SEK';
    default:
      return '';
  }
};

export const getIsNyckeltalSpecificDate = (nyckeltalId: number) => {
  return (
    nyckeltalId === NyckeltalDomain.Antal.Medarbetare ||
    nyckeltalId === NyckeltalDomain.Antal.Maeklare ||
    nyckeltalId === NyckeltalDomain.Antal.Partners ||
    nyckeltalId === NyckeltalDomain.Antal.Underkonsulter
  );
};

export const getIsNyckeltalOmsaettningskrav = (nyckeltalId: number) => {
  return nyckeltalId === NyckeltalDomain.Smaahus.Marknadsandel || nyckeltalId === NyckeltalDomain.BR.Marknadsandel;
};
