import { FbButton } from '@decernointernal/fb-interna-komponenter';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const FbModal: React.FC<{
  titel: string;
  text: string;
  buttonText: string;
  buttonIcon?: IconProp;
  onClick: () => void;
  className?: string;
}> = ({ titel, text, buttonText, buttonIcon, onClick, className }) => {
  return (
    <div className={`bg-fb-color-white shadow-md ${className}`}>
      <div className="bg-fb-color-primary px-4 py-3 shadow-md">
        <h3 className="text-fb-color-white">{titel}</h3>
      </div>
      <div className=" p-4">
        <p>{text}</p>
        <div className="mt-7">
          <FbButton className="m-auto" text={buttonText} type="primary" onClick={onClick} icon={buttonIcon}></FbButton>
        </div>
      </div>
    </div>
  );
};
