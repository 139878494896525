import { FbAlert, FbButton, FbFormInput, FbModal } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions } from '@decernointernal/websd.shared';
import { BudgetListDTO } from 'generated-models/budgetera';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { createPlannedAction, selectCreatePlannedCmd } from './cmdCreatePlanned';

interface CreatePlannedBudgetModalProps {
  readonly mainBudget: BudgetListDTO;
  readonly underlyingNames: string[];
}

export const CreatePlannedBudgetModal: React.FC<CreatePlannedBudgetModalProps> = ({ mainBudget, underlyingNames }) => {
  const createPlanned = useSelector(selectCreatePlannedCmd);
  const dispatch = useAppDispatch();

  const [showNewPlannedModal, setShowNewPlannedModal] = useState(false);
  const [showDuplicationWarning, setShowDuplicationWarning] = useState(false);
  const [plannedBudgetName, setPlannedBudgetName] = useState('');

  useEffect(() => {
    if (underlyingNames.some(c => c === plannedBudgetName)) {
      setShowDuplicationWarning(true);
      return;
    }
    setShowDuplicationWarning(false);
  }, [plannedBudgetName, underlyingNames]);

  useEffect(() => {
    const getNextAvailableName = (counter: number): string => {
      const name = 'Mäklare ' + counter;
      if (underlyingNames.some(c => c === name)) {
        return getNextAvailableName(counter + 1);
      }
      return name;
    };
    if (showNewPlannedModal) {
      setPlannedBudgetName(getNextAvailableName(1));
    }
  }, [showNewPlannedModal, underlyingNames]);

  return (
    <>
      <div className="mt-4 flex">
        <FbButton
          type="secondary"
          text={'Lägg till planerad rekrytering'}
          onClick={() => {
            setShowNewPlannedModal(true);
          }}
        />
      </div>
      {showNewPlannedModal && (
        <FbModal
          titel="Lägg till planerad rekrytering"
          buttonConfirmText="Skapa budget"
          disabled={showDuplicationWarning}
          buttonCancelText="Avbryt"
          loading={CommandFunctions.isExecuting(createPlanned)}
          hideBottomBorder={true}
          onConfirm={() => {
            dispatch(
              createPlannedAction({
                BudgetPK: mainBudget.BudgetPK,
                KontorId: mainBudget.EnhetVO.KontorId ?? 0,
                Name: plannedBudgetName,
              })
            );
            setShowNewPlannedModal(false);
          }}
          onCancel={() => {
            setShowNewPlannedModal(false);
          }}
        >
          <div>
            <FbFormInput
              id="x"
              label="Namnge budget"
              defaultValue={plannedBudgetName}
              onInstantChange={val => {
                setPlannedBudgetName(val);
              }}
            />
            {showDuplicationWarning && (
              <div className="mt-4 text-xs-increased">
                <FbAlert
                  type="danger"
                  text="Namnet som du försöker använda för planerad rekrytering finns redan i listan."
                />
              </div>
            )}
          </div>
        </FbModal>
      )}
    </>
  );
};
