/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';
import {
  DefaultPeriodiseringsprofilListDTO,
  DefaultPeriodiseringsprofilListDTOFromJSON,
  DefaultPeriodiseringsprofilListDTOFromJSONTyped,
  DefaultPeriodiseringsprofilListDTOToJSON,
} from './DefaultPeriodiseringsprofilListDTO';
import {
  PeriodiseringsprofilMaanadVaerdeList,
  PeriodiseringsprofilMaanadVaerdeListFromJSON,
  PeriodiseringsprofilMaanadVaerdeListFromJSONTyped,
  PeriodiseringsprofilMaanadVaerdeListToJSON,
} from './PeriodiseringsprofilMaanadVaerdeList';
import {
  PeriodiseringsprofilPK,
  PeriodiseringsprofilPKFromJSON,
  PeriodiseringsprofilPKFromJSONTyped,
  PeriodiseringsprofilPKToJSON,
} from './PeriodiseringsprofilPK';

/**
 *
 * @export
 * @interface BudgetaarPeriodiseringsprofilListDTO
 */
export interface BudgetaarPeriodiseringsprofilListDTO {
  /**
   *
   * @type {Array<PeriodiseringsprofilMaanadVaerdeList>}
   * @memberof BudgetaarPeriodiseringsprofilListDTO
   */
  PeriodiseringsprofilMaanadList: Array<PeriodiseringsprofilMaanadVaerdeList>;
  /**
   *
   * @type {Array<DefaultPeriodiseringsprofilListDTO>}
   * @memberof BudgetaarPeriodiseringsprofilListDTO
   */
  DefaultPeriodiseringsprofilList?: Array<DefaultPeriodiseringsprofilListDTO> | null;
  /**
   *
   * @type {PeriodiseringsprofilPK}
   * @memberof BudgetaarPeriodiseringsprofilListDTO
   */
  PeriodiseringsprofilPK: PeriodiseringsprofilPK;
  /**
   *
   * @type {number}
   * @memberof BudgetaarPeriodiseringsprofilListDTO
   */
  BostadsmarknadId?: number | null;
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof BudgetaarPeriodiseringsprofilListDTO
   */
  BudgetperiodPK: BudgetperiodPK;
  /**
   *
   * @type {string}
   * @memberof BudgetaarPeriodiseringsprofilListDTO
   */
  Namn: string;
  /**
   *
   * @type {number}
   * @memberof BudgetaarPeriodiseringsprofilListDTO
   */
  Sortering: number;
}

export function BudgetaarPeriodiseringsprofilListDTOFromJSON(json: any): BudgetaarPeriodiseringsprofilListDTO {
  return BudgetaarPeriodiseringsprofilListDTOFromJSONTyped(json, false);
}

export function BudgetaarPeriodiseringsprofilListDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetaarPeriodiseringsprofilListDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PeriodiseringsprofilMaanadList: (json['PeriodiseringsprofilMaanadList'] as Array<any>).map(
      PeriodiseringsprofilMaanadVaerdeListFromJSON
    ),
    DefaultPeriodiseringsprofilList: !exists(json, 'DefaultPeriodiseringsprofilList')
      ? undefined
      : json['DefaultPeriodiseringsprofilList'] === null
      ? null
      : (json['DefaultPeriodiseringsprofilList'] as Array<any>).map(DefaultPeriodiseringsprofilListDTOFromJSON),
    PeriodiseringsprofilPK: PeriodiseringsprofilPKFromJSON(json['PeriodiseringsprofilPK']),
    BostadsmarknadId: !exists(json, 'BostadsmarknadId') ? undefined : json['BostadsmarknadId'],
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
    Namn: json['Namn'],
    Sortering: json['Sortering'],
  };
}

export function BudgetaarPeriodiseringsprofilListDTOToJSON(value?: BudgetaarPeriodiseringsprofilListDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PeriodiseringsprofilMaanadList: (value.PeriodiseringsprofilMaanadList as Array<any>).map(
      PeriodiseringsprofilMaanadVaerdeListToJSON
    ),
    DefaultPeriodiseringsprofilList:
      value.DefaultPeriodiseringsprofilList === undefined
        ? undefined
        : value.DefaultPeriodiseringsprofilList === null
        ? null
        : (value.DefaultPeriodiseringsprofilList as Array<any>).map(DefaultPeriodiseringsprofilListDTOToJSON),
    PeriodiseringsprofilPK: PeriodiseringsprofilPKToJSON(value.PeriodiseringsprofilPK),
    BostadsmarknadId: value.BostadsmarknadId,
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
    Namn: value.Namn,
    Sortering: value.Sortering,
  };
}
