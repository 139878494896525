/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';

/**
 *
 * @export
 * @interface BudgetFoerutsaettningDTO
 */
export interface BudgetFoerutsaettningDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof BudgetFoerutsaettningDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {string}
   * @memberof BudgetFoerutsaettningDTO
   */
  Foerutsaettningar: string;
}

export function BudgetFoerutsaettningDTOFromJSON(json: any): BudgetFoerutsaettningDTO {
  return BudgetFoerutsaettningDTOFromJSONTyped(json, false);
}

export function BudgetFoerutsaettningDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetFoerutsaettningDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    Foerutsaettningar: json['Foerutsaettningar'],
  };
}

export function BudgetFoerutsaettningDTOToJSON(value?: BudgetFoerutsaettningDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    Foerutsaettningar: value.Foerutsaettningar,
  };
}
