import { createWebDataSingletonList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { JournalTypDTO } from 'generated-models/budgetera';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction } from 'utils/eventDispatcher';

const ActionType = '[journal] Get JournalTyp';

const journalTypQry = createWebDataSingletonList<typeof ActionType, JournalTypDTO[]>(ActionType, ActionType);

type JournalTypQryState = WebDataListState<JournalTypDTO[]>;

const mapJournalTypQryState: MapState<JournalTypQryState> = rootState => rootState.main.journal.qryJournalTyp;

export const selectJournalTypQry = () => (state: RootState) => journalTypQry.get(mapJournalTypQryState(state));

export const getJournalTypQryAction = (): ThunkAction => async (dispatch, getState) => {
  const webData = journalTypQry.get(mapJournalTypQryState(getState()));
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(journalTypQry.updating(webData.data));
    } else {
      dispatch(journalTypQry.loading());
    }
    const result = await new ApiClient().JournalQryApi.getJournalTyp();
    dispatch(journalTypQry.success(result));
  } catch (err) {
    dispatch(journalTypQry.failure(await parseErrorResponse(err)));
  }
};

export const journalTypQryReducer: Reducer<JournalTypQryState, IntegrationEventAction> = (state = {}, action) => {
  return journalTypQry.reducer(state, action);
};
