/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';
import {
  PeriodiseringsprofilPK,
  PeriodiseringsprofilPKFromJSON,
  PeriodiseringsprofilPKFromJSONTyped,
  PeriodiseringsprofilPKToJSON,
} from './PeriodiseringsprofilPK';

/**
 *
 * @export
 * @interface PeriodiseringsprofilDTO
 */
export interface PeriodiseringsprofilDTO {
  /**
   *
   * @type {PeriodiseringsprofilPK}
   * @memberof PeriodiseringsprofilDTO
   */
  PeriodiseringsprofilPK: PeriodiseringsprofilPK;
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilDTO
   */
  BostadsmarknadId?: number | null;
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof PeriodiseringsprofilDTO
   */
  BudgetperiodPK: BudgetperiodPK;
  /**
   *
   * @type {string}
   * @memberof PeriodiseringsprofilDTO
   */
  Namn: string;
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilDTO
   */
  Sortering: number;
}

export function PeriodiseringsprofilDTOFromJSON(json: any): PeriodiseringsprofilDTO {
  return PeriodiseringsprofilDTOFromJSONTyped(json, false);
}

export function PeriodiseringsprofilDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodiseringsprofilDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PeriodiseringsprofilPK: PeriodiseringsprofilPKFromJSON(json['PeriodiseringsprofilPK']),
    BostadsmarknadId: !exists(json, 'BostadsmarknadId') ? undefined : json['BostadsmarknadId'],
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
    Namn: json['Namn'],
    Sortering: json['Sortering'],
  };
}

export function PeriodiseringsprofilDTOToJSON(value?: PeriodiseringsprofilDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PeriodiseringsprofilPK: PeriodiseringsprofilPKToJSON(value.PeriodiseringsprofilPK),
    BostadsmarknadId: value.BostadsmarknadId,
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
    Namn: value.Namn,
    Sortering: value.Sortering,
  };
}
