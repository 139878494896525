import * as React from 'react';
import { useIntersection } from 'utils/useIntersection';
import {
  getCorrectScrollingSubcategory,
  getScrollingCategory,
  isScrollingCategory,
  ScrollingCategory,
  ScrollingSubcategory,
} from './FbScrollingCategoryUtils';

interface FbScrollingCategoryProps {
  className: string;
  scrollableContainer?: React.MutableRefObject<Element | null>;
  category: ScrollingCategory | ScrollingSubcategory;
  categories: ScrollingCategory[];
  subcategories: ScrollingSubcategory[];
  topOffset?: number;
  setTitle: (v: string) => void;
  setSubtitle: (v: string) => void;
}

export const FbScrollingCategory: React.FC<React.PropsWithChildren<FbScrollingCategoryProps>> = ({
  className,
  scrollableContainer,
  category,
  categories,
  subcategories,
  topOffset = 0,
  children,
  setTitle,
  setSubtitle,
}) => {
  const elementRef = React.useRef<Element | null>(null);
  const entry = useIntersection(elementRef, scrollableContainer, 1, -topOffset + 'px 0px 5000% 0px');
  const [header] = React.useState(category);

  const updateTitleAndSubtitle = React.useCallback(() => {
    const gridHeader = getScrollingCategory(categories);
    setTitle(gridHeader?.text ?? '');
    const gridSubheader = getCorrectScrollingSubcategory(gridHeader?.text ?? '', subcategories);
    setSubtitle(gridSubheader);
  }, [setTitle, setSubtitle, categories, subcategories]);

  React.useEffect(() => {
    if (entry) {
      if (!entry.isIntersecting) {
        header.scrolledPast = true;
        updateTitleAndSubtitle();
      } else if (entry.isIntersecting) {
        header.scrolledPast = false;
        updateTitleAndSubtitle();
      }
    }
  }, [entry, header, updateTitleAndSubtitle]);

  React.useEffect(() => {
    if (isScrollingCategory(header)) {
      categories.push(header);
      return () => {
        for (let i = 0; i < categories.length; i++) {
          if (categories[i] === header) {
            categories.splice(i, 1);
          }
        }
      };
    } else {
      subcategories.push(header);
      return () => {
        for (let i = 0; i < subcategories.length; i++) {
          if (subcategories[i] === header) {
            subcategories.splice(i, 1);
          }
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={r => (elementRef.current = r)} className={`${className || ''}`}>
      {children}
    </div>
  );
};
