import { SummaUnderliggandeStatusVO } from 'generated-models/budgetera';

export type SummaUnderliggandeStatusDomain =
  | 'should not summeras'
  | 'no underliggande enheter has vaerden'
  | 'some but not all underliggande enheter has vaerden'
  | 'all underliggande enheter has vaerden';

export const getSummaUnderliggandeStatusDomain = (
  vo: SummaUnderliggandeStatusVO
): SummaUnderliggandeStatusDomain | null => {
  switch (vo.Status) {
    case 1:
      return 'should not summeras';
    case 2:
      return 'no underliggande enheter has vaerden';
    case 3:
      return 'some but not all underliggande enheter has vaerden';
    case 4:
      return 'all underliggande enheter has vaerden';
    default:
      return null;
  }
};
