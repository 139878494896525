import { EnhetVO } from 'generated-models/budgetera';

export const isBostadsmarknad = (enhet: EnhetVO) => {
  return enhet.BostadsmarknadId !== null && enhet.BostadsmarknadId !== undefined;
};

export const isAffaersomraade = (enhet: EnhetVO) => {
  return enhet.KontorsgruppId !== null && enhet.KontorsgruppId !== undefined;
};

export const isKontor = (enhet: EnhetVO) => {
  return enhet.KontorId !== null && enhet.KontorId !== undefined && !enhet.PersonId && !enhet.PlaneradBudgetId;
};

export const isPerson = (enhet: EnhetVO) => {
  return enhet.PersonId !== null && enhet.PersonId !== undefined;
};

export const isPlanerad = (enhet: EnhetVO) => {
  return enhet.PlaneradBudgetId !== null && enhet.PlaneradBudgetId !== undefined;
};
