import {
  createWebDataSingletonList,
  RemoteDataFunctions,
  RemoteDataStatus,
  WebDataListState,
} from '@decernointernal/websd.shared';
import { BudgetperiodDTO } from 'generated-models/budgetera';
import { Reducer } from 'redux';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { IntegrationEventAction, IntegrationEventActionType } from 'utils/eventDispatcher';

const ActionType = '[budgetaarList] GetBudgetaarList';

const budgetaarListQry = createWebDataSingletonList<typeof ActionType, BudgetperiodDTO[]>(ActionType, ActionType);

type budgetaarListQryQryState = WebDataListState<BudgetperiodDTO[]>;

const mapBudgetaarListQryState: MapState<budgetaarListQryQryState> = rootState => rootState.Budgetaar.budgetaarListQry;

export const selectBudgetaarListQry = () => (state: RootState) => budgetaarListQry.get(mapBudgetaarListQryState(state));

export const getBudgetaarListQryAction = (): ThunkAction => async (dispatch, getState) => {
  const webData = budgetaarListQry.get(mapBudgetaarListQryState(getState()));
  if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
    return;
  }
  // Call api and handle result
  try {
    if (RemoteDataFunctions.hasData(webData)) {
      dispatch(budgetaarListQry.updating(webData.data));
    } else {
      dispatch(budgetaarListQry.loading());
    }
    const result = await new ApiClient().BudgetaarQryApi.getBudgetaarBudgetperiodList();

    dispatch(budgetaarListQry.success(result));
  } catch (err) {
    dispatch(budgetaarListQry.failure(await parseErrorResponse(err)));
  }
};

export const budgetaarListQryReducer: Reducer<budgetaarListQryQryState, IntegrationEventAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case IntegrationEventActionType.Budgetperiod: {
      const webData = budgetaarListQry.get(state);
      if (!RemoteDataFunctions.isStale(webData) && RemoteDataFunctions.hasData(webData)) {
        return {
          ...state,
          [ActionType]: {
            data: webData.data,
            status: RemoteDataStatus.Stale,
          },
        };
      }
    }
  }
  return budgetaarListQry.reducer(state, action);
};
