/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetnyckeltalPK,
  BudgetnyckeltalPKFromJSON,
  BudgetnyckeltalPKFromJSONTyped,
  BudgetnyckeltalPKToJSON,
} from './BudgetnyckeltalPK';

/**
 *
 * @export
 * @interface UnderliggandeEnhetVaerdeDTO
 */
export interface UnderliggandeEnhetVaerdeDTO {
  /**
   *
   * @type {BudgetnyckeltalPK}
   * @memberof UnderliggandeEnhetVaerdeDTO
   */
  BudgetnyckeltalPK: BudgetnyckeltalPK;
  /**
   *
   * @type {number}
   * @memberof UnderliggandeEnhetVaerdeDTO
   */
  BudgeteratVaerde?: number | null;
  /**
   *
   * @type {number}
   * @memberof UnderliggandeEnhetVaerdeDTO
   */
  FoervaentningVaerde?: number | null;
  /**
   *
   * @type {number}
   * @memberof UnderliggandeEnhetVaerdeDTO
   */
  Omsaettningskrav?: number | null;
  /**
   *
   * @type {number}
   * @memberof UnderliggandeEnhetVaerdeDTO
   */
  SkattadMarknadandel?: number | null;
}

export function UnderliggandeEnhetVaerdeDTOFromJSON(json: any): UnderliggandeEnhetVaerdeDTO {
  return UnderliggandeEnhetVaerdeDTOFromJSONTyped(json, false);
}

export function UnderliggandeEnhetVaerdeDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UnderliggandeEnhetVaerdeDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetnyckeltalPK: BudgetnyckeltalPKFromJSON(json['BudgetnyckeltalPK']),
    BudgeteratVaerde: !exists(json, 'BudgeteratVaerde') ? undefined : json['BudgeteratVaerde'],
    FoervaentningVaerde: !exists(json, 'FoervaentningVaerde') ? undefined : json['FoervaentningVaerde'],
    Omsaettningskrav: !exists(json, 'Omsaettningskrav') ? undefined : json['Omsaettningskrav'],
    SkattadMarknadandel: !exists(json, 'SkattadMarknadandel') ? undefined : json['SkattadMarknadandel'],
  };
}

export function UnderliggandeEnhetVaerdeDTOToJSON(value?: UnderliggandeEnhetVaerdeDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetnyckeltalPK: BudgetnyckeltalPKToJSON(value.BudgetnyckeltalPK),
    BudgeteratVaerde: value.BudgeteratVaerde,
    FoervaentningVaerde: value.FoervaentningVaerde,
    Omsaettningskrav: value.Omsaettningskrav,
    SkattadMarknadandel: value.SkattadMarknadandel,
  };
}
