import { addSpacesToNumber, AriaCombobox, FbSpinner } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { selectUnderliggandeEnheterQry } from 'components/budget/qryUnderliggandeEnheter';
import {
  BudgetEnhetPair,
  BudgetListDTO,
  BudgetnyckeltalListDTO,
  NyckeltalVaerdeDTO,
  NyckeltalvaerdeMaanadDTO,
  Typ,
} from 'generated-models/budgetera/models';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { FbGridCol } from 'shared-components/grid/FbGrid';
import { FbGridColumnHeaderStyles, FbGridColumnHeaderWrapperStyles } from 'shared-components/grid/FbGridHeader';
import { FbTableGrid, FbTableGridRowDef } from 'shared-components/grid/table/FbTableGrid';
import { FbTableGridInputCellProps } from 'shared-components/grid/table/FbTableGridInputCell';
import { ThunkAction, useAppDispatch } from 'store/store';
import { getSummaMaanader } from 'utils/summaMaanaderUtils';
import { useUpdateNyckeltalVaerde } from 'utils/useUpdateNyckeltalVaerde';
import { selectPeriodiseringsprofilListQry } from '../../qryPeriodiseringsprofilList';
import { getUnderliggandeNamn } from '../underliggande';
import { createNyckeltalvaerdeMaanadAction } from './cmdCreateNyckeltalvaerdeMaanad';
import { updateNyckeltalvaerdeMaanadAction } from './cmdUpdateNyckeltalvaerdeMaanad';
import { selectNyckeltalvaerdeMaanadBudgetQry } from './qryNyckeltalvaerdeMaanadBudget';
import {
  nyckeltalvaerdeUtfallMaanadQryAction,
  selectNyckeltalvaerdeUtfallMaanadQry,
} from './qryNyckeltalvaerdeUtfallMaanad';
import {
  getUnderliggandeUtfallMaanadQryAction,
  selectUnderliggandeUtfallMaanadQry,
} from './qryUnderliggandeUtfallMaanad';

interface PeriodiseringProps {
  budget: BudgetListDTO;
  nyckeltal: BudgetnyckeltalListDTO;
  nyckeltalvaerde?: NyckeltalVaerdeDTO;
}

export const Periodisering: React.FC<PeriodiseringProps> = ({ budget, nyckeltal, nyckeltalvaerde }) => {
  const [summaMaanadsvaerde, setSummaMaanadsvaerde] = React.useState<number | undefined>(undefined);
  const [diffAarsvaerde, setDiffAarsvaerde] = React.useState<number | undefined>(undefined);
  const periodiseringsprofilList = useSelector(selectPeriodiseringsprofilListQry(budget.BudgetPK));
  const dispatch = useAppDispatch();
  const underliggandeEnheter = useSelector(selectUnderliggandeEnheterQry(budget.BudgetPK)); // Denna uppdateras i Budget.tsx
  const nyckeltalvaerdeMaanadList = useSelector(selectNyckeltalvaerdeMaanadBudgetQry());
  const nyckeltalvaerdeUtfallMaanadList = useSelector(
    selectNyckeltalvaerdeUtfallMaanadQry({
      BudgetId: nyckeltal.BudgetId,
      BudgetnyckeltalLoepnummer: nyckeltal.BudgetnyckeltalLoepnummer,
    })
  );
  const nyckeltalvaerdeUnderliggandeMaanadList = useSelector(
    selectUnderliggandeUtfallMaanadQry({
      BudgetId: nyckeltal.BudgetId,
      NyckeltalId: nyckeltal.NyckeltalId,
      Type: 'underliggandeUtfall',
    })
  );

  const [createNyckeltalVaerde, updateNyckeltalVaerde] = useUpdateNyckeltalVaerde(nyckeltal);
  const footerStyle = ' border-t-2 border-fb-color-primary bg-fb-color-white w-full h-full p-2 text-xs-increased';

  const nyckeltalvaerdeUtfallMaanad = RemoteDataFunctions.hasData(nyckeltalvaerdeUtfallMaanadList)
    ? nyckeltalvaerdeUtfallMaanadList.data.NyckeltalvaerdeUtfallMaanadDTOList
    : undefined;

  React.useEffect(() => {
    if (
      RemoteDataFunctions.isNotAsked(nyckeltalvaerdeUnderliggandeMaanadList) &&
      RemoteDataFunctions.isSuccess(underliggandeEnheter)
    ) {
      dispatch(
        getUnderliggandeUtfallMaanadQryAction(
          nyckeltal.NyckeltalId,
          budget.BudgetPK,
          underliggandeEnheter.data.UnderliggandeEnheter.map(
            u => ({ EnhetVO: u.EnhetVO, BudgetId: u.BudgetPK.BudgetId } as BudgetEnhetPair)
          )
        )
      );
    }
  }, [budget.BudgetPK, dispatch, nyckeltal.NyckeltalId, nyckeltalvaerdeUnderliggandeMaanadList, underliggandeEnheter]);

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(nyckeltalvaerdeUtfallMaanadList)) {
      dispatch(
        nyckeltalvaerdeUtfallMaanadQryAction({
          BudgetId: nyckeltal.BudgetId,
          BudgetnyckeltalLoepnummer: nyckeltal.BudgetnyckeltalLoepnummer,
        })
      );
    }
  }, [dispatch, nyckeltalvaerdeUtfallMaanadList, nyckeltal, nyckeltal.BudgetId, nyckeltal.BudgetnyckeltalLoepnummer]);

  React.useEffect(() => {
    if (RemoteDataFunctions.hasData(nyckeltalvaerdeMaanadList)) {
      const maanader = nyckeltalvaerdeMaanadList.data.NyckeltalvaerdeMaanadWrapperDTOList.find(
        m => m.NyckeltalVaerdePK.NyckeltalvaerdeId === nyckeltalvaerde?.NyckeltalVaerdePK.NyckeltalvaerdeId
      )?.NyckeltalvaerdeMaanadDTOList;

      const summaMaanader = getSummaMaanader(maanader);
      setSummaMaanadsvaerde(summaMaanader);
      if (summaMaanader) {
        const diff = summaMaanader - (nyckeltalvaerde?.Aarsvaerde ?? 0);
        setDiffAarsvaerde(diff);
      }
    }
  }, [nyckeltalvaerdeMaanadList, nyckeltalvaerde]);

  const getSummaFoerKalenderaar = (aar: number) => {
    if (nyckeltalvaerdeUtfallMaanad === null || nyckeltalvaerdeUtfallMaanad === undefined) {
      return '-';
    } else {
      return nyckeltalvaerdeUtfallMaanad
        .filter(item => item.Year === aar)
        .map(item => item.Value)
        .reduce((prev, next) => prev + next, 0);
    }
  };
  const nyckeltalvaerdeUtfall = (aar: number, maanad: number) => {
    if (nyckeltalvaerdeUtfallMaanad === null || nyckeltalvaerdeUtfallMaanad === undefined) {
      return '-';
    } else {
      const utfall = nyckeltalvaerdeUtfallMaanad.find(n => n.Year === aar && n.Month === maanad)?.Value;
      return utfall !== undefined ? addSpacesToNumber(utfall.toString()) : '-';
    }
  };
  if (
    RemoteDataFunctions.isLoading(nyckeltalvaerdeUtfallMaanadList) ||
    !RemoteDataFunctions.hasData(nyckeltalvaerdeMaanadList) ||
    !RemoteDataFunctions.hasData(nyckeltalvaerdeUnderliggandeMaanadList)
  ) {
    return (
      <div className="flex flex-col justify-center">
        <FbSpinner size={'large'} />
      </div>
    );
  }

  const renderDiffAaersvaerde = (aarsvaerde: number | null | undefined) => {
    if (aarsvaerde === null || aarsvaerde === undefined || diffAarsvaerde === undefined) {
      return <span>-</span>;
    }
    return (
      <span className={diffAarsvaerde !== 0 ? 'text-fb-color-danger' : ''}>
        {(diffAarsvaerde > 0 ? '+ ' : '') + addSpacesToNumber(diffAarsvaerde.toString()).replace('-', '- ')}
      </span>
    );
  };

  const renderDiffAaersvaerdeUnderliggande = (aarsvaerde: number | null | undefined, underliggandeSum: number) => {
    if (aarsvaerde === null || aarsvaerde === undefined) {
      return <span>-</span>;
    }
    const diff = underliggandeSum - aarsvaerde;
    return (
      <span className={diff !== 0 ? 'text-fb-color-danger' : ''}>
        {(diff > 0 ? '+ ' : '') + addSpacesToNumber(diff.toString()).replace('-', '- ')}
      </span>
    );
  };

  const getSummaMaanadsvaerdeText = (summaMaanadsvaerde: number | undefined) => {
    if (summaMaanadsvaerde === undefined) {
      return '-';
    } else {
      return addSpacesToNumber(summaMaanadsvaerde.toString());
    }
  };

  const getColumns = (nyckeltalvaerde: NyckeltalVaerdeDTO): FbGridCol[] => {
    const isPersonalBudget = budget.EnhetVO.PersonId !== undefined || budget.EnhetVO.PlaneradBudgetId !== undefined;
    const cols: FbGridCol[] = [];

    cols.push({
      columnTemplate: 'minmax(max-content, 25%)',
      header: [
        {
          type: 'element',
          value: (
            <AriaCombobox
              id="periodisering"
              label="Vald periodiseringsprofil"
              values={
                RemoteDataFunctions.hasData(periodiseringsprofilList)
                  ? [
                      ...periodiseringsprofilList.data.Periodiseringsprofiler.map(p => {
                        return { id: p.PeriodiseringsprofilPK.PeriodiseringsprofilId!, value: p.Namn };
                      }),
                      { id: -1, value: 'Jag vill ange månadsvärden manuellt' },
                    ]
                  : []
              }
              defaultSelectedId={nyckeltalvaerde.PeriodiseringsprofilId || -1}
              className="w-full"
              onItemSelected={p => {
                if (p) {
                  if (nyckeltalvaerde.NyckeltalvaerdeId) {
                    dispatch(
                      updateNyckeltalVaerde(
                        nyckeltalvaerde.Aarsvaerde || undefined,
                        Number(p.id) === -1 ? null : Number(p.id) ?? undefined
                      )!
                    );
                  } else {
                    dispatch(
                      createNyckeltalVaerde(
                        nyckeltalvaerde.Aarsvaerde || undefined,
                        Number(p.id) === -1 ? null : Number(p.id) ?? undefined
                      )!
                    );
                  }
                }
              }}
            />
          ),
          wrapperClassName: (isPersonalBudget ? ' col-start-1 col-end-3' : ' col-start-1 col-end-4') + ' mb-2 mr-2',
          zIndex: 30,
        },
        {
          type: 'value',
          value: 'Utfall',
          className: ' leading-none text-xs-increased',
          wrapperClassName:
            (isPersonalBudget ? ' col-start-3 col-end-6' : ' col-start-4 col-end-7') + '  mt-auto mb-2 text-center',
        },
        {
          type: 'value',
          value: 'Månad',
          className: ' capitalize text-left' + FbGridColumnHeaderStyles.table,
          wrapperClassName: ' border-l h-min' + FbGridColumnHeaderWrapperStyles.table,
        },
      ],
      footer: {
        type: 'element',
        element: (
          <div className={`${footerStyle} font-semibold flex flex-col justify-between`}>
            <span>Summa</span>
            <span>Diff årsvärde</span>
          </div>
        ),
      },
    });

    cols.push({
      columnTemplate: isPersonalBudget ? 'minmax(max-content, 30%)' : 'minmax(max-content, 15%)',
      header: {
        type: 'value',
        value: nyckeltal.Typ === Typ.Angivet ? 'Budgeterat värde' : 'Beräknat värde',
        className: 'text-right' + FbGridColumnHeaderStyles.table,
        wrapperClassName: (isPersonalBudget ? ' mr-2' : '') + FbGridColumnHeaderWrapperStyles.table,
      },
      footer: {
        type: 'element',
        className: isPersonalBudget ? 'pr-2' : '',
        element: (
          <div className={`${footerStyle} text-right font-semibold flex flex-col justify-between`}>
            <span>{getSummaMaanadsvaerdeText(summaMaanadsvaerde)}</span>
            {renderDiffAaersvaerde(nyckeltalvaerde.Aarsvaerde)}
          </div>
        ),
      },
    });

    if (!isPersonalBudget) {
      cols.push({
        columnTemplate: 'minmax(max-content, 15%)',
        header: {
          type: 'value',
          value: `Budgeterat ${getUnderliggandeNamn(budget, true).toLowerCase()}`,
          className: ' text-right' + FbGridColumnHeaderStyles.table,
          wrapperClassName: ' mr-2' + FbGridColumnHeaderWrapperStyles.table,
        },
        footer: {
          type: 'element',
          className: 'pr-2',
          element: (
            <div className={`${footerStyle} text-right font-semibold flex flex-col justify-between`}>
              <span>
                {addSpacesToNumber(
                  nyckeltalvaerdeUnderliggandeMaanadList.data.NyckeltalvaerdeUtfallMaanadUnderliggandeList.reduce(
                    (prev, next) => prev + next,
                    0
                  ).toString()
                )}
              </span>
              <span>
                {renderDiffAaersvaerdeUnderliggande(
                  nyckeltalvaerde.Aarsvaerde,
                  nyckeltalvaerdeUnderliggandeMaanadList.data.NyckeltalvaerdeUtfallMaanadUnderliggandeList.reduce(
                    (prev, next) => prev + next,
                    0
                  )
                )}
              </span>
            </div>
          ),
        },
      });
    }

    cols.push({
      columnTemplate: 'minmax(max-content, 15%)',
      header: {
        type: 'value',
        value: (budget.Kalenderaar - 1).toString(),
        className: 'text-right' + FbGridColumnHeaderStyles.table,
        wrapperClassName: ' border-l' + FbGridColumnHeaderWrapperStyles.table,
      },
      footer: {
        type: 'element',
        element: (
          <div className={`${footerStyle} text-right font-semibold italic text-fb-grey-dark-2`}>
            {getSummaFoerKalenderaar(budget.Kalenderaar - 1)}
          </div>
        ),
      },
    });

    cols.push({
      columnTemplate: 'minmax(max-content, 15%)',
      header: {
        type: 'value',
        value: (budget.Kalenderaar - 2).toString(),
        className: 'text-right' + FbGridColumnHeaderStyles.table,
        wrapperClassName: FbGridColumnHeaderWrapperStyles.table,
      },
      footer: {
        type: 'element',
        element: (
          <div className={`${footerStyle} text-right font-semibold italic text-fb-grey-dark-2`}>
            {getSummaFoerKalenderaar(budget.Kalenderaar - 2)}
          </div>
        ),
      },
    });

    cols.push({
      columnTemplate: 'minmax(max-content, 15%)',
      header: {
        type: 'value',
        value: (budget.Kalenderaar - 3).toString(),
        className: 'text-right' + FbGridColumnHeaderStyles.table,
        wrapperClassName: FbGridColumnHeaderWrapperStyles.table,
      },
      footer: {
        type: 'element',
        element: (
          <div className={`${footerStyle} text-right font-semibold italic text-fb-grey-dark-2`}>
            {getSummaFoerKalenderaar(budget.Kalenderaar - 3)}
          </div>
        ),
      },
    });

    return cols;
  };

  return (
    <>
      {nyckeltalvaerde && (
        <FbTableGrid
          className="w-full h-full"
          columns={getColumns(nyckeltalvaerde)}
          rows={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map<FbTableGridRowDef>((maanad, index) => {
            const nyckeltalVaerdeMaanad: NyckeltalvaerdeMaanadDTO | undefined =
              nyckeltalvaerdeMaanadList.data.NyckeltalvaerdeMaanadWrapperDTOList.find(
                m => m.NyckeltalVaerdePK.NyckeltalvaerdeId === nyckeltalvaerde.NyckeltalVaerdePK.NyckeltalvaerdeId
              )?.NyckeltalvaerdeMaanadDTOList.find(n => n.Maanad === maanad);
            const vaerde = !nyckeltalVaerdeMaanad ? '-' : addSpacesToNumber('' + nyckeltalVaerdeMaanad.Vaerde);
            const vaerdeSumUnderliggande =
              nyckeltalvaerdeUnderliggandeMaanadList.data.NyckeltalvaerdeUtfallMaanadUnderliggandeList[index];
            const isPersonalBudget =
              budget.EnhetVO.PersonId !== undefined || budget.EnhetVO.PlaneradBudgetId !== undefined;
            const maanadNamn = new Date(2020, index).toLocaleDateString('sv-SE', { month: 'long' });

            const values: {
              value?: string;
              content?: React.ReactElement;
              className?: string;
              inputCellProps?: FbTableGridInputCellProps;
              onClick?: () => void;
            }[] = [
              {
                value: maanadNamn,
                className: ' capitalize border-l border-fb-color-grey-table-border',
              },
              {
                value: vaerde,
                className: isPersonalBudget ? ' mr-2 text-right' : ' text-right',
                inputCellProps: {
                  id: index.toString(),
                  disabled: nyckeltalvaerde.PeriodiseringsprofilId !== undefined,
                  onSave: (v: string | undefined): ThunkAction | undefined => {
                    if (v === undefined) return undefined;
                    const cleanValue = v.replaceAll(' ', '').replace(',', '.');
                    if (cleanValue === '') {
                      return;
                    }
                    const vaerde = Number(cleanValue);
                    if (nyckeltalVaerdeMaanad) {
                      if (vaerde !== nyckeltalVaerdeMaanad.Vaerde) {
                        return updateNyckeltalvaerdeMaanadAction({
                          BudgetId: budget.BudgetPK.BudgetId!,
                          PrimaryKey: nyckeltalVaerdeMaanad.NyckeltalvaerdeMaanadPK,
                          Vaerde: vaerde,
                        });
                      }
                    } else {
                      return createNyckeltalvaerdeMaanadAction({
                        BudgetId: budget.BudgetPK.BudgetId!,
                        Maanad: maanad,
                        NyckeltalvaerdeId: nyckeltalvaerde.NyckeltalVaerdePK.NyckeltalvaerdeId!,
                        Vaerde: vaerde,
                      });
                    }
                    return undefined;
                  },
                  type: 'number',
                },
              },
              {
                value: vaerdeSumUnderliggande ? addSpacesToNumber(vaerdeSumUnderliggande.toString()) : '-',
                className: ' text-right mr-2',
              },
              {
                value: `${nyckeltalvaerdeUtfall(budget.Kalenderaar - 1, maanad)}`,
                className: ' text-right text-fb-grey-dark-2 italic border-l border-fb-color-grey-table-border',
              },
              {
                value: `${nyckeltalvaerdeUtfall(budget.Kalenderaar - 2, maanad)}`,
                className: ' text-right text-fb-grey-dark-2 italic',
              },
              {
                value: `${nyckeltalvaerdeUtfall(budget.Kalenderaar - 3, maanad)}`,
                className: ' text-right text-fb-grey-dark-2 italic',
              },
            ];

            if (isPersonalBudget) {
              values.splice(2, 1);
            }

            return {
              values: values,
            };
          })}
        />
      )}
    </>
  );
};
