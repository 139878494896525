import { FbInfoTooltip } from '@decernointernal/fb-interna-komponenter';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autosaveHoc } from 'components/autosaveHoc';
import * as React from 'react';
import { ThunkAction } from 'store/store';
import { FbGrid, FbGridCol, FbGridRow } from '../FbGrid';
import { FbTableGridInputCell, FbTableGridInputCellProps } from './FbTableGridInputCell';

export interface FbTableGridRowDef {
  values: {
    value?: string;
    content?: React.ReactElement;
    className?: string;
    tooltipIcon?: IconProp;
    tooltipIconClassName?: string;
    tooltipText?: string;
    tooltipMaxwidth?: number;
    inputCellProps?: FbTableGridInputCellProps;
    onClick?: () => void;
  }[];
}

export interface FbTableGridProps {
  columns: FbGridCol[];
  rows: FbTableGridRowDef[];
  className?: string;
  containerClassName?: string;
}

export const FbTableGrid: React.FC<FbTableGridProps> = ({ columns, rows, className, containerClassName }) => {
  const containerRef = React.useRef<Element | null>(null);
  return (
    <div
      ref={r => (containerRef.current = r)}
      className={`overflow-y-auto custom-scroll w-full h-full ${containerClassName ?? ''}`}
    >
      <FbGrid
        className={className}
        scrollableContainer={containerRef}
        columns={columns}
        displayHeaderShadow={false}
        stickyZIndex={20}
        rows={rows.map((r, rowIndex) => {
          const row: FbGridRow = {
            element: (
              <>
                {r.values.map((value, valueIndex) => {
                  return (
                    <div
                      id={'table-grid-row' + rowIndex + '-cell' + valueIndex}
                      className={`relative group ${!value.tooltipText ? 'contents' : 'grid'}`}
                      key={valueIndex}
                    >
                      {value.tooltipIcon && (
                        <FontAwesomeIcon
                          size="sm"
                          className={`absolute pl-2 top-0 bottom-0 my-auto pointer-events-none ${
                            value.tooltipIconClassName ?? ''
                          }`}
                          icon={value.tooltipIcon}
                        />
                      )}
                      {value.inputCellProps ? (
                        value.inputCellProps.onSave ? (
                          <>
                            {autosaveHoc(FbTableGridInputCell)({
                              className: `h-full ${rowIndex % 2 === 1 ? ' bg-fb-color-grey-superlight' : ''} ${
                                value.className
                              }`,
                              defaultValue: value.value ?? undefined,
                              onSave: (val): ThunkAction | undefined => {
                                return value.inputCellProps!.onSave!(val);
                              },
                              ...value.inputCellProps,
                            })}
                          </>
                        ) : (
                          <FbTableGridInputCell
                            className={`h-full ${rowIndex % 2 === 1 ? ' bg-fb-color-grey-superlight' : ''} ${
                              value.className
                            }`}
                            defaultValue={value.value ?? undefined}
                            {...value.inputCellProps}
                          />
                        )
                      ) : (
                        <div
                          className={`h-full p-2 leading-4 font-sans text-xs-increased border-r border-b border-fb-color-grey-table-border text-fb-color-typography ${
                            value.className || ''
                          } ${rowIndex % 2 === 1 ? 'bg-fb-color-grey-superlight' : ''}`}
                          onClick={value.onClick}
                        >
                          {value.content ?? value.value}
                        </div>
                      )}
                      {value.tooltipText && (
                        <FbInfoTooltip
                          targetElement={'table-grid-row' + rowIndex + '-cell' + valueIndex}
                          position="above"
                          trigger="hover"
                          text={value.tooltipText}
                          maxWidth={value.tooltipMaxwidth}
                        />
                      )}
                    </div>
                  );
                })}
              </>
            ),
          };
          return row;
        })}
      />
    </div>
  );
};
