import { addSpacesToNumber, FbInfoTooltip } from '@decernointernal/fb-interna-komponenter';
import { faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typ } from 'generated-models/budgetera';
import { useRef } from 'react';

export interface BudgetCellToolTip {
  text: string;
  maxwidth: number;
  isShowing: boolean;
}

interface BudgetCellProps {
  value: string | null;
  className?: string;
  isCollapsed: boolean;
  nyckeltalTyp?: Typ;
  varningFinns?: boolean;
  infoFinns?: boolean;
  tooltipAbove?: BudgetCellToolTip;
  tooltipBelow?: BudgetCellToolTip;
  tooltipRight?: BudgetCellToolTip;
}

export const BudgetCell: React.FC<BudgetCellProps> = ({
  value,
  className,
  isCollapsed,
  nyckeltalTyp,
  varningFinns,
  infoFinns,
  tooltipAbove,
  tooltipBelow,
  tooltipRight,
}) => {
  const getCellColor = () => {
    if (!isCollapsed) {
      return ' bg-fb-color-primary';
    } else if (nyckeltalTyp === Typ.Beraeknat || !nyckeltalTyp) {
      return ' bg-fb-color-white';
    } else {
      if (value && value !== '-') {
        return ' bg-fb-color-blue-superlight';
      }
      return ' bg-fb-color-orange/20';
    }
  };
  const containerRef = useRef(null);
  const spanRef = useRef(null);
  return (
    <div
      className={
        'text-fb-color-typography flex w-full transition-all duration-300 ' +
        (isCollapsed ? 'bg-fb-color-white' : 'bg-fb-color-primary')
      }
    >
      <div className={`${className} transition-all duration-300 h-full w-full ${getCellColor()}`}>
        <div
          className={`group relative flex justify-between transition-all duration-300 font-sans text-xs-increased w-full h-full items-center p-2 ${
            isCollapsed ? 'opacity-100' : 'opacity-0 transition-none'
          }`}
        >
          <div className="flex justify-between w-full" ref={containerRef}>
            <div>
              {varningFinns && <FontAwesomeIcon icon={faTriangleExclamation} className="text-fb-color-danger pr-1" />}
              {infoFinns && <FontAwesomeIcon icon={faInfoCircle} className="text-fb-color-primary pr-1" />}
            </div>
            <span ref={spanRef} className={varningFinns ? 'text-fb-color-danger' : ''}>
              {value && addSpacesToNumber(value.replaceAll('.', ','))}
            </span>
          </div>
          {isCollapsed && (
            <div>
              {tooltipAbove?.isShowing && (
                <FbInfoTooltip
                  targetElement={varningFinns || infoFinns ? containerRef : spanRef}
                  text={tooltipAbove.text}
                  trigger="hover"
                  maxWidth={tooltipAbove.maxwidth}
                  position={'above'}
                  className="transition-all ease-in-out"
                />
              )}
              {tooltipBelow?.isShowing && (
                <FbInfoTooltip
                  targetElement={varningFinns || infoFinns ? containerRef : spanRef}
                  text={tooltipBelow.text}
                  trigger="hover"
                  maxWidth={tooltipBelow.maxwidth}
                  position={'below'}
                  className="transition-all ease-in-out"
                />
              )}
              {tooltipRight?.isShowing && (
                <FbInfoTooltip
                  targetElement={varningFinns || infoFinns ? containerRef : spanRef}
                  text={tooltipRight.text}
                  trigger="hover"
                  maxWidth={tooltipRight.maxwidth}
                  position={'right'}
                  className="transition-all ease-in-out"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
