interface BudgetaarNyckeltalHeadersProps {
  Utland: boolean;
}

export const BudgetaarNyckeltalHeaders: React.FC<BudgetaarNyckeltalHeadersProps> = ({ Utland }) => {
  return (
    <table className="ml-4 fb-paragraph-lvl-2 w-1/3 table-fixed">
      <thead className="bg-fb-color-blue-superlight">
        <tr>
          <th
            className={
              'border-x border-t border-fb-color-grey-table-border text-left fb-paragraph-lvl-2 relative group h-8 pl-2 w-32'
            }
          >
            Bostadsmarknad
          </th>
          <th
            className={
              'border-x border-t border-fb-color-grey-table-border text-left fb-paragraph-lvl-2 relative group h-8 pl-2 w-28'
            }
          >
            Affärsområde
          </th>
          <th
            className={
              'border-x border-t border-fb-color-grey-table-border text-left fb-paragraph-lvl-2 relative group h-8 pl-2 w-20'
            }
          >
            Kontor
          </th>
          <th
            className={
              'border-x border-t border-fb-color-grey-table-border text-left fb-paragraph-lvl-2 relative group h-8 pl-2' +
              (Utland ? ' w-28' : ' w-20')
            }
          >
            {Utland ? 'Säljare/Intagare' : 'Mäklare'}
          </th>
        </tr>
      </thead>
    </table>
  );
};
