/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PeriodiseringsprofilMaanadVaerdeList
 */
export interface PeriodiseringsprofilMaanadVaerdeList {
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilMaanadVaerdeList
   */
  Vaerde: number;
  /**
   *
   * @type {number}
   * @memberof PeriodiseringsprofilMaanadVaerdeList
   */
  Maanad: number;
}

export function PeriodiseringsprofilMaanadVaerdeListFromJSON(json: any): PeriodiseringsprofilMaanadVaerdeList {
  return PeriodiseringsprofilMaanadVaerdeListFromJSONTyped(json, false);
}

export function PeriodiseringsprofilMaanadVaerdeListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PeriodiseringsprofilMaanadVaerdeList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Vaerde: json['Vaerde'],
    Maanad: json['Maanad'],
  };
}

export function PeriodiseringsprofilMaanadVaerdeListToJSON(value?: PeriodiseringsprofilMaanadVaerdeList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Vaerde: value.Vaerde,
    Maanad: value.Maanad,
  };
}
