interface budgetUtfall {
  budgetId: number | null | undefined;
  utfall?: number | null | undefined;
}

export const beraeknadFoervaentningFoerdelning = (
  nyckeltalvaerdeUtfall: budgetUtfall[],
  total: number
): [
  {
    budgetId: number;
    foerdelning: number;
  }[],
  number
] => {
  const summaUtfall = nyckeltalvaerdeUtfall.reduce((prev, next) => prev + (next.utfall ?? 0), 0);

  const beraeknadFoervaentning = nyckeltalvaerdeUtfall.map(utfall => ({
    budgetId: utfall.budgetId!,
    foerdelning: Math.round(
      ((nyckeltalvaerdeUtfall.find(u => u.budgetId === utfall.budgetId)?.utfall ?? 0) / summaUtfall ?? 0) * total
    ),
  }));

  const summaFoervaentning = beraeknadFoervaentning.reduce((prev, next) => prev + (next.foerdelning ?? 0), 0);

  return [beraeknadFoervaentning, summaFoervaentning];
};
