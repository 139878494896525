import * as React from 'react';
import { FbGrid, FbGridCol } from '../FbGrid';
import { FbCollapsibleGridRow } from './FbCollapsibleGridRow';

export interface FbCollapsibleGridRowDef {
  group: string;
  subgroup?: string;
  texts: (isCollapsed: boolean) => JSX.Element;
  details: JSX.Element;
  containerId?: string;
}

export interface FbCollapsibleGridProps {
  columns: FbGridCol[];
  rows: FbCollapsibleGridRowDef[];
  maxDetailsOpen: number;
}

export const FbCollapsibleGrid: React.FC<FbCollapsibleGridProps> = ({ columns, rows, maxDetailsOpen }) => {
  const [expandedRows] = React.useState<number[]>([]);
  const [collapsibleRows, setCollapsibleRows] = React.useState<boolean[]>([]);
  const containerRef = React.useRef<HTMLElement | null>(null);

  return (
    <div ref={r => (containerRef.current = r)} className="overflow-y-auto w-full h-full">
      <FbGrid
        scrollableContainer={containerRef}
        columns={columns}
        stickyZIndex={40}
        rows={rows.map((r, index) => {
          return {
            group: r.group,
            subgroup: r.subgroup,
            element: (
              <FbCollapsibleGridRow
                scrollableContainer={containerRef}
                containerId={r.containerId}
                isCollapsed={!collapsibleRows[index]}
                onExpand={() => {
                  const collapsible = [...collapsibleRows];
                  if (expandedRows.length >= maxDetailsOpen) {
                    // Vi har max antal öppna. För att kunna öppna en till,
                    // stänger vi den som öppnades först.
                    const setCollapsedIndex = expandedRows.shift();
                    if (setCollapsedIndex !== undefined) {
                      collapsible[setCollapsedIndex] = false;
                    }
                  }
                  collapsible[index] = true;
                  setCollapsibleRows(collapsible);
                  expandedRows.push(index);
                }}
                onCollapse={() => {
                  const collapsible = [...collapsibleRows];
                  collapsible[index] = false;
                  setCollapsibleRows(collapsible);
                  expandedRows.forEach((e, i) => {
                    if (e === index) {
                      expandedRows.splice(i, 1);
                    }
                  });
                }}
                texts={r.texts}
              >
                {r.details}
              </FbCollapsibleGridRow>
            ),
          };
        })}
      />
    </div>
  );
};
