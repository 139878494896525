import { FbButtonState } from '@decernointernal/fb-interna-komponenter';
import { Command, CommandFunctions, ErrorMessage } from '@decernointernal/websd.shared';
import * as React from 'react';

export const useCommandButtonState = (...commands: Command<any, ErrorMessage>[]): FbButtonState => {
  const [buttonState, setButtonState] = React.useState<FbButtonState>('default');
  React.useEffect(() => {
    if (commands.some(command => CommandFunctions.isExecuting(command))) {
      setButtonState('waiting');
    } else if (commands.some(command => CommandFunctions.isSuccess(command))) {
      setButtonState('success');
    } else if (commands.some(command => CommandFunctions.isFailure(command))) {
      setButtonState('failure');
    } else {
      setButtonState('default');
    }
  }, [commands]);

  return buttonState;
};
