import { BudgetListDTO, BudgetnyckeltalListDTO } from 'generated-models/budgetera/models';
import * as React from 'react';
import { NyckeltalInfo } from './NyckeltalInfo';
import { NyckeltalTabs } from './nyckeltaltabs/NyckeltalTabs';

interface NyckeltalProps {
  nyckeltal: BudgetnyckeltalListDTO;
  budget: BudgetListDTO;
  foervaentning?: number | null;
}

export const Nyckeltal: React.FC<NyckeltalProps> = ({ nyckeltal, budget, foervaentning }) => {
  return (
    <div className="flex flex-row w-full">
      <NyckeltalInfo budget={budget} nyckeltal={nyckeltal} foervaentning={foervaentning} />
      <div className="w-px h-full bg-fb-color-bg-main-compliment"></div>
      <div className="flex flex-col w-full">
        <NyckeltalTabs budget={budget} nyckeltal={nyckeltal} />
      </div>
    </div>
  );
};
