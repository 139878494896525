/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { NyckeltalVO, NyckeltalVOFromJSON, NyckeltalVOFromJSONTyped, NyckeltalVOToJSON } from './NyckeltalVO';
import { Operator, OperatorFromJSON, OperatorFromJSONTyped, OperatorToJSON } from './Operator';

/**
 *
 * @export
 * @interface NyckeltalCalculation
 */
export interface NyckeltalCalculation {
  /**
   *
   * @type {NyckeltalVO}
   * @memberof NyckeltalCalculation
   */
  NyckeltalToCalc: NyckeltalVO;
  /**
   *
   * @type {Operator}
   * @memberof NyckeltalCalculation
   */
  Operator: Operator;
  /**
   *
   * @type {Array<NyckeltalVO>}
   * @memberof NyckeltalCalculation
   */
  NyckeltalIncludedInCalcList: Array<NyckeltalVO>;
}

export function NyckeltalCalculationFromJSON(json: any): NyckeltalCalculation {
  return NyckeltalCalculationFromJSONTyped(json, false);
}

export function NyckeltalCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): NyckeltalCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalToCalc: NyckeltalVOFromJSON(json['NyckeltalToCalc']),
    Operator: OperatorFromJSON(json['Operator']),
    NyckeltalIncludedInCalcList: (json['NyckeltalIncludedInCalcList'] as Array<any>).map(NyckeltalVOFromJSON),
  };
}

export function NyckeltalCalculationToJSON(value?: NyckeltalCalculation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalToCalc: NyckeltalVOToJSON(value.NyckeltalToCalc),
    Operator: OperatorToJSON(value.Operator),
    NyckeltalIncludedInCalcList: (value.NyckeltalIncludedInCalcList as Array<any>).map(NyckeltalVOToJSON),
  };
}
