import { faBriefcase, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Enhet, EnhetVO } from 'generated-models/budgetera/models';
import React from 'react';
interface HeaderEnhetListExpandedProps {
  currentEnhet?: EnhetVO;
  enhetList?: Enhet[];
  changeEnhetOnClick: (enhetVO: EnhetVO) => void;
  role: string;
}

export const HeaderEnhetListExpanded: React.FC<HeaderEnhetListExpandedProps> = ({
  currentEnhet,
  enhetList,
  changeEnhetOnClick,
  role,
}) => {
  const [enhetListExpanded, setEnhetListExpanded] = React.useState(false);

  const onClick = (enhetVO: EnhetVO) => {
    changeEnhetOnClick(enhetVO);
    setEnhetListExpanded(false);
  };

  return (
    <>
      <div
        className={
          'bg-fb-color-white h-14 p-2 border-b border-b-fb-color-grey-medium-dark hover:bg-fb-color-blue-light cursor-pointer'
        }
        onClick={() => setEnhetListExpanded(!enhetListExpanded)}
        data-testid="headerEnhetList-test-id"
      >
        <div className="flex items-center">
          <p className="text-xs-increased">Aktuell enhet:</p>
          <FontAwesomeIcon className="absolute right-2" size="xs" icon={faBriefcase} />
        </div>
        <p className="text-xs-increased font-bold">
          {enhetList ? enhetList.find(enhet => enhet.EnhetVO === currentEnhet)?.EnhetName : role}
          {enhetList && (
            <FontAwesomeIcon className="pl-1" size="xs" icon={enhetListExpanded ? faCaretUp : faCaretDown} />
          )}
        </p>
      </div>
      {enhetListExpanded && enhetList && (
        <div className="bg-fb-color-white max-h-80 pl-6 pb-2 overflow-y-auto custom-scroll border-b border-b-fb-color-grey-medium-dark">
          {enhetList.map((enhet, i) => (
            <p
              key={i}
              data-testid={'headerEnhetListExpanded-test-id' + enhet.EnhetName}
              className={
                'pt-2 text-xs-increased ' +
                (enhet.EnhetVO === currentEnhet
                  ? 'text-fb-color-grey-dark-compliment'
                  : 'text-fb-color-link-active hover:underline cursor-pointer')
              }
              onClick={enhet.EnhetVO === currentEnhet ? undefined : () => onClick(enhet.EnhetVO)}
            >
              {enhet.EnhetName}
            </p>
          ))}
        </div>
      )}
    </>
  );
};
