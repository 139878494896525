/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { KommentarPK, KommentarPKFromJSON, KommentarPKFromJSONTyped, KommentarPKToJSON } from './KommentarPK';

/**
 *
 * @export
 * @interface DeleteKommentarCmd
 */
export interface DeleteKommentarCmd {
  /**
   *
   * @type {KommentarPK}
   * @memberof DeleteKommentarCmd
   */
  PrimaryKey: KommentarPK;
}

export function DeleteKommentarCmdFromJSON(json: any): DeleteKommentarCmd {
  return DeleteKommentarCmdFromJSONTyped(json, false);
}

export function DeleteKommentarCmdFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteKommentarCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PrimaryKey: KommentarPKFromJSON(json['PrimaryKey']),
  };
}

export function DeleteKommentarCmdToJSON(value?: DeleteKommentarCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PrimaryKey: KommentarPKToJSON(value.PrimaryKey),
  };
}
