/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';
import {
  DeadlineEnhetstyp,
  DeadlineEnhetstypFromJSON,
  DeadlineEnhetstypFromJSONTyped,
  DeadlineEnhetstypToJSON,
} from './DeadlineEnhetstyp';

/**
 *
 * @export
 * @interface UpdateDeadlineCmd
 */
export interface UpdateDeadlineCmd {
  /**
   *
   * @type {Date}
   * @memberof UpdateDeadlineCmd
   */
  Deadline: Date;
  /**
   *
   * @type {DeadlineEnhetstyp}
   * @memberof UpdateDeadlineCmd
   */
  Enhetstyp: DeadlineEnhetstyp;
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof UpdateDeadlineCmd
   */
  PrimaryKey: BudgetperiodPK;
}

export function UpdateDeadlineCmdFromJSON(json: any): UpdateDeadlineCmd {
  return UpdateDeadlineCmdFromJSONTyped(json, false);
}

export function UpdateDeadlineCmdFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDeadlineCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Deadline: new Date(json['Deadline']),
    Enhetstyp: DeadlineEnhetstypFromJSON(json['Enhetstyp']),
    PrimaryKey: BudgetperiodPKFromJSON(json['PrimaryKey']),
  };
}

export function UpdateDeadlineCmdToJSON(value?: UpdateDeadlineCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Deadline: value.Deadline.toISOString(),
    Enhetstyp: DeadlineEnhetstypToJSON(value.Enhetstyp),
    PrimaryKey: BudgetperiodPKToJSON(value.PrimaryKey),
  };
}
