/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NyckeltalvaerdeMaanadPK,
  NyckeltalvaerdeMaanadPKFromJSON,
  NyckeltalvaerdeMaanadPKFromJSONTyped,
  NyckeltalvaerdeMaanadPKToJSON,
} from './NyckeltalvaerdeMaanadPK';

/**
 *
 * @export
 * @interface UpdateNyckeltalvaerdeMaanadCmd
 */
export interface UpdateNyckeltalvaerdeMaanadCmd {
  /**
   *
   * @type {number}
   * @memberof UpdateNyckeltalvaerdeMaanadCmd
   */
  Vaerde: number;
  /**
   *
   * @type {number}
   * @memberof UpdateNyckeltalvaerdeMaanadCmd
   */
  BudgetId: number;
  /**
   *
   * @type {number}
   * @memberof UpdateNyckeltalvaerdeMaanadCmd
   */
  readonly PersonId?: number | null;
  /**
   *
   * @type {NyckeltalvaerdeMaanadPK}
   * @memberof UpdateNyckeltalvaerdeMaanadCmd
   */
  PrimaryKey: NyckeltalvaerdeMaanadPK;
}

export function UpdateNyckeltalvaerdeMaanadCmdFromJSON(json: any): UpdateNyckeltalvaerdeMaanadCmd {
  return UpdateNyckeltalvaerdeMaanadCmdFromJSONTyped(json, false);
}

export function UpdateNyckeltalvaerdeMaanadCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateNyckeltalvaerdeMaanadCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Vaerde: json['Vaerde'],
    BudgetId: json['BudgetId'],
    PersonId: !exists(json, 'PersonId') ? undefined : json['PersonId'],
    PrimaryKey: NyckeltalvaerdeMaanadPKFromJSON(json['PrimaryKey']),
  };
}

export function UpdateNyckeltalvaerdeMaanadCmdToJSON(value?: UpdateNyckeltalvaerdeMaanadCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Vaerde: value.Vaerde,
    BudgetId: value.BudgetId,
    PrimaryKey: NyckeltalvaerdeMaanadPKToJSON(value.PrimaryKey),
  };
}
