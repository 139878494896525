import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { incrementTenMinutes, selectSecondsLeft, timeLimitSeconds } from './AzureADLoginTimerReducer';

/**
 * This component will attempt to renew the current user session with a login redirect after a configured time of inactivity
 */
export const AzureADLoginTimer: React.FC = () => {
  const { instance } = useMsal();
  const seconds = useSelector(selectSecondsLeft);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let interval: number | undefined = undefined;
    interval = window.setInterval(() => {
      dispatch(incrementTenMinutes());
    }, 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (seconds >= timeLimitSeconds) {
      instance.loginRedirect().catch(e => {
        console.error(e);
      });
    }
  }, [seconds, instance]);

  return null;
};
