/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BudgetNyckeltalVaerdeDTO
 */
export interface BudgetNyckeltalVaerdeDTO {
  /**
   *
   * @type {number}
   * @memberof BudgetNyckeltalVaerdeDTO
   */
  NyckeltalId: number;
  /**
   *
   * @type {number}
   * @memberof BudgetNyckeltalVaerdeDTO
   */
  Aarsvaerde?: number | null;
  /**
   *
   * @type {number}
   * @memberof BudgetNyckeltalVaerdeDTO
   */
  Foervaentning?: number | null;
  /**
   *
   * @type {number}
   * @memberof BudgetNyckeltalVaerdeDTO
   */
  Omsaettningskrav?: number | null;
  /**
   *
   * @type {number}
   * @memberof BudgetNyckeltalVaerdeDTO
   */
  NyckeltalvaerdeId?: number | null;
}

export function BudgetNyckeltalVaerdeDTOFromJSON(json: any): BudgetNyckeltalVaerdeDTO {
  return BudgetNyckeltalVaerdeDTOFromJSONTyped(json, false);
}

export function BudgetNyckeltalVaerdeDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetNyckeltalVaerdeDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: json['NyckeltalId'],
    Aarsvaerde: !exists(json, 'Aarsvaerde') ? undefined : json['Aarsvaerde'],
    Foervaentning: !exists(json, 'Foervaentning') ? undefined : json['Foervaentning'],
    Omsaettningskrav: !exists(json, 'Omsaettningskrav') ? undefined : json['Omsaettningskrav'],
    NyckeltalvaerdeId: !exists(json, 'NyckeltalvaerdeId') ? undefined : json['NyckeltalvaerdeId'],
  };
}

export function BudgetNyckeltalVaerdeDTOToJSON(value?: BudgetNyckeltalVaerdeDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
    Aarsvaerde: value.Aarsvaerde,
    Foervaentning: value.Foervaentning,
    Omsaettningskrav: value.Omsaettningskrav,
    NyckeltalvaerdeId: value.NyckeltalvaerdeId,
  };
}
