/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BudgetaarNyckeltalListDTOQry
 */
export interface BudgetaarNyckeltalListDTOQry {
  /**
   *
   * @type {number}
   * @memberof BudgetaarNyckeltalListDTOQry
   */
  BudgetperiodId?: number | null;
}

export function BudgetaarNyckeltalListDTOQryFromJSON(json: any): BudgetaarNyckeltalListDTOQry {
  return BudgetaarNyckeltalListDTOQryFromJSONTyped(json, false);
}

export function BudgetaarNyckeltalListDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetaarNyckeltalListDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodId: !exists(json, 'BudgetperiodId') ? undefined : json['BudgetperiodId'],
  };
}

export function BudgetaarNyckeltalListDTOQryToJSON(value?: BudgetaarNyckeltalListDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodId: value.BudgetperiodId,
  };
}
