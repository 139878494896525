import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { FbModal } from 'shared-components/fbModal/FbModal';

export const Unauthorized: React.FC = () => {
  const hrSupportMailAdress = 'hrsupport@fastighetsbyran.se';

  return (
    <div className="flex h-screen">
      <FbModal
        titel={'Behörighet till Budgetera saknas'}
        text={'Du saknar behörighet för att komma åt Budgetera. Om det inte stämmer, vänligen kontakta HR-support.'}
        buttonText={'Mejla HR-support'}
        buttonIcon={faEnvelope}
        onClick={() => {
          window.location.href = `mailto:${hrSupportMailAdress}`;
        }}
        className={'m-auto w-[400px]'}
      ></FbModal>
    </div>
  );
};
