/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetperiodDTO,
  BudgetperiodDTOFromJSON,
  BudgetperiodDTOFromJSONTyped,
  BudgetperiodDTOToJSON,
} from './BudgetperiodDTO';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';

/**
 *
 * @export
 * @interface BudgetperiodStatusWrapperDTO
 */
export interface BudgetperiodStatusWrapperDTO {
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof BudgetperiodStatusWrapperDTO
   */
  BudgetperiodPK: BudgetperiodPK;
  /**
   *
   * @type {BudgetperiodDTO}
   * @memberof BudgetperiodStatusWrapperDTO
   */
  BudgetperiodDTO: BudgetperiodDTO;
}

export function BudgetperiodStatusWrapperDTOFromJSON(json: any): BudgetperiodStatusWrapperDTO {
  return BudgetperiodStatusWrapperDTOFromJSONTyped(json, false);
}

export function BudgetperiodStatusWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetperiodStatusWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
    BudgetperiodDTO: BudgetperiodDTOFromJSON(json['BudgetperiodDTO']),
  };
}

export function BudgetperiodStatusWrapperDTOToJSON(value?: BudgetperiodStatusWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
    BudgetperiodDTO: BudgetperiodDTOToJSON(value.BudgetperiodDTO),
  };
}
