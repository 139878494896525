/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';
import {
  BudgetperiodStatusVO,
  BudgetperiodStatusVOFromJSON,
  BudgetperiodStatusVOFromJSONTyped,
  BudgetperiodStatusVOToJSON,
} from './BudgetperiodStatusVO';

/**
 *
 * @export
 * @interface BudgetperiodDTO
 */
export interface BudgetperiodDTO {
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof BudgetperiodDTO
   */
  BudgetperiodPK: BudgetperiodPK;
  /**
   *
   * @type {BudgetperiodStatusVO}
   * @memberof BudgetperiodDTO
   */
  BudgetperiodStatusVO: BudgetperiodStatusVO;
  /**
   *
   * @type {number}
   * @memberof BudgetperiodDTO
   */
  Kalenderaar: number;
  /**
   *
   * @type {Date}
   * @memberof BudgetperiodDTO
   */
  DeadlineMarknad: Date;
  /**
   *
   * @type {Date}
   * @memberof BudgetperiodDTO
   */
  DeadlineAffaersomraade: Date;
  /**
   *
   * @type {Date}
   * @memberof BudgetperiodDTO
   */
  DeadlineKontor: Date;
  /**
   *
   * @type {Date}
   * @memberof BudgetperiodDTO
   */
  DeadlineMaeklare: Date;
}

export function BudgetperiodDTOFromJSON(json: any): BudgetperiodDTO {
  return BudgetperiodDTOFromJSONTyped(json, false);
}

export function BudgetperiodDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetperiodDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
    BudgetperiodStatusVO: BudgetperiodStatusVOFromJSON(json['BudgetperiodStatusVO']),
    Kalenderaar: json['Kalenderaar'],
    DeadlineMarknad: new Date(json['DeadlineMarknad']),
    DeadlineAffaersomraade: new Date(json['DeadlineAffaersomraade']),
    DeadlineKontor: new Date(json['DeadlineKontor']),
    DeadlineMaeklare: new Date(json['DeadlineMaeklare']),
  };
}

export function BudgetperiodDTOToJSON(value?: BudgetperiodDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
    BudgetperiodStatusVO: BudgetperiodStatusVOToJSON(value.BudgetperiodStatusVO),
    Kalenderaar: value.Kalenderaar,
    DeadlineMarknad: value.DeadlineMarknad.toISOString(),
    DeadlineAffaersomraade: value.DeadlineAffaersomraade.toISOString(),
    DeadlineKontor: value.DeadlineKontor.toISOString(),
    DeadlineMaeklare: value.DeadlineMaeklare.toISOString(),
  };
}
