import * as ErrorStackParser from 'error-stack-parser'; // Must be importad with '* as' as the the typing is wrong and not telling us that the parsing functions uses 'this'
import {
  BudgetaarQryApi,
  BudgetCmdApi,
  BudgetperiodCmdApi,
  BudgetperiodQryApi,
  BudgetQryApi,
  BudgetSidedrawerNotiserCmdApi,
  Configuration,
  JournalCmdApi,
  JournalQryApi,
  KibanaApi,
  KommentarCmdApi,
  KommentarQryApi,
  NyckeltalvaerdeCmdApi,
  NyckeltalvaerdeMaanadCmdApi,
  NyckeltalvaerdeQryApi,
  PeriodiseringsprofilCmdApi,
  SidedrawerNotiserQryApi,
  UserQryApi,
} from 'generated-models/budgetera';
import { ClientCrashStackframe } from 'generated-models/budgetera/models';
import { authenticateMiddleware, errorMiddleware, ongoingTransactionMiddleware } from './apiUtils';

class ApiClientBase {
  protected _config: Configuration;

  constructor() {
    this._config = new Configuration({
      basePath: process.env.REACT_APP_BACKEND_API_URL,
      middleware: [authenticateMiddleware, errorMiddleware, ongoingTransactionMiddleware],
    });
  }
}

/**
 * Expand this ApiClient with each new controller added on the server. Note that the generate command must be runned first, otherwise
 * the new api will not be available.
 */
export class ApiClient extends ApiClientBase {
  public KibanaApi = new KibanaApi(this._config);
  public UserQryApi = new UserQryApi(this._config);
  public BudgetQryApi = new BudgetQryApi(this._config);
  public BudgetCmdApi = new BudgetCmdApi(this._config);
  public NyckeltalVaerdeCmdApi = new NyckeltalvaerdeCmdApi(this._config);
  public NyckeltalvaerdeMaanadCmdApi = new NyckeltalvaerdeMaanadCmdApi(this._config);
  public NyckeltalvaerdeQryApi = new NyckeltalvaerdeQryApi(this._config);
  public BudgetaarQryApi = new BudgetaarQryApi(this._config);
  public PeriodiseringsprofilCmdApi = new PeriodiseringsprofilCmdApi(this._config);
  public BudgetperiodCmdApi = new BudgetperiodCmdApi(this._config);
  public BudgetperiodQryApi = new BudgetperiodQryApi(this._config);
  public KommentarCmdApi = new KommentarCmdApi(this._config);
  public KommentarQryApi = new KommentarQryApi(this._config);
  public SidedrawerNotiserCmdApi = new BudgetSidedrawerNotiserCmdApi(this._config);
  public SidedrawerNotiserQryApi = new SidedrawerNotiserQryApi(this._config);
  public JournalQryApi = new JournalQryApi(this._config);
  public JournalCmdApi = new JournalCmdApi(this._config);
}

export const sendClientCrashReport = (
  error: Error,
  felkod: string,
  componentStack?: string,
  currentUrl?: string,
  userAgentInfo?: Navigator
) => {
  const userAgentInfoObject = {
    userAgent: userAgentInfo?.userAgent,
    vendor: userAgentInfo?.vendor,
  };

  return new ApiClient().KibanaApi.postClientCrashError({
    clientCrashErrorMessage: {
      Message: error.message,
      Stackframes: ErrorStackParser.parse(error) as ClientCrashStackframe[],
      CurrentUrl: currentUrl,
      ComponentStack: componentStack,
      UserAgentInfo: userAgentInfo ? JSON.stringify(userAgentInfoObject) : undefined,
      ErrorCode: felkod,
    },
  });
};
