/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';

/**
 *
 * @export
 * @interface StartBudgetperiodCmd
 */
export interface StartBudgetperiodCmd {
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof StartBudgetperiodCmd
   */
  PrimaryKey: BudgetperiodPK;
}

export function StartBudgetperiodCmdFromJSON(json: any): StartBudgetperiodCmd {
  return StartBudgetperiodCmdFromJSONTyped(json, false);
}

export function StartBudgetperiodCmdFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartBudgetperiodCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PrimaryKey: BudgetperiodPKFromJSON(json['PrimaryKey']),
  };
}

export function StartBudgetperiodCmdToJSON(value?: StartBudgetperiodCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PrimaryKey: BudgetperiodPKToJSON(value.PrimaryKey),
  };
}
