/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CreateKommentarCmd,
  CreateKommentarCmdFromJSON,
  CreateKommentarCmdToJSON,
  DeleteKommentarCmd,
  DeleteKommentarCmdFromJSON,
  DeleteKommentarCmdToJSON,
  SdIntegrationEvent,
  SdIntegrationEventFromJSON,
  SdIntegrationEventToJSON,
} from '../models';

export interface CreateKommentarRequest {
  createKommentarCmd?: CreateKommentarCmd;
}

export interface DeleteKommentarRequest {
  deleteKommentarCmd?: DeleteKommentarCmd;
}

/**
 *
 */
export class KommentarCmdApi extends runtime.BaseAPI {
  /**
   */
  async createKommentarRaw(
    requestParameters: CreateKommentarRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/KommentarCmd/CreateKommentar`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateKommentarCmdToJSON(requestParameters.createKommentarCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async createKommentar(
    requestParameters: CreateKommentarRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.createKommentarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async deleteKommentarRaw(
    requestParameters: DeleteKommentarRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<SdIntegrationEvent>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/CmdContext/KommentarCmd/DeleteKommentar`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteKommentarCmdToJSON(requestParameters.deleteKommentarCmd),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SdIntegrationEventFromJSON));
  }

  /**
   */
  async deleteKommentar(
    requestParameters: DeleteKommentarRequest = {},
    initOverrides?: RequestInit
  ): Promise<Array<SdIntegrationEvent>> {
    const response = await this.deleteKommentarRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
