/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { BudgetPK, BudgetPKFromJSON, BudgetPKFromJSONTyped, BudgetPKToJSON } from './BudgetPK';
import {
  BudgetnyckeltalListDTO,
  BudgetnyckeltalListDTOFromJSON,
  BudgetnyckeltalListDTOFromJSONTyped,
  BudgetnyckeltalListDTOToJSON,
} from './BudgetnyckeltalListDTO';

/**
 *
 * @export
 * @interface BudgetnyckeltalListWrapperDTO
 */
export interface BudgetnyckeltalListWrapperDTO {
  /**
   *
   * @type {BudgetPK}
   * @memberof BudgetnyckeltalListWrapperDTO
   */
  BudgetPK: BudgetPK;
  /**
   *
   * @type {Array<BudgetnyckeltalListDTO>}
   * @memberof BudgetnyckeltalListWrapperDTO
   */
  Budgetnyckeltal: Array<BudgetnyckeltalListDTO>;
}

export function BudgetnyckeltalListWrapperDTOFromJSON(json: any): BudgetnyckeltalListWrapperDTO {
  return BudgetnyckeltalListWrapperDTOFromJSONTyped(json, false);
}

export function BudgetnyckeltalListWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BudgetnyckeltalListWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetPK: BudgetPKFromJSON(json['BudgetPK']),
    Budgetnyckeltal: (json['Budgetnyckeltal'] as Array<any>).map(BudgetnyckeltalListDTOFromJSON),
  };
}

export function BudgetnyckeltalListWrapperDTOToJSON(value?: BudgetnyckeltalListWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetPK: BudgetPKToJSON(value.BudgetPK),
    Budgetnyckeltal: (value.Budgetnyckeltal as Array<any>).map(BudgetnyckeltalListDTOToJSON),
  };
}
