/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BeraeknadeNyckeltalDTOQry
 */
export interface BeraeknadeNyckeltalDTOQry {
  /**
   *
   * @type {number}
   * @memberof BeraeknadeNyckeltalDTOQry
   */
  NyckeltalId?: number;
}

export function BeraeknadeNyckeltalDTOQryFromJSON(json: any): BeraeknadeNyckeltalDTOQry {
  return BeraeknadeNyckeltalDTOQryFromJSONTyped(json, false);
}

export function BeraeknadeNyckeltalDTOQryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BeraeknadeNyckeltalDTOQry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: !exists(json, 'NyckeltalId') ? undefined : json['NyckeltalId'],
  };
}

export function BeraeknadeNyckeltalDTOQryToJSON(value?: BeraeknadeNyckeltalDTOQry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
  };
}
