import {
  FbButton,
  FbButtonState,
  FbCollapsible,
  FbSpinner,
  FbTable,
  FbTableColDef,
  FbTableRow,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { postClientComponentError } from 'components/home/budgetList/BudgetList';
import { BudgetperiodStatus, getBudgetperiodStatusTextInSwedish } from 'domain/budgetperiodStatusDomain';
import { BudgetperiodDTO } from 'generated-models/budgetera/models';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BudgetaarRoute } from 'store/location';
import { useAppDispatch } from 'store/store';
import { createBudgetaarAction, selectCreateBudgetaarCmd } from './cmdCreateBudgetaar';
import { getBudgetaarListQryAction, selectBudgetaarListQry } from './qryBudgetaarList';

export interface budgetaarIdentifier {
  kalenderAar: number;
  budgetperiodId: number;
}

export const BudgetaarList: React.FC<{}> = () => {
  const budgetaarListQry = useSelector(selectBudgetaarListQry());
  const createBudgetaarCmd = useSelector(selectCreateBudgetaarCmd);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(budgetaarListQry) || RemoteDataFunctions.isStale(budgetaarListQry)) {
      dispatch(getBudgetaarListQryAction());
    }
  }, [budgetaarListQry, dispatch]);

  const colDefList: FbTableColDef[] = [
    { field: 'budgetaar', headerName: '', cssClass: 'w-5/12' },
    { field: 'status', headerName: 'Status', cssClass: 'w-3/12' },
  ];

  const mapListRow = (budgetaarList: BudgetperiodDTO[]): FbTableRow[] => {
    return budgetaarList.map(b => ({
      identifier: b.BudgetperiodPK,
      colList: {
        budgetaar: {
          text: 'Budgetår ' + b.Kalenderaar,
        },
        status: {
          text: getBudgetperiodStatusTextInSwedish(b.BudgetperiodStatusVO),
        },
      },
    }));
  };

  const isCreateBudgetarButtonDisabled = (budgetaarList: BudgetperiodDTO[]) => {
    const latestCreatedBudgetperiod = budgetaarList.find(
      b => b.Kalenderaar === Math.max(...budgetaarList.map(b => b.Kalenderaar))
    )!;

    // Only allow budgeteraReset to create new budgetår if previous budgetår is already atleast started
    if (
      process.env.REACT_APP_IS_RESET &&
      (latestCreatedBudgetperiod.BudgetperiodStatusVO.Status === BudgetperiodStatus.NotStarted ||
        latestCreatedBudgetperiod.BudgetperiodStatusVO.Status === BudgetperiodStatus.ReadyToStart ||
        latestCreatedBudgetperiod.BudgetperiodStatusVO.Status === BudgetperiodStatus.Starting)
    )
      return true;
    else if (process.env.REACT_APP_IS_RESET) return false;

    const currentYear = new Date().getFullYear();
    if (currentYear < latestCreatedBudgetperiod.Kalenderaar) return true;
    return false;
  };

  const createBudgetaar = () => {
    if (CommandFunctions.isNotExecuted(createBudgetaarCmd)) {
      dispatch(createBudgetaarAction());
    }
  };

  const getButtonState = useCallback((): FbButtonState => {
    if (CommandFunctions.isExecuting(createBudgetaarCmd)) {
      return 'waiting';
    } else if (CommandFunctions.isSuccess(createBudgetaarCmd)) {
      return 'success';
    } else if (CommandFunctions.isFailure(createBudgetaarCmd)) {
      return 'failure';
    }
    return 'default';
  }, [createBudgetaarCmd]);

  return (
    <>
      {!RemoteDataFunctions.hasData(budgetaarListQry) && (
        <div className="flex w-full justify-center pt-4">
          <FbSpinner size={'large'} />
        </div>
      )}
      {RemoteDataFunctions.hasData(budgetaarListQry) && (
        <div className="p-4">
          <FbCollapsible headingTitle="Budgetår" isCollapsible={false}>
            <FbButton
              text={'Skapa nytt'}
              type={'primary'}
              disabled={
                isCreateBudgetarButtonDisabled(budgetaarListQry.data) || CommandFunctions.isSuccess(createBudgetaarCmd)
              }
              onClick={() => createBudgetaar()}
              buttonState={getButtonState()}
              tooltipText={
                process.env.REACT_APP_IS_RESET && isCreateBudgetarButtonDisabled(budgetaarListQry.data)
                  ? 'Föregående budgetår måste vara minst startad för att kunna skapa ett nytt budgetår'
                  : undefined
              }
              tooltipPosition="above"
            />
            <FbTable
              className="mt-4"
              colDefList={colDefList}
              rowList={mapListRow(budgetaarListQry.data)}
              onRowSelected={(selectedRow: FbTableRow) => dispatch(BudgetaarRoute(selectedRow.identifier))}
              postClientComponentError={(componentName, message, componentProps) =>
                postClientComponentError(componentName, message, componentProps)
              }
            />
          </FbCollapsible>
        </div>
      )}
    </>
  );
};
