/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PeriodiseringsprofilPK,
  PeriodiseringsprofilPKFromJSON,
  PeriodiseringsprofilPKFromJSONTyped,
  PeriodiseringsprofilPKToJSON,
} from './PeriodiseringsprofilPK';

/**
 *
 * @export
 * @interface DeletePeriodiseringsprofilCmd
 */
export interface DeletePeriodiseringsprofilCmd {
  /**
   *
   * @type {PeriodiseringsprofilPK}
   * @memberof DeletePeriodiseringsprofilCmd
   */
  PrimaryKey: PeriodiseringsprofilPK;
}

export function DeletePeriodiseringsprofilCmdFromJSON(json: any): DeletePeriodiseringsprofilCmd {
  return DeletePeriodiseringsprofilCmdFromJSONTyped(json, false);
}

export function DeletePeriodiseringsprofilCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeletePeriodiseringsprofilCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    PrimaryKey: PeriodiseringsprofilPKFromJSON(json['PrimaryKey']),
  };
}

export function DeletePeriodiseringsprofilCmdToJSON(value?: DeletePeriodiseringsprofilCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    PrimaryKey: PeriodiseringsprofilPKToJSON(value.PrimaryKey),
  };
}
