import { FbDatepicker } from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { BudgetperiodPK, DeadlineEnhetstyp } from 'generated-models/budgetera';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { selectBudgetperiodStatus } from '../startaBudgetaar/qryStartaBudgetaar';
import { selectUpdateDeadlineCmd, updateDeadlineAction } from './cmdUpdateDeadline';

interface BudgetaarDeadlineProps {
  budgetperiodPK: BudgetperiodPK;
}

export const BudgetaarDeadline: React.FC<BudgetaarDeadlineProps> = ({ budgetperiodPK }) => {
  const dispatch = useAppDispatch();
  const budgetperiodStatus = useSelector(selectBudgetperiodStatus(budgetperiodPK));
  const updateDeadlineCmd = useSelector(selectUpdateDeadlineCmd);

  const saveDate = (value: Date | null, unit: DeadlineEnhetstyp) => {
    if (value) {
      if (!CommandFunctions.isExecuting(updateDeadlineCmd) && budgetperiodPK) {
        dispatch(
          updateDeadlineAction({
            Deadline: value,
            Enhetstyp: unit,
            PrimaryKey: budgetperiodPK,
          })
        );
      }
    }
  };

  return RemoteDataFunctions.hasData(budgetperiodStatus) ? (
    <div className="bg-fb-color-white px-4 py-4">
      <div className="flex flex-col w-48 mb-6">
        <h4 className="font-bold text-sm">Deadlines per nivå</h4>
        <FbDatepicker
          id={'1'}
          label={'Bostadsmarknad'}
          startDate={
            !isNaN(budgetperiodStatus.data.BudgetperiodDTO.DeadlineMarknad.getTime())
              ? budgetperiodStatus.data.BudgetperiodDTO.DeadlineMarknad
              : undefined
          }
          onChange={value => {
            saveDate(value, DeadlineEnhetstyp.Bostadsmarknad);
          }}
        />
        <FbDatepicker
          id={'2'}
          label={'Affärsområde'}
          startDate={
            !isNaN(budgetperiodStatus.data.BudgetperiodDTO.DeadlineAffaersomraade.getTime())
              ? budgetperiodStatus.data.BudgetperiodDTO.DeadlineAffaersomraade
              : undefined
          }
          onChange={value => {
            saveDate(value, DeadlineEnhetstyp.Affaersomraade);
          }}
        />
        <FbDatepicker
          id={'3'}
          label={'Kontor'}
          startDate={
            !isNaN(budgetperiodStatus.data.BudgetperiodDTO.DeadlineKontor.getTime())
              ? budgetperiodStatus.data.BudgetperiodDTO.DeadlineKontor
              : undefined
          }
          onChange={value => {
            saveDate(value, DeadlineEnhetstyp.Kontor);
          }}
        />
        <FbDatepicker
          id={'4'}
          label={'Mäklare'}
          startDate={
            !isNaN(budgetperiodStatus.data.BudgetperiodDTO.DeadlineMaeklare.getTime())
              ? budgetperiodStatus.data.BudgetperiodDTO.DeadlineMaeklare
              : undefined
          }
          onChange={value => {
            saveDate(value, DeadlineEnhetstyp.Maeklare);
          }}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
