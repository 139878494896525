import * as React from 'react';

export const useIntersection = (
  elementRef: React.MutableRefObject<Element | null>,
  root?: React.MutableRefObject<Element | null>,
  threshold?: number | number[],
  rootMargin?: string
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = React.useState<IntersectionObserverEntry>();

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  React.useEffect(() => {
    const node = elementRef.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || !node) return;

    const observerParams = { threshold, root: root ? root.current : null, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, root, threshold, rootMargin]);

  return entry;
};
