/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetperiodPK,
  BudgetperiodPKFromJSON,
  BudgetperiodPKFromJSONTyped,
  BudgetperiodPKToJSON,
} from './BudgetperiodPK';
import {
  PeriodiseringsprofilMaanadItem,
  PeriodiseringsprofilMaanadItemFromJSON,
  PeriodiseringsprofilMaanadItemFromJSONTyped,
  PeriodiseringsprofilMaanadItemToJSON,
} from './PeriodiseringsprofilMaanadItem';

/**
 *
 * @export
 * @interface CreatePeriodiseringsprofilSrvcCmd
 */
export interface CreatePeriodiseringsprofilSrvcCmd {
  /**
   *
   * @type {string}
   * @memberof CreatePeriodiseringsprofilSrvcCmd
   */
  Namn: string;
  /**
   *
   * @type {number}
   * @memberof CreatePeriodiseringsprofilSrvcCmd
   */
  BostadsmarknadId?: number | null;
  /**
   *
   * @type {Array<PeriodiseringsprofilMaanadItem>}
   * @memberof CreatePeriodiseringsprofilSrvcCmd
   */
  PeriodiseringsprofilMaanadList: Array<PeriodiseringsprofilMaanadItem>;
  /**
   *
   * @type {BudgetperiodPK}
   * @memberof CreatePeriodiseringsprofilSrvcCmd
   */
  BudgetperiodPK: BudgetperiodPK;
}

export function CreatePeriodiseringsprofilSrvcCmdFromJSON(json: any): CreatePeriodiseringsprofilSrvcCmd {
  return CreatePeriodiseringsprofilSrvcCmdFromJSONTyped(json, false);
}

export function CreatePeriodiseringsprofilSrvcCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreatePeriodiseringsprofilSrvcCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    Namn: json['Namn'],
    BostadsmarknadId: !exists(json, 'BostadsmarknadId') ? undefined : json['BostadsmarknadId'],
    PeriodiseringsprofilMaanadList: (json['PeriodiseringsprofilMaanadList'] as Array<any>).map(
      PeriodiseringsprofilMaanadItemFromJSON
    ),
    BudgetperiodPK: BudgetperiodPKFromJSON(json['BudgetperiodPK']),
  };
}

export function CreatePeriodiseringsprofilSrvcCmdToJSON(value?: CreatePeriodiseringsprofilSrvcCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Namn: value.Namn,
    BostadsmarknadId: value.BostadsmarknadId,
    PeriodiseringsprofilMaanadList: (value.PeriodiseringsprofilMaanadList as Array<any>).map(
      PeriodiseringsprofilMaanadItemToJSON
    ),
    BudgetperiodPK: BudgetperiodPKToJSON(value.BudgetperiodPK),
  };
}
