import { clickOutside } from '@decernointernal/fb-interna-komponenter';
import { faNavicon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Roles } from 'components/user/Roles';
import { Enhet, EnhetVO } from 'generated-models/budgetera/models';
import React from 'react';
import Fallbackbild from 'resources/Fallbackbild.svg';
import { SideDrawer } from 'shared-components/sidedrawer/SideDrawer';
import { SideDrawerLink } from 'shared-components/sidedrawer/SideDrawerLink';
import { BudgetaarListRoute } from 'store/location';
import { HeaderExpanded } from './headerExpanded/HeaderExpanded';

interface HeaderProps {
  logoutOnClick: () => void;
  // EnhetVO Behöver brytas ut ifall denna ska användas i MA
  currentEnhet?: EnhetVO;
  enhetList?: Enhet[];
  changeEnhetOnClick: (enhetVO: EnhetVO) => void;
  name?: string;
  avatarImageUrl?: string;
  role?: string;
}

export const Header: React.FC<HeaderProps> = ({
  logoutOnClick,
  currentEnhet,
  enhetList,
  changeEnhetOnClick,
  avatarImageUrl,
  name,
  role,
}) => {
  const iconRef = React.useRef(null) as React.MutableRefObject<any | null>;

  const {
    ref: headerRef,
    isComponentVisible: isHeaderExpanded,
    setIsComponentVisible: setIsHeaderExpanded,
  } = clickOutside(false, [iconRef]);

  const {
    ref: sideDrawerRef,
    isComponentVisible: isSideDrawerOpen,
    setIsComponentVisible: setIsSideDrawerOpen,
  } = clickOutside(false, [iconRef, headerRef]);

  return (
    <>
      <div className="bg-fb-color-white w-full h-10 sticky top-0 shadow-md z-[1000]">
        {name && (
          <div className="flex">
            {role && role !== Roles.ANVAENADRE && (
              <div
                className="pl-2 py-2 w-9 border-r border-r-fb-color-grey-medium-dark cursor-pointer"
                onClick={() => setIsSideDrawerOpen(!isSideDrawerOpen)}
                ref={iconRef}
              >
                <FontAwesomeIcon className="self-center" size="lg" icon={faNavicon} />
              </div>
            )}
            <div className="pt-1 pl-2" ref={headerRef}>
              <img
                src={avatarImageUrl ?? Fallbackbild}
                alt={''}
                className={'h-8 w-8 mb-1 rounded-2xl cursor-pointer'}
                onClick={() => setIsHeaderExpanded(!isHeaderExpanded)}
                data-testid="header-test-id"
              ></img>
              {role && (
                <HeaderExpanded
                  logoutOnClick={logoutOnClick}
                  currentEnhet={currentEnhet ?? undefined}
                  enhetList={enhetList ?? undefined}
                  changeEnhetOnClick={changeEnhetOnClick}
                  name={name}
                  role={role}
                  showHeader={isHeaderExpanded}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {role && role !== Roles.ANVAENADRE && (
        <SideDrawer topMargin={40} open={isSideDrawerOpen} sideDrawerRef={sideDrawerRef}>
          <SideDrawerLink
            routeTo={() => BudgetaarListRoute()}
            onClick={() => setIsSideDrawerOpen(false)}
            text="Budgetår"
            className="w-64"
          />
        </SideDrawer>
      )}
    </>
  );
};
