/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NyckeltalVaerdePK,
  NyckeltalVaerdePKFromJSON,
  NyckeltalVaerdePKFromJSONTyped,
  NyckeltalVaerdePKToJSON,
} from './NyckeltalVaerdePK';
import {
  NyckeltalvaerdeMaanadDTO,
  NyckeltalvaerdeMaanadDTOFromJSON,
  NyckeltalvaerdeMaanadDTOFromJSONTyped,
  NyckeltalvaerdeMaanadDTOToJSON,
} from './NyckeltalvaerdeMaanadDTO';

/**
 *
 * @export
 * @interface NyckeltalvaerdeMaanadWrapperDTO
 */
export interface NyckeltalvaerdeMaanadWrapperDTO {
  /**
   *
   * @type {NyckeltalVaerdePK}
   * @memberof NyckeltalvaerdeMaanadWrapperDTO
   */
  NyckeltalVaerdePK: NyckeltalVaerdePK;
  /**
   *
   * @type {Array<NyckeltalvaerdeMaanadDTO>}
   * @memberof NyckeltalvaerdeMaanadWrapperDTO
   */
  NyckeltalvaerdeMaanadDTOList: Array<NyckeltalvaerdeMaanadDTO>;
}

export function NyckeltalvaerdeMaanadWrapperDTOFromJSON(json: any): NyckeltalvaerdeMaanadWrapperDTO {
  return NyckeltalvaerdeMaanadWrapperDTOFromJSONTyped(json, false);
}

export function NyckeltalvaerdeMaanadWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeMaanadWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalVaerdePK: NyckeltalVaerdePKFromJSON(json['NyckeltalVaerdePK']),
    NyckeltalvaerdeMaanadDTOList: (json['NyckeltalvaerdeMaanadDTOList'] as Array<any>).map(
      NyckeltalvaerdeMaanadDTOFromJSON
    ),
  };
}

export function NyckeltalvaerdeMaanadWrapperDTOToJSON(value?: NyckeltalvaerdeMaanadWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalVaerdePK: NyckeltalVaerdePKToJSON(value.NyckeltalVaerdePK),
    NyckeltalvaerdeMaanadDTOList: (value.NyckeltalvaerdeMaanadDTOList as Array<any>).map(
      NyckeltalvaerdeMaanadDTOToJSON
    ),
  };
}
