import * as React from 'react';
import { FbScrollingCategory } from 'shared-components/category/FbScrollingCategory';
import { ScrollingCategory, ScrollingSubcategory } from 'shared-components/category/FbScrollingCategoryUtils';
import { FbGridColumnFooter, FbGridFooter } from './FbGridFooter';
import { FbGridColumnHeader, FbGridHeader } from './FbGridHeader';

export interface FbGridRow {
  group?: string;
  subgroup?: string;
  element: JSX.Element;
}

export interface FbGridCol {
  columnTemplate: string;
  header: FbGridColumnHeader | FbGridColumnHeader[];
  footer?: FbGridColumnFooter;
}

export interface FbGridProps {
  columns: FbGridCol[];
  rows: FbGridRow[];
  stickyZIndex: number;
  scrollableContainer?: React.MutableRefObject<Element | null>;
  className?: string;
  displayHeaderShadow?: boolean;
}

export const FbGrid: React.FC<FbGridProps> = ({
  columns,
  rows,
  stickyZIndex,
  scrollableContainer,
  className,
  displayHeaderShadow = true,
}) => {
  const [title, setTitle] = React.useState<string>('');
  const [subtitle, setSubtitle] = React.useState<string>('');
  const [categories] = React.useState<ScrollingCategory[]>([]);
  const [subcategories] = React.useState<ScrollingSubcategory[]>([]);

  const setTitleCallback = (v: string) => {
    setTitle(v);
  };
  const setSubtitleCallback = (v: string) => {
    setSubtitle(v);
  };

  return (
    <div
      className={`grid relative transition-all duration-300 ${className || ''}`}
      style={{
        gridTemplateColumns: columns.map(c => c.columnTemplate).join(' '),
      }}
    >
      <FbGridHeader
        title={title}
        subtitle={subtitle}
        headers={columns.map(c => c.header).flat()}
        zIndex={stickyZIndex}
        displayShadow={displayHeaderShadow}
      />
      <div className="contents">
        {rows.map((row, index) => {
          return (
            <React.Fragment key={index}>
              {row.group && (index === 0 || rows[index - 1].group !== row.group) && (
                <>
                  <FbScrollingCategory
                    className={index === 0 ? 'invisible pt-0 pb-0 h-0 ' : ''}
                    scrollableContainer={scrollableContainer}
                    category={{ scrolledPast: false, text: row.group }}
                    categories={categories}
                    subcategories={subcategories}
                    setTitle={setTitleCallback}
                    setSubtitle={setSubtitleCallback}
                  >
                    <h4 className="font-bold text-sm whitespace-nowrap pt-3 px-4 pb-4">{row.group}</h4>
                  </FbScrollingCategory>
                  <div className="col-start-2 col-end-[-1]" />
                </>
              )}
              {row.subgroup &&
                (index === 0 || rows[index - 1].subgroup !== row.subgroup || rows[index - 1].group !== row.group) && (
                  <>
                    <FbScrollingCategory
                      className={index === 0 ? 'invisible pt-0 pb-0 h-0' : ''}
                      scrollableContainer={scrollableContainer}
                      category={{ category: row.group, scrolledPast: false, text: row.subgroup }}
                      categories={categories}
                      subcategories={subcategories}
                      setTitle={setTitleCallback}
                      setSubtitle={setSubtitleCallback}
                    >
                      <h5 className="pl-4 pt-3 pb-4 ml-4 font-semibold text-xs-increased whitespace-nowrap">
                        {row.subgroup}
                      </h5>
                    </FbScrollingCategory>
                    <div className="col-start-2 col-end-[-1]" />
                  </>
                )}
              {row.element}
            </React.Fragment>
          );
        })}
      </div>
      {columns.some(c => c.footer) && (
        <FbGridFooter zIndex={stickyZIndex} footers={columns.filter(c => c.footer).map(c => c.footer!)} />
      )}
    </div>
  );
};
