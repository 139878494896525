import {
  FbAlert,
  FbButton,
  FbFormInput,
  FbFormRadiobuttonGroup,
  FbModal,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions } from '@decernointernal/websd.shared';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { getBudgetperiodStatusDomain } from 'domain/budgetperiodStatusDomain';
import {
  BudgetaarPeriodiseringsprofilListDTO,
  BudgetperiodPK,
  BudgetperiodStatusVO,
  ChangePeriodiseringsprofilCmd,
  CreatePeriodiseringsprofilSrvcCmd,
  DeletePeriodiseringsprofilCmd,
} from 'generated-models/budgetera';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FbGridCol } from 'shared-components/grid/FbGrid';
import { FbGridColumnHeaderStyles, FbGridColumnHeaderWrapperStyles } from 'shared-components/grid/FbGridHeader';
import { FbTableGrid, FbTableGridRowDef } from 'shared-components/grid/table/FbTableGrid';
import { useAppDispatch } from 'store/store';
import { changePeriodiseringsprofilAction, selectChangePeriodiseringsprofilCmd } from './cmdChangePeriodiseringsprofil';
import { createPeriodiseringsprofilAction, selectCreatePeriodiseringsprofilCmd } from './cmdCreatePeriodiseringsprofil';
import { deletePeriodiseringsprofilAction, selectDeletePeriodiseringsprofilCmd } from './cmdDeletePeriodiseringsprofil';

export interface BudgetaarPeriodiseringsProfilModalProps {
  onCancel?: () => void;
  periodiseringsProfilToEdit?: BudgetaarPeriodiseringsprofilListDTO;
  budgetperiodStatus: BudgetperiodStatusVO;
  budgetperiodPK: BudgetperiodPK;
}

interface periodiseringsprofilToCreateOrEdit {
  Namn: string;
  BostadsmarknadId?: number | null;
  PeriodiseringsprofilMaanadList: PeriodiseringsprofilMaanadList[];
}

interface PeriodiseringsprofilMaanadList {
  Maanad: number;
  Vaerde: number;
}

const getinitalStateOfPeriodiseringsprofilToCreate = () => {
  return {
    Namn: '',
    BostadsmarknadId: null,
    PeriodiseringsprofilMaanadList: [
      { Maanad: 1, Vaerde: 0 },
      { Maanad: 2, Vaerde: 0 },
      { Maanad: 3, Vaerde: 0 },
      { Maanad: 4, Vaerde: 0 },
      { Maanad: 5, Vaerde: 0 },
      { Maanad: 6, Vaerde: 0 },
      { Maanad: 7, Vaerde: 0 },
      { Maanad: 8, Vaerde: 0 },
      { Maanad: 9, Vaerde: 0 },
      { Maanad: 10, Vaerde: 0 },
      { Maanad: 11, Vaerde: 0 },
      { Maanad: 12, Vaerde: 0 },
    ],
  };
};

const maanader: string[] = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'Dececmber',
];

export const BudgetaarPeriodiseringsProfilModal: React.FC<BudgetaarPeriodiseringsProfilModalProps> = ({
  onCancel,
  periodiseringsProfilToEdit,
  budgetperiodStatus,
  budgetperiodPK,
}) => {
  const [periodiseringsprofil, setPeriodiseringsprofil] = useState<periodiseringsprofilToCreateOrEdit>(
    periodiseringsProfilToEdit ?? getinitalStateOfPeriodiseringsprofilToCreate()
  );

  const [usedAsDefault] = useState<boolean>(
    !!periodiseringsProfilToEdit &&
      !!periodiseringsProfilToEdit.DefaultPeriodiseringsprofilList &&
      periodiseringsProfilToEdit.DefaultPeriodiseringsprofilList.length > 0
  );
  const [disabled] = useState<boolean>(
    getBudgetperiodStatusDomain(budgetperiodStatus) === 'started' && periodiseringsProfilToEdit !== undefined
  );
  const dispatch = useAppDispatch();
  const createPeriodiseringsprofilCmd = useSelector(selectCreatePeriodiseringsprofilCmd);
  const changePeriodiseringsprofilCmd = useSelector(selectChangePeriodiseringsprofilCmd);
  const deletePeriodiseringsprofilCmd = useSelector(selectDeletePeriodiseringsprofilCmd);

  const isStateValid = () => {
    if (periodiseringsprofil.Namn === '') return false;
    if (periodiseringsprofil.BostadsmarknadId === null) return false;
    if (
      (periodiseringsprofil.PeriodiseringsprofilMaanadList.reduce((sum, item) => sum + item.Vaerde, 0) * 100)
        .toFixed(1)
        .replaceAll('.0', '') !== '100'
    )
      return false;
    return true;
  };

  const getSumInPercent = (minusTotal: boolean = false) => {
    let sum = periodiseringsprofil.PeriodiseringsprofilMaanadList.reduce((sum, item) => sum + item.Vaerde, 0) * 100;
    sum = minusTotal ? 100 - sum : sum;
    return sum.toFixed(1).replaceAll('.0', '') + ' %';
  };

  const convertPercentToNumber = (val: number) => {
    return Number((val / 100).toFixed(3));
  };

  const createNewPeriodiseringsprofil = () => {
    if (!isStateValid()) return;
    if (CommandFunctions.isExecuting(createPeriodiseringsprofilCmd)) return;
    const cmd: CreatePeriodiseringsprofilSrvcCmd = { ...periodiseringsprofil, BudgetperiodPK: budgetperiodPK };
    dispatch(createPeriodiseringsprofilAction(cmd));
  };

  const changePeriodiseringsprofil = () => {
    if (periodiseringsProfilToEdit === undefined) return;
    if (!isStateValid()) return;
    if (CommandFunctions.isExecuting(changePeriodiseringsprofilCmd)) return;
    const cmd: ChangePeriodiseringsprofilCmd = {
      PrimaryKey: periodiseringsProfilToEdit!.PeriodiseringsprofilPK,
      BostadsmarknadId: periodiseringsprofil.BostadsmarknadId,
      Namn: periodiseringsprofil.Namn,
      PeriodiseringsprofilMaanadList: periodiseringsprofil.PeriodiseringsprofilMaanadList,
    };
    dispatch(changePeriodiseringsprofilAction(cmd));
  };

  const deletePeriodiseringsprofil = () => {
    if (periodiseringsProfilToEdit === undefined) return;
    if (CommandFunctions.isExecuting(deletePeriodiseringsprofilCmd)) return;
    const cmd: DeletePeriodiseringsprofilCmd = {
      PrimaryKey: periodiseringsProfilToEdit!.PeriodiseringsprofilPK,
    };
    dispatch(deletePeriodiseringsprofilAction(cmd));
  };

  const columns: FbGridCol[] = [
    {
      columnTemplate: 'minmax(142px, 10%)',
      header: [
        {
          type: 'value',
          className: 'border-fb-color-grey-table-border border-l' + FbGridColumnHeaderStyles.table,
          value: 'Månad',
          wrapperClassName: '' + FbGridColumnHeaderWrapperStyles.table,
        },
      ],
      footer: {
        type: 'element',
        element: (
          <div className={`font-semibold flex flex-col justify-between text-xs-increased pt-1`}>
            <span>Summa fördelat</span>
            <span className="pt-1">Kvar att fördela</span>
          </div>
        ),
      },
    },
    {
      columnTemplate: 'minmax(142px, 10%)',
      header: [
        {
          type: 'value',
          className: 'border-fb-color-grey-table-border border-l' + FbGridColumnHeaderStyles.table,
          value: 'Procent',
          wrapperClassName: '' + FbGridColumnHeaderWrapperStyles.table,
        },
      ],
      footer: {
        type: 'element',
        element: (
          <div className={`font-semibold flex flex-col justify-between text-xs-increased text-right pt-1`}>
            <span>{getSumInPercent()}</span>
            <span className={'pt-1 ' + (getSumInPercent(true) === '0 %' ? '' : ' text-fb-color-danger')}>
              {getSumInPercent(true)}
            </span>
          </div>
        ),
      },
    },
  ];

  const getTooltipString = (raderaText: boolean = false) => {
    var tooltipText = raderaText
      ? `Periodiseringsprofilen kan inte raderas då den används som default periodiseringsprofil för ett eller flera nyckeltal.`
      : `Var periodiseringsprofilen används kan inte ändras då profilen används som default för ett eller flera nyckeltal`;

    if (!periodiseringsProfilToEdit?.DefaultPeriodiseringsprofilList) {
      return tooltipText;
    }

    const profilList =
      periodiseringsProfilToEdit.DefaultPeriodiseringsprofilList.length > 4
        ? periodiseringsProfilToEdit?.DefaultPeriodiseringsprofilList?.slice(0, 3)
        : periodiseringsProfilToEdit?.DefaultPeriodiseringsprofilList;

    profilList.forEach(nyckeltal => {
      let tempstring = `
      • `;
      tempstring +=
        nyckeltal.BostadsmarknadId === 0
          ? 'Sverige / '
          : nyckeltal.BostadsmarknadId === 1
          ? 'Utland / '
          : 'Sverige och Utland / ';

      tempstring += nyckeltal.NyckeltalSubkategori;
      tempstring += ' / ';
      tempstring += nyckeltal.NyckeltalKategori;
      tooltipText += tempstring;
    });

    if (periodiseringsProfilToEdit.DefaultPeriodiseringsprofilList.length > 4) {
      tooltipText += `
      + ${periodiseringsProfilToEdit.DefaultPeriodiseringsprofilList.length} nyckeltal`;
    }

    return tooltipText;
  };

  return (
    <FbModal
      titel={(periodiseringsProfilToEdit === undefined ? 'Skapa ny ' : 'Redigera ') + 'periodiseringsprofil'}
      hideBottomBorder={true}
      onCancel={() => {
        if (onCancel) onCancel();
      }}
      buttonCancelText="Avbryt"
      buttonConfirmText={periodiseringsProfilToEdit === undefined ? 'Skapa' : 'Spara'}
      confirmButtonState="default"
      disabled={!isStateValid() || disabled}
      onConfirm={() => {
        periodiseringsProfilToEdit === undefined ? createNewPeriodiseringsprofil() : changePeriodiseringsprofil();
        if (onCancel) onCancel();
      }}
    >
      <div className="w-full">
        {getBudgetperiodStatusDomain(budgetperiodStatus) === 'started' && periodiseringsProfilToEdit !== undefined && (
          <div className="text-xs-increased my-4 mx-1">
            <FbAlert type={'info'} text={'Periodiseringsprofilen kan inte redigeras då budgetåret år startat.'} />
          </div>
        )}
        <div className="flex">
          <FbFormInput
            label="Profilnamn"
            defaultValue={periodiseringsprofil.Namn}
            id="1"
            onBlur={(value: string | undefined) =>
              setPeriodiseringsprofil({ ...periodiseringsprofil, Namn: value ?? '' })
            }
            disabled={disabled}
          />
          {periodiseringsProfilToEdit && (
            <div className="ml-auto">
              <FbButton
                text={'Radera profil'}
                type={'primary'}
                size="default"
                className="ml-auto mr-1 peer"
                icon={faTrashCan}
                onClick={() => {
                  deletePeriodiseringsprofil();
                  if (onCancel) onCancel();
                }}
                disabled={usedAsDefault || disabled}
                tooltipPosition="left"
                tooltipMaxWidth={370}
                tooltipText={getTooltipString(true)}
              />
            </div>
          )}
        </div>
        <div className="text-sm mb-2 mt-4">Används för:</div>
        <FbFormRadiobuttonGroup
          radioButtons={[
            {
              id: '1',
              label: 'Sverige',
              value: '',
              defaultChecked: periodiseringsprofil.BostadsmarknadId === 0,
              onChange: () => {
                setPeriodiseringsprofil({ ...periodiseringsprofil, BostadsmarknadId: 0 });
              },
            },
            {
              id: '2',
              label: 'Utland',
              value: '',
              defaultChecked: periodiseringsprofil.BostadsmarknadId === 1,
              onChange: () => {
                setPeriodiseringsprofil({ ...periodiseringsprofil, BostadsmarknadId: 1 });
              },
            },
            {
              id: '3',
              label: 'Sverige och Utland',
              value: '',
              defaultChecked: periodiseringsprofil.BostadsmarknadId === undefined,
              onChange: () => {
                setPeriodiseringsprofil({ ...periodiseringsprofil, BostadsmarknadId: undefined });
              },
            },
          ]}
          groupname={'Bostadsmarknad'}
          disabled={usedAsDefault || disabled}
          disabledTooltip={getTooltipString()}
          tooltipPosition="right"
          tooltipMaxwidth={370}
        />
        <div className="text-sm font-bold mb-2 mt-4">Månadsfördelning</div>
        <FbTableGrid
          className="w-full"
          columns={columns}
          rows={maanader.map((maanad, maanadIndex) => {
            const value = (periodiseringsprofil.PeriodiseringsprofilMaanadList[maanadIndex].Vaerde * 100)
              .toFixed(1)
              .replace('.0', '')
              .replaceAll('.', ',');
            const rows: FbTableGridRowDef = {
              values: [
                { value: maanad, className: 'border-l border-fb-color-grey-table-border' },
                {
                  value: value,
                  inputCellProps: {
                    id: maanad,
                    suffix: '%',
                    type: 'float',
                    allowNegative: false,
                    decimalLimit: 1,
                    onBlur: (value: string | undefined) => {
                      periodiseringsprofil.PeriodiseringsprofilMaanadList[maanadIndex].Vaerde =
                        value === undefined || value === ''
                          ? 0
                          : convertPercentToNumber(Number(value.replaceAll(',', '.')));
                      setPeriodiseringsprofil({ ...periodiseringsprofil });
                    },
                    disabled: disabled,
                    removeZeroOnFocus: true,
                  },
                },
              ],
            };
            return rows;
          })}
        />
        {!isStateValid() && (
          <div className="text-xs-increased my-4 mx-1">
            <FbAlert
              type={'danger'}
              text={
                'Periodiseringsprofilen kan inte sparas förrän profilen är namngiven, vem den ska användas för är valt och att summan av alla månader är 100%'
              }
            />
          </div>
        )}
      </div>
    </FbModal>
  );
};
