import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import {
  BudgetEnhetPair,
  BudgetPK,
  NyckeltalvaerdeUtfallUnderliggandeWrapperDTO,
} from 'generated-models/budgetera/models';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
const ActionType = '[UnderliggandeUtfall] Get underliggandeUtfall';

export interface UnderliggandeUtfallIdentifier {
  BudgetId: number;
  NyckeltalId: number;
}

const underliggandeUtfallQry = createWebDataList<
  typeof ActionType,
  NyckeltalvaerdeUtfallUnderliggandeWrapperDTO,
  UnderliggandeUtfallIdentifier
>(ActionType, k => {
  return { BudgetId: k.BudgetPK.BudgetId!, NyckeltalId: k.nyckeltalId };
});

type underliggandeUtfallQryState = WebDataListState<NyckeltalvaerdeUtfallUnderliggandeWrapperDTO>;

const mapUnderliggandeUtfallQryState: MapState<underliggandeUtfallQryState> = rootState =>
  rootState.main.nyckeltalVaerde.qryUnderliggandeUtfall;

export const selectUnderliggandeUtfallQry = (id: UnderliggandeUtfallIdentifier) => (state: RootState) =>
  underliggandeUtfallQry.get(mapUnderliggandeUtfallQryState(state), id);

export const getUnderliggandeUtfallQryAction =
  (nyckeltalId: number, budgetId: BudgetPK, kalenderaar: number, budgetEnhetPairList: BudgetEnhetPair[]): ThunkAction =>
  async (dispatch, getState) => {
    const webData = underliggandeUtfallQry.get(mapUnderliggandeUtfallQryState(getState()), {
      BudgetId: budgetId.BudgetId!,
      NyckeltalId: nyckeltalId,
    });
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(underliggandeUtfallQry.updating(webData.data));
      } else {
        dispatch(underliggandeUtfallQry.loading({ BudgetId: budgetId.BudgetId!, NyckeltalId: nyckeltalId }));
      }
      const result = await new ApiClient().NyckeltalvaerdeQryApi.getNyckeltalvaerdeUtfallUnderliggande({
        nyckeltalvaerdeUtfallUnderliggandeDTOQry: {
          BudgetId: budgetId.BudgetId,
          NyckeltalId: nyckeltalId,
          Kalenderaar: kalenderaar,
          BudgetEnhetPairList: budgetEnhetPairList,
        },
      });

      dispatch(underliggandeUtfallQry.success(result));
    } catch (err) {
      dispatch(
        underliggandeUtfallQry.failure(
          { BudgetId: budgetId.BudgetId!, NyckeltalId: nyckeltalId },
          await parseErrorResponse(err)
        )
      );
    }
  };

export default underliggandeUtfallQry.reducer;
