import { FbTabs, FbTabsType } from '@decernointernal/fb-interna-komponenter';
import { RemoteDataFunctions } from '@decernointernal/websd.shared';
import { selectUnderliggandeEnheterQry } from 'components/budget/qryUnderliggandeEnheter';
import { BudgetListDTO, BudgetnyckeltalListDTO } from 'generated-models/budgetera';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { aerSummaMaanaderAarsvaerde } from 'utils/summaMaanaderUtils';
import { selectNyckeltalvaerdeQry } from '../qryNyckeltalVaerde';
import { getUnderliggandeNamn } from '../underliggande';
import { BeraeknadeNyckeltal } from './BeraeknadeNyckeltal';
import { Periodisering } from './Periodisering';
import { beraeknadeNyckeltalQryAction, selectBeraeknadeNyckeltalQry } from './qryBeraeknadeNyckeltal';
import { selectNyckeltalvaerdeMaanadBudgetQry } from './qryNyckeltalvaerdeMaanadBudget';
import { UnderliggandeEnheter } from './UnderliggandeEnheter';

export interface NyckeltalTabsProps {
  budget: BudgetListDTO;
  nyckeltal: BudgetnyckeltalListDTO;
}

export const NyckeltalTabs: React.FC<NyckeltalTabsProps> = ({ budget, nyckeltal }) => {
  const budgetPK = budget.BudgetPK;
  const enhet = budget.EnhetVO;
  const nyckeltalId = nyckeltal.NyckeltalId;
  const nyckeltalvaerdeMaanadList = useSelector(selectNyckeltalvaerdeMaanadBudgetQry());
  const underliggandeEnheter = useSelector(selectUnderliggandeEnheterQry(budgetPK));
  const beraeknadeNyckeltal = useSelector(selectBeraeknadeNyckeltalQry(nyckeltal.NyckeltalId));
  const pk = {
    BudgetId: nyckeltal.BudgetId,
    BudgetnyckeltalLoepnummer: nyckeltal.BudgetnyckeltalLoepnummer,
  };
  const nyckeltalVaerde = useSelector(selectNyckeltalvaerdeQry(pk));
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(beraeknadeNyckeltal)) {
      dispatch(beraeknadeNyckeltalQryAction(nyckeltal.NyckeltalId));
    }
  }, [beraeknadeNyckeltal, dispatch, nyckeltal]);

  const tabBody = (child: JSX.Element) => {
    return (
      <>
        <div className="h-px bg-fb-color-bg-main-compliment"></div>
        <div className="relative w-full h-[636px]">
          <div className="absolute w-full h-full">
            <div className="px-6 py-4 h-full">
              <div className="w-full h-full flex justify-center items-start">{child}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getTabs = () => {
    const tabs: FbTabsType[] = [];
    if (
      enhet.PersonId === undefined &&
      enhet.PlaneradBudgetId === undefined &&
      RemoteDataFunctions.hasData(underliggandeEnheter) &&
      underliggandeEnheter.data.UnderliggandeNyckeltalId.find(id => id === nyckeltalId)
    ) {
      tabs.push({
        headingTitle: `${getUnderliggandeNamn(budget, false)}`,
        content: tabBody(
          <UnderliggandeEnheter
            budget={budget}
            nyckeltal={nyckeltal}
            egetBudgeteratVaerde={RemoteDataFunctions.hasData(nyckeltalVaerde) ? nyckeltalVaerde.data.Aarsvaerde : null}
          />
        ),
      });
    }
    let showWarning = false;
    if (
      RemoteDataFunctions.hasData(nyckeltalvaerdeMaanadList) &&
      RemoteDataFunctions.hasData(nyckeltalVaerde) &&
      nyckeltalVaerde.data.Aarsvaerde !== null &&
      nyckeltalVaerde.data.Aarsvaerde !== undefined
    ) {
      const maanader = nyckeltalvaerdeMaanadList.data.NyckeltalvaerdeMaanadWrapperDTOList.find(
        m => m.NyckeltalVaerdePK.NyckeltalvaerdeId === nyckeltalVaerde.data.NyckeltalVaerdePK.NyckeltalvaerdeId
      )?.NyckeltalvaerdeMaanadDTOList;
      if (maanader && maanader.length > 0) {
        showWarning = !aerSummaMaanaderAarsvaerde(nyckeltalVaerde.data.Aarsvaerde, maanader);
      }
    }
    tabs.push({
      headingTitle: 'Periodisering',
      disabled: !nyckeltal.PeriodiseringsprofilId,
      displayWarning: showWarning,
      tooltipMaxWidth: 320,
      tooltipText: showWarning
        ? 'Summa budgeterade månadsvärden stämmer inte överens med budgeterat årsvärde'
        : 'Detta nyckeltal periodiseras inte',
      content: tabBody(
        <Periodisering
          budget={budget}
          nyckeltal={nyckeltal}
          nyckeltalvaerde={RemoteDataFunctions.hasData(nyckeltalVaerde) ? nyckeltalVaerde.data : undefined}
        />
      ),
    });
    tabs.push({
      headingTitle: 'Beräknade nyckeltal',
      disabled: RemoteDataFunctions.hasData(beraeknadeNyckeltal)
        ? beraeknadeNyckeltal.data.BeraeknadeNyckeltalCalculationList.length === 0
        : false,
      tooltipText: 'Det här nyckeltalet används inte för att beräkna andra nyckeltal',
      tooltipMaxWidth: 320,
      content: tabBody(
        <BeraeknadeNyckeltal
          budgetPK={budget.BudgetPK}
          nyckeltalId={nyckeltal.NyckeltalId}
          aerUtland={budget.AerUtland}
        />
      ),
    });
    return tabs;
  };

  return <FbTabs tabs={getTabs()} colorScheme={'secondary'} />;
};
