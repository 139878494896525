import { AnyAction, configureStore, StoreEnhancer, ThunkAction as ReduxThunkAction } from '@reduxjs/toolkit';
import { hideModal } from 'components/navigateAway/navigateAwayReducer';
import { useDispatch } from 'react-redux';
import { connectRoutes, Options } from 'redux-first-router';
import configureMockStore from 'redux-mock-store';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { autosaveMiddleware } from './autosaveMiddleware';
import { createRootReducer } from './createRootReducer';
import { routesMap } from './location';

export const ClearBudgetActionType = '[budget] Clear Budget';

const routesOptions: Options = {
  // Custom handling of navigation leave - to let the user stay on the page if there are unsaved changes
  displayConfirmLeave: (message, callback) => {
    const canLeave = (can: boolean) => {
      store.dispatch(hideModal({ clearFields: can }));
      return callback(can);
    };

    const state: RootState = store.getState();
    const fields = state.main.navigateAway.fields;
    if (fields.length > 0) {
      store.dispatch({ type: 'autosave', canLeave, message });
    } else {
      canLeave(true);
    }
  },
};
const {
  reducer: routesReducer,
  middleware: locationMiddleware,
  enhancer: locationEnhancer,
} = connectRoutes(routesMap, routesOptions);
const rootReducer = createRootReducer(routesReducer);

const store = configureStore({
  reducer: rootReducer,
  middleware: [locationMiddleware, thunkMiddleware, autosaveMiddleware],
  enhancers: [locationEnhancer as StoreEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
type AppAction = ReturnType<typeof store.dispatch>;
export type AppDispatch = ThunkDispatch<RootState, any, AppAction>;

export type ThunkAction = ReduxThunkAction<void, RootState, {}, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;

export const mockStore = configureMockStore<RootState, AppDispatch>([
  locationMiddleware,
  thunkMiddleware,
  autosaveMiddleware,
]);
