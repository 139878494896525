import { FbOverlayNotify } from '@decernointernal/fb-interna-komponenter';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { removeNotifikation, selectNotifikationList } from './FbOverlayReducer';

export const FbOverlay: React.FC = () => {
  const notifikationList = useSelector(selectNotifikationList);
  const dispatch = useAppDispatch();

  const handleClose = (id: number) => {
    dispatch(removeNotifikation(id));
  };

  return (
    <div className="fixed top-4 right-4" style={{ zIndex: 1001 }}>
      {notifikationList.map((notifikation, i) => (
        <FbOverlayNotify
          key={i}
          titel={notifikation.titel}
          text={notifikation.text}
          felkod={notifikation.felkod}
          nivaa={notifikation.nivaa}
          onClose={() => handleClose(i)}
        ></FbOverlayNotify>
      ))}
    </div>
  );
};
