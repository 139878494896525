/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { JournalPK, JournalPKFromJSON, JournalPKFromJSONTyped, JournalPKToJSON } from './JournalPK';

/**
 *
 * @export
 * @interface UpdateJournalCmd
 */
export interface UpdateJournalCmd {
  /**
   *
   * @type {number}
   * @memberof UpdateJournalCmd
   */
  KontorId: number;
  /**
   *
   * @type {number}
   * @memberof UpdateJournalCmd
   */
  JournalTypId: number;
  /**
   *
   * @type {string}
   * @memberof UpdateJournalCmd
   */
  Beskrivning: string;
  /**
   *
   * @type {Date}
   * @memberof UpdateJournalCmd
   */
  SkapadDatum: Date;
  /**
   *
   * @type {JournalPK}
   * @memberof UpdateJournalCmd
   */
  PrimaryKey: JournalPK;
}

export function UpdateJournalCmdFromJSON(json: any): UpdateJournalCmd {
  return UpdateJournalCmdFromJSONTyped(json, false);
}

export function UpdateJournalCmdFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJournalCmd {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    KontorId: json['KontorId'],
    JournalTypId: json['JournalTypId'],
    Beskrivning: json['Beskrivning'],
    SkapadDatum: new Date(json['SkapadDatum']),
    PrimaryKey: JournalPKFromJSON(json['PrimaryKey']),
  };
}

export function UpdateJournalCmdToJSON(value?: UpdateJournalCmd | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    KontorId: value.KontorId,
    JournalTypId: value.JournalTypId,
    Beskrivning: value.Beskrivning,
    SkapadDatum: value.SkapadDatum.toISOString(),
    PrimaryKey: JournalPKToJSON(value.PrimaryKey),
  };
}
