import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { UpdateBudgetFoerutsaettningarCmd } from 'generated-models/budgetera/models';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Budget] set budget förutsättningar';

const updateBudgetFoerutsaettningarCmd = createWebCommandSingletonList<
  typeof ActionType,
  UpdateBudgetFoerutsaettningarCmd
>(ActionType, ActionType);

export const selectUpdateBudgetFoerutsaettningarCmd = (state: RootState) =>
  updateBudgetFoerutsaettningarCmd.get(state.main.budget.qryUpdateBudgetFoerutsaettningar);

export const updateBudgetFoerutsaettningarCmdAction =
  (cmd: UpdateBudgetFoerutsaettningarCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectUpdateBudgetFoerutsaettningarCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    // Call api and handle result
    try {
      // Set executing status
      dispatch(updateBudgetFoerutsaettningarCmd.executing());

      const result = await new ApiClient().BudgetCmdApi.setBudgetFoerutsaettningar({
        updateBudgetFoerutsaettningarCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);

      // Handle success response for command
      dispatch(updateBudgetFoerutsaettningarCmd.success(cmd));
    } catch (err) {
      dispatch(updateBudgetFoerutsaettningarCmd.failure(await parseErrorResponse(err)));
    }
  };

export const removeBudgetFoerutsaettningarCmdAction = (): ThunkAction => async dispatch => {
  // Remove command from commandList
  dispatch(updateBudgetFoerutsaettningarCmd.remove());
};

export default updateBudgetFoerutsaettningarCmd.reducer;
