import { CommandFunctions, createWebCommandSingletonList } from '@decernointernal/websd.shared';
import { StartBudgetperiodCmd } from 'generated-models/budgetera';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';
import { dispatchIntegrationEvents } from 'utils/eventDispatcher';
const ActionType = '[Budgetaar] StartaBudgetperiod';

const startaBudgetperiodCmd = createWebCommandSingletonList<typeof ActionType, StartBudgetperiodCmd>(
  ActionType,
  ActionType
);

export const selectStartBudgetperiodCmd = (state: RootState) =>
  startaBudgetperiodCmd.get(state.Budgetaar.startaBudgetaar.cmdStartaBudgetaar);

export const startaBudgetperiodQryAction =
  (cmd: StartBudgetperiodCmd): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const webCommand = selectStartBudgetperiodCmd(state);
    if (CommandFunctions.isExecuting(webCommand)) {
      // Cancel execution if command is already executing
      return;
    }
    try {
      // Set executing status
      dispatch(startaBudgetperiodCmd.executing());

      // Call api and handle result
      var result = await new ApiClient().BudgetperiodCmdApi.startBudgetperiod({
        startBudgetperiodCmd: cmd,
      });
      // Dispatch integration events returned from the server
      dispatchIntegrationEvents(dispatch, result);
      // We don't dispatch any integration events at this moment
      dispatch(startaBudgetperiodCmd.success(cmd));
    } catch (error) {
      dispatch(startaBudgetperiodCmd.failure(await parseErrorResponse(error)));
    }
  };

export const removeStartBudgetperiodCmdAction = (): ThunkAction => async dispatch => {
  // Remove command from commandList
  dispatch(startaBudgetperiodCmd.remove());
};

export default startaBudgetperiodCmd.reducer;
