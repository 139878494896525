/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ApiErrorMessage,
  ApiErrorMessageFromJSON,
  ApiErrorMessageToJSON,
  ClientComponentErrorMessage,
  ClientComponentErrorMessageFromJSON,
  ClientComponentErrorMessageToJSON,
  ClientCrashErrorMessage,
  ClientCrashErrorMessageFromJSON,
  ClientCrashErrorMessageToJSON,
} from '../models';

export interface PostApiErrorRequest {
  apiErrorMessage?: ApiErrorMessage;
}

export interface PostClientComponentErrorRequest {
  clientComponentErrorMessage?: ClientComponentErrorMessage;
}

export interface PostClientCrashErrorRequest {
  clientCrashErrorMessage?: ClientCrashErrorMessage;
}

/**
 *
 */
export class KibanaApi extends runtime.BaseAPI {
  /**
   */
  async postApiErrorRaw(
    requestParameters: PostApiErrorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/Kibana/apiError`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiErrorMessageToJSON(requestParameters.apiErrorMessage),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async postApiError(requestParameters: PostApiErrorRequest = {}, initOverrides?: RequestInit): Promise<void> {
    await this.postApiErrorRaw(requestParameters, initOverrides);
  }

  /**
   */
  async postClientComponentErrorRaw(
    requestParameters: PostClientComponentErrorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/Kibana/componentError`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ClientComponentErrorMessageToJSON(requestParameters.clientComponentErrorMessage),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async postClientComponentError(
    requestParameters: PostClientComponentErrorRequest = {},
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.postClientComponentErrorRaw(requestParameters, initOverrides);
  }

  /**
   */
  async postClientCrashErrorRaw(
    requestParameters: PostClientCrashErrorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/Kibana/clientCrashError`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ClientCrashErrorMessageToJSON(requestParameters.clientCrashErrorMessage),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async postClientCrashError(
    requestParameters: PostClientCrashErrorRequest = {},
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.postClientCrashErrorRaw(requestParameters, initOverrides);
  }
}
