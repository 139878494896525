import { Action } from '@reduxjs/toolkit';
import { ICanLeave, showModal } from 'components/navigateAway/navigateAwayReducer';
import store, { RootState } from 'store/store';

export function autosaveMiddleware() {
  return (next: any) => (action: Action) => {
    switch (action.type) {
      case 'autosave':
        const state: RootState = store.getState();
        const fields = state.main.navigateAway.fields;
        if (fields.length > 0) {
          const iCanLeave = action as ICanLeave;
          fields
            .filter(f => f.isValid && !!f.action)
            .forEach(f => {
              if (f.action) {
                store.dispatch(f.action);
              }
            });
          store.dispatch(showModal({ canLeave: iCanLeave.canLeave, message: iCanLeave.message }));
          setTimeout(() => {
            iCanLeave.canLeave && iCanLeave.canLeave(true);
          }, 1000);
        }
    }
    return next(action);
  };
}
