import { createWebDataList, RemoteDataFunctions, WebDataListState } from '@decernointernal/websd.shared';
import { BudgetPK } from 'generated-models/budgetera';
import { BudgetFoerutsaettningDTO } from 'generated-models/budgetera/models/BudgetFoerutsaettningDTO';
import { MapState } from 'store/createRootReducer';
import { RootState, ThunkAction } from 'store/store';
import { ApiClient } from 'utils/apiClient';
import { parseErrorResponse } from 'utils/apiUtils';

const ActionType = '[Foerutsaettning] Get foerutsaettning';

const foerutsaettningQry = createWebDataList<typeof ActionType, BudgetFoerutsaettningDTO, BudgetPK>(
  ActionType,
  k => k.BudgetPK
);

type FoerutsaettningQryState = WebDataListState<BudgetFoerutsaettningDTO>;

const mapFoerutsaettningQryState: MapState<FoerutsaettningQryState> = rootState =>
  rootState.main.budget.qryBudgetFoerutsaettning;

export const selectFoerutsaettningQry = (id: BudgetPK) => (state: RootState) =>
  foerutsaettningQry.get(mapFoerutsaettningQryState(state), id);

export const foerutsaettningQryAction =
  (id: BudgetPK): ThunkAction =>
  async (dispatch, getState) => {
    const webData = foerutsaettningQry.get(mapFoerutsaettningQryState(getState()), id);
    if (RemoteDataFunctions.isLoading(webData) || RemoteDataFunctions.isUpdating(webData)) {
      return;
    }
    // Call api and handle result
    try {
      if (RemoteDataFunctions.hasData(webData)) {
        dispatch(foerutsaettningQry.updating(webData.data));
      } else {
        dispatch(foerutsaettningQry.loading(id));
      }
      const result = await new ApiClient().SidedrawerNotiserQryApi.getBudgetFoerutsaettning({
        budgetFoerutsaettningDTOQry: { BudgetId: id.BudgetId },
      });
      dispatch(foerutsaettningQry.success(result));
    } catch (err) {
      dispatch(foerutsaettningQry.failure(id, await parseErrorResponse(err)));
    }
  };

export default foerutsaettningQry.reducer;
