/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SummaUnderliggandeStatusVO,
  SummaUnderliggandeStatusVOFromJSON,
  SummaUnderliggandeStatusVOFromJSONTyped,
  SummaUnderliggandeStatusVOToJSON,
} from './SummaUnderliggandeStatusVO';

/**
 *
 * @export
 * @interface SummaUnderliggande
 */
export interface SummaUnderliggande {
  /**
   *
   * @type {number}
   * @memberof SummaUnderliggande
   */
  NyckeltalId: number;
  /**
   *
   * @type {number}
   * @memberof SummaUnderliggande
   */
  Summa?: number | null;
  /**
   *
   * @type {SummaUnderliggandeStatusVO}
   * @memberof SummaUnderliggande
   */
  Status: SummaUnderliggandeStatusVO;
}

export function SummaUnderliggandeFromJSON(json: any): SummaUnderliggande {
  return SummaUnderliggandeFromJSONTyped(json, false);
}

export function SummaUnderliggandeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaUnderliggande {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalId: json['NyckeltalId'],
    Summa: !exists(json, 'Summa') ? undefined : json['Summa'],
    Status: SummaUnderliggandeStatusVOFromJSON(json['Status']),
  };
}

export function SummaUnderliggandeToJSON(value?: SummaUnderliggande | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalId: value.NyckeltalId,
    Summa: value.Summa,
    Status: SummaUnderliggandeStatusVOToJSON(value.Status),
  };
}
