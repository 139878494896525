/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  NyckeltalVaerdePK,
  NyckeltalVaerdePKFromJSON,
  NyckeltalVaerdePKFromJSONTyped,
  NyckeltalVaerdePKToJSON,
} from './NyckeltalVaerdePK';

/**
 *
 * @export
 * @interface NyckeltalVaerdeDTO
 */
export interface NyckeltalVaerdeDTO {
  /**
   *
   * @type {number}
   * @memberof NyckeltalVaerdeDTO
   */
  NyckeltalvaerdeId?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalVaerdeDTO
   */
  BudgetId?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalVaerdeDTO
   */
  BudgetnyckeltalLoepnummer?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalVaerdeDTO
   */
  Aarsvaerde?: number | null;
  /**
   *
   * @type {number}
   * @memberof NyckeltalVaerdeDTO
   */
  PeriodiseringsprofilId?: number | null;
  /**
   *
   * @type {NyckeltalVaerdePK}
   * @memberof NyckeltalVaerdeDTO
   */
  NyckeltalVaerdePK: NyckeltalVaerdePK;
}

export function NyckeltalVaerdeDTOFromJSON(json: any): NyckeltalVaerdeDTO {
  return NyckeltalVaerdeDTOFromJSONTyped(json, false);
}

export function NyckeltalVaerdeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NyckeltalVaerdeDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    NyckeltalvaerdeId: !exists(json, 'NyckeltalvaerdeId') ? undefined : json['NyckeltalvaerdeId'],
    BudgetId: !exists(json, 'BudgetId') ? undefined : json['BudgetId'],
    BudgetnyckeltalLoepnummer: !exists(json, 'BudgetnyckeltalLoepnummer')
      ? undefined
      : json['BudgetnyckeltalLoepnummer'],
    Aarsvaerde: !exists(json, 'Aarsvaerde') ? undefined : json['Aarsvaerde'],
    PeriodiseringsprofilId: !exists(json, 'PeriodiseringsprofilId') ? undefined : json['PeriodiseringsprofilId'],
    NyckeltalVaerdePK: NyckeltalVaerdePKFromJSON(json['NyckeltalVaerdePK']),
  };
}

export function NyckeltalVaerdeDTOToJSON(value?: NyckeltalVaerdeDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    NyckeltalvaerdeId: value.NyckeltalvaerdeId,
    BudgetId: value.BudgetId,
    BudgetnyckeltalLoepnummer: value.BudgetnyckeltalLoepnummer,
    Aarsvaerde: value.Aarsvaerde,
    PeriodiseringsprofilId: value.PeriodiseringsprofilId,
    NyckeltalVaerdePK: NyckeltalVaerdePKToJSON(value.NyckeltalVaerdePK),
  };
}
