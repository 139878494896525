import { combineReducers } from 'redux';
import createKommentarCmd from './cmdCreateKommentar';
import deleteKommentarCmd from './cmdDeleteKommentar';
import { kommentarQryReducer } from './qryKommentar';

export const kommentarReducer = combineReducers({
  qryCreateKommentar: createKommentarCmd,
  qryDeleteKommentar: deleteKommentarCmd,
  qryKommentar: kommentarQryReducer,
});
