/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  BudgetDTOQry,
  BudgetDTOQryFromJSON,
  BudgetDTOQryToJSON,
  BudgetListBudgetperiodWrapperDTO,
  BudgetListBudgetperiodWrapperDTOFromJSON,
  BudgetListBudgetperiodWrapperDTOToJSON,
  BudgetListDTOQry,
  BudgetListDTOQryFromJSON,
  BudgetListDTOQryToJSON,
  BudgetListWrapperDTO,
  BudgetListWrapperDTOFromJSON,
  BudgetListWrapperDTOToJSON,
  BudgetNyckeltalVaerdeListDTO,
  BudgetNyckeltalVaerdeListDTOFromJSON,
  BudgetNyckeltalVaerdeListDTOToJSON,
  BudgetNyckeltalVaerdeListDTOQry,
  BudgetNyckeltalVaerdeListDTOQryFromJSON,
  BudgetNyckeltalVaerdeListDTOQryToJSON,
  BudgetnyckeltalListDTOQry,
  BudgetnyckeltalListDTOQryFromJSON,
  BudgetnyckeltalListDTOQryToJSON,
  BudgetnyckeltalListWrapperDTO,
  BudgetnyckeltalListWrapperDTOFromJSON,
  BudgetnyckeltalListWrapperDTOToJSON,
  BudgetperiodDTO,
  BudgetperiodDTOFromJSON,
  BudgetperiodDTOToJSON,
  PeriodiseringsprofilListDTO,
  PeriodiseringsprofilListDTOFromJSON,
  PeriodiseringsprofilListDTOToJSON,
  PeriodiseringsprofilListDTOQry,
  PeriodiseringsprofilListDTOQryFromJSON,
  PeriodiseringsprofilListDTOQryToJSON,
  SummaUnderliggandeListDTO,
  SummaUnderliggandeListDTOFromJSON,
  SummaUnderliggandeListDTOToJSON,
  SummaUnderliggandeListDTOQry,
  SummaUnderliggandeListDTOQryFromJSON,
  SummaUnderliggandeListDTOQryToJSON,
  UnderliggandeEnheterDTO,
  UnderliggandeEnheterDTOFromJSON,
  UnderliggandeEnheterDTOToJSON,
  UnderliggandeEnheterVaerdeDTOQry,
  UnderliggandeEnheterVaerdeDTOQryFromJSON,
  UnderliggandeEnheterVaerdeDTOQryToJSON,
  UnderliggandeEnheterVaerdeListDTO,
  UnderliggandeEnheterVaerdeListDTOFromJSON,
  UnderliggandeEnheterVaerdeListDTOToJSON,
  UnderligganeEnheterDTOQry,
  UnderligganeEnheterDTOQryFromJSON,
  UnderligganeEnheterDTOQryToJSON,
} from '../models';

export interface GetBudgetRequest {
  budgetDTOQry?: BudgetDTOQry;
}

export interface GetBudgetListRequest {
  budgetListDTOQry?: BudgetListDTOQry;
}

export interface GetBudgetNyckeltalVaerdeListRequest {
  budgetNyckeltalVaerdeListDTOQry?: BudgetNyckeltalVaerdeListDTOQry;
}

export interface GetBudgetSummaUnderliggandeRequest {
  summaUnderliggandeListDTOQry?: SummaUnderliggandeListDTOQry;
}

export interface GetBudgetnyckeltalListRequest {
  budgetnyckeltalListDTOQry?: BudgetnyckeltalListDTOQry;
}

export interface GetPeriodiseringsprofilListRequest {
  periodiseringsprofilListDTOQry?: PeriodiseringsprofilListDTOQry;
}

export interface GetUnderliggandeEnheterRequest {
  underligganeEnheterDTOQry?: UnderligganeEnheterDTOQry;
}

export interface GetUnderliggandeEnheterVaerdeRequest {
  underliggandeEnheterVaerdeDTOQry?: UnderliggandeEnheterVaerdeDTOQry;
}

/**
 *
 */
export class BudgetQryApi extends runtime.BaseAPI {
  /**
   */
  async getBudgetRaw(
    requestParameters: GetBudgetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetListWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetBudget`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetDTOQryToJSON(requestParameters.budgetDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BudgetListWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBudget(
    requestParameters: GetBudgetRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetListWrapperDTO> {
    const response = await this.getBudgetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getBudgetListRaw(
    requestParameters: GetBudgetListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetListBudgetperiodWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetBudgetList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetListDTOQryToJSON(requestParameters.budgetListDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BudgetListBudgetperiodWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBudgetList(
    requestParameters: GetBudgetListRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetListBudgetperiodWrapperDTO> {
    const response = await this.getBudgetListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getBudgetNyckeltalVaerdeListRaw(
    requestParameters: GetBudgetNyckeltalVaerdeListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetNyckeltalVaerdeListDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetBudgetNyckeltalVaerdeList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetNyckeltalVaerdeListDTOQryToJSON(requestParameters.budgetNyckeltalVaerdeListDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BudgetNyckeltalVaerdeListDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBudgetNyckeltalVaerdeList(
    requestParameters: GetBudgetNyckeltalVaerdeListRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetNyckeltalVaerdeListDTO> {
    const response = await this.getBudgetNyckeltalVaerdeListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getBudgetSummaUnderliggandeRaw(
    requestParameters: GetBudgetSummaUnderliggandeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<SummaUnderliggandeListDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetBudgetSummaUnderliggande`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SummaUnderliggandeListDTOQryToJSON(requestParameters.summaUnderliggandeListDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => SummaUnderliggandeListDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBudgetSummaUnderliggande(
    requestParameters: GetBudgetSummaUnderliggandeRequest = {},
    initOverrides?: RequestInit
  ): Promise<SummaUnderliggandeListDTO> {
    const response = await this.getBudgetSummaUnderliggandeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getBudgetnyckeltalListRaw(
    requestParameters: GetBudgetnyckeltalListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<BudgetnyckeltalListWrapperDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetBudgetnyckeltalList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BudgetnyckeltalListDTOQryToJSON(requestParameters.budgetnyckeltalListDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => BudgetnyckeltalListWrapperDTOFromJSON(jsonValue));
  }

  /**
   */
  async getBudgetnyckeltalList(
    requestParameters: GetBudgetnyckeltalListRequest = {},
    initOverrides?: RequestInit
  ): Promise<BudgetnyckeltalListWrapperDTO> {
    const response = await this.getBudgetnyckeltalListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getBudgetperiodListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BudgetperiodDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetBudgetperiodList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(BudgetperiodDTOFromJSON));
  }

  /**
   */
  async getBudgetperiodList(initOverrides?: RequestInit): Promise<Array<BudgetperiodDTO>> {
    const response = await this.getBudgetperiodListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async getPeriodiseringsprofilListRaw(
    requestParameters: GetPeriodiseringsprofilListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PeriodiseringsprofilListDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetPeriodiseringsprofilList`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PeriodiseringsprofilListDTOQryToJSON(requestParameters.periodiseringsprofilListDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => PeriodiseringsprofilListDTOFromJSON(jsonValue));
  }

  /**
   */
  async getPeriodiseringsprofilList(
    requestParameters: GetPeriodiseringsprofilListRequest = {},
    initOverrides?: RequestInit
  ): Promise<PeriodiseringsprofilListDTO> {
    const response = await this.getPeriodiseringsprofilListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getUnderliggandeEnheterRaw(
    requestParameters: GetUnderliggandeEnheterRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UnderliggandeEnheterDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetUnderliggandeEnheter`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UnderligganeEnheterDTOQryToJSON(requestParameters.underligganeEnheterDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => UnderliggandeEnheterDTOFromJSON(jsonValue));
  }

  /**
   */
  async getUnderliggandeEnheter(
    requestParameters: GetUnderliggandeEnheterRequest = {},
    initOverrides?: RequestInit
  ): Promise<UnderliggandeEnheterDTO> {
    const response = await this.getUnderliggandeEnheterRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getUnderliggandeEnheterVaerdeRaw(
    requestParameters: GetUnderliggandeEnheterVaerdeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UnderliggandeEnheterVaerdeListDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json-patch+json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/BudgetQry/GetUnderliggandeEnheterVaerde`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UnderliggandeEnheterVaerdeDTOQryToJSON(requestParameters.underliggandeEnheterVaerdeDTOQry),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => UnderliggandeEnheterVaerdeListDTOFromJSON(jsonValue));
  }

  /**
   */
  async getUnderliggandeEnheterVaerde(
    requestParameters: GetUnderliggandeEnheterVaerdeRequest = {},
    initOverrides?: RequestInit
  ): Promise<UnderliggandeEnheterVaerdeListDTO> {
    const response = await this.getUnderliggandeEnheterVaerdeRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
