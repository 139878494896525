import { FbButton } from '@decernointernal/fb-interna-komponenter';
import { selectCurrentEnhet } from 'components/user/uiCurrentEnhet';
import { BudgetListDTO } from 'generated-models/budgetera';
import { useSelector } from 'react-redux';
import { JournalRoute } from 'store/location';
import { useAppDispatch } from 'store/store';

interface BudgetNameCellProps {
  BudgetList: BudgetListDTO;
}

export const BudgetNameCell: React.FC<BudgetNameCellProps> = ({ BudgetList }) => {
  const selectedEnhet = useSelector(selectCurrentEnhet());
  const dispatch = useAppDispatch();
  return (
    <div className="flex items-center w-full">
      {`${BudgetList.Namn}${BudgetList.AerNyEnhet ? ' (Ny)' : ''}${BudgetList.AerInaktivEnhet ? ' (Inaktiv)' : ''}${
        BudgetList.EnhetVO.PlaneradBudgetId ? ' (Planerad)' : ''
      }`}
      {selectedEnhet.currentEnhet?.KontorsgruppId && BudgetList.EnhetVO.KontorId && (
        <div className="ml-auto opacity-0 group-hover:opacity-100 transition-opacity">
          <FbButton
            text="Journal"
            type="secondary"
            size="small"
            className="h-5 text-xxs"
            onClick={e => {
              e.stopPropagation();
              dispatch(JournalRoute(BudgetList.EnhetVO));
            }}
          />
        </div>
      )}
    </div>
  );
};
