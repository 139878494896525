/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BudgetnyckeltalPK,
  BudgetnyckeltalPKFromJSON,
  BudgetnyckeltalPKFromJSONTyped,
  BudgetnyckeltalPKToJSON,
} from './BudgetnyckeltalPK';
import {
  NyckeltalvaerdeUtfallMaanadDTO,
  NyckeltalvaerdeUtfallMaanadDTOFromJSON,
  NyckeltalvaerdeUtfallMaanadDTOFromJSONTyped,
  NyckeltalvaerdeUtfallMaanadDTOToJSON,
} from './NyckeltalvaerdeUtfallMaanadDTO';

/**
 *
 * @export
 * @interface NyckeltalvaerdeUtfallMaanadWrapperDTO
 */
export interface NyckeltalvaerdeUtfallMaanadWrapperDTO {
  /**
   *
   * @type {BudgetnyckeltalPK}
   * @memberof NyckeltalvaerdeUtfallMaanadWrapperDTO
   */
  BudgetnyckeltalPK: BudgetnyckeltalPK;
  /**
   *
   * @type {Array<NyckeltalvaerdeUtfallMaanadDTO>}
   * @memberof NyckeltalvaerdeUtfallMaanadWrapperDTO
   */
  NyckeltalvaerdeUtfallMaanadDTOList: Array<NyckeltalvaerdeUtfallMaanadDTO>;
}

export function NyckeltalvaerdeUtfallMaanadWrapperDTOFromJSON(json: any): NyckeltalvaerdeUtfallMaanadWrapperDTO {
  return NyckeltalvaerdeUtfallMaanadWrapperDTOFromJSONTyped(json, false);
}

export function NyckeltalvaerdeUtfallMaanadWrapperDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NyckeltalvaerdeUtfallMaanadWrapperDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    BudgetnyckeltalPK: BudgetnyckeltalPKFromJSON(json['BudgetnyckeltalPK']),
    NyckeltalvaerdeUtfallMaanadDTOList: (json['NyckeltalvaerdeUtfallMaanadDTOList'] as Array<any>).map(
      NyckeltalvaerdeUtfallMaanadDTOFromJSON
    ),
  };
}

export function NyckeltalvaerdeUtfallMaanadWrapperDTOToJSON(value?: NyckeltalvaerdeUtfallMaanadWrapperDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    BudgetnyckeltalPK: BudgetnyckeltalPKToJSON(value.BudgetnyckeltalPK),
    NyckeltalvaerdeUtfallMaanadDTOList: (value.NyckeltalvaerdeUtfallMaanadDTOList as Array<any>).map(
      NyckeltalvaerdeUtfallMaanadDTOToJSON
    ),
  };
}
