/* tslint:disable */
/* eslint-disable */
/**
 * Budgetera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import { UserHeaderDTO, UserHeaderDTOFromJSON, UserHeaderDTOToJSON } from '../models';

/**
 *
 */
export class UserQryApi extends runtime.BaseAPI {
  /**
   */
  async getUserHeaderRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserHeaderDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('oauth2', [
        'api://baacd2b2-8f65-4b55-ac89-b28d5cad58e2/access_as_user',
      ]);
    }

    const response = await this.request(
      {
        path: `/api/QryContext/UserQry/GetUserHeader`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => UserHeaderDTOFromJSON(jsonValue));
  }

  /**
   */
  async getUserHeader(initOverrides?: RequestInit): Promise<UserHeaderDTO> {
    const response = await this.getUserHeaderRaw(initOverrides);
    return await response.value();
  }
}
